import {STORAGE_URL} from "./config";
import React from "react";
import avatarImage from "../src/assets/profile5.png"

class Helper {


    static highlightJobTitle = (job_title, matchedKeywords) => {
        if (!job_title || !matchedKeywords || matchedKeywords.length === 0) {
            return job_title;
        }

        let highlightedTitle = job_title;

        // Sort matched keywords by length in descending order
        const sortedKeywords = matchedKeywords.sort((a, b) => b.length - a.length);

        sortedKeywords.forEach(keyword => {
            const keywordLowercase = keyword.toLowerCase();
            const titleLowercase = highlightedTitle.toLowerCase();

            // Check for complete word match and highlight it
            if (titleLowercase.includes(keywordLowercase)) {
                const startIdx = titleLowercase.indexOf(keywordLowercase);
                const endIdx = startIdx + keywordLowercase.length;
                const matchedWord = highlightedTitle.substring(startIdx, endIdx);
                highlightedTitle = highlightedTitle.replace(new RegExp(`\\b${this.escapeRegex(matchedWord)}\\b`, 'gi'), `<span class="highlight">${matchedWord}</span>`);
            } else {
                // Handle partial matches using a global regular expression
                const partialRegex = new RegExp(`(?<!\\w)${this.escapeRegex(keyword)}\\w*\\b`, 'gi');
                highlightedTitle = highlightedTitle.replace(partialRegex, '<span class="highlight">$&</span>');
            }
        });

        return highlightedTitle;
    };

// Helper function to escape special characters in a string for regex
    static escapeRegex = (string) => {
        return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    };



    static highlightSkills = (skills, matchedKeywords) => {
        if (!skills || !matchedKeywords || matchedKeywords.length === 0) {
            return skills;
        }

        let highlightedSkills = skills;

        // Sort matched keywords by length in descending order
        const sortedKeywords = matchedKeywords.sort((a, b) => b.length - a.length);

        sortedKeywords.forEach(keyword => {
            const keywordLowercase = keyword.toLowerCase();
            const skillsLowercase = highlightedSkills.toLowerCase();

            // Check for complete word match and highlight it
            if (skillsLowercase.includes(keywordLowercase)) {
                const startIdx = skillsLowercase.indexOf(keywordLowercase);
                const endIdx = startIdx + keywordLowercase.length;
                const matchedWord = highlightedSkills.substring(startIdx, endIdx);
                highlightedSkills = highlightedSkills.replace(new RegExp(`\\b${this.escapeRegex(matchedWord)}\\b`, 'gi'), `<span class="highlight">${matchedWord}</span>`);
            } else {
                // Handle partial matches using a global regular expression
                const partialRegex = new RegExp(`(?<!\\w)${this.escapeRegex(keyword)}\\w*\\b`, 'gi');
                highlightedSkills = highlightedSkills.replace(partialRegex, '<span class="highlight">$&</span>');
            }
        });

        return highlightedSkills;
    };



    static highlightLocation = (location, matchedKeywords) => {
        if (!location || !matchedKeywords || matchedKeywords.length === 0) {
            return location;
        }

        let highlightedLocation = location;

        // Sort matched keywords by length in descending order
        const sortedKeywords = matchedKeywords.sort((a, b) => b.length - a.length);

        sortedKeywords.forEach(keyword => {
            const keywordLowercase = keyword.toLowerCase();
            const locationLowercase = highlightedLocation.toLowerCase();

            // Check for complete word match and highlight it
            if (locationLowercase.includes(keywordLowercase)) {
                const startIdx = locationLowercase.indexOf(keywordLowercase);
                const endIdx = startIdx + keywordLowercase.length;
                const matchedWord = highlightedLocation.substring(startIdx, endIdx);
                highlightedLocation = highlightedLocation.replace(new RegExp(`\\b${this.escapeRegex(matchedWord)}\\b`, 'gi'), `<span class="highlight">${matchedWord}</span>`);
            } else {
                // Handle partial matches using a global regular expression
                const partialRegex = new RegExp(`(?<!\\w)${this.escapeRegex(keyword)}\\w*\\b`, 'gi');
                highlightedLocation = highlightedLocation.replace(partialRegex, '<span class="highlight">$&</span>');
            }
        });

        return highlightedLocation;
    };


    static getTimeSinceShared = (timestamp,serverTime) => {
        const currentDate = new Date(serverTime);
        const sharedDate = new Date(timestamp);
        const timeDiffMillis = currentDate - sharedDate;

        const seconds = Math.floor(timeDiffMillis / 1000);
        if (seconds < 60) {
            return `${seconds} seconds ago`;
        }

        const minutes = Math.floor(seconds / 60);
        if (minutes < 60) {
            return `${minutes} minutes ago`;
        }

        const hours = Math.floor(minutes / 60);
        if (hours < 24) {
            return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
        }

        const days = Math.floor(hours / 24);
        if (days < 7) {
            return `${days} ${days === 1 ? 'day' : 'days'} ago`;
        }

        const weeks = Math.floor(days / 7);
        if (weeks < 52) {
            return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`;
        }

        const years = Math.floor(weeks / 52);
        return `${years} ${years === 1 ? 'year' : 'years'} ago`;
    };

    static highlightText(inputText, matchedKeywords) {
        if (!inputText || !matchedKeywords || matchedKeywords.length === 0) {
            return inputText;
        }

        let highlightedText = inputText;

        matchedKeywords.forEach(keyword => {
            const keywordLowercase = keyword.toLowerCase();
            const textLowercase = highlightedText.toLowerCase();

            function escapeRegex(keyword) {
                return keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

            }

            // Check for complete word match and highlight it
            if (textLowercase.includes(keywordLowercase)) {
                const startIdx = textLowercase.indexOf(keywordLowercase);
                const endIdx = startIdx + keywordLowercase.length;
                const matchedWord = highlightedText.substring(startIdx, endIdx);
                highlightedText = highlightedText.replace(matchedWord, `<span class="highlight">${matchedWord}</span>`);
            } else {
                // Handle partial matches
                const partialRegex = new RegExp(`\\b${escapeRegex(keyword)}\\b`, 'gi');
                highlightedText = highlightedText.replace(partialRegex, '<span class="highlight">$&</span>');
            }
        });

        return highlightedText;
    };


    static changeDateFormat(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };


    static profile_log(user) {
        let img;
        if(user?.userable_type=== "App\\Models\\Employer") {
            if (user.current_employee_logo) {
                img = (
                    <img
                        src={STORAGE_URL + user.current_employee_logo}
                        className="generic-image-size"
                        alt="Logo"

                    />
                );
            } else if (user.current_employee) {
                const letters = user.current_employee.split(" ").slice(0, 2).map(word => word[0]).join('');
                img = <div className="div_logo"><h4>{letters}</h4></div>;
            }
            else {
                img = (
                    <img
                        src={avatarImage}
                        className="generic-image-size"
                        alt="Logo"

                    />
                );
            }
        }
        else if (user?.image) {
            img = (
                <img
                    src={STORAGE_URL + user?.image}
                    className="generic-image-size"
                    alt="Logo"

                />
            );
        } else if (user?.firstname || user?.lastname) {
            const letters = (user?.firstname ? user?.firstname[0] : '') + (user.lastname ? user.lastname[0] : '');
            img = <div className="div_logo"><h4>{letters}</h4></div>;
        } else {
            img = (
                <img
                    src={avatarImage}
                    className="generic-image-size"
                    alt="Logo"

                />
            );
        }

        return img;
    }

    static notification_log(user){
        let img
        if(!user?.current_employee_logo){

            if(!user?.image){

                if(user?.current_employee){

                    let words = user?.current_employee?.split(" ");
                    let letters = words?.slice(0, 2).map(word => word[0]).join('');
                    img = (
                        <div className="div_notf_logo"><h4>{letters}</h4></div>
                    )
                }else if(user?.firstname && user?.lastname){
                    let letters= user?.firstname? user?.firstname?.slice(0, 1): ''
                    letters +=user?.lastname?  user?.lastname.slice(0, 1): ''
                    img = (<div className="div_notf_logo"><h4>{letters}</h4></div>)
                }
                else{
                    img = (
                        <img
                            src={avatarImage}
                            className="generic-image-size"
                            alt="Logo"

                        />
                    );
                }
            }
            else{
                img = (<img
                        src={STORAGE_URL+user.image}
                        className="user_logo_img"
                        alt="Logo" />
                )

            }
        }

        else{
            img = (<img
                src={STORAGE_URL+user?.current_employee_logo}
                className="user_logo_img"
                alt="Logo" />)
        }

        return img
    }
    static current_user_log(user){
        let img
        if(user?.current_employee_logo === null || user === null || user?.current_employee_logo === "" ){

            if(user?.image === "" || user === null || user?.image === null){

                if(user?.current_employee !== "" ){

                    let words = user?.current_employee?.split(" ");
                    let letters = words?.slice(0, 2).map(word => word[0]).join('');
                    img = (
                        <div className="div_user_logo"><h4>{letters}</h4></div>
                    )
                }else if((user?.firstname !== '' && user?.firstname !== null && user?.firstname !== undefined ) ||
                    (user?.lastname !== '' && user?.lastname !== null && user?.lastname !== undefined ) ){
                    let letters= user?.firstname? user?.firstname?.slice(0, 1): ''
                    letters +=user?.lastname?  user?.lastname.slice(0, 1): ''
                    img = (<div className="div_user_logo"><h4>{letters}</h4></div>)
                }
                else{
                    img = (<div className="div_user_logo"><h4>U</h4></div>)
                }
            }
            else{
                img = (<img
                        src={STORAGE_URL+user.image}
                        className="currentuser_logo_img"
                        alt="Logo" />
                )

            }
        }
        else{
            img = (<img
                src={STORAGE_URL+user?.current_employee_logo}
                className="currentuser_logo_img"
                alt="Logo" />)
        }
        return img
    }

    static current_active(showMenuBar, user){
        let img
        if(user?.image === "" || user === null || user?.image === null){

          if((user?.firstname !== '' && user?.firstname !== null && user?.firstname !== undefined ) ||
                (user?.lastname !== '' && user?.lastname !== null && user?.lastname !== undefined ) ){
                let letters= user?.firstname? user?.firstname?.slice(0, 1): ''
                letters +=user?.lastname?  user?.lastname.slice(0, 1): ''
                img = (
                    <div onClick={showMenuBar} className="online_user_container">
                        <div className="online_user_logo">
                            <h4>{letters}</h4>
                        </div>
                        <span className="active_dot"></span>
                    </div>
                        )
            }
            else{
                img = (
                    <div onClick={showMenuBar} className="online_user_container">
                        <div className="online_user_logo">
                            <h4>U</h4>
                        </div>
                        <span className="active_dot"></span>
                    </div>
                        )
            }
        }
        else{
            img = (
                <div onClick={showMenuBar} className="online_user_container">
                    <img
                    src={STORAGE_URL+user.image}
                    className="online_user_logo"
                    alt="Logo" />
                    <span className="active_dot"></span>
                </div>
            )

        }
        return img;
    }

}

export default Helper;