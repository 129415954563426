import React, {useState} from "react";
import './assets/css/header.css';
import '../src/assets/css/login.css';
import logoImage from '../src/assets/logo4.svg';
import emailImage from '../src/assets/emailplaceholder.svg';
import watchPasswordImage from '../src/assets/watchpassword.svg';
import setPasswordImage from '../src/assets/setpassword.svg';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import BASE_URL from "./config";

import style from '../src/assets/css/LoadingComponent.module.css';
import {useMediaQuery} from "react-responsive";
import {IoEyeOffOutline, IoEyeOutline} from "react-icons/io5";


function JobSeekerSignUp() {

    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const signInClick = () => {
        navigate("/employer-login");
    };
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checked, setChecked] = useState(false);

    const [error, setError] = useState([]);
    const [success, setSuccess] = useState([]);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const passwordVisible = () => {
        console.log(isPasswordVisible)
        setIsPasswordVisible(!isPasswordVisible);
    }
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const ConfirmPasswordVisible = () => {
        console.log(isConfirmPasswordVisible)
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    }
    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSignUp();
        }
    };
    const handleSignUp = (e) => {
        setError([]);
        if(e) {
            e.preventDefault();
        }
        setLoading(true);
        setIsPasswordVisible(false);
        setIsConfirmPasswordVisible(false);
        if (password !== confirmPassword) {
            setError({...error, confirmPassword: 'Password does not match'});
            setLoading(false);
            return;
        }
        if (!checked) {
            setError([]);
            setError({...error, checked: 'Please check the "I am not a robot" box before submitting.'});
            setLoading(false);
            return;
        }
        const payload = {
            email: email,
            password: password
        };

        axios.post(`${BASE_URL}/job_seeker_register`, payload)
            .then(response => {
                setLoading(false);
                console.log(response);
                if (response.status === 200) {
                    setError([]);
                    setSuccess({...success, successMessage: 'Check your email to verify account'});
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                    setChecked('');

                    localStorage.setItem('login', 0);

                    //navigate("/");
                } else {
                    setError(response.data);
                }
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
                setError(error.response.data);
            });

    }
    const handleLogoClick = () => {
        navigate("/employer-login");

    }
    return (
            <div className="mainbg">

                    <div className="form">
                        {success.successMessage && (
                            <span>
                                <h4  style={{ display: 'inline-block', backgroundColor: '#60BF9F', color: 'white', padding: '10px', width: '100%', height: '100%',fontSize:'20px' }}>{success.successMessage}</h4>
                              </span>
                        )}

                            <div style={{width: '100%'}}>
                                <div className='d-flex align-items-center mb-4 justify-content-center'>
                                    <img onClick={handleLogoClick} className='' src={logoImage} alt="Logo"/>
                                </div>
                                <br/>
                                <br />
                                {/*<h2>Sign Up <span style={{ fontSize: "0.8em" }}>As Job Seeker</span></h2>*/}

                                {/*<p>Or get registration via an other email</p>*/}
                                <div className="emailinfo">
                                    <img src={emailImage} alt=""/>
                                    <input type="text" placeholder="Email" value={email}
                                           onChange={e => setEmail(e.target.value)} onKeyPress={handleKeyPress} required/>

                                </div>

                                {error.email && <span className="text-danger">{error.email}</span>}

                                <div className="emailinfo passwordinfo">
                                    <img src={setPasswordImage} alt=""/>
                                    <input type={isPasswordVisible ? "text" : "password"} placeholder="Set Password"
                                           value={password}
                                           onChange={e => setPassword(e.target.value)} onKeyPress={handleKeyPress} required/>

                                    <div className="eye-icon-container" onClick={passwordVisible}>
                                        {isPasswordVisible ? (
                                            <IoEyeOutline className="eye-icon" size={24} />
                                        ) : (
                                            <IoEyeOffOutline className="eye-icon" size={24} />
                                        )}
                                    </div>                                </div>
                                {error.password && <span className="text-danger">{error.password}</span>}

                                {/*--------------------Confirm Password ----------*/}
                                <div className="emailinfo passwordinfo">
                                    <img src={setPasswordImage} alt=""/>
                                    <input type={isConfirmPasswordVisible ? "text" : "password"}
                                           placeholder="Confirm Password" value={confirmPassword}
                                           onChange={e => setConfirmPassword(e.target.value)} onKeyPress={handleKeyPress} required/>
                                    <div className="eye-icon-container" onClick={ConfirmPasswordVisible}>
                                        {isConfirmPasswordVisible ? (
                                            <IoEyeOutline className="eye-icon" size={24} />
                                        ) : (
                                            <IoEyeOffOutline className="eye-icon" size={24} />
                                        )}
                                    </div>                                </div>
                                {error.confirmPassword && <span className="text-danger">{error.confirmPassword}</span>}


                                {/*------------------------I am not Rebort --------------------*/}
                                <div className="formforget">
                                    <div>
                                        <input type="checkbox" id="remember" checked={checked}  onChange={() => setChecked(!checked)} name="remember"/>
                                        <label className="checkbox-label"> I am not a robot</label><br/>
                                    </div>
                                    <p><a style={{color: '#bbbbbb'}} href="javascript:void(0)"> Captcha</a></p>

                                </div>
                                {error.checked && <span className="text-danger">{error.checked}</span>}


                                {/*--------------------------------Sign Up BTN ------------------*/}
                                <div className="formbtn">
                                    <button onClick={handleSignUp}>Sign Up</button>
                                    {loading && (
                                        <div className={style.loadingOverlay}>
                                            <div className={style.loadingSpinner}></div>
                                        </div>
                                    )}
                                    <p className='text-center'>
                                        Already have an account? Please
                                        <span>
                                            <a href="javascript:void(0)"
                                               onClick={signInClick}>
                                                {" "}sign in{" "}
                                            </a>
                                        </span>
                                        here!
                                    </p>
                                </div>

                            </div>

                    </div>

                </div>
           );
}

export default JobSeekerSignUp;
