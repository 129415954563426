import React from "react";

import Signup from "./SignUp";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import { BrowserRouter, Route, Routes, useHistory } from "react-router-dom";
import EmployerDashboard from "./EmployerDashboard";
import PostAJob from "./PostAJob";
import EditJob from "./EditJob";
import PostAJobPreview from "./postAJobPreview";
import SeachResume from "./SeachResume";
import JobSeekerDashboard from "./JobSeekerDashboard";
import JobSeekerLogin from "./job_seeker_login";
import JobSeekerSignUp from "./job_seeker_signup";
import CreateResume from "./createResume";
import EditResume from "./editResume";
import Alljobs from "./Alljobs";
import Alljobv2 from "./Alljobv2";
import EmployerDashboard_v1 from "./EmployerDashboard_v1";
import EmployeeMobileHeader from "./EmployeeMobileHeader";
import CreateResumev1 from "./PostResume";
import CreatResumepreview from "./PostResumePreview";
import LandingPage from "./LandingPage";
import PostResume from "./PostResume";
import VideoRecorder from "./VideoRecorder";
import CreateProfile from "./createprofile";
import ResumeDetailsScreen from "./ResumeDetailsScreen";
import ProfileView from "./ProfileView";
import LoginAttempt from "./LoginAttempt";
import SearchJob from "./SearchJob";
import JobApplication from "./jobapplications";
import FavoriteResumes from "./FavoriteResumes";
import FavoriteJobs from "./FavoriteJobs";
import JobsApplied from "./JobsApplied";
import FollowersFollowingScreen from "./FollowersFollowingScreen";
import MyProfileView from "./MyProfileView";
import AllNotification from "./AllNotification";
import Post from "./Post";
import PdfTextHighlighter from "./PdfTextHighlighter";
import SamplePdf from "./SamplePdf";
import ReqForSubscription from "./Views/ReqForSubscription";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/employer-login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/employer_dashboard1" element={<EmployerDashboard />} />
        <Route path="/employer_dashboard" element={<EmployerDashboard_v1 />} />
        <Route path="/job_seeker_dashboard" element={<JobSeekerDashboard />} />

        <Route path="/post_a_job" element={<PostAJob />} />
        <Route path="/edit_a_job" element={<EditJob />} />
        <Route path="/post_a_job_preview" element={<PostAJobPreview />} />
        <Route path="/search_resume" element={<SeachResume />} />

        <Route path="jobseeker_login" element={<JobSeekerLogin />} />
        <Route path="jobseeker_signup" element={<JobSeekerSignUp />} />

        <Route path="create_resume" element={<CreateResume />} />
        <Route path="edit_resume" element={<EditResume />} />
        <Route path="create_profile" element={<CreateProfile />} />
        <Route path="alljobs" element={<Alljobs />} />
        <Route path="alljobv2" element={<Alljobv2 />} />
        <Route path="employeemobileheader" element={<EmployeeMobileHeader />} />
        <Route path="/post-resume" element={<PostResume />} />
        <Route path="/post-resume-preview" element={<CreatResumepreview />} />
        <Route path="/resumes" element={<ResumeDetailsScreen />} />

        <Route path="/" element={<LandingPage />} />
        <Route path="/vr" element={<VideoRecorder />} />
        <Route path="/profile-view" element={<ProfileView />} />
        <Route path="/my-profile-view" element={<MyProfileView />} />

        <Route path="/login-attempt" element={<LoginAttempt />} />
        <Route path="/search_job" element={<SearchJob />} />
        <Route path="/jobapplications" element={<JobApplication />} />
        <Route path="/fav-resumes" element={<FavoriteResumes />} />
        <Route path="/fav-jobs" element={<FavoriteJobs />} />
        <Route path="/applied-jobs" element={<JobsApplied />} />

        <Route path="/follow" element={<FollowersFollowingScreen />} />
        <Route path="/AllNotifications" element={<AllNotification />} />
        <Route path="/Post" element={<Post />} />

        <Route path="/pdf" element={<SamplePdf />} />
        <Route path={"/request-for-subscription" } element={<ReqForSubscription />} />



      </Routes>
    </BrowserRouter>
  );
}
export default App;
