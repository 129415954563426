import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "./slices/UserSlice";
import employerData from "./slices/EmployerData";
import jobSeekerData from "./slices/JobSeekerData";
const store = configureStore({
  reducer: {
    user: UserSlice,
    employee: employerData,
    jobSeeker: jobSeekerData
  },
});
export default store;
