import {AiOutlineSearch} from "react-icons/ai";
import {BsBriefcase, BsHeart, BsPeople} from "react-icons/bs";
import {TbBellRinging2} from "react-icons/tb";
import {CgProfile} from "react-icons/cg";
import {AiOutlineLogout} from "react-icons/ai";
import {Dropdown, Modal} from "react-bootstrap";
import headerIcone6 from "../../assets/searchresumepro.svg";
import React, {useEffect, useState} from "react";
import Helper from "../../Helper";
import {CustomModalStyle} from "../CustomModalStyle";
import { useNavigate } from "react-router-dom";
import {useNotificationHandler} from "../../hooks/useNotificationHandler";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
export const MainHeader = ({
                               viewProfileClick,
                               signInClick,
                               showJobSearchHandler,
                               handleProfileClick,
                               showFavoriteHandler,
                               viewAllAppliedJobsHandler,
                               uploadAResume,
                               resumeStatus,
                               jobSeach,
                               variables,
                               postAJob,
                               notifications, // Pass notifications as a prop
                               serverTime

                           })=>{
    const [showNotifications, setShowNotifications] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0)
    const navigate = useNavigate();
    const { RedirectNotification } = useNotificationHandler();
    useEffect(()=>{
        let count = 0
        notifications?.map((notif)=>{
            if(notif?.read === 0){
                count++;
            }
        })

        setUnreadCount(count)
    },[notifications])

    useEffect(()=>{},[unreadCount])
    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const handleClickNotification = (notification)=>{

        RedirectNotification(notification, setUnreadCount, unreadCount)
    }

    return(
        <div className="col-lg-8 gap-3 d-flex justify-content-end align-items-center">
            <div className="postbtn d-flex gap-3 justify-content-end">

                {variables?.seeker?(  <button
                    type="submit"
                    className="resume-ser"
                    onClick={uploadAResume}

                >
                    {" "}
                    {!resumeStatus ? "UPLOAD A RESUME" : "UPDATE YOUR RESUME"}{" "}
                </button>):(<button type="submit" className="resume-ser" onClick={postAJob}> POST A JOB </button>)
                }

                <button type="submit" className="resume-ser2" onClick={showJobSearchHandler}>
                    <a href="javascript:void(0)" style={{textTransform:"uppercase"}}>
                        {" "}
                        {variables?.search}
                    </a>
                </button>
            </div>
            <div className="header-icon d-flex gap-3 justify-content-end align-items-center">
                <a href="javascript:void(0)" onClick={showJobSearchHandler}>
                    <AiOutlineSearch title={variables?.search} style={{color:'#545454',cursor:"pointer"}} size={24}></AiOutlineSearch>
                </a>
                <a href="javascript:void(0)" onClick={handleProfileClick}>
                    <BsPeople title={variables?.edit_profile} style={{color:'#545454',cursor:"pointer"}} size={24}></BsPeople>
                </a>
                <a href="javascript:void(0)" onClick={showFavoriteHandler}>
                    <BsHeart title={variables?.favorite} style={{color:'#545454',cursor:"pointer"}} size={23}> </BsHeart>
                </a>
                <a href="javascript:void(0)" onClick={viewAllAppliedJobsHandler} >
                    <BsBriefcase title={variables?.jobs} style={{color:'#545454',cursor:"pointer"}} size={24} ></BsBriefcase>
                </a>

                <Dropdown>
                    <Dropdown.Toggle
                        style={{
                            backgroundColor: "transparent",
                            border: "transparent",
                            padding: "0px",
                        }}
                    >

                        <div className="icon-container">
                            <TbBellRinging2 title={variables?.notifications} style={{color:'#545454',cursor:"pointer"}} size={24}></TbBellRinging2>
                        {unreadCount > 0 &&(
                            <small className="number-badge" dangerouslySetInnerHTML={{ __html: unreadCount > 99 ? '99<sup>+</sup>' : unreadCount }}></small>

                        )}
                        </div>
                    </Dropdown.Toggle>
                    {notifications.length > 0 &&(
                        <Dropdown.Menu>
                            {notifications?.slice(0, 6)?.map((notification) => (
                                <>
                                    <Dropdown.Item
                                        key={notification.id}
                                        className={notification?.read === 0? 'notification_content_unread':'notification_content_read'}
                                        onClick={()=>{handleClickNotification(notification)}}
                                    >
                                        <div className='d-flex gap-2 py-1 align-items-center'>
                                            {Helper?.notification_log(notification?.user)}
                                            <div>
                                                <p> {notification.content}</p>
                                                <small>{Helper.getTimeSinceShared(notification?.updated_at, serverTime)}</small>
                                            </div>
                                        </div>

                                    </Dropdown.Item>
                                    <Dropdown.Divider style={{margin: '0'}}   key={notification.id} />
                                </>
                            ))}
                            <Dropdown.Item>
                                <p
                                    className="text-center text-primary"
                                    onClick={()=>navigate("/AllNotifications")}
                                >View All</p>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    )}

                </Dropdown>

            </div>
            <Dropdown>
                <Dropdown.Toggle
                    style={{
                        backgroundColor: "transparent",
                        border: "transparent",
                        padding: "0px",
                    }}
                >
                    <img
                        style={{ width: "35px", marginRight: "0px" }}
                        src={headerIcone6}
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ zIndex: 9999 }}>
                    <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={viewProfileClick}
                    >
                        <div className="d-flex gap-4">
                            <CgProfile className="icon_color" size={24}></CgProfile>
                            <p>{variables?.view_profile}</p>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={signInClick}
                    >
                        <div className="d-flex gap-4">
                            <AiOutlineLogout className="icon_color" size={24}></AiOutlineLogout>
                            <p>{variables?.logout}</p>
                        </div>

                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}


export const MiniHeader = ({
                               viewProfileClick,
                               showNotifcation,
                               setShowNotifcation,
                               signInClick,
                               showJobSearchHandler,
                               handleProfileClick,
                               showFavoriteHandler,
                               viewAllAppliedJobsHandler,
                               uploadAResume,
                               resumeStatus,
                               jobSeach,
                               variables,
                               postAJob,
                               notifications,
                               serverTime
                           })=>{


    const [unreadCount, setUnreadCount] = useState(0)
    const navigate = useNavigate();
    const { RedirectNotification } = useNotificationHandler();

    // useEffect(()=>{
    //     let count = 0
    //     notifications?.map((notif)=>{
    //         if(notif?.read === 0){
    //             count++;
    //         }
    //     })
    //
    //     setUnreadCount(count)
    // },[notifications])

    // useEffect(()=>{},[unreadCount])

    const showNoficationModal = ()=>{
        setShowNotifcation(!showNotifcation)
    }
    const closeNotificationModal = ()=>{
        setShowNotifcation(false)
    }
    const handleClickNotification = (notification)=>{

        RedirectNotification(notification, setUnreadCount, unreadCount)
    }

    return(
        <div className="col-lg-8 gap-3 d-flex justify-content-end align-items-center">
            <div className="postbtn d-flex gap-3 justify-content-end">

                {variables?.seeker?(  <button
                    type="submit"
                    className="resume-ser"
                    onClick={uploadAResume}
                >
                    {" "}
                    {!resumeStatus ? "UPLOAD A RESUME" : "UPDATE YOUR RESUME"}{" "}
                </button>):(<button type="submit" className="resume-ser" onClick={postAJob}> POST A JOB </button>)}

                <button type="submit" className="resume-ser2" onClick={jobSeach}>
                    <a href="javascript:void(0)" style={{textTransform:"uppercase"}}>
                        {" "}
                        {variables?.search}
                    </a>
                </button>
            </div>

            <Dropdown>
                <Dropdown.Toggle
                    style={{
                        backgroundColor: "transparent",
                        border: "transparent",
                        padding: "0px",
                    }}
                >
                    <img
                        style={{ width: "35px", marginRight: "0px" }}
                        src={headerIcone6}
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ zIndex: 9999, width: '200px', marginRight:0 }}>
                    <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={showJobSearchHandler}
                    >
                        <div className="d-flex gap-4" >
                            <AiOutlineSearch className="icon_color" size={24}></AiOutlineSearch>
                            <p>{variables?.search}</p>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={handleProfileClick}
                    >
                        <div className="d-flex gap-4" >
                             <BsPeople className="icon_color" size={24}></BsPeople>
                            <p>{variables?.edit_profile}</p>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={showFavoriteHandler}
                    >
                        <div className="d-flex gap-4" >
                            <BsHeart className="icon_color"size={23}> </BsHeart>

                            <p>{variables?.favorite}</p>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={viewAllAppliedJobsHandler}
                    >


                        <div className="d-flex gap-4" >
                            <BsBriefcase className="icon_color" size={24} ></BsBriefcase>

                            <p>{variables?.jobs}</p>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={showNoficationModal}
                    >
                        <div className="d-flex gap-4">
                            <div className="icon-container">
                               <TbBellRinging2 className="icon_color" size={24}></TbBellRinging2>
                                {unreadCount > 0 &&(
                                    <small className="number-badge" dangerouslySetInnerHTML={{ __html: unreadCount > 99 ? '99<sup>+</sup>' : unreadCount }}></small>

                                )}
                            </div>
                            <p>{variables?.notifications}</p>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={viewProfileClick}
                    >
                        <div className="d-flex gap-4"  >
                            <CgProfile className="icon_color" size={24}></CgProfile>
                            <p>{variables?.view_profile}</p>
                        </div>

                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        href="javascript:void(0)"
                        onClick={signInClick}
                    >
                        <div className="d-flex gap-4" >
                            <AiOutlineLogout className="icon_color" size={24}></AiOutlineLogout>
                            <p>{variables?.logout}</p>
                        </div>

                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Modal show={showNotifcation} onHide={closeNotificationModal} className="modal right fade">
                <Modal.Header closeButton>
                    <Modal.Title>NOTIFICATIONS</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 modal-body-scrollable1" >

                        {notifications.length > 0 &&(
                            <>
                                {notifications?.slice(0, 6)?.map((notification) => (
                                    <>
                                        <div key={notification.id}
                                             className={`${notification?.read === 0?
                                            'notification_content_unread':'notification_content_read'} notification_hover py-1 px-2`}
                                             onClick={()=>{handleClickNotification(notification)}}
                                        >
                                            <div className='d-flex gap-2 py-1 align-items-center'>
                                                {Helper?.notification_log(notification?.user)}
                                                <div>
                                                    <p className='m-0'> {notification.content}</p>
                                                    <small>{Helper.getTimeSinceShared(notification?.updated_at, serverTime)}</small>
                                                </div>
                                            </div>

                                        </div>
                                        <hr className='m-0' key={notification.id} />
                                    </>
                                ))}
                                    <p
                                        className="text-center notification_hover  text-primary m-0 py-2"
                                        onClick={()=>navigate("/AllNotifications")}
                                    >View All</p>

                            </>
                        )}


                </Modal.Body>

            </Modal>

            <CustomModalStyle />
        </div>
    )
}

export const MobileHeader = ({
                                 viewProfileClick,
                                 showNotifcation,
                                 setShowNotifcation,
                                 signInClick,
                                 showJobSearchHandler,
                                 handleProfileClick,
                                 showFavoriteHandler,
                                 viewAllAppliedJobsHandler,
                                 variables,
                                 notifications,
                                 serverTime

                             })=>{

    const [showSideBar, setShowSideBar] = useState(false)

    const [unreadCount, setUnreadCount] = useState(0)
    const navigate = useNavigate();
    const { RedirectNotification } = useNotificationHandler();

    const location = useLocation();
    const currentUrl = location.pathname;
    const current_user = useSelector((state) => {
        return state?.user?.user;
    });

    useEffect(()=>{
        let count = 0
        notifications?.map((notif)=>{
            if(notif?.read === 0){
                count++;
            }
        })

        setUnreadCount(count)
    },[notifications])

    useEffect(()=>{},[unreadCount])

    const showMenuBar = ()=>{
        setShowSideBar(!showSideBar)
    }
    const closeModal = ()=>{
        setShowSideBar(false)
    }

    const showNoficationModal = ()=>{
        setShowNotifcation(!showNotifcation)
        setShowSideBar(false)
    }
    const closeNotificationModal = ()=>{
        setShowNotifcation(false)
    }
    const handleClickNotification = (notification)=>{

        RedirectNotification(notification, setUnreadCount, unreadCount)
    }


    return (
        <>
            {/*<img*/}
            {/*    onClick={showMenuBar}*/}
            {/*    style={{ width: "35px", marginRight: "0px", cursor:'pointer' }}*/}
            {/*    src={headerIcone6}*/}
            {/*/>*/}
            {Helper.current_active(showMenuBar, current_user)}
        <Modal show={showSideBar} onHide={closeModal} className="modal right fade customizeModal">
            <Modal.Header closeButton>
                <Modal.Title>REZMAZE</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-scrollable1">
                <div className="mobileMenu">
                    {/*<div className="menu_content" onClick={showJobSearchHandler}>*/}

                    {/*    <AiOutlineSearch className="icon_color" size={24}></AiOutlineSearch>*/}
                    {/*    <p>{variables.search}</p>*/}

                    {/*</div>*/}
                    {/*<hr />*/}
                    <div className="menu_content" onClick={viewProfileClick}>

                        <CgProfile className="icon_color" size={24}></CgProfile>
                        <p>{variables?.view_profile}</p>

                    </div>
                    <hr />
                    <div className="menu_content" onClick={handleProfileClick}>

                        <BsPeople className="icon_color" size={24}></BsPeople>
                        <p>{variables.edit_profile}</p>

                    </div>
                    <hr />
                    {/*<div className="menu_content" onClick={showFavoriteHandler}>*/}

                    {/*    <BsHeart className="icon_color"size={24}> </BsHeart>*/}
                    {/*    <p>{variables?.favorite}</p>*/}

                    {/*</div>*/}
                    {/*<hr />*/}
                    {/*{!IS_MOBILE_VERSION &&(*/}
                    {/*    <>*/}
                    {/*<div className="menu_content" onClick={viewAllAppliedJobsHandler}>*/}

                    {/*    <BsBriefcase className="icon_color" size={24} ></BsBriefcase>*/}
                    {/*    <p>{variables?.jobs}</p>*/}
                    {/*</div>*/}
                    {/*<hr />*/}
                    {/*    </>*/}
                    {/*    )}*/}

                    {/*{(currentUrl ==='/job_seeker_dashboard' ||*/}
                    {/*    currentUrl ==='/employer_dashboard') &&(*/}
                    {/*        <>*/}
                    {/*            <div className="menu_content"  onClick={showNoficationModal}>*/}
                    {/*                <div className="icon-container">*/}
                    {/*                    <TbBellRinging2 className="icon_color" size={24}></TbBellRinging2>*/}
                    {/*                    {unreadCount > 0 &&(*/}
                    {/*                        <small className="number-badge" dangerouslySetInnerHTML={{ __html: unreadCount > 99 ? '99<sup>+</sup>' : unreadCount }}></small>*/}

                    {/*                    )}*/}
                    {/*                </div>*/}
                    {/*                <p>{variables?.notifications}</p>*/}

                    {/*            </div>*/}
                    {/*            <hr />*/}

                    {/*        </>*/}
                    {/*)*/}
                    {/*}*/}


                    <div className="menu_content" onClick={signInClick}>

                        <AiOutlineLogout className="icon_color" size={24}></AiOutlineLogout>
                        <p>{variables?.logout}</p>

                    </div>
                </div>
            </Modal.Body>

        </Modal>
            <Modal show={showNotifcation} onHide={closeNotificationModal} className="modal right fade">
                <Modal.Header closeButton>
                    <Modal.Title>NOTIFICATIONS</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className="modal-body-scrollable1"
                    style={{ padding: '0px !important' }}
                >

                    {notifications.length > 0 &&(
                        <>
                            {notifications?.slice(0, 6)?.map((notification) => (
                                <>
                                    <div key={notification.id}
                                         className={`${notification?.read === 0?
                                             'notification_content_unread':'notification_content_read'} notification_hover py-1 px-2`}
                                         onClick={()=>{handleClickNotification(notification)}}
                                    >
                                        <div className='d-flex gap-2 py-1 align-items-center'>
                                            {Helper?.notification_log(notification?.user)}
                                            <div>
                                                <p className="m-0"> {notification.content}</p>
                                                <small>{Helper.getTimeSinceShared(notification?.updated_at, serverTime)}</small>
                                            </div>
                                        </div>

                                    </div>
                                    <hr className='m-0'  key={notification.id} />
                                </>

                            ))}

                            <p
                                className="text-center notification_hover text-primary m-0 py-2"
                                onClick={()=>navigate("/AllNotifications")}
                            >View All</p>

                        </>
                    )}


                </Modal.Body>

            </Modal>

           <CustomModalStyle />
        </>
    )
}