import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "./config";
import EmployerHeader from "./EmployerHeader";
import JobSeekerHeader from "./JobSeekerHeader";
import style from "./assets/css/LoadingComponent.module.css";
import { BsArrowLeft } from "react-icons/bs";
import styles from "./assets/css/notification.module.css";
import { useNavigate } from "react-router-dom";
import Helper from "./Helper";
import { useSelector } from "react-redux";
import {UserInfo} from "./components/UserInfo/UserInfo";
import {useNotificationHandler} from "./hooks/useNotificationHandler";
import {EmployerSideBars} from "./components/Sidbars/EmpSideBars";
import {JobSeekerSiderBar} from "./components/Sidbars/JobSeekerSiderBar";

const AllNotification = () => {
    const [notifications, setNotifications] = useState([]);
    const [serverTime, setServerTime] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [notificationCounter, setNotificationCounter] = useState(0)
    const notificationsPerPage = 10;

    const token = localStorage.getItem("access_token");
    const user_data = useSelector((state) => {
        return state.user.user;
    });
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const navigate = useNavigate();
    const { RedirectNotification } = useNotificationHandler();

    const userable_type = localStorage.getItem("userable_type");
    let headerComponent;
    if (userable_type === "App\\Models\\Employer") {
        headerComponent = <EmployerHeader
            setNotificationCounter={setNotificationCounter}

        />;
    } else {
        headerComponent = <JobSeekerHeader
            setNotificationCounter={setNotificationCounter}
        />;
    };



    useEffect(() => {
        setLoading(true);

        axios
            .get(`${BASE_URL}/notifications`, {
                headers,
            })
            .then((response) => {
                if(response?.data?.notifications){
                    setNotifications(response?.data?.notifications);
                }

                setServerTime(response?.data?.server_time);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching notifications:", error);
                setLoading(false);
            });
    }, []);

    const handleRedirection = () => {
        navigate(-1);
    };

    const handleViewMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const visibleNotifications = notifications?.slice(
        0,
        currentPage * notificationsPerPage
    );

    const handleClickNotification = (notification)=>{

        RedirectNotification(notification)
    }


    return (
        <div>
            {headerComponent}

            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}

            <div className="container">
                <div className="JBmain">
                    <div className="RDheadtitle fvthead ">
                        <BsArrowLeft className='AJbackbtn' role='button' onClick={handleRedirection}></BsArrowLeft>
                        <h2>All Notifications</h2>
                        <span></span>
                    </div>
                    <div className="d-flex gap-2">
                        <div className={styles.user_info}>
                            {user_data &&(
                                <UserInfo user_data={user_data} />
                            )}
                        </div>

                        <div className={styles.rightContent}>
                            <div className={`${styles.rightContent_scroll} p-1`}>
                            {visibleNotifications.length > 0 &&
                                visibleNotifications.map((notification, index) => {
                                    return (
                                        <>
                                            <div
                                                key={notification.id}
                                                onClick={()=>handleClickNotification(notification)}
                                                className={`${
                                                    notification?.read === 0
                                                        ? "notification_content_unread"
                                                        : "notification_content_read"
                                                } notification_hover py-2 px-2`}
                                            >
                                                <div className="d-flex gap-2 py-1 align-items-start">

                                                    {Helper?.notification_log(notification?.user)}
                                                    <div className={styles.notification_content}>
                                                        <p className="m-0">{notification.content}</p>
                                                        <small>
                                                            {Helper.getTimeSinceShared(
                                                                notification?.updated_at,
                                                                serverTime
                                                            )}
                                                        </small>
                                                    </div>
                                                </div>

                                            </div>
                                           <hr className="m-0"  key={index} />
                                        </>
                                    );
                                })}
                            {visibleNotifications.length < notifications.length && (
                                <p className="text-center m-0 p-2 text-primary" role="button" onClick={handleViewMore}>View More</p>
                            )}
                                {
                                    notifications.length == 0 &&(
                                        <p className="text-center m-0 p-2 text-danger" role="button" >No Notification found.</p>
                                    )
                                }
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            {userable_type === "App\\Models\\Employer" ? (
                <EmployerSideBars
                    dashboardCheck ={false}
                    notificationCounter={notificationCounter}
                />
            ):(
                <JobSeekerSiderBar
                    dashboardCheck ={false}
                    notificationCounter={notificationCounter}
                />
            )}

        </div>
    );
};

export default AllNotification;
