import React, { useState, useEffect } from 'react';
import {Resume_File_Path} from "../config";
let isLoaded = false;
let interval;
export const DocViewer = ({ resumeFile }) => {

    const [iframeKey, setIframeKey] = useState(0);

    const loadIframe = () => {

        if(isLoaded){
            clearTimeout(interval);
        }else{
            interval = setTimeout(loadIframe, 2000)
            setIframeKey(prevKey => prevKey + 1);
        }
    }

    useEffect(() => {loadIframe()}, [])

    const handleLoad = (res) => {
        clearTimeout(interval);
        isLoaded = true;
    };

    const handleError = () => {

        console.error('Error loading document');
    };

    return (
        <div>
            {/*{isLoaded && <p>Loading...</p>}*/}
            <iframe
                key={iframeKey}
                src={`https://docs.google.com/gview?url=${resumeFile}&embedded=true`}
                width="100%"
                height="900px"
                title="Embedded Document"

                onLoad={handleLoad}
                onError={handleError}
            ></iframe>
        </div>
    );
};
export const PdfViewer = ({ resumeFile, matchedKeywords }) => {
    if (!Array.isArray(matchedKeywords) || matchedKeywords.length === 0) {
        // Handle the case where matchedKeywords is not an array or is empty
        return (<div>
            <iframe
                src={`${Resume_File_Path}?file=${resumeFile}`}
                width="100%"
                height="500px"
                title="Embedded Document"
                frameBorder="0"
            ></iframe>
        </div>);
    }

    const splitKeywords = matchedKeywords
        .map(keyword => keyword.split(' '))
        .reduce((acc, val) => acc.concat(val), []); // Flatten the array

    const searchQuery = splitKeywords.map(keyword => `search[]=${keyword}`).join('&');

    return (
        <div>
            <iframe
                src={`${Resume_File_Path}?file=${resumeFile}&${searchQuery}`}
                width="100%"
                height="500px"
                title="Embedded Document"
                frameBorder="0"
            ></iframe>
        </div>
    );
};


// export const PdfViewer =({resumeFile,matchedKeywords})=>{
//     // const searchQuery = matchedKeywords.join('&search[]=');
//     console.log(matchedKeywords)
//     const splitKeywords = matchedKeywords.flatMap(keyword => keyword.split(' '));
//     const searchQuery = splitKeywords.map(keyword => `search[]=${keyword}`).join('&');
//
//     // const searchQuery = matchedKeywords.map(keyword => `search[]=${keyword}`).join('&');
//     console.log(searchQuery)
//
//     return(
//         <div>
//             <iframe
//             // src={resumeFile}
//                 src={`${Resume_File_Path}?file=${resumeFile}&${searchQuery}`}
//
//             width="100%"
//             height="900px"
//             title="Embedded Document"
//             frameBorder='0'
//         ></iframe>
//        </div>
//     );
// };