import {MdFavorite} from "react-icons/md";
import React from "react";


export const AllFavResume = ({
                                 favoriteResumes,
                                 viewAllResumeHandler,
                                 handleUserImg,
                                 handleResumeClick,
                                 dropdownRef,
                                 handleRemoveClick
                             })=>{

    return(
        <div className="analytics">
            <div className="resume JSleftheading">
                <h2> Favorite Resumes</h2>
                {/* <p><a href="#">View All</a></p> */}
                {favoriteResumes && favoriteResumes.length > 0 && (
                    <p style={{cursor:"pointer"}} onClick={viewAllResumeHandler}>View All <i className="fas fa-chevron-right"></i></p>
                )}

            </div>
            {/*-------------- Favourite Resume 1 ------------*/}
            <div>
                {favoriteResumes && favoriteResumes.length > 0 ? (
                    favoriteResumes && favoriteResumes?.map((resume, index) => (
                        <div className="favourite" key={index}>
                            <div className="fvtresum">
                                <div className="searchresum-img">

                                    {/*<img src={profile5Image}/>*/}
                                    {handleUserImg(resume?.resume?.job_seeker.user)}

                                </div>
                                <div className="rsfvtresumtxt">
                                    <h6

                                        onClick={() => handleResumeClick(resume?.resume?.id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {resume?.resume?.first_name} {resume?.resume?.last_name}
                                    </h6>
                                    <p>
                                        <a href="javascript:void(0)">{resume?.resume?.preferred_job_title}</a>
                                    </p>
                                </div>
                            </div>
                            <div className="dropdown" ref={dropdownRef}>
                                <button
                                    className="dropbtn"
                                    onClick={() => handleRemoveClick(resume?.resume?.id)}
                                >

                                    <MdFavorite
                                        role="button"
                                        size={24}
                                        style={{  color: '#e54545' }}
                                    ></MdFavorite>

                                </button>

                                {/*{activeResumeId === resume?.resume?.id && showRemoveButton && (*/}

                                {/*    <div className="dropdown-content"*/}
                                {/*         ref={dropdownRef}*/}
                                {/*    >*/}
                                {/*        <button*/}
                                {/*            className="removebtn"*/}
                                {/*            onClick={() => handleRemoveClick(resume?.resume?.id)}*/}
                                {/*        >*/}
                                {/*            Remove*/}
                                {/*        </button>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </div>
                        </div>
                    ))
                ) : (
                    <p> No favorite resumes found.</p>
                )}
            </div>

        </div>
    )
}