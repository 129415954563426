import React,{useState, useEffect} from "react";
import {BsSliders2, BsHeart, BsCCircle, BsHeartFill, BsCCircleFill} from "react-icons/bs";
import {SiSimpleanalytics} from "react-icons/si";
import {Modal} from "react-bootstrap";
import {JobAd} from "../JobAd/JobAd";

import {UserInfo} from "./SideBarContent/userInfo";
import {CustomDivider} from "./SideBarContent/Divider";
import {FavResumeContent} from "./SideBarContent/FavResumeContent";
import {JobAnalytics} from "./SideBarContent/JobAnalytics";
import {ResumeAnalytics} from "./SideBarContent/ResumeAnalytics";
import {MyCircle} from "./SideBarContent/MyCircle";
import {CustomModalStyle} from "../CustomModalStyle";
import {AiOutlineHome, AiOutlineSearch, AiOutlineClose, AiTwotoneHome} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import {TbBellRinging2, TbBellRinging2Filled} from "react-icons/tb";
import {CgProfile} from "react-icons/cg";
import {useLocation} from "react-router-dom";
import {BiSearch, BiSolidSearch} from "react-icons/bi";
import {HiUserCircle} from "react-icons/hi";
import {FaUserFriends} from "react-icons/fa";
import {LiaUserFriendsSolid} from "react-icons/lia";
import {RiUserShared2Fill, RiUserShared2Line} from "react-icons/ri";
import {addEmployee} from "../../store/slices/EmployerData";
import {useDispatch, useSelector} from "react-redux";

export const EmployerSideBars = ({
                                     handleConfirmDeleteResume,
                                     showResumeDropdown,
                                     openDropdownIndex,
                                     handleResumeOptionClick,
                                     containerRef1,
                                     handleResumeClick,
                                     handleUserImg,
                                     viewAllResumeHandler,
                                     viewAllHandler,
                                     jobId,
                                     handleEditJobClick,
                                     handleDeleteJobClick,
                                     handleConfirmDeleteJob,
                                     isDeleteModalOpen,
                                     handleCloseModal,
                                     setOpenDropdownIndex,
                                     contactType,
                                     handleFollowFollowing,
                                     user_data,
                                     dashboardCheck,
                                     notificationCounter
                                 })=>{

    const [showAnalytics, setShowAnalytics] = useState(false)
    const [showMoreInfo, setShowMoreInfo] = useState(false)

    const employee = useSelector((state) => {
        return state?.employee?.employer;
    });
    const employeeContactType = useSelector((state) => {
        return state?.employee?.contactType;
    });

    const navigate = useNavigate()
    const location = useLocation()
    const currentUrl = location.pathname;
    const showMoreInfoModal=()=>{
        setShowMoreInfo(true)
    }
    const showAnalyticModal=()=>{
        setShowAnalytics(true)
    }
    const closeMoreInfo = ()=>{
        setShowMoreInfo(false)
    }

    const closeAnalytics = ()=>{
        setShowAnalytics(false)
    }
    const showSearchResume = ()=>{
        navigate("/search_resume")
    }
    const showEmployeeDashBoard = ()=>{
        navigate("/employer_dashboard")
    }
    const showFavoriteResumes= ()=>{
        navigate("/fav-resumes")
    }
    const showAllNotication = ()=>{
        navigate('/AllNotifications')
    }
    const showMyProfile = ()=>{
        navigate('/my-profile-view')
    }
    const showFollower = ()=>{
        navigate('/follow');
    }
    const createUpdateProfile = () => {
        navigate("/create_profile");
    };
    const handleProfileClick = () => {
        navigate("/create_profile");
    };
    const handlePreviewJob =(jobId)=>{
        const from="/employer_dashboard";
        navigate('/post_a_job_preview', { state: { jobId,from } });
    }

    return(
        <>
            <div className="emp_sidebar">
                <div className="d-flex justify-content-around gap-4">
                    <div onClick={showEmployeeDashBoard} className='d-flex align-items-center flex-column'>
                        {currentUrl === '/employer_dashboard'?
                            (<AiTwotoneHome className='blue_color' size={18} role='button' title="home"></AiTwotoneHome>):
                            ( <AiOutlineHome size={18} role="button" title="Home"></AiOutlineHome>)}

                        <small className={`sidebar_content 
                        ${currentUrl === '/employer_dashboard'? 'blue_color':''}`}>Home</small>
                    </div>
                    <div onClick={showFavoriteResumes} className='d-flex align-items-center flex-column'>
                        {currentUrl === '/fav-resumes'?(
                            <BsHeartFill className='blue_color' size={18} role='button' title="Favorite"></BsHeartFill>
                        ):(<BsHeart size={18} role="button" title="Favorite"></BsHeart>)}

                        <small className={`sidebar_content 
                        ${currentUrl === '/fav-resumes'? 'blue_color':''}`}>Favorite</small>
                    </div>
                    <div onClick={showSearchResume} className='d-flex align-items-center flex-column'>
                        {currentUrl === '/search_resume'?(
                            <BiSolidSearch  className='blue_color' size={18} role="button" title="Search"></BiSolidSearch>
                        ):(

                            <BiSearch size={18} role="button" title="Search"></BiSearch>
                        )}

                        <small className={`sidebar_content 
                             ${currentUrl === '/search_resume'? 'blue_color':''}`}>Search</small>
                    </div>
                    <div onClick={showFollower} className='d-flex align-items-center flex-column'>
                        {currentUrl === '/follow'?(
                            <RiUserShared2Fill    className='blue_color' size={18} role='button' title="My Circle"></RiUserShared2Fill   >
                        ):(
                            <RiUserShared2Line    size={18} role="button" title="My Circle"></RiUserShared2Line   >
                        )}

                        <small className={`sidebar_content 
                             ${currentUrl === '/follow'? 'blue_color':''}`}>My Circle</small>
                    </div>
                    {/*{dashboardCheck ? (*/}
                            <div onClick={showMoreInfoModal} className='d-flex align-items-center flex-column'>

                                <BsSliders2 size={18} role="button" title="More Information"></BsSliders2>
                                <small className='sidebar_content'>Analytics</small>
                            </div>
                    {/*) : (*/}
                    {/*    <>*/}
                    {/*    <div onClick={showAllNotication} className='d-flex align-items-center flex-column'>*/}
                    {/*        <div className="icon-container1">*/}

                    {/*            {currentUrl === '/AllNotifications'?(*/}
                    {/*                <TbBellRinging2Filled className='blue_color' size={18} role='button' title="notifications"></TbBellRinging2Filled>*/}
                    {/*            ):(*/}
                    {/*                <TbBellRinging2 title='notifications' size={18}></TbBellRinging2>*/}
                    {/*            )}*/}
                    {/*            {notificationCounter > 0 && (*/}
                    {/*                <small className="number-badge1"></small>*/}
                    {/*            )}*/}
                    {/*        </div>*/}
                    {/*        <small className={`sidebar_content */}
                    {/*         ${currentUrl === '/AllNotifications'? 'blue_color':''}`}>Notifications</small>*/}
                    {/*    </div>*/}
                    {/*    </>*/}
                    {/*)}*/}
                </div>
            </div>


            {/* More Info Modal*/}
            <Modal show={showMoreInfo} onHide={closeMoreInfo} className="modal right fade sidebar_content"  >
                <Modal.Header>
                    <Modal.Title >User Detail</Modal.Title>
                   <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeMoreInfo}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                   <div className="p-1">
                       <UserInfo
                           employer={employee}
                           createUpdateProfile={createUpdateProfile}
                           handleProfileClick={handleProfileClick}
                       />

                      <CustomDivider />

                       <JobAnalytics
                           employer={employee}
                           contactType={employeeContactType}
                       />
                       <CustomDivider />

                       <ResumeAnalytics
                           employer={employee}
                       />

                       <CustomDivider />

                       <div className=" analyticsv1">
                           <div className="resumev1">
                               <h2> Active Jobs</h2>

                           </div>
                           {employee?.employer_data?.job_ads.filter(ads => ads.job_status_id == 1).map((ads) => (
                               <JobAd
                                   key={ads.id}
                                   ad={ads}
                                   handlePreviewJob={handlePreviewJob}
                                   handleEditJobClick={handleEditJobClick}
                                   handleDeleteJobClick={handleDeleteJobClick}
                                   handleConfirmDeleteJob={handleConfirmDeleteJob}
                                   isDeleteModalOpen={isDeleteModalOpen}
                                   handleCloseModal={handleCloseModal}
                                   setOpenDropdownIndex={setOpenDropdownIndex}
                               />

                           ))}

                       </div>

                   </div>
                </Modal.Body>
            </Modal>

            {/*Analytics Modal*/}
            <Modal show={showAnalytics} onHide={closeAnalytics} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title >Analytics</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeAnalytics}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                   <div className='p-1'>

                       <CustomDivider />
                      <MyCircle
                          user_data={user_data}
                          handleFollowFollowing={handleFollowFollowing}
                      />
                   </div>
                </Modal.Body>
            </Modal>

            <CustomModalStyle />

        </>
    )
}