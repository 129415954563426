import {Button, Modal} from "react-bootstrap";
import {STORAGE_URL} from "../../config";
import React from "react";

export const VideoResumeModal = ({
                                     isVideoModalOpen,
                                     setIsVideoModalOpen,
                                     resume,

                                 })=>{

    const handleCloseModal =()=>{
        setIsVideoModalOpen(false);
    };

    return(
        <Modal show={isVideoModalOpen} onHide={handleCloseModal} centered>
            <Modal.Header  >
                <Modal.Title>Video Resume</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <video
                    src={
                        STORAGE_URL +
                        resume?.resume_availability_video_file
                    }
                    controls
                    style={{
                        width: "100%",
                        height: "90%",
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    className="me-2"
                    style={{ background: "#E54545" }}
                    onClick={() => setIsVideoModalOpen(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
