import React from "react";
import { Form,Modal,Button } from "react-bootstrap";



export const ActiveForJob = ({
                                 handleToggle,
                                 showConfirmationModal,
                                 handleCloseConfirmationModal,
                                 handleProceed,
                                 isActiveForJobs,
                                 jobSeeker
                             })=>{

    return (
        <div className="resume2-rd">
            <h2>Active for Jobs</h2>
            <div className="resume2-rd-btn">
                {jobSeeker?.job_seeker?.user.firstname ||
                jobSeeker?.job_seeker?.user.lastname ? (
                    // Code block executed when firstname or lastname exists
                    // This block represents the toggle button in an enabled state
                    <>
                        <Form>
                            <div className="d-flex align-items-center">

                          <span className="me-2 text-color">
                            {isActiveForJobs ? "Yes" : "No"}
                          </span>
                                <Form.Check
                                    type="switch"
                                    id="toggle-switch"
                                    label=""
                                    checked={isActiveForJobs}
                                    onChange={handleToggle}
                                />
                            </div>
                        </Form>
                    </>
                ) : (
                    // Code block executed when firstname and lastname are both missing
                    // This block represents the toggle button in a disabled state
                    <>
                        <Form>
                            <div className="d-flex align-items-center">
                          <span className="me-2" style={{ color: "black" }}>
                            No
                          </span>
                                <Form.Check
                                    type="switch"
                                    id="toggle-switch"
                                    label=""
                                    checked={false}
                                    onChange={handleToggle}
                                    disabled
                                />
                            </div>
                        </Form>
                    </>
                )}
            </div>
            <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isActiveForJobs ? (
                        <p>Your profile will now be hidden from employers. You won't be searchable.</p>
                    ) : (
                        <p>Your profile will now be visible to employers for job opportunities.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmationModal}>
                        Close
                    </Button>
                    <Button  className="btn-primary-color" variant="primary" onClick={handleProceed}>
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}