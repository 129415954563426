import React, {useEffect, useRef, useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/styles.css'
import '../src/assets/css/postjob.css';
import rectangelImage from "./assets/rectangel.png";
import generalInfoImage from '../src/assets/generalinfoicone.svg';
import benefitsImage from '../src/assets/benefitsicone.svg';
import jobDescriptionImage from '../src/assets/jobdescicone.svg';
import Select from "react-select";
import styles from '../src/assets/css/LoadingComponent.module.css';

import EmployerHeader from "./EmployerHeader";
import axios, {all} from "axios";
import BASE_URL from "./config";
import {useNavigate,useLocation,Link} from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Helper from "./Helper";
import {useSelector} from "react-redux";
import {EmployerSideBars} from "./components/Sidbars/EmpSideBars";

function EditJob() {
    const navigate = useNavigate();

    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    var _errors = [];


    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const location = useLocation();
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const code = `${hours}${minutes}${seconds}`;
    const [success, setSuccess] = useState([]);
    const {jobId,from} = location.state || {};
    const [isCompensationClicked, setCompensationClicked] = useState(false);
    const [compensationPlaceholder, setCompensationPlaceholder] = useState('');


    const [employer, setEmployer] = useState(null);
    const total_jobs=10;
    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const user_data = useSelector((state) => {
        return state?.user?.user;
    });
    // Assuming user_data.current_employee contains company name
    const companyName = user_data?.current_employee || '';
    useEffect(() => {
        async function fetchEmployerData() {

            const response = await fetch(`${BASE_URL}/employer_dashboard_data/${employerId}`,{
                headers,
            });

            const data = await response.json();
            if(data.status===200){
                setEmployer(data);
            }
           else if (data.status === 401) {
                // Redirect to the landing page if unauthenticated
                navigate("/");
            }
            // //console.log(data)
        }
        fetchEmployerData();
    }, []);
    // alert(dateTimeString)
    // React state to manage selected options

     const currentDateSend = new Date().toISOString().slice(0, 10);
     const currentDate = new Date().toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: 'numeric'}).replace(/\//g, "/");

    const newRandomNumber = Math.floor(Math.random() * 100);
    const employerId = localStorage.getItem("employerId");
    const [jobData, setJobData] = useState({
        employer_id: employerId,
        job_type: "Full Time",
        interview: "Telephone/Video",
        no_of_rounds_interview: '',
        job_mode: "Onsite/Hybrid",
        compensation: "Hourly",
        compensation_rate: '',
        bonus: "No",
        bonus_percentage:'',
        description: "<p></p>",
        required_skills: "",
        skills: [],
        work_authorization:[],
        years_of_experience_id: '',
        years_of_experience_label:'',
        required_certification: "No",
        certification_type: "",
        can_post:0
    });
    const handleJobChange = (event) => {
        setJobData({...jobData, job_type: event.target.value});
        setError({ ...error, job_type: "" });

    };
    const handleTitleChange = (event) => {
        setJobData({...jobData, job_title: event.target.value});
        setError({ ...error, job_title: "" });


    };

    const handleNoOfRoundOfInterviewChange = (event) => {
        setJobData({...jobData, no_of_rounds_interview: event.target.value});
        setError({ ...error, no_of_rounds_interview: "" });

    };


    const handleBonusPercentageChange = (event) => {
        setJobData({...jobData, bonus_percentage: event.target.value});
        setError({ ...error, bonus_percentage: "" });

    };
    const handleCertificationTypeChange = (event) => {
        setJobData({...jobData, certification_type: event.target.value});
        setError({ ...error, certification_type: "" });

    };
    const handleInterviewModeChange = (event) => {
        setJobData({...jobData, interview: event.target.value});
        setError({ ...error, interview: "" });

    };
    const handleJobModeChange = (event) => {
        setJobData({...jobData, job_mode: event.target.value});
        setError({ ...error, job_mode: "" });

    }
    const handleCompensationChange = (event) => {
        setJobData({...jobData, compensation: event.target.value});
        setError({ ...error, compensation: "" });
        setIsBonusYesClicked(false)
        setCompensationClicked(true);
        if (event.target.value === 'Annual') {
            setIsAnnuallyClicked(true);
        } else {
            setIsAnnuallyClicked(false);
        }

        if(event.target.value=='Annual'){
                 setCompensationPlaceholder(event.target.value+' Salary (USD)');
   
        }
        else{
        setCompensationPlaceholder(event.target.value+' Rate (USD)');
        }
    }
    const handleCompensationRateChange = (event) => {
        if (event.target.value.length <= 0) {
            setJobData({...jobData, compensation_rate: ''});

        }
        else {
            const numericValue = event.target.value.replace(/\D/g, '');
            const formattedValue = parseInt(numericValue).toLocaleString();
            setJobData({...jobData, compensation_rate: formattedValue});
            setError({...error, compensation_rate: ""});
        }
    }
    const [isBonusYesClicked, setIsBonusYesClicked] = useState(false);

    const handleBonusYesChange = (event) => {

        setJobData({...jobData, bonus: event.target.value, bonus_percentage: ''});
        setError({ ...error, bonus_percentage: "" });
        setIsBonusYesClicked(true);

    }

    const handleBonusNoChange = (event) => {
        setJobData({...jobData, bonus: event.target.value, bonus_percentage: 0.0});
        setError({ ...error, bonus_percentage: "" });
        setIsBonusYesClicked(false);

    }
    const [isCertificationYesClicked, setIsCertificationYesClicked] = useState(false);

    const handleCertificationYesChange=(event)=>{
        setJobData({...jobData, required_certification: event.target.value});
        setError({ ...error, required_certification: "" });

        setIsCertificationYesClicked(true);
        //console.log(event.target.value)

    }
    const handleCertificationNoChange=(event)=>{
        setJobData({...jobData, required_certification: event.target.value,certification_type: ''});
        setError({ ...error, required_certification: "" });

        //console.log(event.target.value)
        // setJobData({...jobData, certification_type: ''});
        setIsCertificationYesClicked(false);

    }





    const [selectedOptions, setSelectedOptions] = useState();
    const [selectedZipCodeOptions, setSelectedZipCodeOptions] = useState();

    function handleInputChange(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    const handlePreview = (e) => {
        e.preventDefault();
        localStorage.setItem('jobData', JSON.stringify(jobData));

       // navigate('/post_a_job_preview');
        window.open('/post_a_job_preview', '_blank');


    }
    const handleEditJob = (e) => {
        e.preventDefault();


        const characterLimit = 500; // Set your desired character limit here
        const inputString = jobData.description;

        // Calculate the character count
        const characterCount = inputString.length;

        if (characterCount < characterLimit) {
            setError((prevError) => ({
                ...prevError,
                description: `At least ${characterLimit} characters required for job description!`
            }));
            scrollToErrors(); // Scroll to the errors
            return false;
        }


        setLoading(true);
        setError([]);
        _errors = [];

        // console.log(jobData)
        axios.post(`${BASE_URL}/job_ad/`+jobId+`?_method=PUT`, jobData, {headers: headers})
            .then(response => {

                if (response.data.status == 200) {
                    setLoading(false);
                    setSuccess({...success, successMessage: 'Job Updated successfully'});
                    // setJobData(tempState)
                    // console.log(from);
                    navigate(from);
                }else if(response.data.status == 401){
                    navigate("/");
                }

                else {
                    // alert(response.data.msg)
                    setError(response.data);
                    _errors = response.data;
                    scrollToErrors(); // scroll to the errors

                }
                // Do something with the response
            })
            .catch(error => {
                setLoading(false);

                setError(error.response.data);
                _errors = error.response.data;
                scrollToErrors(); // scroll to the errors
            });


    }


    //this code is for live skills
    const [optionsSkills, setOptions] = useState([]);
    useEffect(() => {
        fetch(`${BASE_URL}/skills_set`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.status === 200){
                    const skills = data.skills;
                    const optionsArray = skills.map((skill) => {
                        return { value: skill.id, label: skill.name };
                    });
                    setOptions(optionsArray);
                    //console.log(optionsArray)
                }else if(data.status === 401){
                    navigate("/");
                }

            })
            .catch((error) => {
                console.error("Error fetching skills data:", error);
            });
    }, []);


    // // this code is for Zipcodes
     const [optionsZipCodes, setOptionsZipCpdes] = useState([]);

    function fetchLocationData(zipcode) {
        fetch(`${BASE_URL}/zipcode/${zipcode}`, {
            headers,
        })
            .then((response) => response.json())

            .then((data) => {
                if(data.status === 200){
                    if (data.zipcodes) {
                        let location='';
                        if(data.zipcodes[0].city!=null) {
                            location= data.zipcodes[0].city;
                        }
                        if(data.zipcodes[0].state!=null) {
                            location+=', ' + data.zipcodes[0].state;
                        }
                        if(data.zipcodes[0].county_area!=null) {
                            location+=', ' + data.zipcodes[0].zipcode;
                        }
                        // location+=', USA';
                        //console.log(location);
                        setJobData(prevJobData => ({
                            ...prevJobData,
                            location: location
                        }));
                    } else {
                        console.error(`Zipcode ${zipcode} not found`);
                    }
                }else if( data.status === 401){
                    navigate("/");
                }

            })
            .catch((error) => {
                console.error(`Error fetching location data for zipcode ${zipcode}:`, error);
            });
    }


    // Function triggered on selection
    function handleSelect(data) {
        setSelectedOptions(data);
        //console.log(data)

        // Create an array of skill objects with the skill_set_id
        const selectedSkills = data.map((option) => ({
            skill_set_id: option.value,
            skill_set_level:''
        }

        ));

        // Update jobData with the selected skills
        setJobData((prevJobData) => ({
            ...prevJobData,
            skills: selectedSkills,
        }));
        //console.log(jobData)

        //setError({ ...error, required_skills: "" });
    }

    // Function to handle option change
    function handleSelectZipCode(selectedZipcode) {
        if (selectedZipcode) {
            setSelectedZipCodeOptions(selectedZipcode);
            // Update job data with selected zipcode
            setJobData(prevJobData => ({
                ...prevJobData,
                location: ''
            }));
            fetchLocationData(selectedZipcode.value);
        } else {
            setSelectedZipCodeOptions(null);
            // Reset location field in job data
            setJobData(prevJobData => ({
                ...prevJobData,
                location: ''
            }));
        }
        setError({ ...error, location: "" });

    }
     const fetchZipCodes = (query) => {
        const isZipCode = /^\d+$/.test(query); // Check if the query is a number

        fetch(`${BASE_URL}/zipcode_filter/${query}`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {

                if(data.status === 200){
                    const zipcodes = data.zipcodes.original;

                    let optionsArray = [];
                    if (Array.isArray(zipcodes)) {
                        optionsArray = zipcodes.map((zipcode) => {
                            let value, label;
                            if (isZipCode) {
                                value = zipcode.zipcode;
                                label = zipcode.zipcode;
                                // label = `${zipcode.zipcode}, ${zipcode.city}, ${zipcode.state}`;
                                label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;


                            } else {
                                value = zipcode.zipcode;
                                label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;
                            }
                            return {
                                value: value,
                                label: label,
                            };
                        });
                    }

                    setOptionsZipCpdes(optionsArray);
                }else if(data.status === 401){
                    navigate("/");
                }

            })
            .catch((error) => {
                console.error("Error fetching zipcodes data:", error);
            });
    };
    const titleRef = useRef(null);
    const locationRef = useRef(null);
    const interviewNumberRef = useRef(null);
    const compensationRef = useRef(null);
    const bonusRef = useRef(null);
    const descriptionRef = useRef(null);
    const yearOfExperienceRef = useRef(null);
    const certificateRef = useRef(null);
    const yOffset = -100;
    const [isAnnuallyClicked, setIsAnnuallyClicked] = useState(false);

    const scrollToTitle = () => {
        if (titleRef.current) {
            const y = titleRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollBy(0, titleRef.current.getBoundingClientRect().top - 400);

        }
    };

    const scrollToLocation = () => {
        if (locationRef.current) {
            const y = locationRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollBy(0, locationRef.current.getBoundingClientRect().top - 500);
        }
    };

    const scrollTointerviewNumber = () => {
        if (interviewNumberRef.current) {
            const y = interviewNumberRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollBy(0, interviewNumberRef.current.getBoundingClientRect().top - 400);
        }
    };

    const scrollToCompansation = () => {
        if (compensationRef.current) {
            const y = compensationRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollBy(0, compensationRef.current.getBoundingClientRect().top - 400);
        }
    };

    const scrollToBonus = () => {
        if (bonusRef.current) {
            const y = bonusRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollBy(0, bonusRef.current.getBoundingClientRect().top - 400);
        }
    };

    const scrollToCertificate = () => {
        if (certificateRef.current) {
            const y = certificateRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollBy(0, certificateRef.current.getBoundingClientRect().top - 400);
        }
    };

    const scrollToyearOfExperience = () => {
        if (yearOfExperienceRef.current) {
            const y = yearOfExperienceRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollBy(0, yearOfExperienceRef.current.getBoundingClientRect().top - 400);
        }
    };

    const scrollToDescription = () => {
        if (descriptionRef.current) {
            const y = descriptionRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollBy(0, descriptionRef.current.getBoundingClientRect().top - 400);
        }
    };

// Call this function to scroll to all input fields with errors
    const scrollToErrors = () => {
        setTimeout(function(){

            //console.log(_errors);
            if (_errors.job_title) {
                scrollToTitle();
            }
            else  if (_errors.location) {
                scrollToLocation();
            }
            else  if (_errors.contract_type) {
                scrollToLocation();
            }
            else  if (_errors.is_it_with_a) {
                scrollToLocation();
            }
            else if (_errors.no_of_rounds_interview) {
                scrollTointerviewNumber();
            }else if (_errors.compensation_rate) {
                scrollToCompansation();
            }else if (_errors.bonus_percentage) {
                scrollToBonus();
            }else if (_errors.description) {
                scrollToDescription();
            }else if (_errors.years_of_experience_id) {
                scrollToyearOfExperience();
            }else if (_errors.certification_type) {
                scrollToCertificate();
            }

        }, 600)
    };
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);

        setJobData(prevJobData => ({
            ...prevJobData,
            can_post: isCheckboxChecked ? 0 : 1
        }));
        setError({ ...error, can_post: "" });

    };

    // Work Authorization Code
    const [selectedWorkAuthorization, setSelectedWorkAuthorization] = useState(null);
    const [workAuthorizations, setWorkAuthorizations] = useState([]);

    useEffect(() => {
        const fetchWorkAuthorizations = async () => {
            try {
                const response = await fetch(`${BASE_URL}/work_authorization`, {
                    headers,
                });
                const data = await response.json();
                if(data.status === 200){
                    setWorkAuthorizations(data.work_authorizations);

                }else if(data.status === 401){
                    navigate("/");
                }
            } catch (error) {
                console.error('Error fetching work authorizations:', error);
            }
        };

        fetchWorkAuthorizations();


    }, []);
    async function fetchJobData() {
        try {
        setLoading(true)
        const response = await fetch(`${BASE_URL}/job_ad/${jobId}`,{
            headers,
        });
            const data = await response.json();
            if(data.status===200){
                let job_ad=data.jobPost;
                setIsCheckboxChecked(job_ad?.can_post);
                setSelectedZipCodeOptions({value: job_ad.location, label: job_ad.location});
                setSelectedWorkAuthorization(
                    job_ad.work_authorizations.map((wa) => ({
                        value: wa.id,
                        label: wa.work_authorization,
                    }))
                );

// Assuming user data is accessible
                const companyName = job_ad?.employer?.users[0]?.current_employee || '';
// ...

// Check if the contract type id is 1 and update the label accordingly
                const selectedContractTypeLabel = job_ad.contract_type?.id === 1
                    ? `Contract with ${companyName}`
                    : job_ad.contract_type?.employment_type;

// Set the selected contract type
                setSelectedContractType({ value: job_ad.contract_type?.id, label: selectedContractTypeLabel });

                // setSelectedWorkAuthorization({value: job_ad.work_authorization?.id, label: job_ad.work_authorization?.work_authorization})
                // setSelectedContractType({value: job_ad.contract_type?.id, label: job_ad.contract_type?.employment_type})
                if(job_ad.skills.length>0)
                {
                const selectedSkills = job_ad.skills.map((skill) => ({
                    value: skill.skill_set_id,
                    label: skill.skillset.name
                }));
                setSelectedOptions(selectedSkills);
                }
                setSelectedYearOfExperience({value: job_ad.years_of_experience?.id, label: job_ad.years_of_experience?.experience});
               

                    setJobData((prevState) => ({
                        ...prevState,
                    employer_id: job_ad?.employer_id,
                    job_status_id: job_ad?.job_status_id,
                    code: job_ad?.code,
                    job_creation_date: currentDateSend,
                    job_title: job_ad?.job_title ?? '',
                    location: job_ad?.location ?? '',
                    job_type: job_ad?.job_type ?? '',
                    work_authorization: job_ad?.work_authorizations.map((wa) => wa.id) ?? [], // Extract and map 'id' values
                    // work_authorization_label: job_ad?.work_authorization?.work_authorization ?? '',
                    contract_type_id: job_ad?.contract_type?.id ?? '',
                    contract_type_label: job_ad?.contract_type?.employment_type ?? '',
                    interview: job_ad?.interview ?? '',
                    no_of_rounds_interview: job_ad?.no_of_rounds_interview ?? '',
                    job_mode: job_ad?.job_mode ?? '',
                    compensation: job_ad?.compensation ?? 'Hourly',
                    compensation_rate: job_ad?.compensation_rate ?? '',
                    bonus: job_ad?.bonus ?? '',
                    description:job_ad?.description?? '',

                    bonus_percentage: job_ad?.bonus_percentage ?? '',
                    required_skills: job_ad?.required_skills ?? '',
                    skills: job_ad?.skills ?? '',
                    years_of_experience_id: job_ad?.years_of_experience?.id ?? '',
                    years_of_experience_label: job_ad?.years_of_experience?.experience ?? '',
                    required_certification: job_ad?.required_certification ?? '',
                    certification_type: job_ad?.certification_type ?? '',
                    can_post:  job_ad?.can_post,

                })) ;
                
                // setJobData((prevJobData) => ({
                //     ...prevJobData,
                //     description:job_ad.description,
                // }));
                setLoading(false)

            }
           else if (data.status === 401) {
                // Redirect to the landing page if unauthenticated
                navigate("/");
            }
        } catch (error) {
            console.error('Error fetching Job Data:', error);
        }
    }
    useEffect(() => {
        fetchJobData();
    }, [jobId]); // Add jobId to the dependency array

    const work_aithorization_options = workAuthorizations.map((authorization) => ({
        value: authorization.id,
        label: authorization.work_authorization,
    }));

    // Contract Type Code
    const [selectedContractType, setSelectedContractType] = useState(null);
    const [contractTypes, setContractTypes] = useState([]);

    useEffect(() => {
        const fetchContractType = async () => {
            try {
                const response = await fetch(`${BASE_URL}/contract_type`, {
                    headers,
                });

                const data = await response.json();
                if(data.status === 200 ){
                    setContractTypes(data.contract_type);
                }else if(data.status === 401){
                    navigate("/");
                }
            } catch (error) {
                console.error('Error fetching contract type:', error);
            }
        };

        fetchContractType();
    }, []);

    const contract_type_options = contractTypes.map((contractType) => ({
        value: contractType.id,
        // label: contractType.employment_type,
        label: contractType.employment_type.replace('Contract with us', 'Contract with '+companyName),

    }));
    // Years Of Experience Code
    const [selectedYearOfExperience, setSelectedYearOfExperience] = useState(null);
    const [YearOfExperiences, setYearOfExperiences] = useState([]);

    useEffect(() => {
        const fetchYearOfExperience = async () => {
            try {
                const response = await fetch(`${BASE_URL}/years_of_experience`, {
                    headers,
                });
                const data = await response.json();
                if(data.status === 200){
                    setYearOfExperiences(data.years_of_experience);

                }else if(data.status === 401){
                    navigate("/");
                }
            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchYearOfExperience();
    }, []);
    const years_of_experience_options = YearOfExperiences.map((YearOfExperience) => ({
        value: YearOfExperience.id,
        label: YearOfExperience.experience,
    }));

    return (
        <div>
            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}

            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR Start                          */}
            {/*---------------------------------------------------------------*/}
            <EmployerHeader/>
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR END                            */}
            {/*---------------------------------------------------------------*/}
            {/*---------------------------------------------------------------*/}
            {/*                        MAin Body Start                        */}
            {/*---------------------------------------------------------------*/}
            <div className="mainpostjob">
                <div className="postcontainer">

                    <div className="AJheadtitle ">
                          
                          <div className="AJtitle d-flex align-items-center px-1 justify-content-between">
                              <div className="AJbackbtn">
                                  {from && <div className=" backbtn">
                                      <button className='m-0' onClick={()=>navigate(-1)}> <BsArrowLeft  /> </button>
                                  </div>}
                              </div>
                            <h2>Edit Job</h2>
                              <span></span>
                          </div>
                           </div>
           
               
                    <div className="postanalyticsPS">
                    <p>No of Jobs Purchased: <span>{employer?.employer_data?.subscription?.package?.jobs}</span></p>
                    <p>No of Jobs Available: <span>{(employer?.employer_data?.subscription?.remaining_jobs)}</span></p>
                    </div>
                    <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>
                    {/* <h2 className="posjobhead">Edit a Job</h2> */}
                    <form action>
                        <div className="formheading">
                            <img src={generalInfoImage}/>
                            <h5>General Information</h5>
                        </div>
                        <div className="jobfield">
                            <div className="job-id">
                                <label>Job ID<span className="labelcol" >:</span></label>
                                <input type="text" placeholder={jobData.code} value={jobData.code} disabled/>
                            </div>
                            <div className="job-date">
                                <label>Date Created<span className="labelcol" >:</span></label>
                                <input type="text" placeholder={Helper.changeDateFormat(currentDate)} value={Helper.changeDateFormat(currentDate)} disabled/>
                            </div>
                        </div>
                        {/*--------------------------- job-title -----------------------*/}
                        <div className="job-title">
                            <label>Job Title<span className="labelcol" >:</span>  <span className="required-field">*</span>
                            </label>
                            <input type="text" placeholder="Job Title" value={jobData?.job_title}
                                   onChange={handleTitleChange}         ref={titleRef} />
                        </div>
                        {error.job_title && (
                            <span className="text-danger" ref={titleRef}>{error?.job_title}</span>
                        )}


                        {/*--------------------------- job-location -----------------------*/}
                        <div className="job-textterm">
                            <div className="zipcode">
                                <label>Job Location (ZIP Code/ City)<span className="labelcol" >:</span> <span className="required-field">*</span></label>
                                <Select
                                    options={optionsZipCodes}
                                    placeholder="Select ZIP Code/City"
                                    value={selectedZipCodeOptions}
                                    onChange={handleSelectZipCode}
                                    onInputChange={handleInputChange}
                                    isSearchable={true}
                                    isMulti={false}

                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            marginTop:'10px',
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px'
                                        }),
                                    }}
                                />

                                {error.location && (
                                    <div className="text-danger" ref={locationRef}>
                                        The zipcode field is required.
                                    </div>
                                )}
                            </div>
                        </div>





                        {/*--------------------------- job-Type -----------------------*/}
                        <div className="job-type">
                            <label>Job Type<span className="labelcol" >:</span></label>
                            <div className="toggle-radio">
                                <div className='toggle-content'>
                                <input type="radio" name="jobtype" id="jobtype-1" defaultChecked checked={jobData.job_type === "Full Time"}
                                       value="Full Time" onChange={handleJobChange}/>
                                <label htmlFor="jobtype-1">Full Time</label>
                                </div>
                                <div className='toggle-content'>
                                <input type="radio" name="jobtype" id="jobtype-3" checked={jobData.job_type === "Contract"}
                                       value="Contract" onChange={handleJobChange}/>
                                <label htmlFor="jobtype-3">Contract </label>
                                </div>
                                <div className='toggle-content'>
                                <input type="radio" name="jobtype" id="jobtype-2" checked={jobData.job_type === "1099"}
                                       value="1099" onChange={handleJobChange}/>
                                <label htmlFor="jobtype-2">1099</label>
                                    <input type="radio" name="jobtype" id="jobtype-4" checked={jobData.job_type === "Any"}defaultValue="any" value="Any" onChange={handleJobChange}/>
                                    <label htmlFor="jobtype-4">Any</label>
                                </div>
                            </div>
                        </div>
                        {/*--------------------------- Text-Term -----------------------*/}
                        <div className="job-textterm">
                            <div className="text-term">
                                <label>Work Authorization<span className="labelcol">:</span> <span className="required-field">*</span></label>
                                <Select
                                    id="work_authorization"
                                    name="term-contract"
                                    options={work_aithorization_options}
                                    value={selectedWorkAuthorization}
                                    isMulti
                                    onChange={(selectedOptions) => {
                                        setSelectedWorkAuthorization(selectedOptions);

                                        const selectedIds = selectedOptions.map(option => parseInt(option.value));
                                        const selectedLabels = selectedOptions.map(option => option.label);

                                        setJobData((prevState) => ({
                                            ...prevState,
                                            work_authorization: selectedIds,
                                            work_authorization_label: selectedLabels,
                                        }));
                                        setError((prevError) => ({
                                            ...prevError,
                                            work_authorization: ""
                                        }));
                                    }}

                                    placeholder="Work Authorization"
                                    isSearchable
                                    required
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px',
                                        }),
                                    }}
                                />
                                {error.work_authorization && (
                                    <div className="text-danger" ref={locationRef}>The work authorization field is required</div>
                                )}
                            </div>

                            {jobData?.job_type==="Contract" &&(

                            <div className="company">
                                <label>Contract Type<span className="labelcol" >:</span> <span className="required-field">*</span></label>
                                <Select
                                    id="contract"
                                    name="term-contract"
                                    options={contract_type_options}
                                    value={selectedContractType}
                                    onChange={(selectedOption) => {
                                        setSelectedContractType(selectedOption);
                                        const id = selectedOption ? parseInt(selectedOption.value) : null;

                                        setJobData((prevJobData) => ({
                                            ...prevJobData,
                                            contract_type_id: id,contract_type_label:selectedOption.label

                                        }));
                                        setError((prevError) => ({
                                            ...prevError,
                                            contract_type_id: ""
                                        }));
                                    }}
                                    placeholder="Select contract type"
                                    isSearchable
                                    required
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px'
                                        }),
                                    }}
                                />
                                {error.contract_type_id && (
                                    <div className="text-danger" ref={locationRef}>The contract type field is required</div>
                                )}

                            </div>
                            )}
                        </div>
                        {/*--------------------------- Interview Mode -----------------------*/}
                        <div className="job-type">
                            <label>Interview Mode<span className="labelcol" >:</span></label>
                        </div>
                        <div className="toggle-radio">
                            <div className='toggle-content'>
                            <input type="radio" name="interviewmode" id="interviewmode-1"
                                   value="Telephone/Video" defaultChecked checked={jobData.interview === "Telephone/Video"} onChange={handleInterviewModeChange}/>
                            <label htmlFor="interviewmode-1">Telephone/Video</label>
                            </div>
                            <div className='toggle-content'>
                            <input type="radio" name="interviewmode" id="interviewmode-3"
                                   value="In Person" checked={jobData.interview === "In Person"} onChange={handleInterviewModeChange}/>
                            <label htmlFor="interviewmode-3">In Person</label>
                            </div>
                            <div className='toggle-content'>
                            <input type="radio" name="interviewmode" id="interviewmode-2"
                                   value="Both" checked={jobData.interview === "Both"} onChange={handleInterviewModeChange}/>
                            <label htmlFor="interviewmode-2">Both</label>
                            </div>
                        </div>
                        {/*--------------------------- No of Rounds of Interview -----------------------*/}
                        

                        <div className="job-worktype">
                                <div className="interview-rounds">
                            <label>No of Rounds of Interview<span className="labelcol" >:</span> <span className="required-field">*</span></label>
                            <input className="interview-input" type="text" placeholder="Total Interview Rounds: 3"
                                   value={jobData.no_of_rounds_interview} onChange={handleNoOfRoundOfInterviewChange} ref={interviewNumberRef}/>
                        {error.no_of_rounds_interview && (
                            <div className="text-danger" ref={interviewNumberRef}>{error.no_of_rounds_interview}</div>
                        )}
                        </div>

                            {/*--------------------------- Job Mode   -----------------------*/}

                            <div className="relocation">
                                <label>Job Mode<span className="labelcol" >:</span></label>
                                <div className="toggle-radio">
                                    <div className='toggle-content'>
                                    <input type="radio" name="job_mode" id="job_mode-1" value="Onsite/Hybrid" defaultChecked  onChange={handleJobModeChange}
                                           checked={jobData.job_mode === "Onsite/Hybrid"}/>
                                    <label htmlFor="job_mode-1">Onsite/Hybrid</label>
                                    </div>
                                    <div className='toggle-content'>
                                    <input type="radio" name="job_mode" id="job_mode-2" value="Remote" onChange={handleJobModeChange} checked={jobData.job_mode === "Remote"}/>
                                    <label htmlFor="job_mode-2">Remote</label>
                                    </div>
                                </div>
                            </div> 

                        </div>
                         <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>
                        {/*--------------------------------------Benefits ---------------*/}
                        <div className="formheading">
                            <img src={benefitsImage}/>
                            <h5>Benefits</h5>
                        </div>
                        {/*--------------------------- Compensation -----------------------*/}
                        <div className="job-worktype">
                            <div className="compension-type">
                                <label>Compensation/Salary<span className="labelcol" >:</span></label>
                                <div className="toggle-radio">
                                    <div className='toggle-content'>
                                    <input type="radio" name="compensation" id="compensation-1"
                                           value="Hourly" onChange={handleCompensationChange}  checked={jobData?.compensation === "Hourly"} />
                                    <label htmlFor="compensation-1">Hourly</label>
                                    </div>
                                    <div className='toggle-content'>
                                    <input type="radio" name="compensation" id="compensation-2"
                                          value="Annual" onChange={handleCompensationChange} checked={jobData?.compensation === "Annual"}/>
                                    <label htmlFor="compensation-2">Annual</label>
                                    </div>
                                </div>
                            </div>
                            <div className="hourly">
                                <input type="text" placeholder={isCompensationClicked ? compensationPlaceholder :'Hourly Rate (USD)'} value={jobData.compensation_rate}
                                       onChange={handleCompensationRateChange} ref={compensationRef}/>

                                {error.compensation_rate && (
                                    <div className="text-danger" ref={compensationRef}>{error.compensation_rate}</div>)}


                            </div>

                        </div>
                        {/*--------------------------- Bonus -----------------------*/}
                        {(isAnnuallyClicked || jobData?.compensation === "Annual") && (
                        <div className="job-worktype">
                            <div className="compension-type">
                                <label>Bonus<span className="labelcol" >:</span></label>
                                <div className="toggle-radio">
                                    <div className='toggle-content'>
                                    <input type="radio" name="bonus" id="bonus-2"  value="No" checked onChange={handleBonusNoChange}/>
                                    <label htmlFor="bonus-2">No</label>
                                    </div>
                                    <div className='toggle-content'>
                                    <input type="radio" name="bonus" id="bonus-1" 
                                           value="Yes" defaultChecked onChange={handleBonusYesChange} checked={jobData?.bonus === "Yes"} />
                                    <label htmlFor="bonus-1">Yes</label>
                                    </div>
                                </div>
                            </div>
                            {(isBonusYesClicked || jobData?.bonus === "Yes") && (
                            <div className="hourly">
                                <input type="text" placeholder="Bonus %" value={jobData.bonus_percentage}
                                       onChange={handleBonusPercentageChange} ref={bonusRef}/>


                                {error.bonus_percentage && (
                                    <div className="text-danger" ref={bonusRef}>{error.bonus_percentage}</div>)}

                            </div>
                            )}


                        </div>
                        )}
                       <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>

                        {/*--------------------------------------Description ---------------*/}
                        <div className="formheading">
                            <img src={jobDescriptionImage}/>
                            <h5>Job Description  <span className="required-field">*</span></h5>
                        </div>


                        <div className="textdesc">
                            <input hidden={true} ref={descriptionRef}/>

                            <CKEditor
                                editor={ ClassicEditor }
                                data={jobData?.description}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    const characterLimit = 500; // Set your desired character limit here
                                    const characterCount = data.length;

                                    let inputString = data;

                                    let wordPattern = /(<.*?>|\b\w+\b)/g;
                                    let wordsAndTags = inputString.match(wordPattern);
                                    let words = [];
                                    if(wordsAndTags !== null ){
                                        wordsAndTags.forEach(function(item) {
                                            // Check if the item is a word (not HTML tag)
                                            if (!item.startsWith("<")) {
                                                words.push(item);
                                            }
                                        });
                                    }

                                    setJobData((prevJobData) => ({
                                        ...prevJobData,
                                        description: data, // Update the description as CKEditor content changes
                                    }));
                                    if (characterCount < characterLimit) {
                                        setError((prevError) => ({
                                            ...prevError,
                                            description: `At least ${characterLimit} characters required for job description!`
                                        }));
                                    } else {
                                        setError((prevError) => ({
                                            ...prevError,
                                            description: ""
                                        }));
                                    }
                                    //console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    //console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    //console.log( 'Focus.', editor );
                                } }
                            />
                        </div>

                        {error.description && (
                            <div className="text-danger" ref={descriptionRef}>{error.description}</div>)}

                        <div className="app" style={{marginTop: "24px"}}>
                            <label style={{paddingBottom:"10px"}}>Skills<span className="labelcol" >:</span></label>
                            <div className="dropdown-container">
                                <Select
                                    options={optionsSkills}
                                    placeholder="Select Skills"
                                    value={selectedOptions}
                                    onChange={handleSelect}
                                    isSearchable={true}
                                    isMulti

                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px'
                                        }),
                                    }}
                                />
                            </div>
                        </div>


                        {/*---------------------------Year of Experience-----------------------*/}
                        <div className="job-year">
                            <label>Years of Experience<span className="labelcol" >:</span> <span className="required-field">*</span></label>

                            <Select
                                id="years_of_experience"
                                name="term-contract"
                                options={years_of_experience_options}
                                value={selectedYearOfExperience}
                                onChange={(selectedOption) => {
                                    setSelectedYearOfExperience(selectedOption);
                                    const id = selectedOption ? parseInt(selectedOption.value) : null;
                                    setJobData((prevJobData) => ({
                                        ...prevJobData,
                                        years_of_experience_id: id,years_of_experience_label:selectedOption.label

                                    }));
                                    setError((prevError) => ({
                                        ...prevError,
                                        years_of_experience_id: ""
                                    }));
                                }}
                                placeholder="Years of Experience"
                                isSearchable
                                required
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        padding: '4px 5px',
                                        border: '1px solid rgba(46, 46, 46, 0.5)',
                                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                        borderRadius: '8px'
                                    }),
                                }}
                            />

                        </div>
                        {error.years_of_experience_id && (
                            <span className="text-danger" ref={yearOfExperienceRef}>{error.years_of_experience_id}</span>)}



                        {/*--------------------------- Certification -----------------------*/}
                        <div className="job-worktype">
                            <div className="compension-type">
                                <label>Certification Required<span className="labelcol" >:</span></label>
                                <div className="toggle-radio">
                                    <div className='toggle-content'>
                                    <input type="radio" name="certification" id="certification-2" checked={jobData.required_certification === "No"}  value="No" onChange={handleCertificationNoChange}/>
                                    <label htmlFor="certification-2">No</label>
                                    </div>
                                    <div className='toggle-content'>
                                    <input type="radio" name="certification" id="certification-1" checked={jobData.required_certification === "Yes"}  value="Yes" onChange={handleCertificationYesChange} />
                                    <label htmlFor="certification-1">Yes</label>
                                    </div>
                                </div>
                            </div>
                            {isCertificationYesClicked && (

                                <div className="certi-type">
                                    <label>Certification Type<span className="labelcol" >:</span></label>
                                <input className="certi-type-input" cla type="text" placeholder="Certification Type" value={jobData.certification_type }
                                       onChange={handleCertificationTypeChange} ref={certificateRef}/>



                                    {error.certification_type && (
                                        <div className="text-danger" ref={certificateRef}>{error.certification_type}</div>)
                                    }
                                </div>
                            )}


                        </div>

                        <div className="checkbox-container" style={{ marginTop: '24px' }}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="pj-checkbox-text">I confirm all details are accurate.</span>
                            </label>

                            {error.can_post && (
                                <div className="text-danger" ref={certificateRef}>{error.can_post}</div>)
                            }
                        </div>


                         <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>
                        <div className="crudbtn">
                            {from && <button onClick={()=>navigate(from)}>Cancel</button>}
                            {/* <button onClick={handlePreview}>Preview</button>
                            <button>Save For Later</button> */}
                            <button onClick={handleEditJob}>

                                Update Job Now

                            </button>
                        </div>
                        {success.successMessage &&
                            <div style={{textAlign:"center",marginTop:"20px"}}>
                        <span className="text-success"><h4>{success.successMessage}</h4></span>
                            </div>
                                }


                    </form>
                </div>
            </div>
            <EmployerSideBars
                dashboardCheck ={false}

            />
        </div>
    );
}

export default EditJob;