import React,{useState} from "react"
import {MdFavorite} from "react-icons/md";
import {AiFillFilter, AiOutlineClose} from "react-icons/ai";
import {Modal} from "react-bootstrap";
import Select from "react-select";
import {CustomDate} from "./SearchJobFilter/CustomDate";
import {JobWorkAuthorization} from "./SearchJobFilter/JobWorkAuthorization";
import {JobExperience} from "./SearchJobFilter/JobExperience";
import {JobEmploymentType} from "./SearchJobFilter/JobEmploymentType";
import {JobType} from "./SearchJobFilter/JobType";
import {JobMode} from "./SearchJobFilter/JobMode";
import {BsThreeDots} from "react-icons/bs";
import {AllFavJobs} from "./SearchJobFilter/AllFavJobs";
import {CustomModalStyle} from "../CustomModalStyle";
import { BsArrowLeft } from 'react-icons/bs';
import {useNavigate} from "react-router-dom";
export const SearchJobfilter = ({
                                    setFilter,
                                    setSelectedWorkAuthorization,
                                    selectedWorkAuthorization,
                                    work_authorization_options,
                                    handleEndDateChange,
                                    custom_date2,
                                    handleStartDateChange,
                                    custom_date1,
                                    options,
                                    handleOptionChange,
                                    filter,
                                    handleJobMode,
                                    handleJobType,
                                    handleEmploymentTypeChange,
                                    handleExperienceChange,
                                    resumeCounter,
                                    handleRemoveClick,
                                    showRemoveButton,
                                    activeResumeId,
                                    handleDropdownClick,
                                    dropdownRef,
                                    handleResumeClick,
                                    handleCompanyImg,
                                    favoriteResumes,
                                    viewAllFavJobsHandler,
                                   })=>{
    const [showFilter, setShowFilter] = useState(false)
    const [showFavJobs, setShowFavJobs] = useState(false)
    const navigate = useNavigate()

    const showFilterhander = ()=>{
        setShowFilter(true)
    }
    const closeFilter = ()=>{
        setShowFilter(false)
    }
    const showFavResumeHandler = ()=>{
        setShowFavJobs(true)
    }
    const closeFavJobsHandler = ()=>{
        setShowFavJobs(false)
    }

    return(
        <>
            <div className="d-flex gap-2 justify-content-between align-items-center p-2 sidebarFilter mb-2">
                <BsArrowLeft role='button' className='AJbackbtn' onClick={()=>navigate(-1)}></BsArrowLeft>
                <h2 className='heading'>Search Jobs</h2>
                <div className='d-flex gap-2'>
                <MdFavorite size={24} role="button" title="Favorite Jobs" style={{color:"#003580"}} onClick={showFavResumeHandler}></MdFavorite>
                <AiFillFilter size={24} role="button" title="Filters" onClick={showFilterhander} style={{color:"#003580"}}></AiFillFilter>
                </div>
            </div>
            {/*Filter Modal*/}
            <Modal show={showFilter} onHide={closeFilter} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title >Filters</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeFilter}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div className='p-1'>

                        {/*-------------------Custom Date ----------- */}
                            <CustomDate
                                filter={filter}
                                handleOptionChange={handleOptionChange}
                                options={options}
                                custom_date1={custom_date1}
                                handleStartDateChange={handleStartDateChange}
                                custom_date2={custom_date2}
                                handleEndDateChange={handleEndDateChange}
                            />
                            {/*-------------------Work Authorization ----------- */}
                             <JobWorkAuthorization
                                 work_authorization_options={work_authorization_options}
                                selectedWorkAuthorization={selectedWorkAuthorization}
                                setSelectedWorkAuthorization={setSelectedWorkAuthorization}
                                setFilter={setFilter}
                             />
                            {/*-----------------Experience ------------- */}
                             <JobExperience
                                 resumeCounter={resumeCounter}
                                filter={filter}
                                handleExperienceChange={handleExperienceChange}
                             />

                            {/*-----------------------Employment Type --------------- */}
                               <JobEmploymentType
                                   resumeCounter={resumeCounter}
                                   filter={filter}
                                   handleEmploymentTypeChange={handleEmploymentTypeChange}
                               />
                            {/*-----------------------Job Type --------------- */}
                               <JobType
                                    resumeCounter={resumeCounter}
                                    filter={filter}
                                    handleJobType={handleJobType}
                               />

                            {/*-----------------------Job Mode --------------- */}
                             <JobMode
                                 handleJobMode={handleJobMode}
                                filter={filter}
                                resumeCounter={resumeCounter}
                             />
                    </div>
                </Modal.Body>
            </Modal>

            {/*Fav Jobs Modal*/}
            <Modal show={showFavJobs} onHide={closeFavJobsHandler} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title >Jobs</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeFavJobsHandler}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div className='p-1'>
                        <AllFavJobs
                            viewAllFavJobsHandler={viewAllFavJobsHandler}
                            handleRemoveClick={handleRemoveClick}
                            dropdownRef={dropdownRef}
                            handleResumeClick={handleResumeClick}
                            handleCompanyImg={handleCompanyImg}
                            favoriteResumes={favoriteResumes}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <CustomModalStyle />
        </>
    )
}