import React from "react";
import Select from "react-select";
import EditResumeImage from "../../assets/editresume.svg";
import {NotUsaLocation} from "./NotUsaLocation";


export const UserLocation = ({
                                 isLocationSectionVisible,
                                 optionsZipCodes,
                                 selectedZipCodeOptions,
                                 handleSelectZipCode,
                                 handleLocationInputChange,
                                 handleSaveLocation,
                                 checkCancels,
                                 personalInfo,
                                 handleEditLocation,
                                 setPersonalInfo,
                                 selectedCountry,
                                 setSelectedCountry,
                                 selectedState,
                                 setSelectedState,
                                 selectedCity,
                                 setSelectedCity,
                                 handleEditLocation1
                             })=>{


    const handleUSBasedChange=(e)=>{
        const us_based=e.target.value;
        setPersonalInfo((prev) => ({
            ...prev,
            us_based: us_based,
        }))

    }
    return (
        <>
        <div className='pt-2 userLocation'>
            <label>Are you located in the United States?<span
                className="CRlabelcol"></span></label>
            <div className="toggle-radio">
                <div className='toggle-content'>
                <input
                    type="radio"
                    name="non-us-based"
                    id="non-us-based"
                    value="yes"
                    checked={personalInfo?.us_based === "yes"?true:false }
                    onChange={handleUSBasedChange}
                />
                <label htmlFor="non-us-based">Yes</label>
                </div>
                <div className='toggle-content'>
                <input
                    type="radio"
                    name="us-based"
                    id="us-based"
                    value="no"
                    checked={personalInfo?.us_based === "no"?true:false }
                    onChange={handleUSBasedChange}
                />
                <label htmlFor="us-based">No</label>
                </div>
            </div>
        </div>
            {
                personalInfo?.us_based === "yes" ? (
                    isLocationSectionVisible ? (
                        <section className="educationsec-CP">
                            <div className="educationdtl-CP">
                                <div className="institute-CP">
                                    <Select
                                        options={optionsZipCodes}
                                        placeholder="Search ZIP Code/City"
                                        // value={selectedZipCodeOptions}
                                        value={personalInfo?.location !== '' ? selectedZipCodeOptions : null}

                                        onChange={handleSelectZipCode}
                                        onInputChange={handleLocationInputChange}
                                        isSearchable={true}
                                        isMulti={false}
                                        isClearable={true}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                padding: '4px 5px',
                                                border: '1px solid rgba(46, 46, 46, 0.5)',
                                                boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                borderRadius: '8px',
                                            }),
                                        }}
                                    />
                                    <div className="savebtn-CP">
                                        <button onClick={handleSaveLocation}>
                                            {checkCancels.locationCancel ? 'Update' : 'Save'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    ) : (
                        <div>
                            <div>
                                <div className="expdtl-CP">
                                    <div className="row">
                                        <div className="col-lg-11 col-md-12 col-sm-12">
                                            <div className="location-CP">
                                                <p>
                                                    Location: <span>{personalInfo.location}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-12 col-sm-12">
                                            <img
                                                src={EditResumeImage}
                                                alt="Edit"
                                                onClick={() => handleEditLocation(personalInfo?.location)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                ) :
                    (
                        <NotUsaLocation
                            isLocationSectionVisible={isLocationSectionVisible}
                            personalInfo={personalInfo}
                            setPersonalInfo={setPersonalInfo}
                            handleSaveLocation={handleSaveLocation}
                            checkCancels={checkCancels}
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                            selectedState={selectedState}
                            setSelectedState={setSelectedState}
                            selectedCity={selectedCity}
                            setSelectedCity = {setSelectedCity}
                            handleEditLocation1={handleEditLocation1}
                        />
                    )
            }
        </>
    )

}
