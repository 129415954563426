import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from '../src/assets/css/LoadingComponent.module.css';
import headerIcon2 from '../src/assets/headericone2.svg';
import { BsArrowLeft } from 'react-icons/bs';
import EmployerHeader from "./EmployerHeader";
import {BASE_URL, STORAGE_URL} from "./config";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import {AiTwotoneHeart} from "react-icons/ai";
import {EmployerSideBars} from "./components/Sidbars/EmpSideBars";
import styles from './assets/css/favoriteResumes.module.css'
import Helper from "./Helper";
import ErrorModal from "./ErrorModal";


function FavoriteResumes() {
    const location = useLocation();
    const navigate = useNavigate();
    const { defaultfrom } = location.state || {}; // Get the passed state data
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("access_token");
    const [favoriteResumes, setFavoriteResumes] = useState([]);
    const employerId = localStorage.getItem("employerId");
    const [notificationCounter, setNotificationCounter] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const resumesPerPage = 10;

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const fetchFavoriteResumes = async () => {
        setLoading(true);
        try {
            // Fetch favorite resumes using the provided endpoint and payload
            const response = await fetch(`${BASE_URL}/resumes/get_favorite`, {
                method: "POST",
                headers: {
                    ...headers,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    employer_id: employerId, // Update with your employer_id
                }),
            });

            const data = await response.json();
            if (data.status === 200) {
                setFavoriteResumes(data.favorite_resume);
            }
            else if (data.status==404) {
                setFavoriteResumes([]);
            }
            else if (data.status==401) {
                navigate('/');
            }
        } catch (error) {
            console.error("Error fetching favorite resumes:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFavoriteResumes();
    }, []);

    const handleViewMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const visibleFavoriteResumes = favoriteResumes.slice(
        0,
        currentPage * resumesPerPage
    );
// Function to show modal with title and message
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const showModalWithMessage = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setShowModal(true);
    };
    // const handleResumeClick = (resumeId) => {
    //     const from="/fav-resumes";
    //     navigate('/resumes', { state: { resumeId,from } });
    // };
    const handleResumeClick = (resumeId) => {
        setLoading(true)
        axios.get(`${BASE_URL}/get-user-data`, {
            headers,
        })
            .then((res) => {
                const userData = res.data.user
                const viewedResumesIds=userData?.userable?.viewed_resumes_ids;
                const subscription = userData.userable.subscription;
                // Check if the resume has been viewed
                const viewedResumeIds = viewedResumesIds?.map(viewedResume => viewedResume?.resume_id);
                if (viewedResumeIds?.includes(resumeId)) {
                    setLoading(false);
                    const from="/fav-resumes";
                    navigate('/resumes', { state: { resumeId,from } });
                    return;
                }
                // Check if subscription is expired
                const endDate = new Date(subscription.end_date);
                const server_time = new Date(res.data?.server_time);

                if (endDate < server_time) {
                    setLoading(false)
                    showModalWithMessage("Subscription Expired", "Please contact admin to renew your subscription.");
                    return;
                }

                // Check if remaining jobs are zero or less
                if (subscription.remaining_views <= 0) {
                    setLoading(false)
                    showModalWithMessage("No Remaining Views", "Your resumes views limit has been reached. Please contact admin for assistance.");
                    return;
                }
                // If subscription is active and Views are remaining, navigate to post job page
                const from="/fav-resumes";
                navigate('/resumes', { state: { resumeId,from } });
            })
            .catch((err) => {
                setLoading(false)
                console.error('Error fetching user data:', err);
            });
    };


    const handleConfirmDeleteResume = async (resumeId) => {
        setLoading(true);
        const url = `${BASE_URL}/fav_resumes/${resumeId}`
        const method = 'DELETE';
        const payload = {
            employer_id: employerId,
            resume_id: resumeId
        };

        try {
            const response = await axios({
                method: method,
                url: url,
                headers: headers,
                data: payload  // Axios uses 'data' instead of 'body' for the request payload
            });

            const data = response.data;
            console.log(data)
            if(data.status==200){
                fetchFavoriteResumes();

            }
            else if(data.status==401){
                navigate("/")
            }
            // Handle the response data here
        } catch (error) {
            // Handle errors here
        }
    };

    const handleRedirection = ()=>{
        navigate(-1);
    }
    return (
        <div>
            <ErrorModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                title={modalTitle}
                message={modalMessage}
            />
            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            <EmployerHeader
                setNotificationCounter={setNotificationCounter}
            />
            <div className="container">
                <div className="JBmain">


                    <div className="RDheadtitle fvthead ">
                        <BsArrowLeft className='AJbackbtn' role='button' onClick={handleRedirection}></BsArrowLeft>
                        <h2>Favorite Resumes</h2>
                        <span></span>
                    </div>
                    <div className={styles.resumeContent}>
                        <div className={styles.resumeContent_scroll}>


                            {visibleFavoriteResumes.length > 0 &&  visibleFavoriteResumes.map((resume)=>(
                                    <div key={resume.resume.id} className={`${styles.resume_content} d-flex gap-2 p-2 align-items-center`}
                                         onClick={() => handleResumeClick(resume.resume.id)}
                                    >
                                        {console.log(resume?.resume?.job_seeker?.user)}
                                        {Helper?.profile_log(resume?.resume?.job_seeker?.user)}

                                        <div className='d-flex flex-column'>
                                            <p className="m-0">
                                                <strong>{`${resume.resume.first_name} ${resume.resume.last_name}`}</strong></p>
                                            <small className={styles.resume_content_text}>
                                                <strong>Preferred Job:</strong> {resume.resume.preferred_job_title}
                                            </small>
                                            <small className={styles.resume_content_text}>
                                                <strong>Contract Type:</strong> {resume.resume.contract_type}
                                            </small>
                                            <small className={styles.resume_content_text}>
                                                <strong>Relocation:</strong> {resume.resume.relocation}
                                            </small>
                                        </div>
                                    </div>
                                ))}

                            {visibleFavoriteResumes.length < favoriteResumes.length && (
                                <p className="text-center m-0 p-2 text-primary" role="button" onClick={handleViewMore}>View More</p>
                            )}
                            {
                                favoriteResumes.length == 0 &&(
                                    <p className="text-center m-0 p-2 text-danger" role="button" >No favorite resume found!</p>
                                )
                            }

                        </div>

                    </div>

                </div>

            </div>
            <EmployerSideBars
                dashboardCheck ={false}
                notificationCounter={notificationCounter}
            />
        </div>
    )
}

export default FavoriteResumes;
