import React, {useEffect, useRef, useState} from 'react';
import logoImage from '../src/assets/logo4.svg';

import { useNavigate } from 'react-router-dom';
import '../src/assets/css/header.css';
import '../src/assets/css/styles.css';

import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import BASE_URL from './config';
import { addUser, setResumeStatus } from './store/slices/UserSlice';
import UserFetch from './UserFetch';
import { useMediaQuery } from 'react-responsive';
import { MainHeader, MiniHeader, MobileHeader } from './components/Header/Header';
import { employer_variable } from './components/Header/variables';
import {BsBriefcase} from "react-icons/bs";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import colors from "./colors";
import {TbBellRinging2} from "react-icons/tb";
import ErrorModal from "./ErrorModal";
import style from "./assets/css/LoadingComponent.module.css";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Pusher from 'pusher-js';
import ButterToast, { POS_TOP, POS_RIGHT,Cinnamon } from 'butter-toast';



function Header({
                    setNotificationCounter
                }) {
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const user_data = useSelector((state) => {
        return state?.user?.user;
    });
    const position = {
        vertical: POS_TOP,
        horizontal: POS_RIGHT
    };
    if (!user_data) {
        UserFetch()
            .then((response) => {
                const user_data = response.data.user;
                console.log("FFFFFFFFFFFFFFFF")
                console.log(user_data)
                dispatch(addUser(user_data));
            })
            .catch((error) => {
                console.error('Error fetching user:', error);
            });
    }
    // Function to show modal with title and message
    const showModalWithMessage = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setShowModal(true);
    };
    const [notifications, setNotifications] = useState([]);
    const [serverTime, setServerTime] = useState('')

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isTablet = useMediaQuery({ query: '(max-width: 930px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [count, setCount] = useState(0);
    const [unreadCount, setUnreadCount] = useState(0)
    const [showNotifcation, setShowNotifcation] = useState(false)
    const token = localStorage.getItem('access_token'); // Get the token from localStorage
    const headers = {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    };
    const [loading, setLoading] = useState(false);
    // Pusher code start
    const [isToastOpen, setIsToastOpen] = useState(false);
    const toastIdRef = useRef(null);

    useEffect(() => {
        const pusher = new Pusher('c6c602043d0983387557', {
            cluster: 'ap2',
            forceTLS: true
        });
        console.log(pusher);
        const channel = pusher.subscribe('channel-active-for-jobs');

        const handleNotification = (data) => {
            console.log('Notification aya ', data?.count);
            console.log(data?.notification.content);
            console.log(user_data?.followings)
            if (user_data && user_data?.followings) {
                let isFollowing = user_data?.followings?.some(
                    (following) => following?.id === data?.notification?.user_id
                );
                if (isFollowing) {
                    // toast.info(data?.notification.content, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: false, // Disable auto closing
                    //     onClick: () => handleNotificationClick(data?.notification?.user_id), // Pass user_id directly
                    //     // You can add other options as needed
                    // });
                    ButterToast.raise({
                        content:     <Cinnamon.Crisp
                            scheme={Cinnamon.Crisp.SCHEME_BLUE}
                            content={data?.notification.content}
                            title="Active For Job"
                            namespace="top" // Optional namespace
                            style={{ top: '50px' }} // Customizing position to override default top position
                        />,
                        onClick: () => handleNotificationClick(data?.notification?.user_id), // Pass user_id directly
                        sticky:true
                    });
                } else {
                    // Handle the case where the user is not following
                }
            } else {
                // Handle the case where user data or followings are not available
            }
        };

        channel.bind('active-for-jobs-event', handleNotification);

        return () => {
            channel.unbind('active-for-jobs-event', handleNotification);
            pusher.unsubscribe('channel-active-for-jobs');
            pusher.disconnect();
        };
    }, []);


    const handleNotificationClick = (userId) => {
        // if (notification) {
        localStorage.setItem('redirection', '/profile-view');
        // const userId = notification.user_id;
        // console.log('NOTOFY   '+notification.user_id)
        // setNotification(null)
        navigate('/profile-view', { state: { user_id: userId } });
        // }
    };
    const postAJob = () => {
        setLoading(true)

        axios.get(`${BASE_URL}/get-user-data`, {
            headers,
        })
            .then((res) => {
                const userData = res.data.user;
                const subscription = userData.userable.subscription;

                // Check if subscription is expired
                const server_time = new Date(res.data?.server_time);
                const endDate = new Date(subscription.end_date);
                if (endDate < server_time) {
                    setLoading(false)
                    showModalWithMessage("Subscription Expired", "Please contact admin to renew your subscription.");
                    return;
                }

                // Check if remaining jobs are zero or less
                if (subscription.remaining_jobs <= 0) {
                    setLoading(false)
                    showModalWithMessage("No Remaining Jobs", "Your job postings limit has been reached. Please contact admin for assistance.");
                    return;
                }

                // If subscription is active and jobs are remaining, navigate to post job page
                navigate('/post_a_job');
            })
            .catch((err) => {
                setLoading(false)

                console.error('Error fetching user data:', err);
            });
    };
    const signInClick = () => {
        let keysToExclude = ['email', 'password', 'userName', 'logo', 'userable_type'];
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (!keysToExclude.includes(key)) {
                localStorage.removeItem(key);
            }
        }
        navigate('/employer-login');
    };
    const SearchResume = () => {
        navigate('/search_resume');
    };
    const handleLogoClick = () => {
        navigate('/employer_dashboard');
    };
    const handleAllPostClick = () => {
        const defaultfrom = '/employer_dashboard';
        navigate('/alljobs', { state: { defaultfrom } });
    };
    const handleProfileClick = () => {
        navigate('/create_profile');
    };
    const viewProfileClick = () => {
        navigate('/my-profile-view');
    };
    const showSearchResume = () => {
        navigate('/search_resume');
    };
    const showFavResume = () => {
        navigate('/fav-resumes');
    };

    useEffect(() => {
        // Fetch notifications from the API
        axios
            .get(`${BASE_URL}/notifications`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if(response?.data?.notifications){

                    setNotifications(response?.data?.notifications);
                    let count = 0
                    response?.data?.notifications?.map((notif)=>{
                        if(notif?.read === 0){
                            count++;
                        }
                    })
                    // setNotificationCounter(count)
                    setUnreadCount(count)
                    setServerTime(response?.data?.server_time);
                }


            })
            .catch((error) => {
                console.error('Error fetching notifications:', error);
            });
    }, [token]); // Include any dependencies that trigger the fetch


    useEffect(() => {}, [count]);
    useEffect(() => {
        if (count === 0) {
            axios
                .get(`${BASE_URL}/get-user-data`, {
                    headers,
                })
                .then((res) => {
                    setCount(1);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const showNoficationModal = ()=>{
        setShowNotifcation(!showNotifcation)
    }


    return (
        <div className="nav bg-white ">
            <ToastContainer/>
            <ButterToast position={position}/>

            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            <ErrorModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                title={modalTitle}
                message={modalMessage}
            />
            <div className="container-fluid">
                <div className="row navpad">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="col-lg-4 logosearch">
                            <div className="d-flex align-items-center"> {/* Added a flex container */}
                                <a href="javascript:void(0)" onClick={handleLogoClick}>
                                    {' '}
                                    <img className="main_image" src={logoImage} alt="Logo" />
                                </a>

                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="link"
                                    id="dropdown-basic-button"
                                    style={{ color: colors.primaryColor, cursor: 'pointer' }}
                                >
                                    <BsBriefcase size={24} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={postAJob}>
                                        Post Job
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleAllPostClick}>
                                        All Jobs
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <style>
                                {`
                                  .dropdown-toggle::after {
                                   display:none;
                                  }
                                `}
                            </style>

                            <div className="icon-container"  onClick={showNoficationModal}>
                                <TbBellRinging2 className="icon_color" size={24}></TbBellRinging2>

                                {unreadCount > 0 &&(
                                    <small className="number-badge" dangerouslySetInnerHTML={{ __html: unreadCount > 99 ? '99<sup>+</sup>' : unreadCount }}></small>

                                 )}
                            </div>

                        {isTablet ? (
                            isMobile ? (
                                <MobileHeader
                                    notifications={notifications}
                                    showNotifcation={showNotifcation}
                                    setShowNotifcation={setShowNotifcation}
                                    serverTime={serverTime}
                                    viewProfileClick={viewProfileClick}
                                    signInClick={signInClick}
                                    showJobSearchHandler={showSearchResume}
                                    handleProfileClick={handleProfileClick}
                                    showFavoriteHandler={showFavResume}
                                    viewAllAppliedJobsHandler={handleAllPostClick}
                                    postAJob={postAJob}
                                    jobSeach={SearchResume}
                                    variables={employer_variable}
                                />
                            ) : (
                                <MiniHeader
                                    notifications={notifications}
                                    showNotifcation={showNotifcation}
                                    setShowNotifcation={setShowNotifcation}
                                    serverTime={serverTime}
                                    viewProfileClick={viewProfileClick}
                                    signInClick={signInClick}
                                    showJobSearchHandler={showSearchResume}
                                    handleProfileClick={handleProfileClick}
                                    showFavoriteHandler={showFavResume}
                                    viewAllAppliedJobsHandler={handleAllPostClick}
                                    postAJob={postAJob}
                                    jobSeach={SearchResume}
                                    variables={employer_variable}
                                />
                            )
                        ) : (
                            <MainHeader
                                notifications={notifications}
                                showNotifcation={showNotifcation}
                                setShowNotifcation={setShowNotifcation}
                                serverTime={serverTime}
                                viewProfileClick={viewProfileClick}
                                signInClick={signInClick}
                                showJobSearchHandler={showSearchResume}
                                handleProfileClick={handleProfileClick}
                                showFavoriteHandler={showFavResume}
                                viewAllAppliedJobsHandler={handleAllPostClick}
                                postAJob={postAJob}
                                jobSeach={SearchResume}
                                variables={employer_variable}
                            />
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Header;
