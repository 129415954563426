import React, {useState, useEffect} from "react";
import './assets/css/header.css';
import '../src/assets/css/login.css';
import styles from '../src/assets/css/LoadingComponent.module.css';

import logoImage from '../src/assets/logo4.svg';
import emailImage from '../src/assets/emailplaceholder.svg';
import passwordImage from '../src/assets/passwordplaceholder.svg';
import watchPasswordImage from '../src/assets/watchpassword.svg';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import BASE_URL, {STORAGE_URL} from "./config";
import { BsArrowRight } from 'react-icons/bs';
import { useDispatch } from "react-redux";
import {addUser} from "./store/slices/UserSlice";
import {IoEyeOffOutline, IoEyeOutline} from "react-icons/io5";

function Login() {

    const [loginStatus, setLoginStatus] = useState(false)
    const [userInfo, setUserInfo] = useState({
        name: '',
        logo: ''
    });
    const [userType, setUserType] = useState(1)
    const [type_error, setType_error] = useState('')

    const invaidCredDivStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    };
    const navigate = useNavigate();
    const dispatch=useDispatch();

    useEffect(()=>{
        let oldEmail = localStorage.getItem('email');
        let oldPassword = localStorage.getItem('password');
        let UserName = localStorage.getItem('userName');
        let image = localStorage.getItem('logo')
        let type = localStorage.getItem('userable_type')
        if(oldEmail !== null &&
            oldPassword !== null &&
            UserName !== null &&
            image !== null &&
            type !== null
        ){
            setLoginStatus(true);
            setPassword(oldPassword);
            setEmail(oldEmail)
            setUserInfo({
                name: UserName,
                logo: image
            })
            if(type === 'App\\Models\\Employer'){
                setUserType(1)
            }else{
                setUserType(2)
            }
        }

    },[loginStatus])

    const signupClick = () => {
        navigate('/signup');
    };
    const dashboard = () => {
        navigate('/employer_dashboard');
    }
    const forgotPasswordClick = () => {
        navigate('/forgot_password');
    };

    const anotherUserLogin = ()=>{
        setEmail('')
        setPassword('')
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('userName');
        localStorage.removeItem('logo')
        localStorage.removeItem('userable_type')
        setLoginStatus(false)

    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);


    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const passwordVisible = () => {
        console.log(isPasswordVisible)
        setIsPasswordVisible(!isPasswordVisible);
    }
    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSignIn();
        }
    };
    const handleSignIn = (e) => {

        if(e){
            e.preventDefault();
        }

        let userable_type
        if(userType === 0){
            setType_error('Please Select user Type')
            return false
        }else{
            if(userType == 1){
                userable_type= 'App\\Models\\Employer'
            }else{
                userable_type= 'App\\Models\\JobSeeker'
            }
        }

        setLoading(true);
        setIsPasswordVisible(false);
        setError([]);
        const payload = {
            email: email,
            password: password,
            userable_type
        };

        axios.post(`${BASE_URL}/login`, payload)
            .then(async response => {
                setLoading(false);
                if (response?.data?.status == 200) {
                    localStorage.clear();

                    dispatch(addUser(response?.data?.user));

                    localStorage.setItem('userName', response?.data?.user?.firstname +' '+ response?.data?.user?.lastname)
                    localStorage.setItem('logo', response?.data?.user?.image)
                    localStorage.setItem('email', email)
                    localStorage.setItem('password', password)
                    const userId = response.data.user.id
                    const userable_type = response.data.user.userable_type;
                    localStorage.setItem('userId', userId)
                    localStorage.setItem('access_token',response.data.access_token)
                    localStorage.setItem('userable_type',userable_type);

                    if(userType == 1){
                        const employerId = response.data.user.userable_id;
                        localStorage.setItem('employerId', employerId);
                        navigate("/employer_dashboard");
                    }else{
                        const jobSeekerId = response.data.user.userable_id;
                        localStorage.setItem('jobSeekerId', jobSeekerId);
                        let login = localStorage.getItem('login')
                        if(login == 0){
                            navigate("/login-attempt");
                        }else{
                            navigate("/job_seeker_dashboard");
                        }
                    }


                } else {
                    // alert(response.data.msg)
                    setError(response?.data);

                }
                // Do something with the response
            })
            .catch(error => {
                setLoading(false);

                setError(error.response.data);
            });
    }
    const handleLogoClick = () => {
        navigate("/employer-login");

    }
    const NewAcountRequest = ()=>{

        if(userType == 1){
            navigate("/request-for-subscription")
        }else{
            navigate('/jobseeker_signup');
        }

    }

    const handleSelectUser = (e)=>{
        setUserType(e.target.value)
        setType_error('')
    }
    return (


            <div className="mainbg">
                <div className="form">
                    <div style={{width: '100%'}}>


                        <div className='d-flex align-items-center mb-4 justify-content-center'>
                            <img onClick={handleLogoClick} className='' src={logoImage} alt="Logo"/>
                        </div>
                         <br/>
                         <br />

                        {
                            loginStatus? (
                                <div className='d-flex flex-column gap-3 align-items-center justify-content-center'>
                                <img className='login_logo' src={STORAGE_URL+userInfo?.logo} />
                                    <label className="checkbox-label">{userInfo?.name}</label>
                                </div>
                            ):(
                                <>
                                    <div className="emailinfo">
                                        <img src={emailImage} alt=""/>
                                        <input type="text" placeholder="Email" value={email}
                                               onChange={e => setEmail(e.target.value)} onKeyPress={handleKeyPress}/>
                                    </div>
                                    {error.email && <span className="text-danger">{error.email}</span>}
                                    <div className="emailinfo passwordinfo">
                                        <img src={passwordImage} alt=""/>
                                        <input type={isPasswordVisible ? "text" : "password"} placeholder="Password"
                                               value={password} onChange={e => setPassword(e.target.value)} onKeyPress={handleKeyPress}/>

                                        <div className="eye-icon-container" onClick={passwordVisible}>
                                            {isPasswordVisible ? (
                                                <IoEyeOutline className="eye-icon" size={24} />
                                            ) : (
                                                <IoEyeOffOutline className="eye-icon" size={24} />
                                            )}
                                        </div>
                                    </div>
                                    {error.password && <span className="text-danger">{error.password}</span>}
                                </>
                            )
                        }

                        <div style={invaidCredDivStyle}>
                            <span className="text-danger">{error.msg}</span>
                        </div>
                        {!loginStatus &&(
                            <>
                            <div className='d-flex align-items-center justify-content-between my-3'>
                                <div className='d-flex align-items-center'>
                                    <input
                                        type="radio"
                                        value='1'
                                        id="employer"
                                        name="user_type"
                                        onChange={handleSelectUser}
                                        checked={userType == 1? true: false}
                                    />
                                    <label className="checkbox-label" htmlFor='employer'>Employer</label>
                                    <br/>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <input
                                        type="radio"
                                        value='2'
                                        id="job_seeker"
                                        name="user_type"
                                        onChange={handleSelectUser}
                                        checked={userType == 2? true: false}
                                    />
                                    <label className="checkbox-label" htmlFor='job_seeker'>Job Seeker</label>
                                    <br/>
                                </div>
                            </div>
                            <span className='text-danger'>{type_error}</span>
                            </>
                        )}

                        <div className="formbtn">

                            <button onClick={handleSignIn}>Login
                                {loading && (
                                    <div className={styles.loadingOverlay}>
                                        <div className={styles.loadingSpinner}></div>
                                    </div>
                                )}
                            </button>
                        </div>
                            {loginStatus?(
                                    <button className='mt-2 another-btn' onClick={anotherUserLogin}>Login into another account
                                        {loading && (
                                            <div className={styles.loadingOverlay}>
                                                <div className={styles.loadingSpinner}></div>
                                            </div>
                                        )}
                                    </button>
                                ):
                                (
                                   <>
                                       <p className='text-center mt-2'>
                                        <a href="javascript:void(0)" onClick={forgotPasswordClick}>Forgot Password?</a>
                                        </p>
                                        <p className='text-center mt-2'>
                                           <a href="javascript:void(0)" onClick={NewAcountRequest}>If you don't have an account, click here?</a>
                                        </p>
                                   </>
                                )}

                    </div>
                </div>
            </div>


    );
}

export default Login;
