import React, {useState, useEffect} from "react"
import EmployerHeader from "./EmployerHeader";
import JobSeekerHeader from "./JobSeekerHeader";
import style from "./assets/css/LoadingComponent.module.css";
import {BsArrowLeft} from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import {UserInfo} from "./components/UserInfo/UserInfo";
import {useDispatch, useSelector} from "react-redux";
import styles from "./assets/css/notification.module.css";
import {STORAGE_URL} from "./config";
import avatar from "./assets/profile5.png";
import Helper from "./Helper";
import {DeleteComment, DeletePost, DeleteSharedPost, FollowUser, UnFollowUser} from "./components/ThreedotDropDown";
import {FaRegCommentDots, FaSpinner} from "react-icons/fa";
import {AiFillLike, AiOutlineClose, AiOutlineLike, AiOutlineShareAlt} from "react-icons/ai";
import {toast, ToastContainer} from "react-toastify";
import {Button, Form, Modal} from "react-bootstrap";
import {useMediaQuery} from "react-responsive";
import axios from "axios";
import BASE_URL from "./config";
import {Carousel} from 'react-responsive-carousel';
import {addUser} from "./store/slices/UserSlice";
import {EmployerSideBars} from "./components/Sidbars/EmpSideBars";
import {JobSeekerSiderBar} from "./components/Sidbars/JobSeekerSiderBar";

const Post =()=>{

    const [loading, setLoading] = useState(false);
    const[post, setPost] = useState({});
    const [images, setImages] = useState([]);
    const [serverTime, setServerTime] = useState('')
    const [likeLoading, setLikeLoading] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [likeClicked, setLikeClicked] = useState(false);
    const [likesCount, setLikesCount] = useState(0);
    const[commentsCount,setCommentsCount]=useState(0);
    const [isShared, setIsShared] = useState(false);
    const [sharesCount, setSharesCount] = useState();
    const [showComments, setShowComments] = useState(true);
    const [commentData, setCommentData] = useState();
    const [visibleComments, setVisibleComments] = useState(4);
    const [postComment, setPostComment] = useState('');
    const [originalHeight, setOriginalHeight] = useState('auto');
    const [commentLoading, setCommentLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [commentId, setCommentId] = useState(0)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showShareCommentModal, setShowShareCommentModal] = useState(false);
    const [shareComment, setShareComment] = useState("");
    const [showPostDropdown, setShowPostDropdow] = useState(false)
    const [openPostDropdownIndex, setOpenPostDropdownIndex] = useState(null)
    const [postId, setPostId] = useState(0)
    const [showDeletePostModal, setShowDeletePostModal] = useState(false)
    const [AuthUser, setAuthUser] = useState({})
    const[openFollowDropDownIndex, setOpenFollowDropDownIndex] = useState(null)
    const[showFollowDropdown, setShowFollowDropdown] = useState(false)
    const[openUnFollowDropDownIndex, setOpenUnFollowDropDownIndex] = useState(null)
    const[showUnFollowDropdown, setShowUnFollowDropdown] = useState(false)
    const [openSharePostDropdownIndex, setOpenSharePostDropdownIndex] = useState(null)
    const[showSharePostDropdown, setShowSharePostDropdown] = useState(false)
    const [sharePostId, setSharePostId] = useState(0)
    const [showDeleteSharePostModal, setShowDeleteSharePostModal] = useState(false)
    const [notificationCounter, setNotificationCounter] = useState(0)

    const userable_type = localStorage.getItem("userable_type");
    let headerComponent;
    if (userable_type === "App\\Models\\Employer") {
        headerComponent = <EmployerHeader
            setNotificationCounter={setNotificationCounter}
        />;
    } else {
        headerComponent = <JobSeekerHeader
            setNotificationCounter={setNotificationCounter}
        />;
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const isMobile = useMediaQuery({ query: '(max-width: 520px)' })
    const {id} = location.state || {};

    const user_data = useSelector((state) => {
        return state.user.user;
    });

    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const user_id = localStorage.getItem('userId'); // Get the user ID from localStorage

    useEffect(()=>{
        setLoading(true);

        axios
            .get(`${BASE_URL}/posts/${id}`, {
                headers,
            })
            .then((response) => {

                setPost(response?.data?.all_posts?.originalPost)
                setLikesCount(response?.data?.all_posts?.originalPost?.likes_count)
                setCommentsCount(response?.data?.all_posts?.originalPost?.comments_count)
                setSharesCount(response?.data?.all_posts?.originalPost?.shares_count)
                setCommentData(response?.data?.all_posts?.originalPost?.comments)
                setServerTime(response?.data?.all_posts?.server_time)
                setAuthUser(response?.data?.all_posts?.current_user)
                setImages(response?.data?.all_posts?.originalPost?.images || []);
                const like = response?.data?.all_posts?.originalPost?.likes?.some(like => like?.user_id === parseInt(user_id));
                setIsLiked(like);

                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching notifications:", error);
                setLoading(false);
            });
    },[])

    // useEffect(()=>{}, [isLiked])

    useEffect(() => {
        const userShared = post?.shares?.some(share => share?.user_id === parseInt(user_id));
        setIsShared(userShared);
    }, [post, user_id]);

    const handleTextareaInput = (e) => {
        setPostComment(e.target.value);

        if (e.target.value === '') {
            e.target.style.height = originalHeight;
        } else {
            e.target.style.height = 'auto';
            e.target.style.height = e.target.scrollHeight + 'px';
            setOriginalHeight( e.target.style.height)
        }
    }

    const handleSendComment = async (socialMediaPost) => {

        if (postComment.trim() === '') {
            return; // Don't send empty comments
        }
        setCommentLoading(true); // Start loading

        const payload = {
            post_id: socialMediaPost?.id,
            comment: postComment
        };

        try {
            const response = await axios.post(`${BASE_URL}/posts/comment`, payload,{headers});
            if (response?.data?.status === 200) {
                // Comment successfully posted, you can perform any necessary actions here
                console.log('Comment posted successfully');
                console.log(response.data.comment)
                setServerTime(new Date(response.data.server_time));

                setCommentData(precomments => [response.data.comment, ...precomments]);
                setPostComment('')
                setCommentLoading(false); // Stop loading
                let comments_count=commentsCount;
                comments_count++;
                setCommentsCount(comments_count)
                setOriginalHeight('auto')
                setTimeout(() => {
                    const commentTextarea = document.getElementById(`commentTextarea${post?.id}`);
                    if (commentTextarea) {
                        commentTextarea.focus();
                    }
                }, 100);
            } else if (response?.data?.status == 401) {
                navigate("/");
            }
        } catch (error) {
            setCommentLoading(false); // Stop loading

            if (error?.response?.status === 401) {
                navigate("/");
            }
            console.error('Error posting comment:', error);
        }
    };

    const handleShowMoreComments = () => {
        setVisibleComments(prevVisibleComments => prevVisibleComments + 4); // Show 4 more comments
    };

    const showDropdownComment = (id)=>{
        if(openDropdownIndex === id){
            setOpenDropdownIndex(null);
            setShowDropdown(false)
        }else{
            setOpenDropdownIndex(id);
            setShowDropdown(true)
        }
    }

    const handleConfirmDeleteComment = (id)=>{
        setCommentId(id)
        setShowDeleteModal(true)
        setOpenDropdownIndex(null);
        setShowDropdown(false)
    }
    const handleRedirection = () => {
        navigate(-1);
    };
    const closeDeleteModal = ()=>{
        setShowDeleteModal(false);

    }
    const handleDeleteComment = ()=>{

        axios
            .delete(`${BASE_URL}/posts/comment/${commentId}`, {headers})
            .then(res=>{
                if(res.data.status === 200){

                    setShowDeleteModal(false);
                    setCommentData(precomments => precomments.filter(comment => comment?.id !== commentId));
                    let comments_count=commentsCount;
                    comments_count--;
                    setCommentsCount(comments_count)
                } else if (res?.data?.status == 401) {
                    navigate("/");
                }
            })
            .catch(err=>{
                console.log(err)
            })
    }

    function handleUserClick (userId) {

        let logged_in_user_id = localStorage.getItem('userId'); // Get the user ID from localStorage
        let from="";
        const userable_type = localStorage.getItem("userable_type");
        if (userable_type === "App\\Models\\Employer") {
            from = "/employer_dashboard";
        } else {
            from = "/job_seeker_dashboard";
        }
        const user_id=userId;
        if(parseInt(logged_in_user_id)==user_id){
            navigate("/my-profile-view", { state: { from,user_id } });

        }
        else {
            navigate("/profile-view", {state: {from, user_id}});
        }
    }

    const handleLikeClick = async (postId) => {
        setLikeLoading(true); // Set like button loading state to true
        setLikeClicked(true);
        try {
            axios.post(`${BASE_URL}/posts/${postId}/like`, null, {headers})
                .then(response => {
                    if (response?.data?.status == 200) {
                        setIsLiked(!isLiked); // Toggle the like status
                        setLikesCount(isLiked ? likesCount - 1 : likesCount + 1);
                        setLikeLoading(false); // Set like button loading state to false on response or error
                    } else if (response?.data?.status == 401) {
                        navigate("/");
                    }
                })
                .catch(error => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    if (error?.response?.status === 401) {
                        navigate("/");
                    }

                    setLikeLoading(false); // Set like button loading state to false on response or error
                });
        } catch (error) {
            console.error('Error:', error);
            toast.error(error, {
                position: toast.POSITION.TOP_CENTER,
            });

            setLikeLoading(false); // Set like button loading state to false on response or error
            setLikeClicked(false);

        }
    };

    const handleCommentSectionClick = () => {
        setShowComments(!showComments); // Toggle the showComments state
        return !showComments;
    };

    const toggleShareCommentModal = () => {
        setShowShareCommentModal(!showShareCommentModal);
    };

    const handleSharePost = () => {
        axios.post(`${BASE_URL}/post/share`, {
            user_id: parseInt(user_id),
            post_id: post?.id,
            comment: shareComment
        }, {headers})
            .then(response => {
                if (response?.data?.status === 200) {
                    setIsShared(true); // Toggle the share status
                    setSharesCount(sharesCount + 1); // Update the share count
                    toggleShareCommentModal(); // Close the modal
                    toast.success('Post shared successfully!', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })
            .catch(error => {
                console.error('Error sharing post:', error);
                if (error?.response?.status === 401) {
                    navigate("/");
                }
                else {
                    toast.error(error, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
                // Handle error cases
            });
        toggleShareCommentModal(); // Close the modal


    };
    const showDropdownPost=(id)=>{
        if(openPostDropdownIndex === id){
            setOpenPostDropdownIndex(null);
            setShowPostDropdow(false)
        }else{
            setOpenPostDropdownIndex(id);
            setShowPostDropdow(true)

        }
    }

    const handleConfirmDeletePost=(id)=>{

        setPostId(id);
        setShowDeletePostModal(true)
        setOpenPostDropdownIndex(null);
        setShowPostDropdow(false);

    }

    const showDropDownUnFollow = (id)=>{

        if(openUnFollowDropDownIndex === id){
            setOpenUnFollowDropDownIndex(null);
            setShowUnFollowDropdown(false)
        }else{
            setOpenUnFollowDropDownIndex(id);
            setShowUnFollowDropdown(true)

        }
    }

    const handleUnFollowUser =(userId)=>{
        axios
            .post(`${BASE_URL}/unfollow`, { following_id: userId }, { headers })
            .then((response) => {
                // Handle success
                if (response?.data?.status === 200) {



                    setAuthUser({
                        ...AuthUser,
                        followings: AuthUser.followings.filter(following => following?.id !== post?.user?.id)
                    });
                    let userCopy = user_data;
                    const currentFollowingsCount = userCopy.followings_count
                    userCopy ={...userCopy, followings_count:currentFollowingsCount - 1}
                    dispatch(addUser(userCopy))

                    toast.success('Unfollowed Successfully!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                } else if (response?.data?.status === 401) {
                    navigate("/");
                } else {
                    // Handle other status codes or responses here
                    console.error("Follow failed:", response);
                }
            })
            .catch((error) => {
                // Handle error
                // You can display an error message or take any other action
                console.error("Follow failed:", error);
            });
    }

    const showDropDownFollow = (id)=>{

        if(openFollowDropDownIndex === id){
            setOpenFollowDropDownIndex(null);
            setShowFollowDropdown(false)
        }else{
            setOpenFollowDropDownIndex(id);
            setShowFollowDropdown(true)

        }
    }

    const handleFollowUser = (userId) => {
        // Make an API call to follow the user with the provided userId
        // Use axios or your preferred HTTP library to send the request
        axios
            .post(`${BASE_URL}/follow`, { following_id: userId }, { headers })
            .then((response) => {
                // Handle success
                if (response?.data?.status === 200) {

                    // setAuthUser({...AuthUser, followings: AuthUser.followings(user)})
                    let userCopy = user_data;
                    const currentFollowingsCount = userCopy.followings_count
                    userCopy ={...userCopy, followings_count:currentFollowingsCount + 1}
                    setAuthUser({ ...AuthUser, followings: [...AuthUser.followings, post?.user] });


                    dispatch(addUser(userCopy))
                    toast.success('Followed Successfully!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                } else if (response?.data?.status === 401) {
                    navigate("/");
                } else {
                    // Handle other status codes or responses here
                    console.error("Follow failed:", response);
                }
            })
            .catch((error) => {
                // Handle error
                // You can display an error message or take any other action
                console.error("Follow failed:", error);
            });
    };

    const showDropdownSharePost =(id)=>{
        if(openSharePostDropdownIndex === id){
            setOpenSharePostDropdownIndex(null);
            setShowSharePostDropdown(false)
        }else{
            setOpenSharePostDropdownIndex(id);
            setShowSharePostDropdown(true)

        }
    }
    const handleConfirmDeleteSharePost=(id,post_id)=>{

        setSharePostId(id);
        setPostId(post_id)
        setShowDeleteSharePostModal(true)
        setOpenSharePostDropdownIndex(null);
        setShowSharePostDropdown(false)

    }

    const showFollowing = (userid)=>{

        if(AuthUser?.followings?.length >0 ){

            if(AuthUser?.followings.some(following=>following?.id === userid)){
                return (<UnFollowUser
                    openUnFollowDropDownIndex={openUnFollowDropDownIndex}
                    id={userid}
                    showUnFollowDropdown={showUnFollowDropdown}
                    showDropDownUnFollow={showDropDownUnFollow}
                    handleUnFollowUser={handleUnFollowUser}
                />)
            }else{
                return(
                    <FollowUser
                        openFollowDropDownIndex={openFollowDropDownIndex}
                        id={userid}
                        showFollowDropdown={showFollowDropdown}
                        showDropDownFollow={showDropDownFollow}
                        handleFollowUser={handleFollowUser}
                    />
                )
            }
        }else{
            return (
                <FollowUser
                    openFollowDropDownIndex={openFollowDropDownIndex}
                    id={userid}
                    showFollowDropdown={showFollowDropdown}
                    showDropDownFollow={showDropDownFollow}
                    handleFollowUser={handleFollowUser}
                />
            )
        }
    }

    const closeDeletePostModal = ()=>{
        setShowDeletePostModal(false)
    }

    const handlePostDelete = ()=>{

        axios
            .delete(`${BASE_URL}/post/${postId}`, {headers})
            .then(res=>{
                if(res.data.status === 200){
                    setShowDeletePostModal(false);
                    if (userable_type === "App\\Models\\Employer") {
                        navigate("/employer_dashboard")
                    } else {
                        navigate("/job_seeker_dashboard")
                    }

                } else if (res?.data?.status == 401) {
                    navigate("/");
                }
            })
            .catch(err=>{
                console.log(err)
            })
    }

    const closeDeleteSharePostModal = ()=>{
        setShowDeleteSharePostModal(false)
    }

    const handleSharedPostDelete = ()=>{

        axios
            .delete(`${BASE_URL}/share_post/${sharePostId}`, {headers})
            .then(res=>{
                if(res.data.status === 200){
                    setShowDeleteSharePostModal(false);
                    if (userable_type === "App\\Models\\Employer") {
                        navigate("/employer_dashboard")
                    } else {
                        navigate("/job_seeker_dashboard")
                    }

                } else if (res?.data?.status == 401) {
                    navigate("/");
                }
            })
            .catch(err=>{
                console.log(err)
            })
    }
    // Full Image Code
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = (index) => {
        setSelectedImageIndex(index);
        setShowModal(true);
    };
    return(
        <>
            {headerComponent}

            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
        <div className="container">
            <div className="JBmain">
                <div className="RDheadtitle fvthead">
                    <BsArrowLeft
                        onClick={handleRedirection}
                        role='button'
                        size={24}
                        className='AJbackbtn'
                    ></BsArrowLeft>
                    <h2>Post</h2>
                    <span></span>
                </div>
                <div className="d-flex gap-2">

                        <div className={styles.user_info}>
                            {post?.id &&(
                            <UserInfo user_data={post?.user} />
                            )}
                        </div>

                    <div className={styles.rightContent}>
                        <div className='p-1'>
                            {post?.id && (
                                <div className="showpost-rd" style={{marginTop:'0'}}>

                                    {post?.shares?.length > 0 && (
                                        <>
                                            <div className='d-flex justify-content-between'>
                                                <div className="col-8 postprofile-rd" style={{display: 'flex', alignItems: 'center'}}>
                                                    <img
                                                        src={post?.shares[0]?.user?.image ? `${STORAGE_URL}${post?.shares[0]?.user?.image }` : avatar}
                                                        className="sm-small-img-style"
                                                        alt="Shared Profile Image"
                                                    />
                                                    <div className="posttxt-rd">
                                                        <p className="text-dark m-0">
                                  <span
                                      role="button"
                                      onClick={() => handleUserClick(post?.shares[0]?.user?.id)}
                                  >
                                    {post?.shares[0]?.user?.firstname} {post?.shares[0]?.user?.lastname}
                                  </span>
                                                            <span style={{ fontSize: 'smaller' }}> shared this post</span>
                                                        </p>

                                                        <p className="text-dark m-0">
                                                            {Helper.getTimeSinceShared(post?.shares[0]?.updated_at, serverTime)}</p>
                                                    </div>
                                                </div>
                                                {/*<img role="button" src={threedots} alt="Options"/>*/}
                                                {post?.shares?.map(share=>{
                                                    if(share.user_id === parseInt(user_id)){
                                                        return <DeleteSharedPost
                                                            openSharePostDropdownIndex={openSharePostDropdownIndex}
                                                            id={share?.id}
                                                            post_id={post?.id}
                                                            showSharePostDropdown={showSharePostDropdown}
                                                            showDropdownSharePost={showDropdownSharePost}
                                                            handleConfirmDeleteSharePost={handleConfirmDeleteSharePost}

                                                        />
                                                    }})}

                                            </div>

                                            <div className="col-12 postcontent-rd"
                                                 style={{borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: "10px"}}>
                                                <p style={{margin: '10px 0'}}>{post?.shares[0]?.comment}</p>
                                            </div>
                                        </>
                                    )}

                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <div className="col-8 postprofile-rd">
                                            <img
                                                src={post?.user?.image ? `${STORAGE_URL}${post?.user?.image}` : avatar}
                                                className="sm-img-style"
                                                alt="Profile Image"
                                            />

                                            <div className="posttxt-rd">
                                                <p className="text-dark " role="button"
                                                   onClick={() => handleUserClick(post?.user?.id)}
                                                >
                                                    {post?.user?.firstname} {post?.user?.lastname}
                                                </p>
                                                <p className="text-dark ">
                                                    {Helper.getTimeSinceShared(post?.updated_at,serverTime)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-4 postdots-rd">
                                            {post?.user?.id === parseInt(user_id)?
                                                (<DeletePost
                                                        openPostDropdownIndex={openPostDropdownIndex}
                                                        id ={post?.id}
                                                        showPostDropdown={showPostDropdown}
                                                        showDropdownPost={showDropdownPost}
                                                        handleConfirmDeletePost={handleConfirmDeletePost}

                                                    />
                                                ):

                                                showFollowing(post?.user?.id)
                                            }

                                        </div>
                                    </div>
                                    <div className="col-12 postcontent-rd">
                                        {/*<p>{post?.content}</p>*/}

                                        <p dangerouslySetInnerHTML={{ __html: post?.content?.replace(/\n/g, '<br>') }} />

                                        {post?.images && post?.images?.length > 0 && (
                                            <Carousel showThumbs={true} showIndicators={true} infiniteLoop={true} swipeable={true} emulateTouch={true}>
                                                {post?.images?.map((image, index) => (
                                                    <div key={index} onClick={() => handleOpenModal(index)} style={{ cursor: 'pointer' }}>
                                                        <img
                                                            src={image?.file_path ? `${STORAGE_URL}${image?.file_path}` : avatar}
                                                            alt={`Post Image ${index + 1}`}
                                                            className="carousel-image"
                                                        />
                                                    </div>
                                                ))}
                                            </Carousel>
                                        )}
                                        <Modal
                                            show={showModal}
                                            onHide={handleCloseModal}
                                            size="xl"
                                            centered
                                            backdrop="static"
                                            keyboard={false}
                                            className="social-media-custom-modal"

                                        >
                                            <Modal.Header className="social-media-custom-modal-header">
                                                {/* Replace the default close button with the close icon */}
                                                <AiOutlineClose size={24} className="social-media-close-icon" onClick={handleCloseModal} />
                                                <Modal.Title></Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body  className="social-media-custom-modal"
                                                         style={{ padding: 0, margin:"0 auto" }}>  {/* Remove padding for better fit */}
                                                <img
                                                    src={images[selectedImageIndex]?.file_path ? `${STORAGE_URL}${images[selectedImageIndex]?.file_path}` : avatar}
                                                    alt={`Post Image ${selectedImageIndex + 1}`}
                                                    className="social-media-full-image"
                                                />
                                            </Modal.Body>
                                            <Modal.Footer style={{ display: 'none' }}>  {/* Hide the footer for a cleaner look */}
                                                <Button variant="secondary" onClick={handleCloseModal}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>


                                    <div className={`col-md-12 posticons-rd align-items-center ${isMobile?'justify-content-around':''}`}>
                                        <div className="postshare-rd">
                                            <a
                                                onClick={() => !likeLoading && handleLikeClick(post?.id)}
                                            >
                                                {likeLoading ? (

                                                    <FaSpinner className="loading-icon"  style={{cursor: "pointer"}} size={24}/>

                                                ) : (
                                                    isLiked ? (
                                                        likeClicked ? (
                                                                <AiFillLike
                                                                    className={`show-like-animation`}
                                                                    style={{marginBottom:"5px", cursor: "pointer", color: isLiked ? '#4d91f3' : 'inherit'}}
                                                                    size={24}
                                                                />
                                                            ) :
                                                            <AiFillLike
                                                                style={{marginBottom:"5px", cursor: "pointer", color: isLiked ? '#4d91f3' : 'inherit'}}
                                                                size={24}
                                                            />
                                                    ) : (
                                                        <AiOutlineLike style={{marginBottom:"5px",cursor: "pointer"}} size={24}/>
                                                    )
                                                )}
                                            </a>
                                            <p >
                                                <a
                                                    style={{
                                                        color: isLiked ? '#4d91f3' : 'inherit',
                                                    }}
                                                >
                                                    {likesCount} {!isMobile ? (isLiked ? 'Likes' : 'Likes') : ''}
                                                </a>
                                            </p>
                                        </div>
                                        <div
                                            className="postshare-rd"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                let check= handleCommentSectionClick(); // Toggle the showComments state
                                                if (check) {
                                                    setTimeout(() => {
                                                        const commentTextarea = document.getElementById(`commentTextarea${post?.id}`);
                                                        if (commentTextarea) {
                                                            commentTextarea.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                                        }
                                                    }, 100);


                                                    setTimeout(() => {
                                                        const commentTextarea = document.getElementById(`commentTextarea${post?.id}`);
                                                        if (commentTextarea) {
                                                            commentTextarea.focus();
                                                        }
                                                    }, 500);
                                                }
                                            }}
                                        >
                                            <a>
                                                <FaRegCommentDots size={24}  />
                                            </a>
                                            <p>
                                                <a>{commentsCount} {!isMobile?'Comments':''} </a>
                                            </p>
                                        </div>


                                        <div className="postshare-rd">
                                            <a
                                                onClick={() => {
                                                    if (!isShared && post?.user?.id !== user_data?.id) {
                                                        toggleShareCommentModal();
                                                    } else if (post?.user?.id === user_data?.id) {
                                                        // Show a toast indicating that the user can't share their own post
                                                        toast.info("You can't share your own post!", {
                                                            position: toast.POSITION.TOP_CENTER
                                                        });
                                                    } else {
                                                        // Show a toast indicating that the user has already shared the post
                                                        toast.info('You have already shared the post!', {
                                                            position: toast.POSITION.TOP_CENTER
                                                        });
                                                    }
                                                }}
                                                style={{
                                                    cursor: (isShared || post?.user?.id === user_data?.id) ? "not-allowed" : "pointer",
                                                    color: isShared ? "#4d91f3" : "inherit"
                                                }}
                                            >
                                                <AiOutlineShareAlt style={{ color: "inherit" }} size={24} />
                                            </a>
                                            <p>
                                                <a style={{ color: isShared ? "#4d91f3" : "inherit" }}>{sharesCount} {!isMobile ? 'Share' : ''}</a>
                                            </p>
                                        </div>

                                        <Modal show={showShareCommentModal} onHide={toggleShareCommentModal} centered>
                                            <Modal.Header closeButton> <Modal.Title> Share Post</Modal.Title></Modal.Header>
                                            <Modal.Body>
                                                <div className="facebook-share-modal">
                                                    <div className="facebook-share-content">
                                                        <Form>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={4}
                                                                    value={shareComment}
                                                                    onChange={(e) => setShareComment(e.target.value)}
                                                                    placeholder="Write about the post..."
                                                                    style={{backgroundColor: "#fafafa"}}
                                                                />
                                                            </Form.Group>

                                                            <Button
                                                                variant="primary"
                                                                onClick={handleSharePost}
                                                                style={{width: "100%", marginTop: "10px"}}
                                                            >

                                                                Share
                                                            </Button>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>

                                    </div>
                                    {showComments && ( // Conditionally render comments section

                                        <div className="comments-section">

                                            <div className="comments-divider"></div>

                                            {commentData && commentData.slice(0, visibleComments).map((comment) => (
                                                <div key={comment?.id} className="comment-container">
                                                    <div className="user-details">
                                                        <img
                                                            src={comment?.user?.image ? `${STORAGE_URL}${comment.user.image}` : avatar}
                                                            className="sm-small-img-style"
                                                            alt="Shared Profile Image"
                                                        />
                                                        <div className="user-info">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="user-name" role="button"
                                                                   onClick={() => handleUserClick(comment?.user?.id)}
                                                                >
                                                                    {comment.user?.firstname} {comment.user?.lastname}
                                                                </p>
                                                                <div className="comment-time d-flex align-items-center">

                                                                    {Helper.getTimeSinceShared(comment?.updated_at,serverTime)}
                                                                    {
                                                                        parseInt(user_id) === comment?.user?.id?
                                                                            (
                                                                                <DeleteComment
                                                                                    showDropdown={showDropdown}
                                                                                    showDropdownComment={showDropdownComment}
                                                                                    comment={comment}
                                                                                    openDropdownIndex={openDropdownIndex}
                                                                                    handleConfirmDeleteComment={handleConfirmDeleteComment}
                                                                                />
                                                                            )
                                                                            :''
                                                                    }


                                                                </div>

                                                            </div>
                                                            <p className="comment-text">
                                                                {comment.comment}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))}

                                            {/*Show "View More" button if there are more comments*/}
                                            {commentData && visibleComments < commentData.length && (
                                                <div className="text-start mt-2">
                                            <span
                                                role="button"
                                                className="text-secondary custom-load-text"
                                                onClick={handleShowMoreComments}
                                            >
                                                View more comments
                                            </span>
                                                </div>
                                            )}

                                            <div className="post-comment-container">
                                                <img
                                                    src={user_data?.image? `${STORAGE_URL}${user_data?.image}` : avatar}
                                                    className="sm-small-img-style"
                                                    alt="Shared Profile Image"
                                                />
                                                {/*<FaRegCommentDots size={24}  />*/}

                                                <div className="comment-input">
                                                 <textarea
                                                     id={`commentTextarea${post?.id}`}
                                                     rows="1"
                                                     placeholder="Write a comment..."
                                                     className="comment-textarea"
                                                     value={postComment}
                                                     onChange={handleTextareaInput}
                                                     onFocus={(e) => {
                                                         // setOriginalHeight();
                                                         e.target.style.height = originalHeight
                                                         // e.target.style.height = 'auto';
                                                     }}
                                                     onBlur={(e) => {
                                                         // if (!commentSubmitted) {
                                                         e.target.style.height = originalHeight;
                                                         // }
                                                     }}
                                                 />


                                                    <button
                                                        className={`send-button btn btn-primary ${commentLoading || postComment.trim() === '' ? 'disabled' : ''}`}
                                                        onClick={() => !commentLoading && postComment.trim() !== '' && handleSendComment(post)}
                                                    >
                                                        {commentLoading ? <FaSpinner className="loading-icon" /> : "Send"}
                                                    </button>


                                                </div>

                                            </div>


                                        </div>

                                    )}


                                    {/*Comment Delete Confirmation*/}
                                    <Modal show={showDeleteModal} onHide={closeDeleteModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete Comment</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure to delete this comment?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" size="sm" onClick={handleDeleteComment}>Delete</Button>
                                            <Button variant="secondary" size="sm" onClick={closeDeleteModal}>Close</Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {/*  Post Delete Confirmation*/}

                                    <Modal show={showDeletePostModal} onHide={closeDeletePostModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete Post</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure to delete this post?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" size="sm" onClick={handlePostDelete}>Delete</Button>
                                            <Button variant="secondary" size="sm" onClick={closeDeletePostModal}>Close</Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {/*  Shared Post Delete Confirmation*/}

                                    <Modal show={showDeleteSharePostModal} onHide={closeDeleteSharePostModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete Share Post</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure to delete this share post?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="danger" size="sm" onClick={handleSharedPostDelete}>Delete</Button>
                                            <Button variant="secondary" size="sm" onClick={closeDeleteSharePostModal}>Close</Button>
                                        </Modal.Footer>
                                    </Modal>


                                    <ToastContainer/>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>

            {userable_type === "App\\Models\\Employer" ? (
                <EmployerSideBars
                    dashboardCheck ={false}
                    notificationCounter={notificationCounter}
                />
            ):(
                <JobSeekerSiderBar
                    dashboardCheck ={false}
                    notificationCounter={notificationCounter}
                />
            )}
        </>
    )
}
export default Post;