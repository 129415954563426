import { createSlice } from "@reduxjs/toolkit";

const employerSlice = createSlice({
    name: "employer",
    initialState: {
        employer: null,
        contactType: null,

    },
    reducers: {
        addEmployee(state, action) {
            state.employer = action.payload; // Corrected syntax
        },
        addEmployeeContact(state, action) {
            state.contactType = action.payload; // Corrected syntax
        }
    },
});

export default employerSlice.reducer;
export const { addEmployee, addEmployeeContact } = employerSlice.actions;
