import React, {useEffect,useCallback, useRef, useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/Alljobs.css';
import style from '../src/assets/css/LoadingComponent.module.css';
import { BsArrowLeft } from 'react-icons/bs';
import {STORAGE_URL} from "./config";
import EmployerHeader from "./EmployerHeader";
import axios from "axios";
import BASE_URL from "./config";
import favorite_heart from '../src/assets/redheart.png';
import moment from 'moment';
import Select from "react-select";
import {useNavigate,useLocation} from "react-router-dom";
import {Modal, Button} from "react-bootstrap"
import {JobTypeCount} from "./components/JobTypeCount"
import {JobModeCount} from "./components/JobModeCount";
import Helper from "./Helper";
import {useMediaQuery} from "react-responsive";
import {AllJobsSidebar} from "./components/Sidbars/AllJobsSidebar";
import {WorkAuthorization} from "./components/Sidbars/AllJobsFilter/WorkAuthorization";
import {ContractType} from "./components/Sidbars/AllJobsFilter/ContractType";
import {Status} from "./components/Sidbars/AllJobsFilter/Status";
import {Experience} from "./components/Sidbars/AllJobsFilter/Experience";
import { FaUser, FaFile, FaTrash  } from "react-icons/fa";
import { FaPenToSquare } from "react-icons/fa6";
import {EmployerSideBars} from "./components/Sidbars/EmpSideBars";

let custom_date1 = null
let custom_date2 = null
let count = 0;


function Alljobs() {
    //Employer Stats Data
    const [employerData, setEmployerData] = useState(null);
    const [employerJobs, setEmployerJobs] = useState([]);
    const [JobStatuses,setJobStatuses]=useState([]);
    const [selectedStatus, setselectedStatus] = useState('');
    const [contactType, setContactType] = useState({});
    const [contactTypeData, setContactTypeData] = useState([])
    const [selectedContractType, setSelectedContractType] = useState(null);
    const [workAuthorizations, setWorkAuthorizations] = useState([]);
    const [selectedWorkAuthorization, setSelectedWorkAuthorization] = useState(null);
    const[openDeleteModal, setOpenDeleteModal] = useState(false)
    const[job_id, setJob_id] = useState(0)
    const location = useLocation();
    const { defaultfrom } = location.state || {};

    const [loading, setLoading] = useState(false);

    const [filter, setFilter] = useState({

        years_of_experience_id: [],
        contract_type_id: '',
        work_authorization_id: '',
        job_type: [],
        job_status_id: [],
        job_mode: [],
        employer_id: localStorage.getItem("employerId")
    })
    const employerId = localStorage.getItem("employerId");
    const resume_viewed = 2;
    const navigate = useNavigate();

    const token = localStorage.getItem("access_token"); // Get the token from localStorage
    const headers = {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    };
    const isMiniScreen = useMediaQuery({ query: '(max-width: 1064px)' })
    const fetchAllJobStatuses = async () => {
        axios.get(`${BASE_URL}/job_statuses`, {
            headers
        })
            .then(response => {
                if (response?.data?.status === 200) {
                    setJobStatuses(response?.data?.job_statuses);
                } else if (response?.data?.status === 401) {
                    navigate("/");
                }
            })
            .catch(error => {
                // setLoading(false);
                console.log(error);
            });
    }
    const fetchAllFilterJobs = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${BASE_URL}/jobad/filterEmployerJobs?${new URLSearchParams(filter).toString()}`, {
                headers,
            });
            const data = await response.json();
            if(data.status === 200){
                setEmployerJobs(data?.jobs)
                setLoading(false)
            }else if( data.status === 401){
                // Redirect to the landing page if unauthenticated
                navigate("/");
            }


        } catch (error) {
            console.error('Error fetching all jobs:', error);
        }
    };
    const fetchEmployerData = useCallback(async () => {
        try {
            const response = await fetch(`${BASE_URL}/employer_dashboard_data/${employerId}`, {
                headers, // Pass the headers in the options
            });
            if (response.status === 200) {
                const data = await response.json();
                setEmployerData(prevEmployerData => ({
                    ...prevEmployerData,
                    ...data.employer_data,
                }));                setLoading(false)
            } else if (response.status === 401) {
                // Redirect to the landing page if unauthenticated
                navigate("/");
            }
        } catch (error) {
            console.error('Error fetching employer data:', error);
            setLoading(false)
        }
    }, [employerId]);
    async function fetchFilterData(){
        const response = await fetch(`${BASE_URL}/job_ads/employer_job_counters/${employerId}`,{headers});
        const data = await response.json();

        if(data.status === 200){
            setContactType(data.jobs)
            setContactTypeData(data?.jobs?.contractTypeCount.map((contactType)=>{
                return contactType ={value: contactType.id, label: contactType.employment_range}
            }))
        }else if( data.status === 401){
            // Redirect to the landing page if unauthenticated
            navigate("/");
        }
    }

    const fetchWorkAuthorizations = async () => {
        try {
            const response = await fetch(`${BASE_URL}/work_authorization`, {
                headers,
            });
            const data = await response.json();
            if(data.status === 200){
                setWorkAuthorizations(data.work_authorizations.map((authorization)=>{

                    return  authorization = {value: authorization.id, label: authorization.work_authorization}
                }))
            }else if( data.status === 401){
                // Redirect to the landing page if unauthenticated
                navigate("/");
            }


        } catch (error) {
            console.error('Error fetching work authorizations:', error);
        }
    };
    useEffect(() => {
        fetchAllJobStatuses();
        fetchAllFilterJobs();
        fetchEmployerData();
        fetchWorkAuthorizations();
        fetchFilterData()

    }, []);

    useEffect(()=>{
        fetchAllFilterJobs();
    }, [filter])
    const handleStatusUpdate = (e,job_ad_id) =>{
        setselectedStatus(e.target.value);
        setLoading(true);
        var job_application_status_id=e.target.value;
        const payload={
            'job_status_id':job_application_status_id,
            'employer_id':employerId,
        }
        axios.post(`${BASE_URL}/job_status_update/`+job_ad_id, payload,{headers:headers})
            .then((response) => {
                fetchAllJobStatuses();
                fetchAllFilterJobs();
                fetchEmployerData();
                fetchWorkAuthorizations();
                fetchFilterData();
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleViewApplicants = (jobId) =>{
        let from='/alljobs';
        navigate('/jobapplications', { state: { jobId,from,defaultfrom } });
    }
    const handleExperienceChange = (id) =>{

        const experienceIdsSelected = filter.years_of_experience_id.includes(id);

        if (experienceIdsSelected) {
            setFilter(prevFilter => ({
                ...prevFilter,
                years_of_experience_id: prevFilter.years_of_experience_id.filter(item => item != id)
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                years_of_experience_id: [...prevFilter.years_of_experience_id, id]
            }));
        }
    }

    const handleJobStatusChange = (id) =>{

        const statusSelected = filter.job_status_id.includes(id);

        if (statusSelected) {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_status_id: prevFilter.job_status_id.filter(item => item != id)
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_status_id: [...prevFilter.job_status_id, id]
            }));
        }

    }

    const handleChangeWorkAuthorization = (selectedOption)=>{
        // (selectedOption) => {
        setSelectedWorkAuthorization(selectedOption);
        if(selectedOption){
            const id = selectedOption ? parseInt(selectedOption.value) : null;
            setFilter((prevJobData) => ({
                ...prevJobData,
                work_authorization_id: id
            }));
        }else {
            setFilter((prevJobData) => ({
                ...prevJobData,
                work_authorization_id: ''
            }));
        }

    }
    const handleChangeContractType = (selectedOption)=>{
        // (selectedOption) => {
        setSelectedContractType(selectedOption);
        if(selectedOption){
            const id = selectedOption ? parseInt(selectedOption.value) : null;
            setFilter((prevJobData) => ({
                ...prevJobData,
                contract_type_id: id
            }));
        }else {
            setFilter((prevJobData) => ({
                ...prevJobData,
                contract_type_id: ''
            }));
        }
    }

    const handlePreviewJob =(jobId)=>{
        const from='/alljobs';
        navigate('/post_a_job_preview', { state: { jobId, from } });
    }

    const handleEditJobClick = (jobId) => {
        const from='/alljobs';
        navigate("/edit_a_job", { state: { jobId, from } });
    };
    const handleCloseModal =()=>{
        setOpenDeleteModal(false)
    }
    const handleDeleteJob = (job_id)=>{
        setJob_id(job_id)
        setOpenDeleteModal(true)
    }
    const handleConfirmDeleteJob =()=>{
        const payload = {
            employer_id: parseInt(employerId)
        };
        if(job_id !=0){
            axios.delete(`${BASE_URL}/job_ad/${job_id}`, {
                headers,
                data:payload
            })
                .then(res =>{
                    if(res.status === 200){
                        setOpenDeleteModal(false)
                        fetchAllFilterJobs();

                    }
                    else if(res.status === 401){
                        navigate('/');
                    }
                })
                .catch(err => console.log(err))


        }else {
            alert("Some thing please try again")
        }

    }


    return (
        <div>
            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR Start                          */}
            {/*---------------------------------------------------------------*/}
            <EmployerHeader/>
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR END                            */}
            {/*---------------------------------------------------------------*/}
            {/*---------------------------------------------------------------*/}
            {/*                        MAin Body Start                        */}
            {/*---------------------------------------------------------------*/}
            <div className="maina1ljob">
                <div className="container-fluid">
                    {/*---------------------------------Main section -----------------*/}
                    <div className="mainpage1aljob">
                        {/*---------------------------First Column ---------------------*/}
                        <div className="resumecol1aljob">

                            {/*-------------------Work Authorization ----------- */}
                            <WorkAuthorization
                                handleChangeWorkAuthorization={handleChangeWorkAuthorization}
                                selectedWorkAuthorization={selectedWorkAuthorization}
                                workAuthorizations={workAuthorizations}
                            />


                            {/*-------------------Contract Type ----------- */}
                            <ContractType
                                contactTypeData={contactTypeData}
                                selectedContractType={selectedContractType}
                                handleChangeContractType={handleChangeContractType}
                            />
                            {/*-----------------------Status --------------- */}
                            <Status
                                contactType={contactType}
                                filter={filter}
                                handleJobStatusChange={handleJobStatusChange}
                            />
                            {/*-----------------------Job Type --------------- */}
                            <div className="resumeupdatealjob work-authorizaljob alljobbox">
                                <h2>Job Type</h2>

                                {contactType && contactType?.jobTypeCount?.map((Jobtype, index)=>(
                                    <JobTypeCount
                                        filter = {filter}
                                        setFilter = {setFilter}
                                        Jobtype={Jobtype}
                                    />
                                ))}

                            </div>

                            {/*-----------------------Interview Mode --------------- */}
                            <div className="resumeupdatealjob work-authorizaljob alljobbox">
                                <h2>Job Mode</h2>

                                {contactType && contactType?.jobModeCount?.map((JobMode)=>(

                                    <JobModeCount
                                        filter={filter}
                                        setFilter={setFilter}
                                        JobMode={JobMode} />
                                ))}


                            </div>
                            {/*-----------------Experience ------------- */}
                            <Experience
                                contactType={contactType}
                                filter={filter}
                                handleExperienceChange={handleExperienceChange}
                            />

                        </div>

                        {/*--------------------------- Second  Column ---------------------*/}



                        <div className="postcol1aljob">




                            <div className="AJheadtitle ">

                                <div className="AJtitle d-flex align-items-center px-1 justify-content-between">
                                    <div className="AJbackbtn">
                                        <button className='m-0' onClick={()=>navigate(-1)}><BsArrowLeft  /> </button>
                                    </div>
                                    <h2>All Jobs </h2>
                                    {isMiniScreen &&
                                        (<AllJobsSidebar

                                            workAuthorizations={workAuthorizations}
                                            selectedWorkAuthorization={selectedWorkAuthorization}
                                            handleChangeWorkAuthorization={handleChangeWorkAuthorization}
                                            contactTypeData={contactTypeData}
                                            selectedContractType={selectedContractType}
                                            handleChangeContractType={handleChangeContractType}
                                            contactType={contactType}
                                            filter={filter}
                                            handleJobStatusChange={handleJobStatusChange}
                                            setFilter={setFilter}
                                            handleExperienceChange={handleExperienceChange}
                                        />)
                                    }
                                </div>
                            </div>
                            {/* Analytics of all jobs  */}
                            <div className="postanalyticsPSaljob">
                                <p>No of Jobs Purchased: <span>{employerData?.subscription?.package?.jobs}</span>
                                </p>
                                <p>No of Jobs
                                    Available:<span> {employerData?.subscription?.package?.jobs - employerData?.jobAdsCount}</span>
                                </p>
                                {contactType && contactType?.statusCount?.map((status)=>(
                                    <p>{status.status} Jobs: <span>{status?.count}</span></p>
                                ))}
                            </div>

                            {/*-------------------Table List ------------------------------- */}
                            <div className="alljoblist">
                                {employerJobs && employerJobs.length > 0 ? (
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Job Title</th>
                                                <th>Job Mode</th>
                                                <th>Job Type</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Job Applications</th>
                                                <th className="text-center">Posted Date</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {employerJobs.map((job, index) => (
                                                <tr key={index}>
                                                    <td>{job?.job_title}</td>
                                                    <td>{job?.job_mode}</td>
                                                    <td>{job?.job_type}</td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="text-white" id="job-action" name="jobaction" onChange={(e) => handleStatusUpdate(e, job.id)}>
                                                                {JobStatuses?.map((status) => {
                                                                    if (status.id === 3 && job.job_status_id !== 3) {
                                                                        // Skip rendering the "Draft" option if the current job status is not "Draft"
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <option
                                                                            value={status.id}
                                                                            selected={status.id === job.job_status_id}
                                                                            key={status.id}
                                                                        >
                                                                            {status.status}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>

                                                    </td>
                                                    <td className="text-center">{job?.job_applications_count}</td>
                                                    <td className="text-center">{Helper.changeDateFormat(new Date(job?.job_creation_date))}</td>
                                                    {/* <td className="text-center">{new Date(job?.job_creation_date).toLocaleDateString('en-US')}</td> */}
                                                    <td>
                                                        <div className="editjobsicon">
                                                            {job?.job_applications_count>0 && job?.job_status_id!="2" &&

                                                                <FaUser
                                                                        onClick={() => handleViewApplicants(job.id)}
                                                                        role='button'
                                                                        title="View Applicants"
                                                                />

                                                            //     <i
                                                            //     className="fa-solid fa-users"
                                                            //     style={{ cursor: "pointer" }}
                                                            //     data-bs-toggle="tooltip"
                                                            //     onClick={() => handleViewApplicants(job.id)}
                                                            //     data-bs-placement="top"
                                                            //     title="View Applicants"
                                                            // ></i>
                                                            }
                                                            {job?.job_status_id!="2" &&
                                                                <FaFile

                                                                onClick={() => handlePreviewJob(job.id)}
                                                                title="View"
                                                                role='button'
                                                                />
                                                            //     <i
                                                            //     className="fa-solid fa-file"
                                                            //     style={{ cursor: "pointer" }}
                                                            //     data-bs-toggle="tooltip"
                                                            //     onClick={() => handlePreviewJob(job.id)}
                                                            //     data-bs-placement="top"
                                                            //     title="View"
                                                            // ></i>
                                                            }
                                                            <FaPenToSquare

                                                                onClick={() => handleEditJobClick(job.id)}
                                                                title="edit"
                                                                role='button'
                                                            />
                                                            {/*<i*/}
                                                            {/*    className="fa-solid fa-pen-to-square"*/}
                                                            {/*    style={{ cursor: "pointer" }}*/}
                                                            {/*    onClick={() => handleEditJobClick(job.id)}*/}
                                                            {/*    data-bs-toggle="tooltip"*/}
                                                            {/*    data-bs-placement="top"*/}
                                                            {/*    title="edit"*/}
                                                            {/*></i>*/}
                                                            <FaTrash
                                                                onClick={() => handleDeleteJob(job.id)}
                                                                title="Inactive"
                                                                role='button'
                                                            />
                                                            {/*<i*/}
                                                            {/*    className="fa-sharp fa-solid fa-trash"*/}
                                                            {/*    style={{ cursor: "pointer" }}*/}
                                                            {/*    onClick={() => handleDeleteJob(job.id)}*/}
                                                            {/*    data-bs-toggle="tooltip"*/}
                                                            {/*    data-bs-placement="top"*/}
                                                            {/*    title="Inactive"*/}
                                                            {/*></i>*/}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <p className="text-center text-danger">We're sorry, we couldn't find any jobs that match your search criteria or against this employer.</p>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <EmployerSideBars
                dashboardCheck ={false}

            />
            <Modal show={openDeleteModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to delete this job!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDeleteJob}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Alljobs;