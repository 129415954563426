import '../src/assets/css/CreatResume-v1.css';
import PRpreferencesImage from '../src/assets/PRpreferences.svg';
import PRresumeavailabilityImage from '../src/assets/PRresumeavailability.svg';
import DeleteResumeImage from '../src/assets/deleteresumePR.svg';
import rectangelImage from "./assets/rectangel.png";
import contactnforesume from '../src/assets/contactnforesume.svg'
import PRpersonalinfoImage from '../src/assets/PRpersonalinfo.svg'
import JobSeekerHeader from "./JobSeekerHeader";
import React, {useEffect, useRef, useState} from "react";
import '../src/assets/css/CreatResume-v1.css';
import styles from '../src/assets/css/LoadingComponent.module.css';
import {useLocation, useNavigate,Link} from "react-router-dom";
import { BsArrowLeft } from 'react-icons/bs';
import {BASE_URL, STORAGE_URL} from "./config";
import axios from "axios";
import Select from "react-select";
import VideoRecorder from "./VideoRecorder";
// import Modal from 'react-modal';
import { Modal,Button } from "react-bootstrap";
import { DocViewer, PdfViewer } from "./components/FileViewer1";
import Helper from "./Helper";

function EditResume() {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isVideoDeleteModalOpen, setIsVideoDeleteModalOpen] = useState(false);
    const {resumeId,from} = location.state || {};
    const jobSeekerId = localStorage.getItem("jobSeekerId");
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const resume_code = `${hours}${minutes}${seconds}`;
    const currentDateSend = new Date().toISOString().slice(0, 10);
    const currentDate = new Date().toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    }).replace(/\//g, "/");

    const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
    const [resFile, setResFile] = useState("")
    const [pdfCheck, setPdfCheck] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const [usaBaseValue, setUsaBaseValue] = useState("");
    const [openConfirmModel, setOpenConfirmModel] = useState(false);
    const [openFileModel, setOpenFileModel] = useState(false);
    const [chooseFile, setChooseFile] = useState(false);

    //State
    const [resumeData, setResumeData] = useState({
        job_seeker_id: jobSeekerId,
        resume_code: resume_code + jobSeekerId,
        resume_post_creation_date: currentDateSend,
        us_based: 'Yes',
        preferred_job_title: '',
        first_name: '',
        last_name: '',
        address: '',
        location: '',
        country_id: '',
        country_label: '',
        state_id: '',
        state_label: '',
        city_id: '',
        city_label: '',
        mobile: '',
        home: '',
        office: '',
        preferred_number: '',
        resume_availability: 'No',
        resume_availability_file_path: '',
        resume_availability_video_file: '',
        work_authorization_id: '',
        work_authorization_label: '',
        relocation: 'No',
        relocation_option: 'US',
        anywhere_in_us: 0,
        anywhere_in_country: 0,
        preferred_location1: '',
        preferred_location2: '',
        preferred_location3: '',
        preferred_job_type: 'Full Time',
        contract_type: 'Independent',
        independent_c2c_company_name: '',
        salary_expectations: '',
        expected_hourly_rate: '',
        skills: [], // Array to store selected skills
        yearsof_experience_id: 0,
        yearsof_experience_label: '',
        created_at:'',
        can_post: 0,

    });
    const handleRelocationOptionChange = (option) => {
        setResumeData((prevData) => ({
            ...prevData,
            relocation_option: option,
        }));
    };

    const [errors, setErrors] = useState([]);
    const jobTitleRef = useRef(null);
    const jobLocationRef = useRef(null);
    const jobWorkAuthorizationRef = useRef(null);
    const resumeAvailableRef = useRef(null);

    const handlePreferredJobTitleChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            preferred_job_title: value,
        }));
        setErrors({...errors, preferred_job_title: ""}); // Clear the error for the title field


    };
    const handleCloseModal =()=>{
        setIsDeleteModalOpen(false)
        setIsResumeModalOpen(false);
        setIsVideoDeleteModalOpen(false);
        setIsVideoModalOpen(false);
        setOpenFileModel(false);
        setOpenConfirmModel(false);
    }
    const handleFirstNameChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            first_name: value,
        }));
    };
    const handleLastNameChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            last_name: value,
        }));
    };
    const handleAddressChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            address: value,
        }));
    };

    const handleResumeAvailabilityChange = (event) => {
        // setResumeAvailability(event.target.value);
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            resume_availability: value,
        }));
    }

    // Work Authorization Code
    const [selectedWorkAuthorization, setSelectedWorkAuthorization] = useState(null);
    const [workAuthorizations, setWorkAuthorizations] = useState([]);

    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };


    useEffect(() => {
        const fetchWorkAuthorizations = async () => {
            try {

                const response = await fetch(`${BASE_URL}/work_authorization`, {
                    headers,
                });
                const data = await response.json();
                if (data.status === 200) {
                    setWorkAuthorizations(data.work_authorizations);

                } else if (data.status === 401) {
                    // Redirect to the landing page if unauthenticated
                    navigate("/");
                }

            } catch (error) {
                console.error('Error fetching work authorizations:', error);
            }
        };
        fetchWorkAuthorizations();


    }, []);
    const fetchResumeData = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${BASE_URL}/resume/` + resumeId, {
                headers,
            });
            const data = await response.json();
            if (data.status === 200) {
                const resume = data.resume;
                //console.log(resume);
                setPreferredNumber(resume.preferred_number);
                let _data = '';
                let data2 = '';
                let data3 = '';
                var location_arr = [];
                if (resume.us_based == "No" && resume.location) {
                    location_arr = resume.location.replace(" ", "").split(',');
                    if (location_arr.length == 1) {
                        const response = await fetch(`${BASE_URL}/data/${location_arr[0]}/country`, {
                            headers,
                        });
                        _data = await response.json();
                        setSelectedCountry({value: data.id, label: location_arr[0]})
                    } else if (location_arr.length == 2) {
                        const response = await fetch(`${BASE_URL}/data/${location_arr[0]}/country`, {
                            headers,
                        });
                        _data = await response.json();
                        setSelectedCountry({value: data.id, label: location_arr[0]})
                        const response2 = await fetch(`${BASE_URL}/data/${location_arr[1]}/state`, {
                            headers,
                        });
                        data2 = await response2.json();
                        setSelectedState({value: data2.id, label: location_arr[1]})
                    } else {
                        const response = await fetch(`${BASE_URL}/data/${location_arr[0]}/country`, {
                            headers,
                        });
                        _data = await response.json();
                        setSelectedCountry({value: data.id, label: location_arr[0]})
                        const response2 = await fetch(`${BASE_URL}/data/${location_arr[1]}/state`, {
                            headers,
                        });
                        data2 = await response2.json();
                        setSelectedState({value: data2.id, label: location_arr[1]})
                        const response3 = await fetch(`${BASE_URL}/data/${location_arr[1]}/city`, {
                            headers,
                        });
                        data3 = await response3.json();
                        setSelectedCity({value: data3.id, label: location_arr[2]})
                    }
                }

                setSelectedYearOfExperience({
                    value: resume?.yearsof_experience_id,
                    label: resume?.years_of_experiecne?.experience
                })
                setSelectedWorkAuthorization({
                    value: resume?.work_authorization.id,
                    label: resume?.work_authorization?.work_authorization
                })
                setIsCheckboxChecked(resume?.can_post);
                setSelectedSkills(resume?.skills);
                let extension = resume?.resume_availability_file_path?resume?.resume_availability_file_path?.split('.'):'';
                if(extension[extension.length-1] == 'pdf'){
                    setPdfCheck(true)
                }
                setResFile(resume.resume_availability_file_path)

                setResumeData((prevState) => ({
                    ...prevState,
                    preferred_job_title: resume?.preferred_job_title || "Full Time",
                    first_name: resume?.first_name || "",
                    last_name: resume?.last_name || "",
                    address: resume?.address || "",
                    mobile: resume?.mobile || "",
                    home: resume?.home || "",
                    location: resume?.location || "",
                    office: resume?.office || "",
                    resume_availability: resume?.resume_availability || "No",
                    resume_availability_file_path: resume?.resume_availability_file_path || "",
                    resume_availability_video_file: resume?.resume_availability_video_file || "",
                    relocation: resume?.relocation || "No",
                    relocation_option: resume?.relocation_option || "US",
                    salary_expectations: resume?.salary_expectations || "",
                    expected_hourly_rate: resume?.expected_hourly_rate || "",
                    yearsof_experience_id: resume?.yearsof_experience_id || 0,
                    created_at: resume?.created_at || "",
                    yearsof_experience_label: resume?.years_of_experiecne?.experience || "",
                    can_post: resume?.can_post || 0,
                    skills: selectedSkills,
                    country_id: _data?.id || "",
                    country_label: location_arr.length > 0 ? location_arr[0] : "",
                    state_id: data2?.id || "",
                    state_label: location_arr.length > 0 ? location_arr[1] : "",
                    city_id: data3?.id || "",
                    city_label: location_arr.length > 0 ? location_arr[2] : "",
                    preferred_job_type: resume?.preferred_job_type || "Full Time",
                    us_based: resume?.us_based || "Yes",
                    work_authorization_id: resume?.work_authorization?.id || "",
                    work_authorization_label: resume?.work_authorization?.work_authorization || "",
                    preferred_number: resume?.preferred_number || "",
                    anywhere_in_us: resume?.anywhere_in_us || 0,
                    anywhere_in_country: resume?.anywhere_in_country || 0,
                    preferred_location1: resume?.preferred_location1 || "",
                    preferred_location2: resume?.preferred_location2 || "",
                    preferred_location3: resume?.preferred_location3 || "",
                    contract_type: resume?.contract_type || "Independent",
                    independent_c2c_company_name: resume?.independent_c2c_company_name || "",

                }));


                setLoading(false)
            } else if (data.status === 401) {
                // Redirect to the landing page if unauthenticated
                navigate("/");
            }

        } catch (error) {
            console.error('Error fetching work authorizations:', error);
        }
    };
    const work_authorization_options = workAuthorizations?.map((authorization) => ({
        value: authorization?.id,
        label: authorization?.work_authorization,
    }));
// Skills Code
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedSkills, setSelectedSkills] = useState([]);
    useEffect(() => {
        ////console.log(selectedSkills);
    }, [selectedSkills]);

    // State
    const [selectedOptions, setSelectedOptions] = useState([]);

// Fetch skills and set options
    const [optionsSkills, setOptionsSkills] = useState([]);

    useEffect(() => {
        fetchSkills();
    }, []);

    const fetchSkills = () => {
        fetch(`${BASE_URL}/skills_set`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    const skills = data.skills;
                    const optionsArray = skills?.map((skill) => ({
                        value: skill?.name,
                        label: skill?.name,
                        id: skill?.id, // Assuming the API provides the ID for each skill
                    }));
                    setOptionsSkills(optionsArray);
                } else if (data.status === 401) {
                    navigate("/");
                }

            })
            .catch((error) => {
                console.error("Error fetching skills data:", error);
            });
    };

// Handle skill select
    const handleSkillSelect = (selectedOption) => {
        const selectedOptions = Array.isArray(selectedOption) ? selectedOption : [selectedOption];
        setErrors({...errors, skills: ""});
        setSelectedOptions(selectedOptions);
        if (selectedLevel) {
            selectedOptions.forEach((option) => {
                const skill = option.label;
                const skillSetId = option.id;
                let replace = false;
                selectedSkills.forEach((skill) => {
                    if (skill.skill_set_id == option.id)
                        replace = true;
                })
                if (replace) {
                    const newSkill = {
                        skill: skill,
                        skill_set: {name: skill},
                        skill_set_level: selectedLevel,
                        skill_set_id: skillSetId,
                    };
                    const updatedSkills = selectedSkills?.map((prevSkill) => {
                        if (prevSkill?.skill_set_id === newSkill?.skill_set_id) {
                            return {
                                ...prevSkill,
                                skill_set_level: newSkill?.skill_set_level,
                            };
                        }
                        return prevSkill;
                    });
                    setSelectedSkills(updatedSkills);
                    setResumeData((prevData) => ({
                        ...prevData,
                        skills: updatedSkills,
                    }));
                } else {
                    const newSkill = {
                        skill: skill,
                        skill_set: {name: skill},
                        skill_set_level: selectedLevel,
                        skill_set_id: skillSetId,
                    };
                    setSelectedSkills((prevSkills) => [...prevSkills, newSkill]);

                    setResumeData((prevData) => ({
                        ...prevData,
                        skills: [...prevData.skills, newSkill],
                    }));
                }
            });

            setSelectedLevel(null);
        }
    };

// Handle skill level select
    const [skillLevels, setSkillLevels] = useState([]);
    useEffect(() => {
        // Fetch skill levels from the API endpoint
        axios.get(`${BASE_URL}/skill_level`, {
            headers,
        })
            .then(response => {
                if (response.status === 200) {
                    const levels = response.data.skill_level?.map(item => ({
                        value: item?.id,
                        label: item?.skill_level
                    }));
                    setSkillLevels(levels);
                    ////console.log(skillLevels)
                } else if (response.status === 401) {
                    navigate("/");
                }
            })

            .catch(error => {
                console.error('Error fetching skill levels:', error);
            });
    }, []);

    const handleLevelSelect = (selectedOption) => {
        setSelectedLevel(selectedOption.label);
        ////console.log(selectedOption)
        if (selectedOptions.length > 0) {
            selectedOptions.forEach((option) => {
                const skill = option?.label;
                const skillSetId = option?.id;
                let replace = false;
                selectedSkills.forEach((skill) => {
                    if (skill?.skill_set_id == option?.id)
                        replace = true;
                })
                if (replace) {
                    const newSkill = {
                        skill: skill,
                        skill_set: {name: skill},
                        skill_set_level: selectedOption?.label,
                        skill_set_id: skillSetId,
                    };
                    const updatedSkills = selectedSkills?.map((prevSkill) => {
                        if (prevSkill?.skill_set_id === newSkill?.skill_set_id) {
                            return {
                                ...prevSkill,
                                skill_set_level: newSkill?.skill_set_level,
                            };
                        }
                        return prevSkill;
                    });
                    setSelectedSkills(updatedSkills);
                    setResumeData((prevData) => ({
                        ...prevData,
                        skills: updatedSkills,
                    }));
                } else {
                    const newSkill = {
                        skill: skill,
                        skill_set: {name: skill},
                        skill_set_level: selectedOption.label,
                        skill_set_id: skillSetId,
                    };
                    setSelectedSkills((prevSkills) => [...prevSkills, newSkill]);

                    setResumeData((prevData) => ({
                        ...prevData,
                        skills: [...prevData.skills, newSkill],
                    }));
                }
            });

            setSelectedSkill(null);
            setSelectedLevel(null);
            setSelectedOptions([]);
        }
    };

    const handleSkillRemove = (skill) => {
        setSelectedSkills((prevSkills) => {
            return prevSkills.filter((s) => s !== skill);
        });
    };

    const handleRelocationChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            relocation: value,
        }));
    };
    const handleUSBasedChange = (event) => {
        setOpenConfirmModel(true)
        const value = event.target.value;
        setUsaBaseValue(value)
        setConfirmMessage('Are you sure you want to switch to "' + value + '"?')
    };

    const handleConfirmUSBasedChange = () => {

        setErrors({...errors, location: ''})
        if (usaBaseValue === 'Yes') {
            setResumeData((prevState) => ({
                ...prevState,
                relocation_option: 'US',
            }));
        }
        setResumeData((prevState) => ({
            ...prevState,
            us_based: usaBaseValue,
        }));

        setConfirmMessage("")
        setOpenConfirmModel(false)
    }
    const navigate = useNavigate();
    const previewClickBtn = () => {
        const {resume_availability_file_path, resume_availability_video_file, ...dataWithoutFiles} = resumeData;

        const resumeFile = {
            fileName: resume_availability_file_path?.name || '',
            dataUrl: resume_availability_file_path ? URL.createObjectURL(resume_availability_file_path) : '',
        };
        localStorage.setItem('resumeData', JSON.stringify(dataWithoutFiles));
        localStorage.setItem('resumeFile', JSON.stringify(resumeFile));
        if (resume_availability_video_file) {
            const videoFile = {
                fileName: resume_availability_video_file?.name || 'Resume Video',
                dataUrl: URL.createObjectURL(resume_availability_video_file),
            };

            localStorage.setItem('videoFile', JSON.stringify(videoFile));
        } else {
            localStorage.setItem('videoFile', JSON.stringify(null));
        }
        window.open('/post-resume-preview', '_blank');
    };


    const handleJobTypeChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            preferred_job_type: value,
        }));
    };

    const handleContractTypeChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            contract_type: value,
        }));
    };

    const handleC2CCompanyNameChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            independent_c2c_company_name: value,
        }));
    };

    const handleSalaryExpectationsChange = (event) => {
        if (event.target.value.length <= 0) {
            setResumeData((prevState) => ({
                ...prevState,
                salary_expectations: '',
            }));
        } else {
            const numericValue = event.target.value.replace(/\D/g, '');
            const formattedValue = parseInt(numericValue).toLocaleString();
            setResumeData((prevState) => ({
                ...prevState,
                salary_expectations: formattedValue,
            }));
        }
    };

    const handleExpectedHourlyRateChange = (event) => {
        if (event.target.value.length <= 0) {
            setResumeData((prevState) => ({
                ...prevState,
                expected_hourly_rate: '',
            }));
        } else {
            const numericValue = event.target.value.replace(/\D/g, '');
            const formattedValue = parseInt(numericValue).toLocaleString();
            setResumeData((prevState) => ({
                ...prevState,
                expected_hourly_rate: formattedValue,
            }));
        }
    };

    function getBadgeColor(skillLevel) {
        switch (skillLevel) {
            case 'Expert':
                return '#E54545';
            case 'Intermediate':
                return '#60BF9F';
            case 'Beginner':
                return '#003580';
            default:
                return 'badge-default';
        }
    }

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        setResumeData(prevJobData => ({
            ...prevJobData,
            can_post: isCheckboxChecked ? 0 : 1
        }));
        setErrors({...errors, can_post: ""}); // Clear the error for the title field

    };
    const [preferredNumber, setPreferredNumber] = useState('');
    const handlePreferredCheckboxChange = (event, phoneNumber) => {
        if (event.target.checked) {
            setPreferredNumber(phoneNumber);
            setResumeData((prevData) => ({
                ...prevData,
                preferred_number: phoneNumber,
            }));
        } else {
            setPreferredNumber('');
            setResumeData((prevData) => ({
                ...prevData,
                preferred_number: '',
            }));
        }
    };
    const formatPhoneNumber = (phoneNumber) => {
        // Remove all non-digit characters from the input
        const numericValue = phoneNumber.replace(/\D/g, '');

        // Apply the desired format (973)-997-4556 using regular expressions
        const formattedNumber = numericValue.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1)-$2-$3'
        );

        return formattedNumber;
    };
    const handleMobileChange = (event) => {
        if (resumeData.us_based === 'Yes') {
            const formattedNumber = formatPhoneNumber(event.target.value);

            setResumeData((prevState) => ({
                ...prevState,
                mobile: formattedNumber,
            }));
        } else {
            setResumeData((prevState) => ({
                ...prevState,
                mobile: event.target.value,
            }));
        }
    };

    const handleHomeChange = (event) => {

        if (resumeData.us_based === 'Yes') {
            const formattedNumber = formatPhoneNumber(event.target.value);

            setResumeData((prevState) => ({
                ...prevState,
                home: formattedNumber,
            }));
        } else {
            setResumeData((prevState) => ({
                ...prevState,
                home: event.target.value,
            }));
        }
    };

    const handleOfficeChange = (event) => {
        if (resumeData.us_based === 'Yes') {
            const formattedNumber = formatPhoneNumber(event.target.value);

            setResumeData((prevState) => ({
                ...prevState,
                office: formattedNumber,
            }));
        } else {
            setResumeData((prevState) => ({
                ...prevState,
                office: event.target.value,
            }));
        }
    };

    //ZipCode/Location Code
    const [optionsZipCodes, setOptionsZipCpdes] = useState([]);
    const [selectedZipCodeOptions, setSelectedZipCodeOptions] = useState();

    function handleLocationInputChange(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    const fetchZipCodes = (query) => {
        const isZipCode = /^\d+$/.test(query); // Check if the query is a number

        fetch(`${BASE_URL}/zipcode_filter/${query}`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {
                const zipcodes = data.zipcodes.original;

                let optionsArray = [];
                if (Array.isArray(zipcodes)) {
                    optionsArray = zipcodes?.map((zipcode) => {
                        let value, label;
                        if (isZipCode) {
                            value = zipcode?.zipcode;
                            label = zipcode?.zipcode;
                            // label = `${zipcode?.zipcode}, ${zipcode?.city}, ${zipcode?.state}`;
                            label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;

                        } else {
                            value = zipcode?.zipcode;
                            label = `${zipcode?.city}, ${zipcode?.state}, ${zipcode?.zipcode}`;
                        }
                        return {
                            value: value,
                            label: label,
                        };
                    });
                }

                setOptionsZipCpdes(optionsArray);
            })
            .catch((error) => {
                console.error("Error fetching zipcodes data:", error);
            });
    };

    function handleSelectZipCode(selectedZipcode) {
        if (selectedZipcode) {
            setSelectedZipCodeOptions(selectedZipcode);
            // Update job data with selected zipcode
            setResumeData(prevJobData => ({
                ...prevJobData,
                location: ''
            }));
            setErrors({...errors, location: ""}); // Clear the error for the title field
            fetchLocationData(selectedZipcode.value, 'location');
        } else {
            setSelectedZipCodeOptions(null);
            // Reset location field in job data
            setResumeData(prevJobData => ({
                ...prevJobData,
                location: ''
            }));
        }
        // setError({ ...error, location: "" }); // Clear the error for the title field

    }

//Preferred Location ZipCode Start
    const [selectedZipCodeOptions1, setSelectedZipCodeOptions1] = useState([]);
    const [selectedZipCodeOptions2, setSelectedZipCodeOptions2] = useState([]);
    const [selectedZipCodeOptions3, setSelectedZipCodeOptions3] = useState([]);

    // Preferred Location 1
    function handlePreferredLocationInputChange1(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    function handlePreferredLocationSelect1(selectedOptions) {
        if (selectedOptions) {
            setSelectedZipCodeOptions1(selectedOptions);
            // Update job data with selected zip codes
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location1: selectedOptions.label,
            }));
            fetchLocationData(selectedOptions.value, 'preferred_location1');

        } else {
            setSelectedZipCodeOptions1([]);
            // Reset preferred_location1 field in job data
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location1: "",
            }));
        }
    }

// Preferred Location 2
    function handlePreferredLocationInputChange2(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    function handlePreferredLocationSelect2(selectedOptions) {
        if (selectedOptions) {
            setSelectedZipCodeOptions2(selectedOptions);
            // Update job data with selected zip codes
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location2: selectedOptions.label,
            }));
            fetchLocationData(selectedOptions.value, 'preferred_location2');

        } else {
            setSelectedZipCodeOptions2([]);
            // Reset preferred_location2 field in job data
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location2: "",
            }));
        }
    }

// Preferred Location 3
    function handlePreferredLocationInputChange3(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    function handlePreferredLocationSelect3(selectedOptions) {
        if (selectedOptions) {
            setSelectedZipCodeOptions3(selectedOptions);
            // Update job data with selected zip codes
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location3: selectedOptions.label,
            }));
            fetchLocationData(selectedOptions.value, 'preferred_location3');

        } else {
            setSelectedZipCodeOptions3([]);
            // Reset preferred_location3 field in job data
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location3: "",
            }));
        }
    }

    function fetchLocationData(zipcode, selected_location) {
        fetch(`${BASE_URL}/zipcode/${zipcode}`, {
            headers,
        })
            .then((response) => response.json())

            .then((data) => {
                if (data.zipcodes) {
                    let location = '';
                    if (data.zipcodes[0].city != null) {
                        location = data.zipcodes[0].city;
                    }
                    if (data.zipcodes[0].state != null) {
                        location += ', ' + data.zipcodes[0].state;
                    }
                    if (data.zipcodes[0].county_area != null) {
                        location += ', ' + data.zipcodes[0].zipcode;
                    }
                    // location+=', USA';
                    ////console.log(location);
                    if (selected_location === 'location') {
                        setResumeData(prevJobData => ({
                            ...prevJobData,
                            location: location,
                            temp_location: location
                        }));
                    }
                    if (selected_location === 'preferred_location1') {
                        setResumeData(prevJobData => ({
                            ...prevJobData,
                            preferred_location1: location
                        }));
                    }
                    if (selected_location === 'preferred_location2') {
                        setResumeData(prevJobData => ({
                            ...prevJobData,
                            preferred_location2: location
                        }));
                    }
                    if (selected_location === 'preferred_location3') {
                        setResumeData(prevJobData => ({
                            ...prevJobData,
                            preferred_location3: location
                        }));
                    }
                } else {
                    console.error(`Zipcode ${zipcode} not found`);
                }
            })
            .catch((error) => {
                console.error(`Error fetching location data for zipcode ${zipcode}:`, error);
            });
    }

    //End ZipCode/Location Code
    const [isAnywhereChecked, setIsAnywhereChecked] = useState(false);
    const handleAnywhereChange = (checked) => {

        setIsAnywhereChecked(!isAnywhereChecked);
        setResumeData(prevJobData => ({
            ...prevJobData,
            anywhere_in_us: isAnywhereChecked ? 0 : 1
        }));
    };

    const [isAnywhereInTheCountry, setIsAnywhereInTheCountry] = useState(false);
    const handleAnywhereInTheCountry = (checked) => {

        setIsAnywhereInTheCountry(!isAnywhereInTheCountry);
        setResumeData(prevJobData => ({
            ...prevJobData,
            anywhere_in_country: isAnywhereInTheCountry ? 0 : 1
        }));
    };


    // Years Of Experience Code
    const [selectedYearOfExperience, setSelectedYearOfExperience] = useState(null);
    const [YearOfExperiences, setYearOfExperiences] = useState([]);

    useEffect(() => {
        const fetchYearOfExperience = async () => {
            try {
                const response = await fetch(`${BASE_URL}/years_of_experience`, {
                    headers,
                });
                const data = await response.json();
                if (data.status === 200) {
                    setYearOfExperiences(data.years_of_experience);

                } else if (data.status === 401) {
                    navigate("/");
                }
            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchYearOfExperience();
    }, []);
    const years_of_experience_options = YearOfExperiences?.map((YearOfExperience) => ({
        value: YearOfExperience?.id,
        label: YearOfExperience?.experience,
    }));


    // Countries Code
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [Countries, setCountries] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch(`${BASE_URL}/countries`, {
                    headers,
                });
                const data = await response.json();
                if (data.status === 200) {
                    setCountries(data.countries);

                } else if (data.status === 401) {
                    navigate("/");
                }

            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchCountries();
    }, []);
    const countries_options = Countries?.map((Country) => ({
        value: Country?.id,
        label: Country?.name,
    }));


    // State Code
    const [selectedState, setSelectedState] = useState(null);
    const [States, setStates] = useState([]);

    useEffect(() => {
        const fetchStates = async () => {
            try {
                if (selectedCountry) {
                    const response = await fetch(`${BASE_URL}/states/` + resumeData.country_id, {
                        headers,
                    });
                    const data = await response.json();
                    setStates(data.states);
                    ////console.log(data)
                }
            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchStates();
    }, [selectedCountry]);
    const states_options = States ? States?.map((State) => ({
        value: State?.id,
        label: State?.name,
    })) : [];


    // City Code
    const [selectedCity, setSelectedCity] = useState(null);
    const [Cities, setCities] = useState([]);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                if (selectedState) {
                    const response = await fetch(`${BASE_URL}/cities/` + resumeData.state_id, {
                        headers,
                    });
                    const data = await response.json();
                    setCities(data.cities);
                    ////console.log(data)
                }
            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchCities();
    }, [selectedState]);
    const cities_options = Cities ? Cities?.map((City) => ({
        value: City?.id,
        label: City?.name,
    })) : [];


    // State Code
    const [selectedState1, setSelectedState1] = useState(null);
    const [States1, setStates1] = useState([]);

    useEffect(() => {
        const fetchStates1 = async () => {
            try {
                if (selectedCountry) {
                    const response = await fetch(`${BASE_URL}/states/` + resumeData.country_id, {
                        headers,
                    });
                    const data = await response.json();
                    setStates1(data.states);
                    ////console.log(data);
                }
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates1();
    }, [selectedCountry]);
    const states1_options = States1
        ? States1?.map((state) => ({
            value: state?.id,
            label: state?.name,
        }))
        : [];

// City Code
    const [selectedCity1, setSelectedCity1] = useState(null);
    const [Cities1, setCities1] = useState([]);

    useEffect(() => {
        const fetchCities1 = async () => {
            try {
                if (selectedState1) {
                    const response = await fetch(`${BASE_URL}/cities/` + selectedState1.value, {
                        headers,
                    });
                    const data = await response.json();
                    setCities1(data.cities);
                    ////console.log(data);
                }
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities1();
    }, [selectedState1]);
    const cities1_options = Cities1
        ? Cities1?.map((city) => ({
            value: city?.id,
            label: city?.name,
        }))
        : [];

// State2 Code
    const [selectedState2, setSelectedState2] = useState(null);
    const [States2, setStates2] = useState([]);

    useEffect(() => {
        const fetchStates2 = async () => {
            try {
                if (selectedCountry) {
                    const response = await fetch(`${BASE_URL}/states/` + resumeData.country_id, {
                        headers,
                    });
                    const data = await response.json();
                    setStates2(data.states);
                    ////console.log(data);
                }
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates2();
    }, [selectedCountry]);
    const states2_options = States2
        ? States2?.map((state) => ({
            value: state?.id,
            label: state?.name,
        }))
        : [];

// City2 Code
    const [selectedCity2, setSelectedCity2] = useState(null);
    const [Cities2, setCities2] = useState([]);

    useEffect(() => {
        const fetchCities2 = async () => {
            try {
                if (selectedState2) {
                    const response = await fetch(`${BASE_URL}/cities/` + selectedState2.value, {
                        headers,
                    });
                    const data = await response.json();
                    setCities2(data.cities);
                    ////console.log(data);
                }
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities2();
    }, [selectedState2]);
    const cities2_options = Cities2
        ? Cities2?.map((city) => ({
            value: city?.id,
            label: city?.name,
        }))
        : [];

// State3 Code
    const [selectedState3, setSelectedState3] = useState(null);
    const [States3, setStates3] = useState([]);

    useEffect(() => {
        const fetchStates3 = async () => {
            try {
                if (selectedCountry) {
                    const response = await fetch(`${BASE_URL}/states/` + resumeData.country_id, {
                        headers,
                    });
                    const data = await response.json();
                    setStates3(data.states);
                    ////console.log(data);
                }
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates3();
    }, [selectedCountry]);
    const states3_options = States3
        ? States3?.map((state) => ({
            value: state?.id,
            label: state?.name,
        }))
        : [];

// City3 Code
    const [selectedCity3, setSelectedCity3] = useState(null);
    const [Cities3, setCities3] = useState([]);
    useEffect(() => {
        fetchResumeData();
    }, []);
    useEffect(() => {
        const fetchCities3 = async () => {
            try {
                if (selectedState3) {
                    const response = await fetch(`${BASE_URL}/cities/` + selectedState3.value, {
                        headers,
                    });
                    const data = await response.json();
                    setCities3(data.cities);
                    ////console.log(data);
                }
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities3();
    }, [selectedState3]);
    const cities3_options = Cities3
        ? Cities3?.map((city) => ({
            value: city?.id,
            label: city?.name,
        }))
        : [];


    // Errors
    // File Upload Code
    const fileInputRef = useRef(null);
    const handleVideoDeleteResume = () => {
        setIsVideoDeleteModalOpen(true);
    }
    const handleDeleteResumeDocument = () => {
        setIsDeleteModalOpen(true);
    }
    const handleConfirmDeleteVideoResume = () => {
        setLoading(true);
        axios
            .get(`${BASE_URL}/resume/delete/video-resume/` + resumeId, {
                headers: headers,
            })
            .then((response) => {
                setLoading(false);
                setIsVideoDeleteModalOpen(false);
                fetchResumeData()
            })
            .catch((error) => {
                // Handle error
                setLoading(false);
            });
    };
    const handleConfirmDeleteResumeDocument = () => {
        setLoading(true);
        axios
            .get(`${BASE_URL}/resume/delete/document/` + resumeId, {
                headers: headers,
            })
            .then((response) => {
                setLoading(false);
                setIsDeleteModalOpen(false);
                fetchResumeData()
            })
            .catch((error) => {
                // Handle error
                setLoading(false);
            });
    };
    const handleFileChange = (e) => {

        setOpenFileModel(true)
        setChooseFile(e)
        setConfirmMessage('Please also update your skills according new resume!')

        ////console.log(resumeData)
    };

    const handleFileChangeConfirm = () => {

        const file = chooseFile.target.files[0];

        if (file) {
            const fileName = file.name;
            document.getElementById('file-label').innerText = fileName;
        }
        setResumeData((prevState) => ({
            ...prevState,
            resume_availability_file_path: file,
        }));
        setErrors({...errors, resume_availability_file_path: ""}); // Clear the error for the title field
        setConfirmMessage("")
        setOpenFileModel(false)
    }


    //

    const [UpdateNowClicked, setUpdateNowClicked] = useState(false);

    const UpdateNow = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors([]);
        setUpdateNowClicked(true);

        const updatedResumeData = {...resumeData};
        updatedResumeData.skills = selectedSkills;
        updatedResumeData.resume_availability_file_path = resumeData.resume_availability_file_path;
        if (resumeData.relocation === 'Yes' && resumeData.relocation_option === 'US' && updatedResumeData.anywhere_in_us === 1) {
            updatedResumeData.preferred_location1 = '';
            updatedResumeData.preferred_location2 = '';
            updatedResumeData.preferred_location3 = '';
        } else if (resumeData.relocation === 'Yes' && resumeData.relocation_option === 'Local' && updatedResumeData.anywhere_in_country === 1) {
            updatedResumeData.preferred_location1 = '';
            updatedResumeData.preferred_location2 = '';
            updatedResumeData.preferred_location3 = '';
        } else if (resumeData.relocation === 'Yes' && resumeData.relocation_option === 'US') {
            ////console.log('US HAI');

            updatedResumeData.preferred_location1 = resumeData.preferred_location1 || '';
            updatedResumeData.preferred_location2 = resumeData.preferred_location2 || '';
            updatedResumeData.preferred_location3 = resumeData.preferred_location3 || '';
        } else if (resumeData.relocation === 'Yes' && resumeData.relocation_option === 'Local') {
            ////console.log('LOCAL HAI');
            updatedResumeData.preferred_location1 =
                (resumeData.preferred_location1 && resumeData.state1
                    ? resumeData.state1 + ', '
                    : '') +
                (resumeData.preferred_location1 && resumeData.city1
                    ? resumeData.city1
                    : '');

            updatedResumeData.preferred_location2 =
                (resumeData.preferred_location2 && resumeData.state2
                    ? resumeData.state2 + ', '
                    : '') +
                (resumeData.preferred_location2 && resumeData.city2
                    ? resumeData.city2
                    : '');

            updatedResumeData.preferred_location3 =
                (resumeData.preferred_location3 && resumeData.state3
                    ? resumeData.state3 + ', '
                    : '') +
                (resumeData.preferred_location3 && resumeData.city3
                    ? resumeData.city3
                    : '');
        }


        if (resumeData.us_based === 'Yes') {
            updatedResumeData.location = resumeData.location;
        } else {
            updatedResumeData.location =
                (resumeData.country_label ? resumeData.country_label : '') +
                (resumeData.country_label && resumeData.state_label ? ', ' : '') +
                (resumeData.state_label ? resumeData.state_label : '') +
                (resumeData.state_label && resumeData.city_label ? ', ' : '') +
                (resumeData.city_label ? resumeData.city_label : '');
        }
        const formData = new FormData();
        for (const key in updatedResumeData) {
            if (key === 'skills') {
                updatedResumeData.skills.forEach((skill, index) => {
                    formData.append(`skills[${index}][skill_set_level]`, skill.skill_set_level);
                    formData.append(`skills[${index}][skill_set_id]`, skill.skill_set_id);
                });
            } else {
                if (updatedResumeData[key] === 'null' ||
                    updatedResumeData[key] === null ||
                    updatedResumeData[key] === undefined
                ) {
                    updatedResumeData[key] = '';
                }
                formData.append(key, updatedResumeData[key]);
            }
        }
        axios
            .post(`${BASE_URL}/resume/` + resumeId + `?_method=PUT`, formData, {
                headers: headers
            }).then((response) => {
            // Handle success
            setLoading(false);
            ////console.log(response.data);
            navigate("/job_seeker_dashboard");
        })
            .catch((error) => {
                // Handle error
                setLoading(false);
                setErrors(error.response.data); // Set the errors state
            });
    };


    useEffect(() => {
        if (UpdateNowClicked) {
            if (errors.preferred_job_title) {
                jobTitleRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setUpdateNowClicked(false); // Reset UpdateNowClicked state

                window.scrollBy(0, jobTitleRef.current.getBoundingClientRect().top - 100);
            } else if (errors.location) {
                jobLocationRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setUpdateNowClicked(false); // Reset UpdateNowClicked state

                window.scrollBy(0, jobLocationRef.current.getBoundingClientRect().top - 100);
            } else if (errors.work_authorization_id) {
                jobWorkAuthorizationRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setUpdateNowClicked(false); // Reset UpdateNowClicked state

                window.scrollBy(0, jobWorkAuthorizationRef.current.getBoundingClientRect().top - 100);
            } else if (errors.resume_availability_file_path) {
                resumeAvailableRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setUpdateNowClicked(false); // Reset UpdateNowClicked state

                window.scrollBy(0, resumeAvailableRef.current.getBoundingClientRect().top - 100);
            }
        }
    }, [UpdateNowClicked, errors.preferred_job_title, errors.location, errors.work_authorization_id, errors.resume_availability_file_path]);

// Inside your component
    const [showModal, setShowModal] = useState(false);

    const openVideoRecorder = () => {
        setShowModal(true);
    };
    const handleVideoRecording = (videoBlob) => {
        // Do something with the videoBlob, such as storing it in the resumeData state
        setResumeData((prevJobData) => ({
            ...prevJobData,
            resume_availability_video_file: videoBlob

        }));
    };
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

    const openResumeView = () => {
        setIsResumeModalOpen(true);
    };

    return (
        <div>
            <JobSeekerHeader/>
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR END                            */}
            {/*---------------------------------------------------------------*/}
            {/*---------------------------------------------------------------*/}
            {/*                        MAin Body Start                        */}
            {/*---------------------------------------------------------------*/}
            <div className="main-RP">
                <div className="ERscreen">
                    <hr className="custom-hr"/>
                    <div className="postcontainer-CR">
                    <div className=" AJbackbtn">
                            {from && <div className=" backbtn mb-2">
                    <button onClick={()=>navigate(-1)}> <BsArrowLeft  /> </button>
                    </div>}
                          </div>

                    <div className="AJheadtitle">
                          
                          <div className="AJtitle w-100">
                            <h2>Update Resume</h2>
                          </div>
                           </div>
                        <div style={{position: "relative"}}>
                                <img
                                    src={rectangelImage}
                                    alt="Image Line"
                                    className="rectangel-image"
                                />
                                <hr className="hr-style"/>
                            </div>

                        {/* <h2>Edit Resume</h2> */}
                        <section action>
                            <div className="jobfield-RP">
                                <div className="job-id-PR">
                                    <label>Resume ID:</label>
                                    <input type="text" placeholder={resumeData.resume_code} disabled/>
                                </div>
                                <div className="job-date-PR">
                                    <label>Date Created:</label>
                                    <input type="text" placeholder={currentDate} value={Helper.changeDateFormat(resumeData?.created_at)} disabled/>
                                </div>
                            </div>

                            {/*----US-Based Or International */}

                            <div className="adress-CR">
                                <div className="CRrow1">
                                    <label>Are you located in the United States?<span
                                        className="CRlabelcol"></span></label>
                                    <div className="toggle-radio">
                                        <input
                                            type="radio"
                                            name="non-us-based"
                                            id="non-us-based"
                                            value="Yes"
                                            checked={resumeData.us_based === 'Yes'}
                                            onChange={handleUSBasedChange}
                                        />
                                        <label htmlFor="non-us-based">Yes</label>
                                        <input
                                            type="radio"
                                            name="us-based"
                                            id="us-based"
                                            value="No"
                                            checked={resumeData.us_based === 'No'}
                                            onChange={handleUSBasedChange}
                                        />
                                        <label htmlFor="us-based">No</label>

                                    </div>

                                </div>
                            </div>


                            <div className="job-title-CR" ref={jobTitleRef}>
                                <label>Preferred Job Title<span className="CRlabelcol">:</span><span
                                    className="required-field">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Preferred Job Title"
                                    value={resumeData.preferred_job_title}
                                    onChange={handlePreferredJobTitleChange}
                                />
                                {errors.preferred_job_title && (
                                    <div className="text-danger">{errors.preferred_job_title}</div>)
                                }
                            </div>
                            <div className="adress-CR">

                                <div className="CRrow1">
                                    <label>Years of Experience<span className="CRlabelcol">:</span> </label>
                                    <Select
                                        id="years_of_experience"
                                        name="term-contract"
                                        options={years_of_experience_options}
                                        value={selectedYearOfExperience}
                                        onChange={(selectedOption) => {
                                            setSelectedYearOfExperience(selectedOption);
                                            const id = selectedOption ? parseInt(selectedOption.value) : null;
                                            setResumeData((prevJobData) => ({
                                                ...prevJobData,
                                                yearsof_experience_id: id,
                                                yearsof_experience_label: selectedOption.label

                                            }));
                                            setErrors((prevError) => ({
                                                ...prevError,
                                                yearsof_experience_id: ""
                                            }));
                                        }}
                                        placeholder="Years of Experience"
                                        isSearchable
                                        required
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                padding: '4px 5px',
                                                border: '1px solid rgba(46, 46, 46, 0.5)',
                                                boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                borderRadius: '8px'
                                            }),
                                        }}
                                    />


                                </div>
                            </div>
                             <div style={{position: "relative"}}>
                                <img
                                    src={rectangelImage}
                                    alt="Image Line"
                                    className="rectangel-image"
                                />
                                <hr className="hr-style"/>
                            </div>
                            {/*---------------------------/// General Information ///-----------------------*/}
                            <section className="generalinosec">
                                <div className="formheading-RP">
                                    <img src={PRpersonalinfoImage}/>
                                    <h5>General Information</h5>
                                </div>
                                {/*--------------------------- Full Name -----------------------*/}
                                <div className="adress-CR">
                                    <div className="CRrow1">
                                        <label>First Name<span className="CRlabelcol">:</span></label>
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            value={resumeData.first_name}
                                            onChange={handleFirstNameChange}
                                        />
                                    </div>
                                    <div className="CRrow1">
                                        <label>Last Name<span className="CRlabelcol">:</span></label>
                                        <input type="text" placeholder="Last Name" value={resumeData.last_name}
                                               onChange={handleLastNameChange}/>
                                    </div>
                                </div>
                                {/*--------------------------Adress / City -------------------- */}
                                <div className="adress-CR">
                                    <div className="CRrow1">
                                        <label>Address<span className="CRlabelcol">:</span></label>
                                        <input
                                            className="adres-CR"
                                            type="text"
                                            placeholder="Address"
                                            value={resumeData.address}
                                            onChange={handleAddressChange}
                                        />
                                    </div>
                                    {/* -------------------------Location/city , state-------------- */}
                                </div>


                                {resumeData.us_based === 'Yes' && (
                                    <div className="adress-CR">

                                        <div className="CRrow1" ref={jobLocationRef}>

                                            <label>Location (ZIP Code/City)<span className="CRlabelcol">:</span><span
                                                className="required-field">*</span> </label>

                                            <Select
                                                options={optionsZipCodes}
                                                placeholder="Search ZIP Code/City"
                                                value={selectedZipCodeOptions}
                                                onChange={handleSelectZipCode}
                                                onInputChange={handleLocationInputChange}
                                                isSearchable={true}

                                                isMulti={false}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        padding: '4px 5px',
                                                        border: '1px solid rgba(46, 46, 46, 0.5)',
                                                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                        borderRadius: '8px'
                                                    }),
                                                }}
                                            />


                                            {errors.location && (
                                                <div className="text-danger">{errors.location}</div>)
                                            }
                                        </div>
                                    </div>
                                )}

                                {/*-----Country Code------*/}
                                {resumeData.us_based === 'No' && (
                                    <div className="adress-CR">
                                        <div className="CRrow1" ref={jobLocationRef}>
                                            <label>Country<span className="CRlabelcol">:</span><span
                                                className="required-field">*</span> </label>
                                            <Select
                                                name="term-contract"
                                                options={countries_options}
                                                value={selectedCountry}
                                                onChange={(selectedOption) => {
                                                    setSelectedCountry(selectedOption);
                                                    setSelectedState(null); // Reset selected state
                                                    setSelectedCity(null); // Reset selected city
                                                    const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                    setResumeData((prevJobData) => ({
                                                        ...prevJobData,
                                                        // location: selectedOption ? selectedOption.label : '',
                                                        country_id: id,
                                                        country_label: selectedOption ? selectedOption.label : ''
                                                    }));
                                                    setErrors((prevError) => ({
                                                        ...prevError,
                                                        country_id: '',
                                                        location: ''
                                                    }));
                                                }}

                                                placeholder="Select Country"
                                                isSearchable
                                                required
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        padding: '4px 5px',
                                                        border: '1px solid rgba(46, 46, 46, 0.5)',
                                                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                        borderRadius: '8px'
                                                    }),
                                                }}
                                            />


                                            {errors.location && (
                                                <div className="text-danger">{'The Country field is required'}</div>)
                                            }
                                        </div>


                                    </div>
                                )}


                                {/*----State Code---------------*/}
                                {resumeData.us_based === 'No' && (
                                    <div className="adress-CR">

                                        <div className="CRrow1" ref={jobLocationRef}>
                                            <label>State/Province<span className="CRlabelcol">:</span> </label>
                                            <Select
                                                name="term-contract"
                                                options={states_options}
                                                value={selectedState}
                                                onChange={(selectedOption) => {
                                                    setSelectedState(selectedOption);
                                                    setSelectedCity(null); // Reset selected city

                                                    const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                    setResumeData((prevJobData) => ({
                                                        ...prevJobData,
                                                        // location: `${selectedCountry ? selectedCountry.label : ''}, ${selectedOption.label ? selectedOption.label : ''}`,
                                                        state_id: id,
                                                        state_label: selectedOption.label

                                                    }));
                                                    setErrors((prevError) => ({
                                                        ...prevError,
                                                        state_id: ""
                                                    }));
                                                }}
                                                placeholder="Select State/Province"
                                                isSearchable
                                                required
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        padding: '4px 5px',
                                                        border: '1px solid rgba(46, 46, 46, 0.5)',
                                                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                        borderRadius: '8px'
                                                    }),
                                                }}
                                            />


                                            {errors.country_id && (
                                                <div className="text-danger">{errors.country_id}</div>)
                                            }
                                        </div>

                                    </div>
                                )}

                                {/*-----City Code------*/}
                                {resumeData.us_based === 'No' && (
                                    <div className="adress-CR">

                                        <div className="CRrow1" ref={jobLocationRef}>
                                            <label>City<span className="CRlabelcol">:</span> </label>

                                            <Select
                                                options={cities_options}
                                                placeholder="Search City"
                                                value={selectedCity}
                                                onChange={(selectedOption) => {
                                                    setSelectedCity(selectedOption);
                                                    const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                    setResumeData((prevJobData) => ({
                                                        ...prevJobData,
                                                        // location: `${selectedCountry ? selectedCountry.label : ''}, ${selectedState ? selectedState.label : ''}, ${selectedOption.label ? selectedOption.label : ''}`,
                                                        city_id: id,
                                                        city_label: selectedOption.label

                                                    }));
                                                    setErrors((prevError) => ({
                                                        ...prevError,
                                                        city_id: ""
                                                    }));
                                                }}
                                                isSearchable={true}

                                                isMulti={false}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        padding: '4px 5px',
                                                        border: '1px solid rgba(46, 46, 46, 0.5)',
                                                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                        borderRadius: '8px'
                                                    }),
                                                }}
                                            />


                                            {errors.city_id && (
                                                <div className="text-danger">{errors.city_id}</div>)
                                            }
                                        </div>
                                    </div>
                                )}
                                <div style={{position: "relative"}}>
                                    <img
                                        src={rectangelImage}
                                        alt="Image Line"
                                        className="rectangel-image"
                                    />
                                    <hr className="hr-style"/>
                                </div>

                                {/*--------------------------Home -------------------- */}
                                <div className="formheading-RP contacticon">
                                    <img src={contactnforesume}/>
                                    <h5>Contact Info</h5>
                                </div>
                                {/* Mobile */}
                                <div className="adress-CR">
                                    <div className="CRrow1">
                                        <label>Mobile<span className="CRlabelcol">:</span></label>
                                        <input
                                            className="adres-CR"
                                            type="tel"
                                            placeholder="Mobile"
                                            value={resumeData.mobile}
                                            onChange={handleMobileChange}
                                        />
                                    </div>
                                    <div className="CRrow1 pre-phone-num">
                                        <input
                                            className="pre-phone-num-CR"
                                            type="checkbox"
                                            checked={preferredNumber === 'mobile'}
                                            onChange={(event) => handlePreferredCheckboxChange(event, 'mobile')}
                                        />

                                        <p>Preferred Number</p>
                                    </div>
                                </div>

                                {/* Home */}
                                <div className="adress-CR">
                                    <div className="CRrow1">
                                        <label>Home<span className="CRlabelcol">:</span></label>
                                        <input
                                            className="adres-CR"
                                            type="text"
                                            placeholder="Home"
                                            value={resumeData.home}
                                            onChange={handleHomeChange}
                                        />
                                    </div>
                                    <div className="CRrow1 pre-phone-num">
                                        <input
                                            className="pre-phone-num-CR"
                                            type="checkbox"
                                            checked={preferredNumber === 'home'}
                                            onChange={(event) => handlePreferredCheckboxChange(event, 'home')}
                                        />

                                        <p>Preferred Number</p>
                                    </div>
                                </div>

                                {/* Office */}
                                <div className="adress-CR">
                                    <div className="CRrow1">
                                        <label>Office<span className="CRlabelcol">:</span></label>
                                        <input
                                            className="adres-CR"
                                            type="text"
                                            placeholder="Office"
                                            value={resumeData.office}
                                            onChange={handleOfficeChange}
                                        />
                                    </div>
                                    <div className="CRrow1 pre-phone-num">
                                        <input
                                            className="pre-phone-num-CR"
                                            type="checkbox"
                                            checked={preferredNumber === 'office'}
                                            onChange={(event) => handlePreferredCheckboxChange(event, 'office')}
                                        />

                                        <p>Preferred Number</p>
                                    </div>
                                </div>

                                {/* Other form components */}
                                <div style={{position: "relative"}}>
                                    <img
                                        src={rectangelImage}
                                        alt="Image Line"
                                        className="rectangel-image"
                                    />
                                    <hr className="hr-style"/>
                                </div>
                                {/*--------------------------------------Resume Availability ---------------*/}
                                <div className="formheading-RP" ref={resumeAvailableRef}>
                                    <img src={PRresumeavailabilityImage}/>
                                    <h5>Resume Availability</h5>
                                </div>
                                <div className="CRrow3">
                                    <div className="toggle-radio">
                                        <input
                                            type="radio"
                                            name="resumeavailbility"
                                            id="resumeavailbility-2"
                                            value="No"
                                            checked={resumeData.resume_availability === 'No'}
                                            onChange={handleResumeAvailabilityChange}
                                        />
                                        <label htmlFor="resumeavailbility-2">No</label>
                                        <input
                                            type="radio"
                                            name="resumeavailbility"
                                            id="resumeavailbility-1"
                                            value="Yes"
                                            checked={resumeData.resume_availability === 'Yes'}
                                            onChange={handleResumeAvailabilityChange}
                                        />
                                        <label htmlFor="resumeavailbility-1">Yes</label>
                                    </div>

                                </div>
                                <Modal show={isVideoDeleteModalOpen} onHide={()=>handleCloseModal()} centered>
                                    <Modal.Header  >
                                        <Modal.Title>Delete Video Resume</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure you want to delete Video Resume!</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={()=>handleCloseModal()}>
                                            Close
                                        </Button>
                                        <Button variant="danger" onClick={() =>handleConfirmDeleteVideoResume()}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={isDeleteModalOpen} onHide={()=>handleCloseModal()} centered>
                                    <Modal.Header  >
                                        <Modal.Title>Delete Resume Document</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure you want to delete Resume Document!</Modal.Body>
                                    <Modal.Footer>
                                        <h5 className="mb-4">
                                                                                       <span className="text-primary message">
                                                Note: Removing this document will also erase its associated skills. Consider updating them beforehand.
                                            </span>
                                        </h5>
                                        <Button variant="secondary" onClick={()=>handleCloseModal()}>
                                            Close
                                        </Button>
                                        <Button variant="danger" onClick={() =>handleConfirmDeleteResumeDocument()}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {resumeData.resume_availability === 'Yes' && typeof resumeData.resume_availability_file_path != "object" && resumeData.resume_availability_file_path && (
                                    <div className='resume_btn'>
                                        <button class="btn btn-primary" style={{background: '#003580'}}
                                                onClick={openResumeView}>My Uploaded Resume
                                        </button>
                                        <i class="fa-sharp fa-solid fa-trash fa-xl"
                                           onClick={() => handleDeleteResumeDocument()}
                                           style={{color: 'red', cursor: 'pointer'}} data-bs-toggle="tooltip"
                                           data-bs-placement="top" title="Remove Resume"></i></div>)}
                                {resumeData.resume_availability === 'Yes' && (!resumeData.resume_availability_file_path || typeof resumeData.resume_availability_file_path == "object") && (
                                    <>
                                        <div className="resume-upload-container">
                                            <div className="resume-upload" style={{width: '48%'}}>
                                                {/* File Upload */}
                                                <div className="resumedesign">
                                                    <p>Upload Resume!</p>

                                                    <label htmlFor="myfile" className="btn btn-primary btn-sm"
                                                           style={{background: '#003580'}}>
                                                        <input type="file" id="myfile" name="myfile"
                                                               onChange={handleFileChange} accept=".doc, .docx,.pdf"
                                                               ref={fileInputRef} className="d-none"/>
                                                        Choose File
                                                    </label>
                                                    <span id="file-label">No file chosen</span>
                                                    <br/>
                                                    <p></p>
                                                    <p>Supported formats: pdf, doc, docx</p>
                                                </div>
                                            </div>
                                        </div>
                                        {errors.resume_availability_file_path && (
                                            <div className="text-danger">{errors.resume_availability_file_path}</div>)
                                        }
                                    </>
                                )}
                                {errors.resume_availability_file_path && (
                                    <div className="text-danger">{errors.resume_availability_file_path}</div>)
                                }
                                {resumeData.resume_availability === 'Yes' &&
                                    resumeData.resume_availability_video_file != "" &&
                                    resumeData.resume_availability_video_file != "null" &&
                                    resumeData.resume_availability_video_file != null &&
                                    typeof resumeData.resume_availability_video_file != "object" && (
                                    <div className='resume_btn'>
                                        <button class="btn btn-primary" onClick={() => setIsVideoModalOpen(true)}
                                                style={{background: '#003580'}}>My Uploaded Video Resume
                                        </button>
                                        <i class="fa-sharp fa-solid fa-trash fa-xl" style={{color: 'red',cursor: 'pointer'}}
                                           data-bs-toggle="tooltip"
                                           data-bs-placement="top" title="Remove Video Resume"
                                           onClick={() => handleVideoDeleteResume()}></i></div>)}
                                <Modal show={isVideoModalOpen} onHide={()=>handleCloseModal()} centered>
                                    <Modal.Header  >
                                        <Modal.Title>Video Resume</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>{resumeData.resume_availability_video_file != "null" && resumeData.resume_availability_video_file && typeof resumeData.resume_availability_video_file == "object" &&
                                    <video
                                        src={URL.createObjectURL(resumeData.resume_availability_video_file)}
                                        // src={STORAGE_URL+resumeData. resume_availability_video_file}

                                        controls
                                        style={{
                                            width: '100%',
                                            height: '90%',
                                        }}
                                    />}
                                    {resumeData.resume_availability_video_file != "null" && resumeData.resume_availability_video_file && typeof resumeData.resume_availability_video_file == "string" &&
                                    <video
                                        // src={URL.createObjectURL(resumeData.resume_availability_video_file)}
                                        src={STORAGE_URL + resumeData.resume_availability_video_file}

                                        controls
                                        style={{
                                            width: '100%',
                                            height: '90%',
                                        }}
                                    />
                                    }</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={()=>handleCloseModal()}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {resumeData.resume_availability === 'Yes' && (
                                    <>
                                        <div className="resume-upload-container">
                                            {((resumeData.resume_availability_video_file == "null" || resumeData.resume_availability_video_file == "" || resumeData.resume_availability_video_file == null) || typeof resumeData.resume_availability_video_file == "object") && (<>
                                                <div className="video-resume">
                                                    <p>Record Video Resume!</p>
                                                    <button
                                                        className="btn btn-primary btn-sm me-2"
                                                        onClick={openVideoRecorder}
                                                        style={{background: '#003580'}}
                                                    >
                                                        Open Video Recorder
                                                    </button>
                                                    {/* Place your video resume component or additional elements here */}
                                                    <VideoRecorder
                                                        showModal={showModal}
                                                        setShowModal={setShowModal}
                                                        onVideoRecorded={handleVideoRecording}
                                                    />
                                                    {resumeData.resume_availability_video_file != "null" && resumeData.resume_availability_video_file != null && resumeData.resume_availability_video_file != "" && (
                                                        <div>
                                                            {/* Display your video recording confirmation or other content here */}

                                                            {/* Open the video modal when the button is clicked */}
                                                            <Button className="btn btn-primary btn-sm me-2"
                                                                    style={{background: "#60BF9F", border: "#60BF9F"}}
                                                                    onClick={() => setIsVideoModalOpen(true)}>View Video
                                                                Resume</Button>
                                                            <p>Video recorded successfully!</p>

                                                            {/* Render the video modal */}
                                                            {/*<Modal*/}
                                                            {/*    isOpen={isVideoModalOpen}*/}
                                                            {/*    onRequestClose={() => setIsVideoModalOpen(false)}*/}
                                                            {/*    style={{*/}
                                                            {/*        content: {*/}
                                                            {/*            width: '500px', // Adjust the width as needed*/}
                                                            {/*            height: '400px', // Adjust the height as needed*/}
                                                            {/*            margin: 'auto',*/}

                                                            {/*        },*/}
                                                            {/*    }}*/}
                                                            {/*>*/}
                                                            {/* Render the video with controls for playback */}
                                                            {/*    {resumeData.resume_availability_video_file!="null" && resumeData.resume_availability_video_file!=null && <video*/}
                                                            {/*        src={URL.createObjectURL(resumeData.resume_availability_video_file)}*/}
                                                            {/*        controls*/}
                                                            {/*        style={{*/}
                                                            {/*            width: '100%',*/}
                                                            {/*            height: '90%',*/}
                                                            {/*        }}*/}
                                                            {/*    />}*/}
                                                            {/*    /!* Add a close button *!/*/}
                                                            {/*    <Button variant="danger" className="me-2"*/}
                                                            {/*            style={{background: "#E54545"}}*/}
                                                            {/*            onClick={() => setIsVideoModalOpen(false)}>Close</Button>*/}
                                                            {/*</Modal>*/}

                                                        </div>
                                                    )}
                                                    {errors.resume_availability_video_file && (
                                                        <div
                                                            className="text-danger">{errors.resume_availability_video_file}</div>)
                                                    }

                                                </div>
                                            </>)}
                                        </div>
                                    </>
                                )}
                                {/*-------------------------- Skills / Levls -----------------------*/}

                                <div className="adress-CR">
                                    <div className="CRrow1">
                                        <label>Select Skills<span className="CRlabelcol">:</span></label>
                                        <Select
                                            options={optionsSkills}
                                            placeholder="Select Skill"
                                            value={selectedOptions}
                                            onChange={handleSkillSelect}
                                            isSearchable={true}
                                            isMulti={false}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    padding: '4px 5px',
                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                    borderRadius: '8px'
                                                }),
                                            }}
                                        />
                                        {errors.skills && (
                                            <div className="text-danger">{errors.skills}</div>)
                                        }

                                    </div>

                                    <div className="CRrow1">
                                        <label>Skill Level<span className="CRlabelcol">:</span></label>
                                        <Select
                                            options={skillLevels}
                                            onChange={handleLevelSelect}
                                            value={selectedLevel}
                                            isSearchable={true}
                                            isMulti={false}
                                            placeholder="Select Skill Level"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    padding: '4px 5px',
                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                    borderRadius: '8px'
                                                }),
                                            }}
                                        />
                                    </div>

                                </div>
                                {selectedSkills.length > 0 && (
                                    <div className="col-lg-12 three-d-perspective" style={{marginTop: '24px'}}>
                                        <ul className="list-group">
                                            {selectedSkills?.map((skill, index) => (
                                                <li className="list-group-item d-flex justify-content-between align-items-center"
                                                    key={index}>
                                                    {skill.skill_set?.name}
                                                    <div>

                                                <span className="badge rounded-pill" style={{
                                                    backgroundColor: getBadgeColor(skill?.skill_set_level),
                                                    color: '#FFF'
                                                }}>
                                                    {skill?.skill_set_level}
                                                  </span>
                                                        <img
                                                            src={DeleteResumeImage}
                                                            alt="Delete"
                                                            onClick={() => handleSkillRemove(skill)}
                                                            className="delete-icon"
                                                        />
                                                    </div>
                                                </li>
                                            ))}

                                        </ul>
                                    </div>
                                )}

                                <div style={{position: "relative"}}>
                                    <img
                                        src={rectangelImage}
                                        alt="Image Line"
                                        className="rectangel-image"
                                    />
                                    <hr className="hr-style"/>
                                </div>
                                {/* ------------ Work Authorization And Job Location Preferences--------------*/}
                                <section className="jobpreferencesec" ref={jobWorkAuthorizationRef}>
                                    <div className="formheading-RP">
                                        <img src={PRpreferencesImage}/>
                                        <h5>Work Authorization And Job Location Preferences</h5>
                                    </div>
                                    {/* ---------------Work Authorization / relocation ---------*/}
                                    <div className="CRrow1">
                                        <br/>
                                        <label>Work Authorization<span className="CRlabelcol">:</span><span
                                            className="required-field">*</span></label>
                                        <Select
                                            id="work_authorization"
                                            name="term-contract"
                                            options={work_authorization_options}
                                            value={selectedWorkAuthorization}
                                            onChange={(selectedOption) => {
                                                setSelectedWorkAuthorization(selectedOption);

                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                setResumeData((prevState) => ({
                                                    ...prevState,
                                                    work_authorization_id: id,
                                                    work_authorization_label: selectedOption.label
                                                }));
                                                setErrors((prevError) => ({
                                                    ...prevError,
                                                    work_authorization_id: ""
                                                }));
                                            }}

                                            placeholder="Work Authorization"
                                            isSearchable={true}

                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    padding: '4px 5px',
                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                    borderRadius: '8px',
                                                }),
                                            }}
                                        />
                                        {errors.work_authorization_id && (
                                            <div className="text-danger">{errors.work_authorization_id}</div>)
                                        }

                                    </div>


                                    <div className="adress-CR">
                                        <div className="CRrow1">
                                            <label>Relocation<span className="CRlabelcol">:</span></label>
                                            <div className="toggle-radio">
                                                <input
                                                    type="radio"
                                                    name="relocation"
                                                    id="relocation-2"
                                                    value="No"
                                                    checked={resumeData.relocation === 'No'}
                                                    onChange={handleRelocationChange}
                                                />
                                                <label htmlFor="relocation-2">No</label>
                                                <input
                                                    type="radio"
                                                    name="relocation"
                                                    id="relocation-1"
                                                    value="Yes"
                                                    checked={resumeData.relocation === 'Yes'}
                                                    onChange={handleRelocationChange}
                                                />
                                                <label htmlFor="relocation-1">Yes</label>
                                            </div>
                                            {errors.relocation && (
                                                <div className="text-danger">{errors.relocation}</div>
                                            )}
                                        </div>
                                    </div>

                                    {resumeData.relocation === 'Yes' && (

                                        <div className="adress-CR">
                                            <div className="CRrow1">
                                                <label>Relocation Option<span className="CRlabelcol">:</span></label>
                                                <div className="toggle-radio usaradio">
                                                    <input
                                                        type="radio"
                                                        name="relocationOption"
                                                        id="relocationOption-us"
                                                        value="US"
                                                        checked={resumeData.relocation_option === 'US'}
                                                        onChange={() => handleRelocationOptionChange('US')}
                                                    />
                                                    <label htmlFor="relocationOption-us">US</label>
                                                    {resumeData.us_based === 'No' && (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                name="relocationOption"
                                                                id="relocationOption-local"
                                                                value="Local"
                                                                checked={resumeData.relocation_option === 'Local'}
                                                                onChange={() => handleRelocationOptionChange('Local')}
                                                            />
                                                            <label htmlFor="relocationOption-local">Local</label>
                                                        </>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    )}

                                    {resumeData.relocation_option === 'US' && (
                                        <>
                                            {resumeData.relocation === 'Yes' && (
                                                <div className="checkbox-container">
                                                    <input
                                                        type="checkbox"
                                                        id="anywhere-checkbox"
                                                        checked={isAnywhereChecked}
                                                        onChange={(e) => handleAnywhereChange(e.target.checked)}

                                                    />
                                                    <span className="pj-checkbox-text">Anywhere in the US</span>
                                                </div>
                                            )}
                                            {!isAnywhereChecked && resumeData.relocation === 'Yes' && (
                                                <>

                                                    <div className="CRrow1 PL1">
                                                        <label>Preferred Location(s)<span
                                                            className="CRlabelcol">:</span></label>
                                                        <Select
                                                            options={optionsZipCodes}
                                                            placeholder="Search ZIP Code/City"
                                                            value={selectedZipCodeOptions1}
                                                            onChange={handlePreferredLocationSelect1}
                                                            onInputChange={handlePreferredLocationInputChange1}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: "10px",
                                                                    padding: "4px 5px",
                                                                    border: "1px solid rgba(46, 46, 46, 0.5)",
                                                                    boxShadow: "0px 1px 4px rgba(115, 153, 191, 0.05)",
                                                                    borderRadius: "8px",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="CRrow1 PL">
                                                        <Select
                                                            options={optionsZipCodes}
                                                            placeholder="Search ZIP Code/City"
                                                            value={selectedZipCodeOptions2}
                                                            onChange={handlePreferredLocationSelect2}
                                                            onInputChange={handlePreferredLocationInputChange2}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: "10px",
                                                                    padding: "4px 5px",
                                                                    border: "1px solid rgba(46, 46, 46, 0.5)",
                                                                    boxShadow: "0px 1px 4px rgba(115, 153, 191, 0.05)",
                                                                    borderRadius: "8px",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="CRrow1 PL">
                                                        <Select
                                                            options={optionsZipCodes}
                                                            placeholder="Search ZIP Code/City"
                                                            value={selectedZipCodeOptions3}
                                                            onChange={handlePreferredLocationSelect3}
                                                            onInputChange={handlePreferredLocationInputChange3}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: "10px",
                                                                    padding: "4px 5px",
                                                                    border: "1px solid rgba(46, 46, 46, 0.5)",
                                                                    boxShadow: "0px 1px 4px rgba(115, 153, 191, 0.05)",
                                                                    borderRadius: "8px",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}


                                        </>
                                    )}

                                    {resumeData.relocation === 'Yes' && resumeData.relocation_option === 'Local' && (
                                        <>
                                            {resumeData.relocation === 'Yes' && (
                                                <div className="checkbox-container">
                                                    <input
                                                        type="checkbox"
                                                        id="anywhere-checkbox"
                                                        checked={isAnywhereInTheCountry}
                                                        onChange={(e) => handleAnywhereInTheCountry(e.target.checked)}

                                                    />
                                                    <span className="pj-checkbox-text">
                                          {resumeData.country_label ? `Anywhere in the ${resumeData.country_label}` : 'Anywhere in the Country'}
                                        </span>
                                                </div>
                                            )}

                                            {!isAnywhereInTheCountry && (
                                                <div className="adress-CR">
                                                    <div className="CRrow1">
                                                        <label>State 1<span className="CRlabelcol"></span></label>
                                                        <Select
                                                            name="term-contract"
                                                            options={states1_options}
                                                            value={selectedState1}
                                                            onChange={(selectedOption) => {
                                                                setSelectedState1(selectedOption);
                                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                                setResumeData((prevJobData) => ({
                                                                    ...prevJobData,
                                                                    state1: selectedOption ? selectedOption.label : '',
                                                                }));
                                                                setErrors((prevError) => ({
                                                                    ...prevError,
                                                                }));
                                                            }}
                                                            placeholder="Select State/Province"
                                                            isSearchable
                                                            required
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    padding: '4px 5px',
                                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                                    borderRadius: '8px'
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="CRrow1">
                                                        <label>City 1<span className="CRlabelcol"></span></label>
                                                        <Select
                                                            options={cities1_options}
                                                            placeholder="Search City"
                                                            value={selectedCity1}
                                                            onChange={(selectedOption) => {
                                                                setSelectedCity1(selectedOption);
                                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                                setResumeData((prevJobData) => ({
                                                                    ...prevJobData,
                                                                    city1: selectedOption ? selectedOption.label : '',
                                                                }));
                                                                setErrors((prevError) => ({
                                                                    ...prevError,
                                                                }));
                                                            }}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    padding: '4px 5px',
                                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                                    borderRadius: '8px'
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {!isAnywhereInTheCountry && (
                                                <div className="adress-CR">
                                                    <div className="CRrow1">
                                                        <label>State 2<span className="CRlabelcol"></span></label>
                                                        <Select
                                                            name="term-contract"
                                                            options={states2_options}
                                                            value={selectedState2}
                                                            onChange={(selectedOption) => {
                                                                setSelectedState2(selectedOption);
                                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                                setResumeData((prevJobData) => ({
                                                                    ...prevJobData,
                                                                    state2: selectedOption ? selectedOption.label : '',
                                                                }));
                                                                setErrors((prevError) => ({
                                                                    ...prevError,
                                                                }));
                                                            }}
                                                            placeholder="Select State/Province"
                                                            isSearchable
                                                            required
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    padding: '4px 5px',
                                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                                    borderRadius: '8px'
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="CRrow1">
                                                        <label>City 2<span className="CRlabelcol"></span></label>
                                                        <Select
                                                            options={cities2_options}
                                                            placeholder="Search City"
                                                            value={selectedCity2}
                                                            onChange={(selectedOption) => {
                                                                setSelectedCity2(selectedOption);
                                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                                setResumeData((prevJobData) => ({
                                                                    ...prevJobData,
                                                                    city2: selectedOption ? selectedOption.label : '',
                                                                }));
                                                                setErrors((prevError) => ({
                                                                    ...prevError,
                                                                }));
                                                            }}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    padding: '4px 5px',
                                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                                    borderRadius: '8px'
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {!isAnywhereInTheCountry && (
                                                <div className="adress-CR">
                                                    <div className="CRrow1">
                                                        <label>State 3<span className="CRlabelcol"></span></label>
                                                        <Select
                                                            name="term-contract"
                                                            options={states3_options}
                                                            value={selectedState3}
                                                            onChange={(selectedOption) => {
                                                                setSelectedState3(selectedOption);
                                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                                setResumeData((prevJobData) => ({
                                                                    ...prevJobData,
                                                                    state3: selectedOption ? selectedOption.label : '',
                                                                }));
                                                                setErrors((prevError) => ({
                                                                    ...prevError,
                                                                }));
                                                            }}
                                                            placeholder="Select State/Province"
                                                            isSearchable
                                                            required
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    padding: '4px 5px',
                                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                                    borderRadius: '8px'
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="CRrow1">
                                                        <label>City 3<span className="CRlabelcol"></span></label>
                                                        <Select
                                                            options={cities3_options}
                                                            placeholder="Search City"
                                                            value={selectedCity3}
                                                            onChange={(selectedOption) => {
                                                                setSelectedCity3(selectedOption);
                                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                                setResumeData((prevJobData) => ({
                                                                    ...prevJobData,
                                                                    city3: selectedOption ? selectedOption.label : '',
                                                                }));
                                                                setErrors((prevError) => ({
                                                                    ...prevError,
                                                                }));
                                                            }}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    padding: '4px 5px',
                                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                                    borderRadius: '8px'
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}


                                        </>
                                    )}

                                    {/*----------------------Job Type --------------- */}
                                    <div className="adress-CR">

                                        <div className="CRrow2">
                                            <label>Preferred Job Type<span className="CRlabelcol">:</span></label>
                                            <div className="toggle-radio">
                                                <input
                                                    type="radio"
                                                    name="jobtype"
                                                    id="jobtype-1"
                                                    value="Full Time"
                                                    checked={resumeData.preferred_job_type === 'Full Time'}
                                                    onChange={handleJobTypeChange}
                                                />
                                                <label htmlFor="jobtype-1">Full Time</label>
                                                <input
                                                    type="radio"
                                                    name="jobtype"
                                                    id="jobtype-2"
                                                    value="Contract"
                                                    checked={resumeData.preferred_job_type === 'Contract'}
                                                    onChange={handleJobTypeChange}
                                                />
                                                <label htmlFor="jobtype-2">Contract</label>
                                                <input
                                                    type="radio"
                                                    name="jobtype"
                                                    id="jobtype-3"
                                                    value="1099"
                                                    checked={resumeData.preferred_job_type === '1099'}
                                                    onChange={handleJobTypeChange}
                                                />
                                                <label htmlFor="jobtype-3">1099</label>


                                            </div>
                                            {errors.preferred_job_type && (
                                                <div className="text-danger">{errors.preferred_job_type}</div>)
                                            }
                                            {resumeData.preferred_job_type === 'Contract' && (
                                                <div className="adress-CR">
                                                    {/* <label><span className="CRlabelcol"></span></label> */}

                                                    <div className="contractc2c">
                                                        <label>Contract Type<span
                                                            className="CRlabelcol">:</span></label>
                                                        <div className="toggle-radio">
                                                            <input
                                                                type="radio"
                                                                name="contract"
                                                                id="contract-1"
                                                                value="Independent"
                                                                checked={resumeData.contract_type === 'Independent'}
                                                                onChange={handleContractTypeChange}
                                                            />
                                                            <label htmlFor="contract-1">Independent</label>
                                                            <input
                                                                type="radio"
                                                                name="contract"
                                                                id="contract-2"
                                                                value="Corp to Corp"
                                                                checked={resumeData.contract_type === 'Corp to Corp'}
                                                                onChange={handleContractTypeChange}
                                                            />
                                                            <label htmlFor="contract-2">Corp to Corp</label>
                                                        </div>
                                                    </div>

                                                    {resumeData.contract_type && (

                                                        <div className="CRrow1">
                                                            <label>Independent/C2C Company Name<span
                                                                className="CRlabelcol">:</span></label>
                                                            <div className="salary-range">
                                                                <div className="C2C">
                                                                    <input
                                                                        className="C2Cclass"
                                                                        type="text"
                                                                        placeholder="Independent/C2C Company Name"
                                                                        value={resumeData.independent_c2c_company_name}
                                                                        onChange={handleC2CCompanyNameChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/*---------------------------Salary Expectations-------------*/}
                                    <div className="salary-CRsec">
                                        <div className="salary-PR">
                                            <label>Salary Expectations ($)<span className="CRlabelcol">:</span></label>
                                            <div className="salary-range">
                                                <div className="min-salary">
                                                    <input
                                                        className="min-salary-PR"
                                                        type="text"
                                                        placeholder="Expected Annual Salary"
                                                        value={resumeData.salary_expectations}
                                                        onChange={handleSalaryExpectationsChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="salary-PR">
                                            <label>Expected Hourly Rate ($)<span className="CRlabelcol">:</span></label>
                                            <div className="salary-range">
                                                <div className="min-salary">
                                                    <input
                                                        className="min-salary-PR"
                                                        type="text"
                                                        placeholder="Expected Hourly Rate"
                                                        value={resumeData.expected_hourly_rate}
                                                        onChange={handleExpectedHourlyRateChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </section>


                            </section>

                            <div className="checkbox-container">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isCheckboxChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <span className="pj-checkbox-text">I confirm all details are accurate.</span>
                                </label>

                                {errors.can_post && (
                                    <div className="text-danger">{errors.can_post}</div>)
                                }
                            </div>

                            <div style={{position: "relative"}}>
                                <img
                                    src={rectangelImage}
                                    alt="Image Line"
                                    className="rectangel-image"
                                />
                                <hr className="hr-style"/>
                            </div>

                            <div className="crudbtnCR">
                                <div className="crudbtnlft-CR">
                                {from && <button onClick={()=>navigate(from)}>Cancel</button>}
                                    {/*<button><a href="postjobpreview.html"> Preview</a></button>*/}
                                    {/* <button onClick={previewClickBtn}>Preview</button> */}
                                    {/* <button>Save and Post Later</button> */}
                                </div>
                                <div className="crudbtnrlt-CR">
                                    <button onClick={UpdateNow}>Update</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}
            <Modal show={isResumeModalOpen} onHide={()=>handleCloseModal()} centered   dialogClassName="custom-half-width-modal" // Apply the custom class here

            >
                <Modal.Header  >
                    <Modal.Title>Uploaded Resume</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {resFile && (
                        <div className="col-lg-12" >
                            <div className="CRpostpreviewcontainer" >
                                <hr className="custom-hr" />
                                <div  >
                                    {console.log(pdfCheck, 'pdfCheck')}
                                    {pdfCheck? (
                                        <PdfViewer height='550px' resumeFile={STORAGE_URL + resumeData.resume_availability_file_path}  />

                                    ):
                                        (
                                            <DocViewer resumeFile={STORAGE_URL + resumeData.resume_availability_file_path}  />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>handleCloseModal()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={openConfirmModel} onHide={()=>handleCloseModal()} centered>
                <Modal.Header  >
                    <Modal.Title>{confirmMessage}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                <Button variant="success" size="sm" onClick={handleConfirmUSBasedChange}>Confirm</Button>
                        <Button variant="danger" size="sm" onClick={()=>handleCloseModal()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={openFileModel} onHide={()=>handleCloseModal()} centered>
                <Modal.Header  >
                    <Modal.Title>{confirmMessage}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div>
                        <Button variant="success" size="sm" onClick={handleFileChangeConfirm}>Ok</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default EditResume;