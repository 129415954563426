import React,{useState} from "react";
import {AiFillFilter} from "react-icons/ai";
import {Modal} from "react-bootstrap";
import {AiOutlineClose} from "react-icons/ai";
import {JobTypeCount} from "../JobTypeCount";
import {JobModeCount} from "../JobModeCount";
import {WorkAuthorization} from "./AllJobsFilter/WorkAuthorization";
import {ContractType} from "./AllJobsFilter/ContractType";
import {Status} from "./AllJobsFilter/Status";
import {Experience} from "./AllJobsFilter/Experience";
import {CustomModalStyle} from "../CustomModalStyle";

export const AllJobsSidebar = ({
                                   workAuthorizations,
                                   selectedWorkAuthorization,
                                   handleChangeWorkAuthorization,
                                   contactTypeData,
                                   selectedContractType,
                                   handleChangeContractType,
                                   contactType,
                                   filter,
                                   handleJobStatusChange,
                                   setFilter,
                                   handleExperienceChange
                                 })=>{

    const [showFilter, setShowFilter] = useState(false)



    const showAnalyticModal=()=>{
        setShowFilter(true)
    }

    const closeAnalytics = ()=>{
        setShowFilter(false)
    }

    return(
        <>
            <div className="">
                <div className="d-flex justify-content-end gap-4">
                    <AiFillFilter
                        size={24}
                        role="button"
                        title="Filters"
                        style={{color:"#003580"}}
                        onClick={showAnalyticModal}
                    ></AiFillFilter>

                </div>

            </div>


            {/*Filter Modal*/}
            <Modal show={showFilter} onHide={closeAnalytics} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title >Filters</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeAnalytics}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div className='p-1'>


                            {/*-------------------Work Authorization ----------- */}
                             <WorkAuthorization
                                 handleChangeWorkAuthorization={handleChangeWorkAuthorization}
                                selectedWorkAuthorization={selectedWorkAuthorization}
                                workAuthorizations={workAuthorizations}
                             />
                            {/*-------------------Contract Type ----------- */}
                            <ContractType
                                contactTypeData={contactTypeData}
                                selectedContractType={selectedContractType}
                                handleChangeContractType={handleChangeContractType}
                            />
                            {/*-----------------------Status --------------- */}
                            <Status
                                contactType={contactType}
                                filter={filter}
                                handleJobStatusChange={handleJobStatusChange}
                            />
                            {/*-----------------------Job Type --------------- */}
                            <div className="resumeupdatealjob work-authorizaljob alljobbox">
                                <h2>Job Type</h2>

                                {contactType && contactType?.jobTypeCount?.map((Jobtype, index)=>(
                                    <JobTypeCount
                                        filter = {filter}
                                        setFilter = {setFilter}
                                        Jobtype={Jobtype}
                                    />
                                ))}

                            </div>

                            {/*-----------------------Interview Mode --------------- */}
                            <div className="resumeupdatealjob work-authorizaljob alljobbox">
                                <h2>Job Mode</h2>

                                {contactType && contactType?.jobModeCount?.map((JobMode)=>(

                                    <JobModeCount
                                        filter={filter}
                                        setFilter={setFilter}
                                        JobMode={JobMode} />
                                ))}


                            </div>
                            {/*-----------------Experience ------------- */}
                             <Experience
                                contactType={contactType}
                                filter={filter}
                                handleExperienceChange={handleExperienceChange}
                             />

                    </div>
                </Modal.Body>
            </Modal>

            <CustomModalStyle />

        </>
    )
}