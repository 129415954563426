import EmployerHeader from "./EmployerHeader";
import '../src/assets/css/createresume.css';
// import PRpersonalinfoImage from '../src/assets/PRpersonalinfo.svg';
import PRpreferencesImage from '../src/assets/PRpreferences.svg';
import PRresumeavailabilityImage from '../src/assets/PRresumeavailability.svg';
import ExperienceImage from  '../src/assets/experiencePR.svg';
import rectangelImage from "./assets/rectangel.png";
import AddAnotherImage from '../src/assets/addanother.svg';
import EditResumeImage from '../src/assets/editresume.svg';
import DeleteResumeImage from  '../src/assets/deleteresumePR.svg';
import EducationImage from  '../src/assets/educationPR.svg';
import JobSeekerHeader from "./JobSeekerHeader";
import SkillsImage from  '../src/assets/skillsPR.svg';
import {useState} from "react";

function CreateResume(){


    const [showExperience, setShowExperience] = useState(false);

    const handleAddExperience = () => {

        setShowExperience(true);
    };

    const [showExperienceDetail, setShowExperienceDetail] = useState(false);


    const [showJobDescription, setShowJobDescription] = useState(false);

    const toggleJobDescription = () => {
        setShowJobDescription(!showJobDescription);
    };
    const [experienceDetails, setExperienceDetails] = useState([]);
    const handleAddExperience1 = () => {
        //Experience Code
        // Get the data from the form fields
        const companyName = document.getElementById("exp-companyname-input").value;
        const jobTitle = document.getElementById("exp-jobtitle-input").value;
        // const startDate = document.getElementById("exp-startdate-input").value;
        const startDate = new Date(document.getElementById("exp-startdate-input").value).toLocaleDateString("en-US");

        const endDate = new Date(document.getElementById("exp-enddate-input").value).toLocaleDateString("en-US");
        const jobDescription = document.getElementById("exp-jobdescription-textarea").value;
        console.log(companyName, jobTitle, startDate, endDate, jobDescription);

        // Create an object to store the experience details
        const newExperience = {
            companyName,
            jobTitle,
            startDate,
            endDate,
            jobDescription
        };
        // Add the new experience to the state variable
        if(newExperience.companyName || newExperience.jobTitle || (newExperience.startDate && newExperience.startDate!='Invalid Date') || (newExperience.endDate && newExperience.endDate!='Invalid Date') || newExperience.jobDescription) {
            setExperienceDetails([...experienceDetails, newExperience]);
            setShowExperienceDetail(true);
        }
        // Hide the experience input fields
        setShowExperience(false);

        // Show the experience details

    };
    //Education Code

    const [showEducation, setShowEducation] = useState(false);

    const handleAddEducation = () => {

        setShowEducation(true);
    };

    const [showEducationDetail, setShowEducationDetail] = useState(false);



    const [educationDetails, setEducationDetails] = useState([]);
    const handleAddEducation1 = () => {

        // Get the data from the form fields
        const edu_institute_name = document.getElementById("edu-institute-name").value;
        const edu_degree_name = document.getElementById("edu-degree-name").value;
        const edu_major =document.getElementById('edu-major').value;
        const edu_start_date = new Date(document.getElementById("edu-start-date").value).toLocaleDateString("en-US");
        const edu_end_date = new Date(document.getElementById("edu-end-date").value).toLocaleDateString("en-US");

        const edu_cgpa = document.getElementById("edu-cgpa").value;
        const edu_percentage =document.getElementById('edu-percentage').value;

        // Create an object to store the experience details
        const newEducation = {
            edu_institute_name,
            edu_degree_name,
            edu_major,
            edu_start_date,
            edu_end_date,
            edu_cgpa,
            edu_percentage
        };
        // Add the new experience to the state variable
        if(newEducation.edu_institute_name || newEducation.edu_degree_name || newEducation.edu_major || (newEducation.edu_start_date && newEducation.edu_start_date!='Invalid Date') || (newEducation.edu_end_date && newEducation.edu_end_date!='Invalid Date') || newEducation.edu_cgpa || newEducation.edu_percentage) {
            setEducationDetails([...educationDetails, newEducation]);
            setShowEducationDetail(true);
        }
        // Hide the experience input fields
        setShowEducation(false);

        // Show the experience details

    };

    //Skills Code
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedSkills, setSelectedSkills] = useState([]);

    const handleSkillSelect = (event) => {
        const skill = event.target.value;
        setSelectedSkill(skill);

        if (selectedLevel) {
            setSelectedSkills((prevSkills) => [...prevSkills, `${skill} ${selectedLevel}`]);
            setSelectedSkill(null);
            setSelectedLevel(null);
        }
    };

    const handleLevelSelect = (event) => {
        const level = event.target.value;
        setSelectedLevel(level);

        if (selectedSkill) {
            setSelectedSkills((prevSkills) => [...prevSkills, `${selectedSkill} ${level}`]);
            setSelectedSkill(null);
            setSelectedLevel(null);
        }

        // reset the dropdowns
        document.getElementById("skills").selectedIndex = 0;
        document.getElementById("skilllevel").selectedIndex = 0;

        // add the default options back
        const defaultSkillOption = document.createElement("option");
        defaultSkillOption.text = "Select Skill";
        defaultSkillOption.disabled = true;
        defaultSkillOption.selected = true;

        const defaultLevelOption = document.createElement("option");
        defaultLevelOption.text = "Select Skill Level";
        defaultLevelOption.disabled = true;
        defaultLevelOption.selected = true;
    };

    const handleSkillRemove = (skill) => {
        setSelectedSkills((prevSkills) => {
            return prevSkills.filter((s) => s !== skill);
        });
    };

// Code For Languages
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [selectedLanguageLevel, setSelectedLanguageLevel] = useState(null);
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const handleLanguageSelect = (event) => {
        const language = event.target.value;
        setSelectedLanguage(language);

        if (selectedLanguageLevel) {
            setSelectedLanguages((prevLanguages) => [
                ...prevLanguages,
                `${language} ${selectedLanguageLevel}`,
            ]);
            setSelectedLanguage(null);
            setSelectedLanguageLevel(null);
        }
    };

    const handleLanguageLevelSelect = (event) => {
        const languageLevel = event.target.value;
        setSelectedLanguageLevel(languageLevel);

        if (selectedLanguage) {
            setSelectedLanguages((prevLanguages) => [
                ...prevLanguages,
                `${selectedLanguage} ${languageLevel}`,
            ]);
            setSelectedLanguage(null);
            setSelectedLanguageLevel(null);
        }

        // reset the dropdowns
        const languagesDropdown = document.getElementById("languages");
        const languageLevelDropdown = document.getElementById("languagelevel");

        if (languagesDropdown && languageLevelDropdown) {
            languagesDropdown.selectedIndex = 0;
            languageLevelDropdown.selectedIndex = 0;

            // add the default options back
            const defaultLanguageOption = document.createElement("option");
            defaultLanguageOption.text = "Select Language";
            defaultLanguageOption.disabled = true;
            defaultLanguageOption.selected = true;
            languagesDropdown.add(defaultLanguageOption, 0);

            const defaultLanguageLevelOption = document.createElement("option");
            defaultLanguageLevelOption.text = "Select Language Level";
            defaultLanguageLevelOption.disabled = true;
            defaultLanguageLevelOption.selected = true;
            languageLevelDropdown.add(defaultLanguageLevelOption, 0);
        }
    };

    const handleLanguageRemove = (language) => {
        setSelectedLanguages((prevLanguages) => {
            return prevLanguages.filter((l) => l !== language);
        });
    };

    return(
        <div>
            <JobSeekerHeader/>
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR END                            */}
            {/*---------------------------------------------------------------*/}
            {/*---------------------------------------------------------------*/}
            {/*                        MAin Body Start                        */}
            {/*---------------------------------------------------------------*/}
            <div className="main-RP">
                <div className="postcontainer-RP">
                    <h2>Post a Resume</h2>
                    <section action>
                        <div className="jobfield-RP">
                            <div className="job-id-PR">
                                <label>Resume ID</label>
                                <input type="text" placeholder={23287911} disabled />
                            </div>
                            <div className="job-date-PR">
                                <label>Created Date</label>
                                <input type="text" placeholder="01/02/2023" disabled />
                            </div>
                        </div>
                        {/*--------------------------------------///Video Resume///  ---------------*/}
                        <section className="profilesec">
                            <div className="videores-PR">
                                <div className="formheading-RP">
                                    <img src={PRresumeavailabilityImage} />
                                    <h5>Video Resume</h5>
                                </div>
                                <div className="video-resume">
                                    <input type="file" accept=".jpg,.jpeg.,.gif,.png,.mov,.mp4" />
                                    <div className="video-PR" />
                                </div>
                            </div>
                            <div className="Profilepic-PR">
                                <div className="formheading-RP">
                                    <img src={PRresumeavailabilityImage} />
                                    <h5>Profile Picture</h5>
                                </div>
                                <div className="video-resume">
                                    <input type="file" accept=".jpg,.jpeg.,.gif,.png,.mov,.mp4" />
                                    <div className="video-PR" />
                                </div>
                            </div>
                        </section>
                        {/*---------------------------/// General Information ///-----------------------*/}
                        <section className="generalinosec">
                            <div className="formheading-RP">
                                {/*<img src={PRpersonalinfoImage} />*/}
                                <h5>General Information</h5>
                            </div>
                            {/*--------------------------- Full Name -----------------------*/}
                            <div className="job-title-PR">
                                <input type="text" placeholder="Full Name" />
                            </div>
                            <div className="adress-PR">
                                <div className="industry-PR">
                                    <select className="industry-catagory" id="industry" name="industry">
                                        <option value="Industry">Industry</option>
                                        <option value="IT industry">IT industry</option>
                                        <option value="accounting">Accounting</option>
                                        <option value="Health Care">Health Care</option>
                                        <option value="Banking">Banking</option>
                                    </select>
                                </div>
                                <input className="phone-PR" type="number" placeholder="Phone" />
                            </div>
                            {/*--------------------------- Zip / Adress-----------------------*/}
                            <div className="adress-PR">
                                <div className="zip-PR">
                                    <select className="zip-dress" id="zip-code" name="zipcode">
                                        <option value="employement-type">Zip</option>
                                        <option value={54000}>54000</option>
                                        <option value={46578}>46578</option>
                                        <option value={67007}>67007</option>
                                    </select>
                                </div>
                                <input className="adres-PR" type="text" placeholder="Address" />
                            </div>
                            {/*-------------------------- Summery ---------------------------*/}
                            <div className="resume-summery">
                                <label>Summary</label>
                                <textarea defaultValue={""} />
                            </div>
                            {/*------------------ Position / Year of experience -----------*/}
                            <div className="exp-pos">
                                <div className="res-position">
                                    <input type="text" placeholder="Current Position" />
                                </div>
                                <div className="res-experience">
                                    <select id="experience" name="year-experience">
                                        <option value="experience" select>Experience</option>
                                        <option value="1-2 years" select>1-2 years</option>
                                        <option value="2-3 years">2-3 years</option>
                                        <option value="4-5 years">4-5 years</option>
                                        <option value="5-7 years">5-7 years</option>
                                        <option value="8-10 years">8-10 years</option>
                                    </select>
                                </div>
                            </div>
                            {/*-------------------------- Work Authorization-PR / Employement Type -----------------------*/}
                            <div className="res-work">
                                <div className="text-term-PR">
                                    <select className="work-authorization-PR" id="contract" name="term-contract" style={{textTransform: 'capitalize'}}>
                                        <option value="work-authorization">Work Authorization</option>
                                        <option value="US_CITIZEN">US Citizen</option>
                                        <option value="CANADIAN_CITIZEN">Canadian Citizen</option>
                                        <option value="HAVE_H1_VISA">Have H1 Visa</option>
                                        <option value="NEED_H1_VISA">Need H1 Visa</option>
                                        <option value="GREEN_CARD_HOLDER">Green Card Holder</option>
                                        <option value="TN_PERMIT_HOLDER">TN Permit Holder</option>
                                        <option value="EMPLOYMENT_AUTH_DOCUMENT">Employment Auth Document</option>
                                    </select>

                                </div>
                                <div className="text-term2-PR">
                                    <select className="employement-Type-PR" id="employementtype" name="employement-type">
                                        <option value="employement-type">Employement Type</option>
                                        <option value="Full-time">Full-time</option>
                                        <option value="Part-time">Part-time</option>
                                        <option value="Contract-Corp-to-Corp">Contract-Corp-to-Corp</option>
                                        <option value="Contract-Independent">Contract-Independent</option>
                                        <option value="Contract-W2">Contract-W2</option>
                                        <option value="ContracttoHire-Corp-to-Corp">Contract to Hire-Corp-to-Corp</option>
                                        <option value="ContracttoHire-Independent">Contract to Hire-Independent</option>
                                        <option value="ContracttoHire-W2">Contract to Hire-W2</option>
                                        <option value="Internship">Internship</option>
                                    </select>
                                </div>
                            </div>
                        </section>
                        {/*--------------------------------------/// Experience ///---------------*/}
                        <section className="experiencesec">
                            <div className="expsection-PR">
                                <div className="expheading-RP">
                                    <img src={ExperienceImage} />
                                    <h5>Experience</h5>
                                </div>
                                <div className="addanother-PR">
                                    <button id="add-experience-btn" onClick={handleAddExperience}>
                                        {showExperienceDetail ? "Add Another" : "Add"}{" "}
                                        <img src={AddAnotherImage} alt="Add another" />
                                    </button>
                                </div>
                            </div>
                            {showExperience &&(

                            <div className="experiencedtl-PR">
                                {/*------------------------- Job Title --------------------------*/}
                                <div className="job-title-PR">
                                    <input id="exp-jobtitle-input" type="text" placeholder="Job Title" />
                                </div>
                                {/*----------------------------Current job / company ------------*/}
                                <div className="job-worktype-PR">
                                    <label>Current Job</label>
                                    <div className="relocation-PR">
                                        <div className="relocation">
                                            <div className="toggle-radio">
                                                <input type="radio" name="currentjob" id="currentjob-1" defaultValue="yes" defaultChecked />
                                                <label htmlFor="currentjob-1">Yes</label>
                                                <input type="radio" name="currentjob" id="currentjob-2" defaultValue="no" />
                                                <label htmlFor="currentjob-2">No</label>
                                            </div>
                                        </div>
                                        <div className="preferloc">
                                            <input id="exp-companyname-input" className="companyname-PR" type="text" placeholder="Company Name " />
                                        </div>
                                    </div>
                                </div>
                                {/*---------------------------------- Start / End Date ----------------------------*/}
                                <div className="dateselect-PR">
                                    <div className="startdate">
                                        <label>Start Date</label>
                                        <input id="exp-startdate-input" className="startdate-PR" type="date" placeholder="Start Date" />
                                    </div>
                                    <div className="enddate">
                                        <label>End Date</label>
                                        <input id="exp-enddate-input" className="enddate-PR" type="date" placeholder="End Date" />
                                    </div>
                                </div>
                                {/*---------------------------------- Job Description ----------------------------*/}
                                <div className="resume-summery">
                                    <label>Job Description</label>
                                    <textarea id="exp-jobdescription-textarea" defaultValue={""} />
                                </div>
                                <div className="addbtn-PR">
                                    <button onClick={handleAddExperience1}>ADD</button>
                                </div>
                            </div>
                            )}
                            {/*--------Experience detail after Filling Data --------------*/}
                            {/*------------------Preview 1 ------------*/}
                                {showExperienceDetail &&experienceDetails.map((experience, index) => (
                                        <div className="expdtl-PR" key={index}>
                                            <div className="row">
                                                <div className="col-lg-12 d-flex">
                                                    <div className="col-lg-11">
                                                        <div className="expdtl1-PR">
                                                            <h4>{experience.companyName}</h4>
                                                        </div>
                                                        <div className="expdtl2-PR ">
                                                            <p>{experience.jobTitle}</p>
                                                            <p>From {experience.startDate} - To {experience.endDate}</p>
                                                        </div>
                                                        <div className="expdtl4-PR">
                                                            <p className="expjobdes-RR" onClick={toggleJobDescription}>
                                                                {showJobDescription ? 'Hide Job Description' : 'View Job Description'}
                                                            </p>
                                                            {showJobDescription && (
                                                                <p className="expjobdes">{experience.jobDescription}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1  expdtl-img-PR">
                                                        <img src={EditResumeImage} />
                                                        <img src={DeleteResumeImage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            {/*)}*/}
                            {/*------------------Preview 2 ------------*/}
                        </section>
                        {/*--------------------------------------/// Education ///---------------*/}
                        <section className="educationsec">
                            <div className="expsection-PR">
                                <div className="expheading-RP">
                                    <img src= {EducationImage}/>
                                    <h5>Education</h5>
                                </div>
                                <div className="addanother-PR">
                                    <button onClick={handleAddEducation}>
                                        {showEducationDetail ? "Add Another" : "Add"}{" "}
                                        <img src={AddAnotherImage} alt="Add another" />
                                    </button>
                                </div>
                            </div>
                            {showEducation &&(

                                <div className="educationdtl-PR">
                                {/*------------------------- Institute Name --------------------------*/}
                                <div className="institute-PR">
                                    <input id="edu-institute-name" type="text" placeholder="Institute Name" />
                                </div>
                                {/*--------------------------Degree Name / Major ---------------------*/}
                                <div className="degreemajor-PR">
                                    <div className="degreemajor">
                                        <input id="edu-degree-name" className="degree-PR" type="text" placeholder="Degree Name" />
                                    </div>
                                    <div className="degreemajor">
                                        <input id="edu-major" className="major-PR" type="text" placeholder="Major" />
                                    </div>
                                </div>
                                {/*---------------------------------- Start / End Date ----------------------------*/}
                                <div className="dateselect-PR">
                                    <div className="startdate">
                                        <label>Start Date</label>
                                        <input id="edu-start-date" className="startdate-PR" type="date" placeholder="Start Date" />
                                    </div>
                                    <div className="enddate">
                                        <label>End Date</label>
                                        <input id="edu-end-date" className="enddate-PR" type="date" placeholder="End Date" />
                                    </div>
                                </div>
                                {/*--------------------------CGPA / percentage ---------------------*/}
                                <div className="degreemajor-PR">
                                    <div className="degreemajor">
                                        <input id="edu-cgpa" className="degree-PR" type="number" placeholder="CGPA" />
                                    </div>
                                    <div className="degreemajor">
                                        <input id="edu-percentage" className="major-PR" type="number" placeholder="Percentage (%)" />
                                    </div>
                                </div>
                                {/*---------------------------Add Education btn -------------*/}
                                <div className="addbtn-PR">
                                    <button id="add-education-btn-inside" onClick={handleAddEducation1}>ADD</button>

                                </div>
                            </div>
                            )}
                            {/*--------Education detail after Filling Data --------------*/}
                            {/*------------------Preview 1 ------------*/}
                            {showEducationDetail &&educationDetails.map((education, index) => (

                            <div className="expdtl-PR" key={index}>
                                <div className="row">
                                    <div className="col-lg-12 d-flex">
                                        <div className="col-lg-11">
                                            <div className="expdtl1-PR">
                                                <h4>Institute Name: {education.edu_institute_name}</h4>
                                                <p>Degree: {education.edu_degree_name} </p>
                                            </div>
                                            <div className="expdtl2-PR ">
                                                <p>Major: {education.edu_major}</p>
                                                <p>From {education.edu_start_date} - To {education.edu_end_date}</p>
                                            </div>
                                            <div className="expdtl3-PR ">
                                                <p>CGPA: {education.edu_cgpa}</p>
                                                <p>Percentage: {education.edu_percentage}%</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-1  expdtl-img-PR">
                                            <img src={EditResumeImage} />
                                            <img src={DeleteResumeImage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </section>
                        {/*--------------------------------------/// Skills ///---------------*/}
                        <section className="skillssec">
                            <div className="formheading-RP">
                                <img src={SkillsImage} />
                                <h5>Skills</h5>
                            </div>
                            {/*-------------------------- Skills / Levls -----------------------*/}

                            <div className="res-work">
                                <div className="text-term-PR">
                                    <select className="skill-PR" id="skills" name="skills" onChange={handleSkillSelect}>
                                        <option disabled selected value> Select Skill </option>
                                        <option value="HTML">HTML</option>
                                        <option value="JavaScript">JavaScript</option>
                                        <option value="React">React</option>
                                        <option value="Python">Python</option>
                                    </select>
                                </div>
                                <div className="text-term2-PR">
                                    <select className="skill-level-PR" id="skilllevel" name="skill-level-type" onChange={handleLevelSelect}>
                                        <option disabled selected value> Select Skill Level </option>
                                        <option value="Beginner">Beginner</option>
                                        <option value="Intermediate">Intermediate</option>
                                        <option value="Expert">Expert</option>
                                    </select>
                                </div>

                                {/*<button onClick={handleSkillAdd}>Add Skill</button>*/}
                            </div>
                            {selectedSkills.length > 0 && (
                                <div className="skilltodo-PR">
                                    {selectedSkills.map((skill, index) => (
                                        <div className="skillpreview-PR" key={index}>
                                            <p>
                                                {skill}
                                                <img
                                                    src={DeleteResumeImage}
                                                    alt="Delete"
                                                    onClick={() => handleSkillRemove(skill)}
                                                />
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/*<div className="res-work">*/}
                            {/*    <div className="text-term-PR">*/}
                            {/*        <select className="skill-PR" id="skills" name="skills">*/}
                            {/*            <option value="Skills">Skills</option>*/}
                            {/*            <option value="HTML">HTML</option>*/}
                            {/*            <option value="JavaScript">JavaScript</option>*/}
                            {/*            <option value="React">React</option>*/}
                            {/*            <option value="Python">Python</option>*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*    <div className="text-term2-PR">*/}
                            {/*        <select className="skill-level-PR" id="skilllevel" name="skill-level-type">*/}
                            {/*            <option value="Skilllevel">Skill Level</option>*/}
                            {/*            <option value="Beginner">Beginner</option>*/}
                            {/*            <option value="Intermediate">Intermediate</option>*/}
                            {/*            <option value="Expert">Expert</option>*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="skilltodo-PR">*/}
                            {/*    <div className="skillpreview-PR">*/}
                            {/*        <p>Skill1 <span style={{color: '#4C5966'}}> Expert</span><img*/}
                            {/*            src={DeleteResumeImage}/>*/}
                            {/*        </p>*/}
                            {/*    </div>*/}
                            {/*    <div className="skillpreview-PR">*/}
                            {/*        <p>Skill 2 <span style={{color: '#4C5966'}}> Beginner</span><img*/}
                            {/*            src={DeleteResumeImage}/></p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                        </section>
                        {/*--------------------------------------/// Job Preferences  ///---------------*/}
                        <section className="jobpreferencesec">
                            <div className="formheading-RP">
                                <img src={PRpreferencesImage} />
                                <h5>Preferences</h5>
                            </div>
                        </section>
                        {/*---------------------------Salary Expectations-------------*/}
                        <div className="salary-PR">
                            <label>Salary Expectations ($)</label>
                            <div className="salary-range">
                                <div className="min-salary">
                                    <input className="min-salary-PR" type="number" placeholder="Min Annual Salary" />
                                </div>
                                <div className="max-salary">
                                    <input className="max-salary-PR" type="number" placeholder="Max Annual Salary" />
                                </div>
                            </div>
                        </div>
                        {/*---------------------------------- Hourly Rate / Relocation ----------------------------*/}
                        <div className="hourrecloc-PR">
                            <div className="hourly-rates">
                                <label>Expected Hourly Rate ($)</label>
                                <input className="hourly-PR" type="text" placeholder=" $" />
                            </div>
                            <div className="relocation">
                                <label>Relocation</label>
                                <div className="toggle-radio">
                                    <input type="radio" name="relocation" id="relocation-1" defaultValue="yes" defaultChecked />
                                    <label htmlFor="relocation-1">Yes</label>
                                    <input type="radio" name="relocation" id="relocation-2" defaultValue="no" />
                                    <label htmlFor="relocation-2">No</label>
                                </div>
                            </div>
                        </div>
                        {/*-------------------------- Languages / Levls -----------------------*/}
                        <div className="res-work">
                            <div className="text-term-PR">
                                <select className="skill-PR" id="languages" name="languages" onChange={handleLanguageSelect}>
                                    <option disabled selected value> Select Language </option>
                                    <option value="English">English</option>
                                    <option value="Spanish">Spanish</option>
                                    <option value="French">French</option>
                                    <option value="German">German</option>
                                </select>
                            </div>
                            <div className="text-term2-PR">
                                <select className="skill-level-PR" id="languagelevel" name="language-level-type" onChange={handleLanguageLevelSelect}>
                                    <option disabled selected value> Select Language Level </option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Expert">Expert</option>
                                </select>
                            </div>

                            {/*<button onClick={handleSkillAdd}>Add Language</button>*/}
                        </div>

                        {selectedLanguages.length > 0 && (
                            <div className="skilltodo-PR">
                                {selectedLanguages.map((language, index) => (
                                    <div className="skillpreview-PR" key={index}>
                                        <p>
                                            {language}
                                            <img
                                                src={DeleteResumeImage}
                                                alt="Delete"
                                                onClick={() => handleLanguageRemove(language)}
                                            />
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                        {/*<div className="res-work">*/}
                        {/*    <div className="text-term-PR">*/}
                        {/*        <select className="skill-PR" id="skills" name="skills">*/}
                        {/*            <option value="languages">Languages</option>*/}
                        {/*            <option value="English">English</option>*/}
                        {/*            <option value="Chines">Chines</option>*/}
                        {/*            <option value="Korean">Korean</option>*/}
                        {/*            <option value="Hindi">Hindi</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*    <div className="text-term2-PR">*/}
                        {/*        <select className="skill-level-PR" id="skilllevel" name="skill-level-type">*/}
                        {/*            <option value="Skilllevel">Skill Level</option>*/}
                        {/*            <option value="Expert">Native</option>*/}
                        {/*            <option value="Beginner">Fluent</option>*/}
                        {/*            <option value="Intermediate">Beginner</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*--------------------------------------Resume Availability ---------------*/}
                        <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style" />
                    </div>
                        <div className="formheading-RP">
                            <img src={PRresumeavailabilityImage} />
                            <h5>Resume Availability</h5>
                        </div>
                        <div className="resume-upload">
                            {/* <p>Upload</p> */}
                            <input type="file" id="myfile" name="myfile" multiple /><br />
                            <p>pdf, doc, docx</p>
                        </div>
                        <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style" />
                    </div>
                        <div className="crudbtn">
                        <div className="crudbtnlft-CR d-flex gap-3">
                            <button>Cancel</button>
                            {/*<button><a href="postjobpreview.html"> Preview</a></button>*/}
                            <button><a href="https://rezmaze.globalconsultingpk.com/rezmazenew/postresumepreview.html">Preview</a></button>
                            <button>Save For Later</button>
                        </div>
                        <div className="crudbtnrlt-CR">
                            <button>Post Now</button>
                        </div>
                        </div>
                    </section></div>
            </div>
        </div>
    )
}
export default CreateResume;