import React,{useState} from "react";
import {AiFillFilter} from "react-icons/ai";
import {Modal} from "react-bootstrap";
import {AiOutlineClose} from "react-icons/ai";
import {CustomModalStyle} from "../CustomModalStyle";
import {HiMiniInformationCircle} from "react-icons/hi2";
import styles from "../../assets/css/ProfileView.module.css";
import {STORAGE_URL} from "../../config";
import avatarImage from "../../assets/profile5.png";
import {LuUsers} from "react-icons/lu";
import {useNavigate} from "react-router-dom";
import {CustomDivider} from "./SideBarContent/Divider";
import {BiSolidMessageRoundedEdit} from "react-icons/bi";
import { BsArrowLeft } from 'react-icons/bs';

export const MyProfieSideBar = ({
                                    userData,
                                    firstThreeFollowers,
                                    followers,
                                    showAllFollowers,
                                    handleFollowFollowing,
                                    handleUserClick

                               })=>{

    const [showFilter, setShowFilter] = useState(false)
    const navigate = useNavigate()
    const showDetailModal=()=>{
        setShowFilter(true)
    }

    const closeDetailModal = ()=>{
        setShowFilter(false)
    }
    const handleEditProfile = () => {
        navigate("/create_profile")

    }
    //Location code
    const generateNonUsLocation = () => {
        const country =  userData?.userable?.country?.name || '';
        const state =  userData?.userable?.state || '';
        const city = userData?.userable?.city || '';

        return `${country}, ${state}, ${city}`;
    };

    return(
        <>
            <div className={styles.user_info_detail}>
                <div className='backbtn'>
                    <button onClick={()=>navigate(-1)}>
                     <BsArrowLeft style={{color:"#003580"}} role='button'  />
                    </button>
                </div>
                <div className="d-flex justify-content-end gap-2">

                    <BiSolidMessageRoundedEdit
                        size={24}
                        role="button"
                        title="Edit Profile"
                        style={{color:"#003580"}}
                        onClick={handleEditProfile}>
                    </BiSolidMessageRoundedEdit>
                    <HiMiniInformationCircle
                        size={24}
                        role="button"
                        title="Detail"
                        style={{color:"#003580"}}
                        onClick={showDetailModal}
                    ></HiMiniInformationCircle>

                </div>



            </div>


            {/*Filter Modal*/}
            <Modal show={showFilter} onHide={closeDetailModal} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title >Details</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeDetailModal}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div>
                        <div className={styles.pv_left_below}>

                            <div className={styles.pv_left_detail_desc}>
                                <p>

                                    {userData?.about_me}
                                </p>
                                <hr />

                                <div className={styles.pv_left_detail_content1_section}>
                                    <p>Location: <span>{userData?.userable?.us_based === 'yes' ?userData?.userable?.location : generateNonUsLocation() }</span></p>
                                </div>


                                <div className={styles.pv_left_detail_content1_section}>

                                    {userData?.current_employee &&(
                                        <p>Current Job: <span>{userData?.current_employee}</span></p>
                                    )
                                    }
                                </div>
                                <div className={styles.pv_left_detail_content1_section}>
                                    {/* <img src={searchSkill} alt="skill icon" /> */}
                                    {userData?.skills.length > 0 && (
                                        <p>
                                            Skill Set:&nbsp;
                                            {userData?.skills.map((skill, index) => (
                                                <span key={skill.id} className={styles.pv_blue_content}>
                                                        #{skill.name}
                                                    {index < userData.skills.length - 1 && ", "}
                                                      </span>
                                            ))}
                                        </p>
                                    )}
                                </div>
                                <div className={styles.pv_left_detail_content1_section}>
                                    {/* <img src={searchSkill} alt="skill icon" /> */}
                                    {userData?.experience_details.length > 0 && (
                                        <p>
                                            Experience Details:&nbsp;
                                            {userData?.experience_details.map((experience_detail, index) => (
                                                <span key={experience_detail.id} className={styles.pv_blue_content}>
                    {experience_detail.job_title} at {experience_detail.company_name} ({experience_detail.start_year} - {experience_detail.end_year})
                                                    {index < userData.experience_details.length - 1 && ", "}
                </span>
                                            ))}
                                        </p>
                                    )}
                                </div>


                                <div className={styles.pv_left_detail_content1_section}>
                                    {userData?.education_details.length > 0 && (
                                        <p>
                                            Education Details:&nbsp;
                                            {userData?.education_details.map((education_detail, index) => (
                                                <span key={education_detail.id} className={styles.pv_blue_content}>
                                                         {education_detail.degree_name} at {education_detail.institute_name}, Graduated in {education_detail.year_of_graduation}
                                                    {index < userData.education_details.length - 1 && ", "}
                                                     </span>
                                            ))}
                                        </p>
                                    )}
                                </div>
                                <div className={styles.pv_left_detail_content1_section}>
                                    {/* <img src={searchSkill} alt="skill icon" /> */}
                                    {userData?.hobbies.length > 0 && (
                                        <p>
                                            Hobbies:&nbsp;
                                            {userData?.hobbies.map((hobbie, index) => (
                                                <span key={hobbie.id} className={styles.pv_blue_content}>
                                                        {hobbie.name}
                                                    {index < userData.hobbies.length - 1 && ", "}
                                                      </span>
                                            ))}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div className="container">
                                <CustomDivider />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex gap-2 align-items-center mb-2">
                                            {/*<img src={headerIcone1} alt="follower icon" />*/}
                                            <LuUsers size={24} className={styles.follower_heading}></LuUsers>
                                            <p className={`${styles.follower_heading} mb-0`}><strong>Followers</strong></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-wrap gap-2">
                                    {firstThreeFollowers.map((follower) => (
                                        <>
                                        <div
                                            className={`d-flex align-items-center flex-row gap-2 py-2 ${styles.custom_follower_content}`}
                                            onClick={() =>{handleUserClick(follower.id)}}
                                        >
                                        <img
                                            src={`${STORAGE_URL}${follower.image || avatarImage}`}
                                            alt={`${follower.firstname}'s Image`}
                                            className={styles.follower_img}
                                        />
                                            <div className='d-flex flex-column'>
                                                <p className={`${styles.follower_name} m-0`} role="button">
                                                   <strong> {follower.firstname} {follower.lastname}</strong>
                                                </p>
                                                <p className={`${styles.follower_des} m-0`}>{follower.current_employee}</p>
                                            </div>
                                        </div>

                                        </>
                                        // <div key={follower.id} style={{width: '48%'}}> {/* Add margin-bottom (mb-3) */}
                                        //     <div className="card">
                                        //         <img
                                        //             src={`${STORAGE_URL}${follower.image || avatarImage}`}
                                        //             alt={`${follower.firstname}'s Image`}
                                        //             className="card-img-top"
                                        //         />
                                        //         <div className="card-body">
                                        //             <h5 className="card-title" role="button" onClick={() =>{handleUserClick(follower.id)}}>
                                        //                 {follower.firstname} {follower.lastname}
                                        //             </h5>
                                        //             <p className="card-text">{follower.current_employee}</p>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                    ))}
                                </div>

                                {followers.length > 3 && !showAllFollowers && (
                                    <div className="row">
                                        <div className="col-12">
                                            <button className="btn btn-link" onClick={handleFollowFollowing}>
                                                View All ({followers.length - 3} more)
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <CustomModalStyle />

        </>
    )
}