import React, {useState} from "react";
import './assets/css/header.css';
import '../src/assets/css/login.css';
import logoImage from '../src/assets/logo4.svg';
import emailImage from '../src/assets/emailplaceholder.svg';
import passwordImage from '../src/assets/passwordplaceholder.svg';
import watchPasswordImage from '../src/assets/watchpassword.svg';
import setPasswordImage from '../src/assets/setpassword.svg';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import BASE_URL from "./config";
import styles from "./assets/css/LoadingComponent.module.css";
import style from '../src/assets/css/LoadingComponent.module.css';
import {SignInHeader} from "./components/Header/SignInHeader";
import {useMediaQuery} from "react-responsive";

function SignUp() {

    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const signInClick = () => {
        navigate('/employer-login');
    };
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checked, setChecked] = useState(false);

    const [error, setError] = useState([]);
    const [success, setSuccess] = useState([]);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const passwordVisible = () => {
        console.log(isPasswordVisible)
        setIsPasswordVisible(!isPasswordVisible);
    }
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const ConfirmPasswordVisible = () => {
        console.log(isConfirmPasswordVisible)
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    }
    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSignUp();
        }
    };
    const handleSignUp = (e) => {
        setError([]);
        if(e) {
            e.preventDefault();
        }
        setLoading(true);
        setIsPasswordVisible(false);
        setIsConfirmPasswordVisible(false);
        if (password !== confirmPassword) {
            setError({...error, confirmPassword: 'Password does not match'});
            setLoading(false);
            return;
        }
        if (!checked) {
            setError([]);
            setError({...error, checked: 'Please check the "I am not a robot" box before submitting.'});
            setLoading(false);
            return;
        }
        const payload = {
            email: email,
            password: password
        };

        axios.post(`${BASE_URL}/employee_register`, payload)
            .then(response => {
                setLoading(false);
                console.log(response);
                if (response.status === 200) {
                    setError([]);
                    setSuccess({...success, successMessage: 'Check your email to verify account'});
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                    setChecked('');

                    //navigate("/");
                } else {
                    setError(response.data);
                }
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
                setError(error.response.data);
            });

    }
    const handleLogoClick = () => {
        navigate("/");

    }
    return (
        <div>
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR Start                          */}
            {/*---------------------------------------------------------------*/}
            <div className="nav bg-white ">
                <div className="container-fluid">
                    <div className="row navpad">
                        <div className="d-flex justify-content-between">
                            <div className="col-lg-4 logosearch ">
                                <a href="javascript:void(0)">
                                    <img onClick={handleLogoClick} src={logoImage} alt="Logo"/>
                                </a></div>
                            <div className="col-lg-8 d-flex justify-content-end align-items-center">
                                {isTabletOrMobile?(<SignInHeader text="LOGIN" handlerClick={signInClick} />)
                                    :(
                                        <div className="postbtn">
                                            <button type="submit"><a href="javascript:void(0)" onClick={signInClick}> LOGIN </a></button>
                                        </div>
                                    ) }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {/*---------------------------------------------------------------*/}
                {/*                         NAVBAR END                            */}
                {/*---------------------------------------------------------------*/}
                {/*---------------------------------------------------------------*/}
                {/*                        MAin Body Start                        */}
                {/*---------------------------------------------------------------*/}

                <div className="mainbg">

                    <div className="form">
                        {/*{success.successMessage && <span className="text-success"><h4>{success.successMessage}</h4></span>}*/}

                        {success.successMessage && (
                            <span>
    <h4  style={{ display: 'inline-block', backgroundColor: '#60BF9F', color: 'white', padding: '10px', width: '100%', height: '100%',fontSize:'20px' }}>{success.successMessage}</h4>
  </span>
                        )}

                        <form action="">
                            <div className="formdata">
                                <h2>Sign Up <span style={{ fontSize: "0.8em" }}>As Employer</span></h2>

                                <p>Or get registration via an other email</p>
                                <div className="emailinfo">
                                    <img src={emailImage} alt=""/>
                                    <input type="text" placeholder="Email" value={email}
                                           onChange={e => setEmail(e.target.value)} onKeyPress={handleKeyPress} required />

                                </div>

                                {error.email && <span className="text-danger">{error.email}</span>}

                                <div className="emailinfo passwordinfo">
                                    <img src={setPasswordImage} alt=""/>
                                    <input type={isPasswordVisible ? "text" : "password"} placeholder="Set Password"
                                           value={password}
                                           onChange={e => setPassword(e.target.value)} onKeyPress={handleKeyPress} required/>

                                    <img src={watchPasswordImage} onClick={passwordVisible}/>
                                </div>
                                {error.password && <span className="text-danger">{error.password}</span>}

                                {/*--------------------Confirm Password ----------*/}
                                <div className="emailinfo passwordinfo">
                                    <img src={setPasswordImage} alt=""/>
                                    <input type={isConfirmPasswordVisible ? "text" : "password"}
                                           placeholder="Confirm Password" value={confirmPassword}
                                           onChange={e => setConfirmPassword(e.target.value)} onKeyPress={handleKeyPress} required/>
                                    <img src={watchPasswordImage} onClick={ConfirmPasswordVisible}/>
                                </div>
                                {error.confirmPassword && <span className="text-danger">{error.confirmPassword}</span>}


                                {/*------------------------I am not Rebort --------------------*/}
                                <div className="formforget">
                                    <div>
                                        <input type="checkbox" id="remember" checked={checked}  onChange={() => setChecked(!checked)} name="remember"/>
                                        <label className="checkbox-label"> I am not a robot</label><br/>
                                    </div>
                                    <p><a style={{color: '#bbbbbb'}} href="javascript:void(0)"> Captcha</a></p>

                                </div>
                                {error.checked && <span className="text-danger">{error.checked}</span>}


                                {/*--------------------------------Sign Up BTN ------------------*/}
                                <div className="formbtn">
                                    <button onClick={handleSignUp}>Sign Up</button>
                                    {loading && (
                                        <div className={style.loadingOverlay}>
                                            <div className={style.loadingSpinner}></div>
                                        </div>
                                    )}
                                    <p>Already have an account? Please<span><a href="javascript:void(0)"
                                                                               onClick={signInClick}> Sign In </a> </span> here!
                                    </p>
                                </div>

                            </div>
                        </form>

                    </div>

                </div>

            </div>




    );
}

export default SignUp;
