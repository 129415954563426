
import React, {useEffect, useState} from "react";


import './assets/css/header.css';
import '../src/assets/css/styles.css';
import jobSearchImage from '../src/assets/job-search.png';
import profile3 from '../src/assets/profile3.png';
import profile4 from '../src/assets/profile4.png';
import profile5 from '../src/assets/profile5.png';
import threedots from  '../src/assets/threedots.svg'
import post1Image from  '../src/assets/postimg1.jpg';
import profile1Image from  '../src/assets/profile1.png';
import profileImage2 from  '../src/assets/profile2.png';
import EmployerHeader    from "./EmployerHeader";
import likeImage from '../src/assets/likereact.svg';
import commentImage from  '../src/assets/commentreact.svg';
import postImage3 from  '../src/assets/postimg3.jpg';
import profile6 from  '../src/assets/profile6.png';
import comp1Image from '../src/assets/comp1.png';
import comp2Image from '../src/assets/comp2.png';
import comp3Image from '../src/assets/comp3.png';
import comp4Image from '../src/assets/comp4.png';
import comp5Image from '../src/assets/comp5.png';
import comp6Image from '../src/assets/comp6.png';
import post1 from '../src/assets/post1.svg';
import post2 from '../src/assets/post2.svg';
import post3 from '../src/assets/post3.svg';
import post4 from '../src/assets/post4.svg';
import post5 from '../src/assets/post5.svg';

import emailImage from '../src/assets/emailplaceholder.svg';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import BASE_URL, {STORAGE_URL} from "./config";
import threeDots from "./assets/threedots.svg";
import {useSelector} from "react-redux";

// function creatbtn() {
//     alert('fd');
//
// // var x = document.getElementById("postcreat");
//
// }
function EmployerDashboard(){

    const [isPostCreateVisible, setIsPostCreateVisible] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };



    const handlePostSubmit = () => {
        // Perform any necessary actions when the "Post" button is clicked
        // This can include sending the user's input to the backend or other logic
        console.log('User input:', userInput);
        setPosts([...posts, userInput]);
        setUserInput('');
    };

    const [employer, setEmployer] = useState(null);
    const employerId = localStorage.getItem("employerId");
    const total_jobs=10;
    const total__resume_views=100;
    const resume_viewed=2;
    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    useEffect(() => {

        async function fetchEmployerData() {

            const response = await fetch(`${BASE_URL}/employer_dashboard_data/${employerId}`,{
                headers,
            });

            const data = await response.json();
            if(data.status===200){
                setEmployer(data);
            }
           else if (data.status === 401) {
                // Redirect to the landing page if unauthenticated
                navigate("/");
            }
            // console.log(data)
        }

        fetchEmployerData();
    }, []);

    // if (!employer) {
    //     return <div>Loading...</div>;
    // }

    const togglePostCreate = () => {
        setIsPostCreateVisible(!isPostCreateVisible);
    };
    const postCreateStyle = {
        display: isPostCreateVisible ? "block" : "none",
        // add any other styles here
    };

    const viewAllHandler = ()=>{
        const defaultfrom="/employer_dashboard1";
        navigate('/alljobs', { state: { defaultfrom } });
    }

    return (
            <div>

                {/*Header*/}
                <EmployerHeader/>
                {/*---------------------------------------------------------------*/}
                {/*                        MAin Body Start                        */}
                {/*---------------------------------------------------------------*/}
                <div className="maindashbord">
                    <div className="container">
                        {/*---------------------------------Main section -----------------*/}
                        <div className="mainpage">
                            {/*---------------------------First Column ---------------------*/}
                            <div className="resumecol">
                                <h2> Analytics</h2>
                                <br/>

                                <div className=" analytics">
                                    <h2> Jobs</h2>
                                    <br/>

                                    <div className="dashjobicon ">
                                        <p>No of Jobs Purchased </p>
                                        <p> {employer.employer_data.subscription?.package?.jobs} </p>
                                    </div>
                                    <div className="dashjobicon ">
                                        <p>No of Jobs Available </p>
                                        <p>  {(employer?.employer_data?.subscription?.remaining_jobs)} </p>
                                    </div>
                                    <div className="dashjobicon ">
                                        <p>Active Jobs </p>
                                        <p> {employer.employer_data.jobAdsCount} </p>
                                    </div>                                    
                                    <div className="dashjobicon ">
                                        <p>Inactive Jobs </p>
                                        <p> 0 </p>
                                    </div>

                                    

                                </div>
                                <hr />
                                {/*--------------- 2nd section of first column -----------------*/}
                                <div className=" analytics">
                                    <div className="resume">
                                        <h2>Resumes</h2>
                                        {/* <p><a href="">View All</a></p> */}
                                    </div>
                                    <div className="dashjobicon ">
                                        <p> No of Views Purchased</p>
                                        <p>  {employer.employer_data.subscription?.package?.views} </p>
                                    </div>

                                    <div className="dashjobicon ">
                                        <p>Remaining Views</p>
                                        <p> {employer.employer_data.subscription.remaining_views} </p>
                                    </div>
                                    <div className="dashjobicon ">
                                        <p>Favorite Resumes </p>
                                        <p> {employer.employer_data.favoriteResumesCount} </p>
                                    </div>
                                    

                                    

                                </div>
                                <hr />
                                {/*--------------- 3rd section of first column -----------------*/}
                                <div className=" analytics">
                                    <div className="resume">
                                        <h2> Favorite Resumes</h2>
                                        <p><a href="javascript:void(0)">View All</a></p>
                                    </div>
                                    {/*-------------- Favourite Resume 1 ------------*/}
                                    <div>
                                        {employer && employer.employer_data.favorite_resume_user_resumes ? (
                                            employer.employer_data.favorite_resume_user_resumes.map((resume, index) => (
                                                <div className="favourite" key={index}>
                                                    <div className="fvtresum">
                                                        <div className="searchresum-img">
                                                            <img
                                                                src={`${STORAGE_URL}${resume.image}`}
                                                                className="round-image"
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = 'profile5.jpg';
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="rsfvtresumtxt">
                                                            <h6><a href="javascript:void(0)">{resume.first_name}</a></h6>
                                                            <p><a href="javascript:void(0)">{resume.position}</a></p>
                                                        </div>
                                                    </div>
                                                    <img src={threedots} />

                                                </div>
                                            ))
                                        ) : (
                                            <p style={{color:"white"}}>No favorite resumes found.</p>
                                        )}
                                    </div>

                                    {/*-------------- Favourite Resume 2 ------------*/}
                                    {/*<div className="favourite ">*/}
                                    {/*    <div className="fvtresum">*/}
                                    {/*        <img src={profile4} />*/}
                                    {/*        <div className="fvtresumtxt">*/}
                                    {/*            <h6><a href="javascript:void(0)""">Theresa Webb</a></h6>*/}
                                    {/*            <p><a href="javascript:void(0)""">Sales Representative</a></p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <a href="javascript:void(0)"""><img src={threedots} /> </a>*/}
                                    {/*</div>*/}

                                </div>
                            </div>
                            {/*--------------------------- Second  Column ---------------------*/}
                            <div className="postcol">
                                {/*------------------- Creat Post ------------------------*/}
                                <div className="creatpost">
                                    <p onClick={togglePostCreate}>What's on your mind?</p>

                                         <div id="postcreat" style={postCreateStyle}>
                                             <textarea
                                                 name="postText"
                                                 cols={30}
                                                 rows={8}
                                                 placeholder=""
                                                 value={userInput}
                                                 onChange={handleInputChange}
                                                 style={{padding:'10px'}}
                                             />
                                        <div className="col-12 d-flex ">
                                            <div className="col-6 postmaterial">
                                                <img src={post1}/>
                                                <img src={post2}/>
                                                <img src={post3} />

                                            </div>
                                            <div
                                                className=" col-6 d-flex justify-content-end align-items-baseline postpublish">
                                             {/*   <select name="posttypecat" id="posttype-cat">*/}
                                             {/*<option value="Anyone">Anyone</option>*/}
                                             {/*<option value="my-circle">My Circle</option>*/}
                                             {/* </select>*/}

                                                <button onClick={handlePostSubmit}>Post</button>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                                {posts.length > 0 && (
                                    <div >
                                        {posts.map((post, index) => (
                                            <div  key={index}>
                                                

                                                 <div className=" showpost">
                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <div className="col-8 postprofile">
                                            <img src={profileImage2} />
                                            <div className="posttxt">
                                                <p style={{color: 'black'}}>Guy Hawkins</p>
                                                <p>1 day ago</p>
                                            </div>
                                        </div>
                                        <div className="col-4 postdots">
                                            <a href="javascript:void(0)"> <img src={threedots} /></a>
                                        </div>
                                    </div>
                                  <p>{post}</p>
                                    <div className="col-12 posticons">
                                        <div className="postshare ">
                                            <a href="javascript:void(0)"><img src={likeImage} /></a>
                                            <p><a href="javascript:void(0)">React</a></p>
                                        </div>
                                        <div className="postshare ">
                                            <a href="javascript:void(0)"><img src={commentImage} /></a>
                                            <p><a href="javascript:void(0)">Comment</a></p>
                                        </div>
                                    </div>
                                </div>
                                            </div>





                                        ))}
                                    </div>
                                )}




                                {/*------------------------------- 1st post------------------*/}
                                <div className=" showpost">
                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <div className="col-8 postprofile">
                                            <img src={profile1Image} />
                                            <div className="posttxt">
                                                <p style={{color: 'black'}}>Cameron Williamson</p>
                                                <p>Suggested for you</p>
                                            </div>
                                        </div>
                                        <div className="col-4 postdots">
                                            <a href="javascript:void(0)"> <img src={threedots} /></a>
                                        </div>
                                    </div>
                                    <div className="col-12 mainpost">
                                        <img src={post1Image} />
                                    </div>
                                    <div className="col-12 posticons">
                                        <div className="postshare ">
                                            <a href="javascript:void(0)"><img src={likeImage} /></a>
                                            <p><a href="javascript:void(0)">React</a></p>
                                        </div>
                                        <div className="postshare ">
                                            <a href="javascript:void(0)"><img src={commentImage} /></a>
                                            <p><a href="javascript:void(0)">Comment</a></p>
                                        </div>
                                    </div>
                                </div>
                                {/*-----------------2nd post--------*/}
                                <div className=" showpost">
                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <div className="col-8 postprofile">
                                            <img src={profileImage2} />
                                            <div className="posttxt">
                                                <p style={{color: 'black'}}>Guy Hawkins</p>
                                                <p>1 day ago</p>
                                            </div>
                                        </div>
                                        <div className="col-4 postdots">
                                            <a href="javascript:void(0)"> <img src={threedots} /></a>
                                        </div>
                                    </div>
                                    <div className="col-12 mainpost">
                                        <img src={postImage3} />
                                    </div>
                                    <div className="col-12 posticons">
                                        <div className="postshare ">
                                            <a href="javascript:void(0)"><img src={likeImage} /></a>
                                            <p><a href="javascript:void(0)">React</a></p>
                                        </div>
                                        <div className="postshare ">
                                            <a href="javascript:void(0)"><img src={commentImage} /></a>
                                            <p><a href="javascript:void(0)">Comment</a></p>
                                        </div>
                                    </div>
                                </div>
                                {/*-----------------3rd post--------*/}
                                <div className=" showpost">
                                    <div className="col-12 mb-3 d-flex justify-content-between">
                                        <div className="col-8 postprofile">
                                            <img src={profile6} />
                                            <div className="posttxt">
                                                <p style={{color: 'black'}}>Guy Hawkins</p>
                                                <p>1 day ago</p>
                                            </div>
                                        </div>
                                        <div className="col-4 postdots">
                                            <a href="javascript:void(0)"> <img src={threedots} /></a>
                                        </div>
                                    </div>
                                    <div className="col-12 mainpost">
                                        <p>Knowde is looking to add an immensely talented Senior Front End Developer
                                            to join our Engineering team. As one of the founding engineers, you will
                                            help us invent, design, refine, and deliver the products that will enable
                                            this digital transformation.</p>
                                        <ul>
                                            <li>As a small, remote team you’ll be empowered to work directly with leadership
                                                and the product team to make decisions about your code and the future
                                                of the product.</li>
                                            <li>Have a strong emphasis on shipping features quickly without sacrificing code quality.</li>
                                            <li>Own your projects from software design to implementation and deployment.</li>
                                            <li>Build modular front-end code leveraging REST-based services.</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 posticons">
                                        <div className="postshare ">
                                            <a href="javascript:void(0)"><img src={likeImage} /></a>
                                            <p><a href="javascript:void(0)">React</a></p>
                                        </div>
                                        <div className="postshare ">
                                            <a href="javascript:void(0)"><img src={commentImage} /></a>
                                            <p><a href="javascript:void(0)">Comment</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* --------------------------------Third Column ----------------------*/}
                            <div className="dashboardcol">
                                {/*-------------------------------My circle -------------*/}
                                <div className=" analytics">
                                    <div className="resume">
                                        <h2><a href="javascript:void(0)"> My Circle</a></h2>
                                        {/* <p><a href="javascript:void(0)""">View All</a></p> */}
                                    </div>
                                    <div className="dashjobicon ">
                                        <p><a href="javascript:void(0)">Following</a></p>
                                        <p><a href="javascript:void(0)"> 10 </a></p>
                                    </div>
                                    <div className="dashjobicon ">
                                        <p><a href="javascript:void(0)">Followers </a></p>
                                        <p><a href="javascript:void(0)"> 15 </a></p>
                                    </div>
                                </div>
                                <hr />
                                {/*--------------------Active Jobs ------------------*/}

                                <div className=" analytics">
                                    <div className="resume">
                                        <h2><a href="javascript:void(0)"> Active Jobs</a></h2>
                                        <p onClick={viewAllHandler}>View All</p>
                                    </div>
                                    {/*-------------- Active JOb 1 ------------*/}
                                    {console.log(employer, 'employer')}
                                    {employer?.employer_data?.job_ads.map((ads)=>{
                                        return (<div className="activejob">
                                            <img src={jobSearchImage} />
                                            <div className="activejobtxt">
                                                <h6><a href="javascript:void(0)">{ads?.title}</a></h6>
                                                <p><a href="javascript:void(0)">{ads?.required_skills}</a></p>
                                            </div>
                                        </div>)
                                    })}



                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

export default EmployerDashboard;