import React, {useEffect} from 'react';
import '../src/assets/css/LandingPage.css';
import {ImSpinner4} from "react-icons/im";
import {useNavigate} from "react-router-dom";
import logoImage from '../src/assets/logo4.svg';


function LandingPage() {

    const navigate = useNavigate()

    useEffect(() => {
        // setTimeout(() => {
            navigate('/employer-login');
        // }, 3000);
    }, []);

    return (
        <div className='splash-screen visible'>
            {/*<ImSpinner4 className='spinner'  size={200}>*/}

            {/*</ImSpinner4>*/}
            <img src={logoImage} alt='logo'/>
        </div>

    );
}

export default LandingPage;
