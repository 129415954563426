import {RiUserFollowFill, RiUserUnfollowFill} from "react-icons/ri";
import React from "react";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {addUser} from "../../store/slices/UserSlice";
import BASE_URL from "../../config";
import { useNavigate} from "react-router-dom";
import {SlUserFollow, SlUserFollowing} from "react-icons/sl";

export const Follow = ({user, setLoading})=>{

    const user_data = useSelector((state) => {
        return state?.user?.user;
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let token = localStorage.getItem("access_token");
    let headers = {
        Authorization: `Bearer ${token}`,
    };
    const handleUnFollow =(id)=>{
        setLoading(true)
        axios
            .post(`${BASE_URL}/unfollow`, { following_id: id }, { headers })
            .then((response) => {
                // Handle success
                if (response?.data?.status === 200) {

                    let userCopy = user_data;
                    const currentFollowingsCount = userCopy.followings_count
                    userCopy ={...userCopy, followings_count:currentFollowingsCount - 1}

                    userCopy.followings = userCopy?.followings.filter(following => following?.id !== id);

                    dispatch(addUser(userCopy))
                    setLoading(false)

                    // toast.success('UnFollowed Successfully!', {
                    //     position: toast.POSITION.TOP_CENTER
                    // });
                } else if (response?.data?.status === 401) {
                    navigate("/");
                    setLoading(false)
                } else {
                    // Handle other status codes or responses here
                    console.error("Follow failed:", response);
                }
            })
            .catch((error) => {
                console.error("Follow failed:", error);
                setLoading(false)
            });

    }

    const handleFollow =(user)=>{
        setLoading(true)
        axios
            .post(`${BASE_URL}/follow`, { following_id: user?.id }, { headers })
            .then((response) => {
                // Handle success
                if (response?.data?.status === 200) {

                    let userCopy = user_data;
                    const currentFollowingsCount = userCopy.followings_count
                    userCopy ={...userCopy, followings_count:currentFollowingsCount + 1}
                    let update_following = Array.isArray(userCopy.followings) ? userCopy.followings.slice() : [];
                    update_following.push(user);
                    userCopy = { ...userCopy, followings: update_following };
                    dispatch(addUser(userCopy));

                    setLoading(false)

                } else if (response?.data?.status === 401) {
                    setLoading(false)
                    navigate("/");
                } else {
                    console.error("Follow failed:", response);
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.error("Follow failed:", error);
                setLoading(false)
            });

    }
    let check = user_data?.followings.some(following=>following.id === user?.id)

    if(check){
        return(
            <RiUserFollowFill
                size={24}
                role="button"
                title="Unfollow"
                style={{ color: "#079c15"}}
                onClick={()=>handleUnFollow(user?.id)}
            >
            </RiUserFollowFill>
        )
    }else{
        return(
            <RiUserUnfollowFill
                size={24}
                role="button"
                title="Follow"
                style={{ color: "#e54545" }}
                onClick={()=>handleFollow(user)}
            >
            </RiUserUnfollowFill>
        )
    }

}