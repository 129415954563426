import React, {useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/login.css';
import logoImage from '../src/assets/logo4.svg';
import emailImage from '../src/assets/emailplaceholder.svg';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {BASE_URL} from "./config";
import styles from "./assets/css/LoadingComponent.module.css";
import {BsArrowLeft} from "react-icons/bs";

function ForgotPassword () {

    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage]= useState('')
    const [status, setStatus]= useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const token = localStorage.getItem('access_token'); // Get the token from localStorage
    const headers = {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    };
    const handleLogoClick = () => {
        navigate("/employer-login");

    }

    const handleSubmit = ()=>{
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        console.log(email, 'em')
        if (!email) {
            setErrorMessage('Email is required');
            return false

        }
        if (!emailPattern.test(email)) {
            setErrorMessage('Please enter valid email');
            return false;
        }
        setLoading(true)
        axios
            .post(`${BASE_URL}/forgot-password`, {email},{
                headers,
            })
            .then((res) => {
                if(res.data.status === 200){
                    if(res.data.error){
                        setErrorMessage(res.data.message)
                        setStatus(false)
                    }else{
                        setErrorMessage(res.data.message)
                        setStatus(true)
                    }

                }else if(res.data.status === 404){
                    setErrorMessage(res.data.message)
                    setStatus(false)
                }
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            });

    }

    const handleChange = (e)=>{
        setEmail(e.target.value)
        setErrorMessage('')
    }

        return (
            <div>
                {loading && (
                    <div className={styles.loadingOverlay}>
                        <div className={styles.loadingSpinner}></div>
                    </div>
                )
                }
                <div className="mainbg">

                    <div className="form">

                        <div  style={{width: '100%'}}>

                            <div className='d-flex align-items-center mb-4  justify-content-center'>
                                <img onClick={handleLogoClick} className='' src={logoImage} alt="Logo"/>
                            </div>
                            <div className=" backbtn">
                                <button onClick={() => {
                                    navigate(-1)
                                }}>
                                    <BsArrowLeft/> Back
                                </button>
                            </div>


                            <p >Enter your email to reset your password</p>
                            <div className="emailinfo">
                                <img src={emailImage} alt="" />
                                <input type="text" name='email' onChange={handleChange} placeholder="Email" />
                            </div>
                            <span className={status?"text-success":"text-danger"}>{errorMessage}</span>
                            {/*--------------------------------Sign Up BTN ------------------*/}
                            <div className="formbtn">
                                <button onClick={handleSubmit}><a href="javascript:void(0)"> Send </a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
export default ForgotPassword;
