import React, {useEffect, useRef, useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/alljobv2.css';
import headerIcon2 from '../src/assets/headericone2.svg';
import threeDots from '../src/assets/threedots.svg';
import searchDollar from '../src/assets/searchdollar.svg';
import searchLoca from '../src/assets/searchloca.svg';
import searchSkill from '../src/assets/searchskill.svg';
import searchFollow from '../src/assets/searchfollow.svg';
import viewjob from '../src/assets/Viewjob.png';
import editjob from '../src/assets/editjob.png';
import deletejob from '../src/assets/deletejob.png';
import style from '../src/assets/css/LoadingComponent.module.css';

import {STORAGE_URL} from "./config";
import EmployerHeader from "./EmployerHeader";
import axios from "axios";
import BASE_URL from "./config";
import favorite_heart from '../src/assets/redheart.png';
import moment from 'moment';
import Select from "react-select";

let custom_date1 = null
let custom_date2 = null
let count = 0;

const fetchResumes = async (filter, setLoading) => {
    let _data = JSON.parse(JSON.stringify(filter))
    delete _data.last_updated_dropdown
    console.log('filter', filter)
    if (count === 0) setLoading(true); // set loading state to true
    const res = await fetch(`${BASE_URL}/resumes/filter?${new URLSearchParams(_data).toString()}`);
    const data = await res.json();
    console.log(data);
    if (Array.isArray(data)) {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched

        count++;
        return data;
    } else if (data.resume && Array.isArray(data.resume.original)) {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched

        count++;
        return data.resume.original;
    } else {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched
        console.log('No resumes found');

        count++;
        return [];
    }

};


function Alljobv2() {
     const [dropdownOpen, setDropdownOpen] = useState(false);
    const [filter, setFilter] = useState(() => {
        const savedFilter = localStorage.getItem('resumeFilter');
         
        return savedFilter ? JSON.parse(savedFilter) : {
            last_updated_dropdown: '',
            last_updated: '',
            experience: '',
            work_authorization: '',
            employment_type: '',
            relocation: '',
            position: '',
            address: '',

        };
    });

   
 

 
    useEffect(() => {
        localStorage.setItem('resumeFilter', JSON.stringify(filter));
    }, [filter]);


    const [activeResumeId, setActiveResumeId] = useState(null);
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const dropdownRef = useRef(null);
    //Employer Stats Data
    const [employer, setEmployer] = useState(null);
    const employerId = localStorage.getItem("employerId");
    const resume_viewed = 2;
    useEffect(() => {
        async function fetchEmployerData() {
            const response = await fetch(`${BASE_URL}/employer_dashboard_data/${employerId}`);
            const data = await response.json();
            console.log(data)
            setEmployer(data);
        }

        fetchEmployerData();
    }, []);

    //Employer Counters Data
    const [resumeCounter, setResumeCounter] = useState(null);
    useEffect(() => {
        async function fetchResumeCounterData() {
            const response = await fetch(`${BASE_URL}/resumes/resume_counters`);
            const data = await response.json();
            console.log(data.resumes)
            setResumeCounter(data.resumes);

        }

        fetchResumeCounterData();
    }, []);

    useEffect(() => {
        // Attach event listener to detect clicks outside of the dropdown
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowRemoveButton(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleDropdownClick = (resumeId) => {
        setActiveResumeId(resumeId);
        setShowRemoveButton(!showRemoveButton);
    };

    const handleRemoveClick = (resumeId) => {
        handleFavorite(resumeId, true);
        setShowRemoveButton(false);
    };
  

    const [loading, setLoading] = useState(false);

    const [favoriteResumes, setFavoriteResumes] = useState([]);
    const getFavoriteResumes = () => {

        const employerId = localStorage.getItem("employerId");
        axios.post(`${BASE_URL}/resumes/get_favorite`, {employer_id: employerId})
            .then((response) => {
                setFavoriteResumes(response?.data?.favorite_resume);
                setLoading(false)

            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getFavoriteResumes(); // call the getFavoriteResumes function initially to fetch the favorite resumes
    }, []);


    const [resumes, setResumes] = useState([]);

    useEffect(() => {
        fetchResumes(filter, setLoading).then(data => {
            setResumes(data);
        });
        if(filter.last_updated != ''){
            const [startDate, endDate] = filter.last_updated.split(' - ')
            custom_date1 = startDate??''
            custom_date2 = endDate??''
        }
        console.log(filter, 'filter')
    }, [filter]);
    const handleWorkAuthChange = (event) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            work_authorization: event.target.value,
        }));

    }
    const handleRelocationChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setFilter(prevFilter => ({
                ...prevFilter,
                relocation: event?.target.value
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                relocation: ''
            }));
        }

    }
    const handleExperienceChange = (value) => {

        if (filter.experience === value) {
            // if the current experience is already selected, unselect it
            setFilter(prevFilter => ({
                ...prevFilter,
                experience: ''
            }));
        } else {
            // otherwise, select the new experience
            setFilter(prevFilter => ({
                ...prevFilter,
                experience: value
            }));
        }
    };
    const handleEmploymentTypeChange = (value) => {

        if (filter.employment_type === value) {
            // if the current experience is already selected, unselect it
            setFilter(prevFilter => ({
                ...prevFilter,
                employment_type: ''
            }));
        } else {
            // otherwise, select the new experience
            setFilter(prevFilter => ({
                ...prevFilter,
                employment_type: value
            }));
        }
    };
    const handleLastUpdateChange = (event) => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            last_updated: event.target.value,

        }));
    };
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [titleOptions, setTitleOptions] = useState([]);

    function handleTitleInputChange(inputValue) {
        const titleInputValue = inputValue;
        if (titleInputValue.length >= 2) {
            fetch(`${BASE_URL}/resumes/search_position?position=${titleInputValue}`)
                .then(response => response.json())
                .then(data => {
                    const positions = data.resume.original;

                    const titleOptions = positions
                        .filter(position => position.position) // filter out null name results
                        .map(position => ({value: position.position, label: position.position}));

                    const nameOptions = positions
                        .filter(position => position.name) // filter out null name results
                        .map(position => ({value: position.name, label: position.name}));

                    const options = [
                        {
                            label: 'Resume Title',
                            options: titleOptions
                        },
                        {
                            label: 'Keywords',
                            options: nameOptions
                        }
                    ];

                    setTitleOptions(options);
                    console.log(titleOptions, 'titleOption')
                })
                .catch(error => console.log(error));
        }
    }

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);

    function handleLocationInputChange(inputValue) {
        const locationInputValue = inputValue;
        if (locationInputValue.length >= 2) {
            fetch(`${BASE_URL}/resumes/search_location?address=${locationInputValue}`)
                .then(response => response.json())
                .then(data => {
                    const locations = data.resume.original;
                    const options = locations.map((location) => ({value: location.address, label: location.address}));
                    setLocationOptions(options);
                })
                .catch(error => console.log(error));
        }
    }


    //
    // const handleLocationInputChange = async (event) => {
    //     const locationInputValue = event.target.value;
    //     if (locationInputValue.length >= 2) {
    //         const searchLocationResponse = await fetch(`http://localhost:8000/api/resumes/search_location?address=${locationInputValue}`);
    //         const searchLocationData = await searchLocationResponse.json();
    //         console.log(searchLocationData);
    //         // Update state or do something else with the data
    //     }
    // };

    const handleSearchButtonClick = async () => {
        if (selectedTitle) {
            setFilter((prevState) => ({
                ...prevState,
                position: selectedTitle.value ?? '',
            }));
        }
        if (selectedLocation) {
            setFilter((prevState) => ({
                ...prevState,
                address: selectedLocation.value ?? ''
            }));
        }
    };




    const handleThreeDots = () => {
        alert('Action')
    }

    const handleFavorite = async (resumeId, isFavorite) => {
        setLoading(true);
        const employerId = localStorage.getItem("employerId");
        const url = isFavorite
            ? `${BASE_URL}/fav_resumes/${resumeId}` // Use the correct endpoint for deleting favorite resumes
            : `${BASE_URL}/resumes/favorite`;
        const method = isFavorite ? 'DELETE' : 'POST';
        const payload = {
            employer_id: employerId,
            resume_id: resumeId
        };
        const response = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });
        const data = await response.json();
        console.log(data);
        getFavoriteResumes();
    };
    const calculateDaysSinceLastUpdated = (lastUpdated) => {
        if (!lastUpdated) {
            return null;
        }

        if (lastUpdated === 'custom') {
            return 'Custom Range';
        }
        console.log(lastUpdated)
        const [startDate, endDate] = lastUpdated.split(' - ');

        if (startDate === endDate) {
            return 'today';
        }

        const daysSinceLastUpdated = moment().diff(moment(startDate), 'days');

        return `${daysSinceLastUpdated}days`;
    };


    return (
        <div>
            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR Start                          */}
            {/*---------------------------------------------------------------*/}
            <EmployerHeader/>
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR END                            */}
            {/*---------------------------------------------------------------*/}
            {/*---------------------------------------------------------------*/}
            {/*                        MAin Body Start                        */}
            {/*---------------------------------------------------------------*/}
            <div className="maina1ljob">
                <div className="container-fluid">
                    {/*---------------------------------Main section -----------------*/}
                    <div className="mainpage1aljobv2">
                    {/*--------------------------- Second  Column ---------------------*/}

                        <div className="postcol1aljobv2">
                            <div className="topjoballv2">
                            
                            <div className="postanalyticsPSaljobv2">
                                <p>Jobs Purchased: <span>{ employer?.employer_data?.subscription.total_purchased_jobs}</span></p>
                                <p>Jobs Available:<span> {employer?.employer_data?.subscription.total_purchased_jobs -  employer?.employer_data?.jobAdsCount}</span></p>
                                <p>Active Jobs: <span>3</span></p>
                                <p>Inactive Jobs:<span> 2</span></p>
                                <p>Draft Jobs:<span> 2</span></p>
                            </div>
                            <div className="joballsearchv2">
                             <input className="alljobtable-search" type="text" placeholder="Search" />
                            </div>
                            {/* <Dropdown>
                                    <Dropdown.Toggle style={{backgroundColor:'transparent', border:"transparent", padding:'0px'}}>
                                         <img  style={{width: '35px', marginRight: '0px'}} src={headerIcone6} /> 
                                        <div className="alljobfilterbn" style={{width: '35px', marginRight: '0px'}} >
                                       <i class="fa-sharp fa-solid fa-filter"></i>
                                      </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ zIndex: 9999 ,transform: 'translate(0px, 70px)'}}>
                                        <Dropdown.Item  >
                                          <h6>Filter Operation </h6><hr/>
                                          <label>Status:</label>
                                        <div>
                                          <select  
                                           className="form-select"  aria-label="Default select example">
                                          <option value="Select Status">Select Status</option>
                                          <option value="Active">Active</option>
                                          <option value="Inactive">Inactive</option>
                                          <option value="Draft">Draft</option>
                                          </select>
                                        </div>

                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown> */}
                            <div className="alljobfilterbn">
                            <i class="fa-sharp fa-solid fa-filter"></i>
                            </div>

                           </div>
                        {/*-------------------Table List ------------------------------- */}
                        <div className="alljoblistv2">
                        <table>
  <tr>
    <th>ID</th>
    <th>Title</th>
    <th>Status</th>
    <th>Resumes</th>
    <th>Date Created</th>
    <th>Expiring In (Days)</th>
    <th>Action</th>

  </tr>
  <tr>
    <td>1524031</td>
    <td>Java Developer</td>
    <td><select  className="jobstatusv2">
  <option value="active">Active</option>
  <option value="Inactive">Inactive</option>
  <option value="Draft">Draft</option>
</select></td>
    <td className="jobappliv2">20</td>
    <td>27th May 2023</td>
    <td>12</td>
    <td><div className="editjobsiconv2">        
        <i  class="fa-solid fa-file" data-bs-toggle="tooltip" data-bs-placement="top" title="View"></i>
        <i class="fa-solid fa-pen-to-square" data-bs-toggle="tooltip" data-bs-placement="top" title="edit"></i>
        <i class="fa-sharp fa-solid fa-trash" data-bs-toggle="tooltip" data-bs-placement="top" title="Inactive"></i>
        
        </div></td>
  </tr>
  <tr>
    <td>143431</td>
    <td>Mern Developer</td>
    <td><select  className="jobstatusv2">
  <option value="active">Active</option>
  <option value="Inactive">Inactive</option>
  <option value="Draft">Draft</option>
</select></td>
    <td className="jobappliv2">3</td>
    <td>3rd May 2023</td>
    <td>18 </td>
    <td><div className="editjobsiconv2">
        {/* <img src={viewjob} />
        <img src={editjob} />
        <img src={deletejob} /> */}
       <i  class="fa-solid fa-file" data-bs-toggle="tooltip" data-bs-placement="top" title="View"></i>
        <i class="fa-solid fa-pen-to-square" data-bs-toggle="tooltip" data-bs-placement="top" title="edit"></i>
        <i class="fa-sharp fa-solid fa-trash" data-bs-toggle="tooltip" data-bs-placement="top" title="Inactive"></i>
        </div></td>
  </tr>
  <tr>
    <td>1398031</td>
    <td>React Developer</td>
    <td><select  className="jobstatusv2">
  <option value="active">Active</option>
  <option value="Inactive">Inactive</option>
  <option value="Draft">Draft</option>
</select></td>
    <td className="jobappliv2">9</td>
    <td>28th Jun 2023</td>
    <td>29 </td>
    <td><div className="editjobsiconv2">
        {/* <img src={viewjob} />
        <img src={editjob} />
        <img src={deletejob} /> */}
        <i  class="fa-solid fa-file" data-bs-toggle="tooltip" data-bs-placement="top" title="View"></i>
        <i class="fa-solid fa-pen-to-square" data-bs-toggle="tooltip" data-bs-placement="top" title="edit"></i>
        <i class="fa-sharp fa-solid fa-trash" data-bs-toggle="tooltip" data-bs-placement="top" title="Inactive"></i>
        </div></td>
  </tr>
   <tr>
    <td>1186531</td>
    <td>Rust Developer</td>
    <td><select  className="jobstatusv2">
  <option value="Inactive">Inactive</option>
  <option value="active">Active</option>
  <option value="Draft">Draft</option>
</select></td>
    <td className="jobappliv2">10</td>
    <td>10th July 2023</td>
    <td>10 </td>
    <td><div className="editjobsiconv2">
        {/* <img src={viewjob} />
        <img src={editjob} />
        <img src={deletejob} /> */}
       <i  class="fa-solid fa-file" data-bs-toggle="tooltip" data-bs-placement="top" title="View"></i>
        <i class="fa-solid fa-pen-to-square" data-bs-toggle="tooltip" data-bs-placement="top" title="edit"></i>
        <i class="fa-sharp fa-solid fa-trash" data-bs-toggle="tooltip" data-bs-placement="top" title="Inactive"></i>
        </div></td>
  </tr>
   <tr>
    <td>1406781</td>
    <td>Project Manager</td>
    <td><select  className="jobstatusv2">
  <option value="Inactive">Inactive</option>
  <option value="active">Active</option>
  <option value="Draft">Draft</option>
</select></td>
    <td className="jobappliv2">7</td>
    <td>18th May 2023</td>
    <td>22 </td>
    <td><div className="editjobsiconv2">
        {/* <img src={viewjob} />
        <img src={editjob} />
        <img src={deletejob} /> */}
        <i  class="fa-solid fa-file" data-bs-toggle="tooltip" data-bs-placement="top" title="View"></i>
        <i class="fa-solid fa-pen-to-square" data-bs-toggle="tooltip" data-bs-placement="top" title="edit"></i>
        <i class="fa-sharp fa-solid fa-trash" data-bs-toggle="tooltip" data-bs-placement="top" title="Inactive"></i>
        </div></td>
  </tr>
  <tr>
    <td>1287092</td>
    <td>Scrum Master</td>
    <td><select  className="jobstatusv2">
  <option value="Draft">Draft</option>
  <option value="active">Active</option>
  <option value="Inactive">Inactive</option>
  
</select></td>
    <td className="jobappliv2">5</td>
    <td>15th Jun 2023</td>
    <td>15 </td>
    <td><div className="editjobsiconv2">
        {/* <img src={viewjob} />
        <img src={editjob} />
        <img src={deletejob} /> */}
        <i  class="fa-solid fa-file" data-bs-toggle="tooltip" data-bs-placement="top" title="View"></i>
        <i class="fa-solid fa-pen-to-square" data-bs-toggle="tooltip" data-bs-placement="top" title="edit"></i>
        <i class="fa-sharp fa-solid fa-trash" data-bs-toggle="tooltip" data-bs-placement="top" title="Inactive"></i>
        </div></td>
  </tr>
  <tr className="alljoblstrowv2">
    <td>1406781</td>
    <td>Full Stack Developer</td>
    <td><select  className="jobstatusv2">
  <option value="Draft">Draft</option>
  <option value="active">Active</option>
  <option value="Inactive">Inactive</option>
  
</select></td>
    <td className="jobappliv2">12</td>
    <td>12th May 2023</td>
    <td>18 </td>
    <td><div className="editjobsiconv2">
        {/* <img src={viewjob} />
        <img src={editjob} />
        <img src={deletejob} /> */}
        <i  class="fa-solid fa-file" data-bs-toggle="tooltip" data-bs-placement="top" title="View"></i>
        <i class="fa-solid fa-pen-to-square" data-bs-toggle="tooltip" data-bs-placement="top" title="edit"></i>
        <i class="fa-sharp fa-solid fa-trash" data-bs-toggle="tooltip" data-bs-placement="top" title="Inactive"></i>
        </div></td>
  </tr>
</table>
</div>                  
             </div>               
                        {/* --------------------------------Third Column ----------------------*/}
                        {/* <div className="dashboardcol1aljob">
                            <div className=" analyticsaljob">
                                <div className="resumealjob">
                                    <h2> Favorite Resumes</h2>
                                
                                </div>
                               
                                <div>
                                    {favoriteResumes && favoriteResumes.length > 0 ? (
                                        favoriteResumes.map((resume, index) => (
                                            <div className="favouritealjob" key={index}>
                                                <div className="fvtresumaljob">
                                                    <div className="searchresum-img">
                                                        <img
                                                            src={`${STORAGE_URL}${resume.resume.image}`}
                                                            className="round-image"
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = 'profile5.jpg';
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="rsfvtresumtxtaljob">
                                                        <h6><a href="#">{resume.resume.name}</a></h6>
                                                        <p><a href="#">{resume.resume.position}</a></p>
                                                    </div>
                                                </div>
                                                <div className="dropdown" ref={dropdownRef}>
                                                    <button
                                                        className="dropbtn"
                                                        onClick={() => handleDropdownClick(resume.resume.id)}
                                                    >
                                                        <img src={threeDots}/>
                                                    </button>
                                                    {activeResumeId === resume.resume.id && showRemoveButton && (
                                                        <div className="dropdown-content" ref={dropdownRef}>
                                                            <button
                                                                className="removebtn"
                                                                onClick={() => handleRemoveClick(resume.resume.id)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No favorite resumes found.</p>
                                    )}
                                </div>

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Alljobv2;