import React, {useEffect, useRef, useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/resumesearch.css';
import searchLoca from '../src/assets/searchloca.svg';
import { BsArrowLeft,BsFillHeartFill,BsHeart, BsHeartFill } from 'react-icons/bs';
import searchSkill from '../src/assets/searchskill.svg';

import style from '../src/assets/css/LoadingComponent.module.css';
import { BsThreeDots } from "react-icons/bs";
import EmployerHeader from "./EmployerHeader";
import axios from "axios";
import BASE_URL from "./config";
import moment from 'moment';
import Select from "react-select";
import {Link, useNavigate} from "react-router-dom";
import {BsEye} from "react-icons/bs";
import Helper from "./Helper";
import {Follow} from "./components/UserFollow/Follow";
import {useMediaQuery} from "react-responsive";
import {SearchResumeFilter} from "./components/Sidbars/SearchResumeFilter";
import {EmployerSideBars} from "./components/Sidbars/EmpSideBars";
import ErrorModal from "./ErrorModal";
import colors from "./colors";
import {BiVideo} from "react-icons/bi";

//Yahan sy

let custom_date1 = null
let custom_date2 = null
let count = 0;
let total_resumes=null;


const fetchResumes = async (filter, setLoading) => {
    let token = localStorage.getItem("access_token");
    let headers = {
        Authorization: `Bearer ${token}`,
    };
    document.querySelector('.resumesercard-main').classList.add('blur')
    let _data = JSON.parse(JSON.stringify(filter))
    delete _data.last_updated_dropdown
    delete _data.last_updated_label
    delete _data.zip_code_option

    if (count === 0) setLoading(true); // set loading state to true
    if(token==null)
    {
        token = localStorage.getItem("access_token");
        headers = {
            Authorization: `Bearer ${token}`,
        };
    }
    const res = await fetch(`${BASE_URL}/resumes/filter?${new URLSearchParams(_data).toString()}`,{
        headers,
    });
    const data = await res.json();
    console.log('data', data)

    if (data && data.resume && data.resume?.original && Array.isArray(data.resume?.original)) {
        total_resumes = data.resume?.original.length;
    }
    else {
        total_resumes=0;
    }
    if (Array.isArray(data)) {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched

        add_saperator()
        count++;
        return data;
    } else if (data.resume && Array.isArray(data.resume?.original)) {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched

        add_saperator()
        count++;
        return data.resume?.original;
    } else {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched
        // console.log('No resumes found');
        document.querySelector('.resumesercard-main').classList.remove('blur')
        count++;
        return [];
    }
};

function add_saperator(){
    setTimeout(function () {
        console.log('called to remove')
        let lines = document.querySelectorAll(".add-line");
        if(lines.length > 0){
            lines.forEach(el => el.remove());

        }

        const containers = document.getElementsByClassName('divider');
        for (let idx = 0; idx < containers.length; idx++) {
            let container = containers[idx];

            const divElements = container.querySelectorAll('.f-width-div');

            let prevBottom = divElements[0].getBoundingClientRect().bottom;
            let update_elements = [];

            for (let i = 1; i < divElements.length; i++) {
                const currentElement = divElements[i];
                const currentBottom = currentElement.getBoundingClientRect().bottom;

                if (currentBottom > prevBottom) {
                    var div = document.createElement('div');
                    div.innerHTML = '';
                    div.className = 'add-line';
                    update_elements.push(div)
                    update_elements.push(currentElement)
                }

                prevBottom = currentBottom;
            }
            for (let i = 0; i < update_elements.length; i+=2) {
                container.insertBefore(update_elements[i], update_elements[i+1]);
            }
        }
        console.log('called to add')
        document.querySelector('.resumesercard-main').classList.remove('blur')
    }, 500)

}


function SeachResume() {
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    let token = localStorage.getItem("access_token");
    let headers = {
        Authorization: `Bearer ${token}`,
    };
    const [filter, setFilter] = useState(() => {
        const savedFilter = localStorage.getItem('resumeFilter');
        return savedFilter ? JSON.parse(savedFilter) : {
            last_updated_dropdown: '',
            last_updated: '',
            yearsof_experience_id: [],
            work_authorization_id: '',
            preferred_job_type: [],
            relocation: [],
            preferred_job_title: '',
            location: '',
            last_updated_label:'',
            zip_code_option:'',
            is_us_based: true


        };
    });

    const isMiniScreen = useMediaQuery({ query: '(max-width: 1024px)' })


    useEffect(() => {
        localStorage.setItem('resumeFilter', JSON.stringify(filter));
    }, [filter]);

    //preferred_job_title callback
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [errors, setErrors] = useState([]);
    const handlepreferred_job_titleInputChange = (e) => {
        if (e.target.value.length > 70) {
            setErrors({ ...errors, title: "Maximum 70 characters allowed" });
        }
        else {
            setSelectedTitle(e.target.value);
            setErrors({ ...errors, title: "" });
            if (e.target.value.length <= 0) {
                setFilter((prevFilter) => ({
                    ...prevFilter,
                    preferred_job_title: '',

                }));
            }
        }
    };
    const clearInput = () => {
        setErrors({ ...errors, title: "" });
        setSelectedTitle('');
        setFilter((prevFilter) => ({
            ...prevFilter,
            preferred_job_title:'',

        }));
    };
    useEffect(() => {
        const savedFilter = localStorage.getItem('resumeFilter');
        if (savedFilter) {
            const parsedFilter = JSON.parse(savedFilter);
            setSelectedTitle(parsedFilter.preferred_job_title);
            setIsUSBased(parsedFilter.is_us_based)
            if(parsedFilter.is_us_based && parsedFilter.zip_code_option){
                setSelectedZipCodeOptions({value: parsedFilter.zip_code_option, label: parsedFilter.zip_code_option});
                // fetchLocationData(parsedFilter.zip_code_option)
            }
        }
    }, []);
    //location callback
    const [selectedLocation, setSelectedLocation] = useState(null);

    const handleLocationInputChange = (e) => {
        if (e.target.value.length > 70) {
            setErrors({ ...errors, location: "Maximum 70 characters allowed" });

        }
        else {
            setSelectedLocation(e.target.value);
            setErrors({ ...errors, location: "" });

            if (e.target.value.length <= 0) {

                setFilter((prevFilter) => ({
                    ...prevFilter,
                    location: '',
                }));
            }
        }
        // console.log(selectedLocation);
    };
    const clearInputAddress = () => {
        setErrors({ ...errors, location: "" });
        setSelectedLocation('');
        setFilter((prevFilter) => ({
            ...prevFilter,
            location:'',

        }));
    };

    useEffect(() => {
        const savedFilter = localStorage.getItem('resumeFilter');
        if (savedFilter) {
            const parsedFilter = JSON.parse(savedFilter);
            setSelectedLocation(parsedFilter.location);
        }
    }, []);

    const [activeResumeId, setActiveResumeId] = useState(null);
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const dropdownRef = useRef(null);
    //Employer Stats Data
    const [employer, setEmployer] = useState(null);
    const employerId = localStorage.getItem("employerId");
    const resume_viewed = 2;
    useEffect(() => {
        async function fetchEmployerData() {
            try {
                if(token==null)
                {
                    token = localStorage.getItem("access_token");
                    headers = {
                        Authorization: `Bearer ${token}`,
                    };
                }
                const response = await fetch(`${BASE_URL}/employer_dashboard_data/${employerId}`,{
                    headers,
                });

                const data = await response.json();
                if(data.status===200){
                    setEmployer(data);
                }
            }
            catch (e) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(e)
            }

        }

        fetchEmployerData();
    }, []);

    //Employer Counters Data
    const [resumeCounter, setResumeCounter] = useState(null);
    useEffect(() => {
        async function fetchResumeCounterData() {
            try {


                if(token==null)
                {
                    token = localStorage.getItem("access_token");
                    headers = {
                        Authorization: `Bearer ${token}`,
                    };
                }
                const response = await fetch(`${BASE_URL}/resumes/resume_counters`, {
                    headers,
                });
                const data = await response.json();
                if(data.status === 200){
                    setResumeCounter(data.resumes);

                }
            }
            catch (e) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(e)
            }

        }

        fetchResumeCounterData();
    }, []);


    // Last Resume Updated code
    const options = [
        {label: 'Today', value: 'today'},
        // {label: 'Yesterday', value: 'yesterday'},
        {label: 'Last 7 Days', value: '7days'},
        {label: 'Last 30 Days', value: '30days'},
        {label: 'Custom', value: 'custom'},
    ];
    const [selectedOption, setSelectedOption] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
    }, [startDate, endDate])

    function handleOptionChange(selectedOption) {
        if (selectedOption) {
            const value = selectedOption.value;
            const label = selectedOption.label
            setSelectedOption(value);

            switch (value) {
                case 'today':
                    const today = moment().format('YYYY-MM-DD');
                    setStartDate(today);
                    setEndDate(today);
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: today + ' - ' + today,
                    }));
                    break;
                case '7days':
                    const last7DaysStart = moment().subtract(7, 'days').format('YYYY-MM-DD');
                    const last7DaysEnd = moment().format('YYYY-MM-DD');
                    setStartDate(last7DaysStart);
                    setEndDate(last7DaysEnd);
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: last7DaysStart + ' - ' + last7DaysEnd
                    }));
                    break;
                case '30days':
                    const last30DaysStart = moment().subtract(30, 'days').format('YYYY-MM-DD');
                    const last30DaysEnd = moment().format('YYYY-MM-DD');
                    setStartDate(last30DaysStart);
                    setEndDate(last30DaysEnd);
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: last30DaysStart + ' - ' + last30DaysEnd
                    }));
                    break;
                default:
                    setStartDate('');
                    setEndDate('');
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: ''
                    }));
            }
            setFilter(prevFilter => ({
                ...prevFilter,
                last_updated_dropdown: value,
                last_updated_label:label,

            }));
        }
        else {
            // Clear the selected option and set last_updated to an empty string
            // setSelectedOption(null);
            setFilter((prevFilter) => ({
                ...prevFilter,
                last_updated: '',last_updated_dropdown:''
            }));
        }
    }


    function handleStartDateChange(event) {
        const value = event.target.value;
        custom_date1 = value;
        setFilter(prevFilter => ({
            ...prevFilter,
            last_updated: custom_date1 && custom_date2 ? custom_date1 + ' - ' + custom_date2 : ''
        }));
    }

    function handleEndDateChange(event) {
        const value = event.target.value;
        custom_date2 = value;
        setFilter(prevFilter => ({
            ...prevFilter,
            last_updated: custom_date1 && custom_date2 ? custom_date1 + ' - ' + custom_date2 : ''
        }));
    }


    // End Code
    useEffect(() => {
        // Attach event listener to detect clicks outside of the dropdown
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowRemoveButton(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleDropdownClick = (resumeId) => {

        setActiveResumeId(resumeId);
        setShowRemoveButton(!showRemoveButton);
    };

    const handleRemoveClick = (resumeId) => {

        handleFavorite(resumeId, true);
        setShowRemoveButton(false);
    };

    const [loading, setLoading] = useState(false);

    const [favoriteResumes, setFavoriteResumes] = useState([]);
    const getFavoriteResumes = () => {
        setLoading(true)
        const employerId = localStorage.getItem("employerId");
        if(token==null)
        {
            token = localStorage.getItem("access_token");
            headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        axios.post(`${BASE_URL}/resumes/get_favorite`, {employer_id: employerId}, {
            headers:headers
        })
            .then((response) => {
                if(response?.data.status === 200){
                    setFavoriteResumes(response?.data?.favorite_resume);
                    setLoading(false)
                }
                else {
                    setFavoriteResumes([]);
                    setLoading(false)
                }

            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    };
    useEffect(() => {
        getFavoriteResumes(); // call the getFavoriteResumes function initially to fetch the favorite resumes
    }, []);


    const [resumes, setResumes] = useState([]);

    useEffect(() => {
        fetchResumes(filter, setLoading).then(data => {
            setResumes(data);
        });
        if(filter.last_updated != ''){
            const [startDate, endDate] = filter.last_updated.split(' - ')
            custom_date1 = startDate??''
            custom_date2 = endDate??''
        }
        // console.log(filter, 'filter')
    }, [filter]);
    const handleWorkAuthChange = (event) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            work_authorization: event.target.value,
        }));

    }
    const handleRelocationChange = (event) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            setFilter(prevFilter => ({
                ...prevFilter,
                relocation: [...prevFilter.relocation, event?.target.value]
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                relocation: prevFilter.relocation.filter(item => item != event.target.value)

            }));
        }


    }
    const handleExperienceChange = (id) => {

        const experienceSelected = filter.yearsof_experience_id.includes(id);

        if (experienceSelected) {
            setFilter(prevFilter => ({
                ...prevFilter,
                yearsof_experience_id: prevFilter.yearsof_experience_id.filter(item => item != id)
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                yearsof_experience_id: [...prevFilter.yearsof_experience_id, id]
            }));
        }

    };

    const handleEmploymentTypeChange = (value) => {

        const preferredJobType = filter.preferred_job_type.includes(value);

        if (preferredJobType) {
            setFilter(prevFilter => ({
                ...prevFilter,
                preferred_job_type: prevFilter.preferred_job_type.filter(item => item != value)
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                preferred_job_type: [...prevFilter.preferred_job_type, value]
            }));
        }

    };


    const handleSearchButtonClick = async () => {
        if (selectedTitle) {
            setFilter((prevState) => ({
                ...prevState,
                preferred_job_title: selectedTitle ?? '',
            }));
        }
        if (selectedLocation) {
            setFilter((prevState) => ({
                ...prevState,
                location: selectedLocation ?? ''
            }));
        }
    };
    const handleResetButtonClick = async () => {
        setErrors([]);
        setSelectedZipCodeOptions(null);
        setOptionsZipCpdes([]);
        localStorage.removeItem('resumeFilter');
        setFilter((prevState) => ({
            ...prevState,
            last_updated_dropdown: '',
            last_updated: '',
            yearsof_experience_id: [],
            work_authorization_id: '',
            preferred_job_type: [],
            relocation: [],
            preferred_job_title: '',
            location: '',
            last_updated_label: ''
        }));
        setSelectedTitle('')
        setSelectedLocation('');

    };


    const handleFavorite = async (resumeId, isFavorite) => {

        setLoading(true);
        const employerId = localStorage.getItem("employerId");
        const url = isFavorite
            ? `${BASE_URL}/fav_resumes/${resumeId}`
            : `${BASE_URL}/resumes/favorite`;
        const method = isFavorite ? 'DELETE' : 'POST';
        const payload = {
            employer_id: employerId,
            resume_id: resumeId
        };
        if(token==null)
        {
            token = localStorage.getItem("access_token");
            headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        try {
            const response = await axios({
                method: method,
                url: url,
                headers: headers,
                data: payload  // Axios uses 'data' instead of 'body' for the request payload
            });

            const data = response.data;
            // Handle the response data here
        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)
        }

        getFavoriteResumes();
    };
    const calculateDaysSinceLastUpdated = (lastUpdated) => {
        if (!lastUpdated) {
            return null;
        }

        if (lastUpdated === 'custom') {
            return 'Custom Range';
        }
        // console.log(lastUpdated)
        const [startDate, endDate] = lastUpdated.split(' - ');

        if (startDate === endDate) {
            return 'today';
        }

        const daysSinceLastUpdated = moment().diff(moment(startDate), 'days');

        return `${daysSinceLastUpdated}days`;
    };

// Work Authorization Code
    const [selectedWorkAuthorization, setSelectedWorkAuthorization] = useState(null);
    const [workAuthorizations, setWorkAuthorizations] = useState([]);

    useEffect(() => {
        const fetchWorkAuthorizations = async () => {
            try {
                setLoading(true);
                if(token==null)
                {
                    token = localStorage.getItem("access_token");
                    headers = {
                        Authorization: `Bearer ${token}`,
                    };
                }
                const response = await fetch(`${BASE_URL}/work_authorization`, {
                    headers,
                });
                const data = await response.json();
                console.log(data)
                if(data.status==200){
                    setWorkAuthorizations(data.work_authorizations);
                    setLoading(false)
                }


            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        };

        fetchWorkAuthorizations();
    }, []);

    const work_authorization_options = workAuthorizations?.map((authorization) => ({
        value: authorization.id,
        label: authorization.work_authorization,
    }));

    useEffect(() => {
        const storedLabel = localStorage.getItem('work_authorization_label');
        if (storedLabel) {
            const selectedOption = work_authorization_options.find(
                (option) => option.label === storedLabel
            );
            setSelectedWorkAuthorization(selectedOption);
        }
    }, [work_authorization_options]);

    useEffect(() => {
        const handleResize = () => {
            fetchResumes(filter, setLoading).then((data) => {
                setResumes(data);
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [filter, setLoading, setResumes]);

    const [resumesCardHeight, setResumesCardHeight] = useState(0);

    useEffect(() => {
        adjustResumesCardHeight(); // Call the function on component mount

        window.addEventListener('resize', adjustResumesCardHeight); // Add event listener for window resize

        return () => {
            window.removeEventListener('resize', adjustResumesCardHeight); // Clean up the event listener on component unmount
        };
    }, []);

    const adjustResumesCardHeight = () => {
        const windowHeight = window.innerHeight;
        const resumesCardTop = document.querySelector('.resumesercard-main').offsetTop;
        const calculatedHeight = windowHeight - resumesCardTop;
        setResumesCardHeight(calculatedHeight-73);
    };
    const navigate = useNavigate();
    // Function to show modal with title and message
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const showModalWithMessage = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setShowModal(true);
    };

    const handleResumeClick = (resumeId, matched_keywords, matched_location) => {

        setLoading(true)
        axios.get(`${BASE_URL}/get-user-data`, {
            headers,
        })
            .then((res) => {
                const userData = res.data.user
                const viewedResumesIds=userData?.userable?.viewed_resumes_ids;
                const subscription = userData.userable.subscription;
                // Check if the resume has been viewed
                console.log(viewedResumesIds)

                const viewedResumeIds = viewedResumesIds?.map(viewedResume => viewedResume?.resume_id);
                if (viewedResumeIds?.includes(resumeId)) {
                    setLoading(false);
                    navigate(`/resumes`, { state: {resumeId, from: '/search_resume', matched_keywords, matched_location } });

                    return;
                }
                // Check if subscription is expired
                const endDate = new Date(subscription.end_date);
                const server_time = new Date(res.data?.server_time);

                if (endDate < server_time) {
                    setLoading(false)
                    showModalWithMessage("Subscription Expired", "Please contact admin to renew your subscription.");
                    return;
                }

                // Check if remaining jobs are zero or less
                if (subscription.remaining_views <= 0) {
                    setLoading(false)
                    showModalWithMessage("No Remaining Views", "Your resumes views limit has been reached. Please contact admin for assistance.");
                    return;
                }
                // If subscription is active and Views are remaining, navigate to post job page
                var from='/search_resume';
                navigate("/resumes", { state: { resumeId, from, matched_keywords, matched_location } });            })
            .catch((err) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(err)
            });
    };


    const handleUserImg = (user)=>{
        let img = Helper.profile_log(user)
        return img;

    }

    const viewAllResumeHandler = () => {
        const defaultfrom = "search_resume";
        navigate("/fav-resumes", {
            state: { defaultfrom } // Pass the favoriteResumes state
        });
    };



    // Pagination code
    const [currentPage, setCurrentPage] = useState(1);
    const resumesPerPage = 10; // Number of jobs to display per page
    const indexOfLastResume = currentPage * resumesPerPage;
    const indexOfFirstResume = indexOfLastResume - resumesPerPage;
    const currentResumes = resumes.slice(indexOfFirstResume, indexOfLastResume);
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(resumes.length / resumesPerPage); i++) {
        pageNumbers.push(i);
    }

    //Us based code
    const [isUSBased, setIsUSBased] = useState(true); // Defaulting to US-based
    const [optionsZipCodes, setOptionsZipCpdes] = useState([]);
    const [selectedZipCodeOptions, setSelectedZipCodeOptions] = useState();
    function fetchLocationData(zipcode) {
        fetch(`${BASE_URL}/zipcode/${zipcode}`, {
            headers,
        })
            .then((response) => response.json())

            .then((data) => {
                if (data.status === 200) {
                    if (data.zipcodes) {
                        let location = '';
                        if (data.zipcodes[0].city != null) {
                            location = data.zipcodes[0].city;
                        }
                        if (data.zipcodes[0].state != null) {
                            location += ', ' + data.zipcodes[0].state;
                        }
                        if (data.zipcodes[0].county_area != null) {
                            location += ', ' + data.zipcodes[0].zipcode;
                        }
                        // location+=', USA';
                        // setFilter((prevFilter) => ({
                        //     ...prevFilter,
                        //     location: location,
                        // }));
                        setSelectedLocation(location);
                        setFilter((prevState) => ({
                            ...prevState,
                            zip_code_option: location ?? '',
                        }));

                    }
                }

            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    }
// Function to handle option change
    function handleSelectZipCode(value) {
        if (value) {

            setSelectedZipCodeOptions(value);
            // Update job data with selected location
            fetchLocationData(value.value);
        } else {
            setOptionsZipCpdes([]);
            setSelectedZipCodeOptions(null); // Clear select ki hui value
            setSelectedLocation('');
            // Reset location field in job data
        }

        // Reset errors related to location
        setErrors({ ...errors, location: '' });

        // If the input value is empty, reset the filter
        if (!value) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                location: '',
            }));
        }

    }
    function handleInputChange(inputValue) {

        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    const fetchZipCodes = (query) => {
        const isZipCode = /^\d+$/.test(query); // Check if the query is a number

        fetch(`${BASE_URL}/zipcode_filter/${query}`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {

                if (data.status === 200) {
                    const zipcodes = data.zipcodes.original;


                    let optionsArray = [];
                    if (Array.isArray(zipcodes)) {
                        optionsArray = zipcodes.map((zipcode) => {
                            let value, label;
                            if (isZipCode) {
                                value = zipcode.zipcode;
                                label = zipcode.zipcode;
                                label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;

                            } else {
                                value = zipcode.zipcode;
                                label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;
                            }
                            return {
                                value: value,
                                label: label,
                            };
                        });
                    }

                    setOptionsZipCpdes(optionsArray);
                }

            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    };
    // Update filter state when isUSBased changes
    const handleIsUSBasedChange = () => {
        setSelectedLocation('');
        setSelectedZipCodeOptions(null);
        setOptionsZipCpdes([]);

        setIsUSBased(!isUSBased); // Toggle isUSBased value
        setFilter(prevFilter => ({
            ...prevFilter,
            is_us_based: !isUSBased, // Update is_us_based field in filter state
            location: '', // Update is_us_based field in filter state
            zip_code_option: ''

        }));
    };

    const [notificationCounter, setNotificationCounter] = useState(0)

//Yahan tk




    return (
        <div>
            <ErrorModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                title={modalTitle}
                message={modalMessage}
            />
            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR Start                          */}
            {/*---------------------------------------------------------------*/}
            <EmployerHeader
                setNotificationCounter={setNotificationCounter}
            />

            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR END                            */}
            {/*---------------------------------------------------------------*/}
            {/*---------------------------------------------------------------*/}
            {/*                        MAin Body Start                        */}
            {/*---------------------------------------------------------------*/}
            <div className="main1">
                <div className="container-fluid">
                    {/*---------------------------------Main section -----------------*/}
                    <div className="mainpage1">
                        {/*---------------------------First Column ---------------------*/}
                        <div className="resumecol1">

                            <div className="resumeupdate JSleftheading">
                                <h2>Last Resume Updated</h2>
                                <Select
                                    value={filter.last_updated_dropdown?{ label: filter?.last_updated_label, value: filter?.last_updated_dropdown }:'' }
                                    onChange={handleOptionChange}
                                    options={options}
                                    isClearable
                                    placeholder="Select an option"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px'
                                        }),
                                    }}
                                />
                                {filter.last_updated_dropdown === 'custom' ? (
                                    <div className="custom_date">
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={custom_date1}
                                            onChange={handleStartDateChange}
                                        />
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={custom_date2}
                                            onChange={handleEndDateChange}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        {/* Display the number of days since last_updated */}
                                        {/*<p>Number of days: {calculateDaysSinceLastUpdated(filter.last_updated)}</p>*/}
                                    </div>
                                )}
                            </div>

                            {/*-------------------Work Authorization ----------- */}
                            <div className="resumeupdate work-authoriz JSleftheading">
                                {console.log(filter, 'filter')}
                                <h2>Work Authorization</h2>
                                <Select
                                    id="work_authorization"
                                    name="term-contract"
                                    options={work_authorization_options}
                                    value={selectedWorkAuthorization}
                                    onChange={(selectedOption) => {
                                        setSelectedWorkAuthorization(selectedOption);
                                        const id = selectedOption ? parseInt(selectedOption.value) : null;
                                        setFilter((prevJobData) => ({
                                            ...prevJobData,
                                            work_authorization_id: id
                                        }));
                                        // Save selected label to localStorage
                                        localStorage.setItem(
                                            'work_authorization_label',
                                            selectedOption ? selectedOption.label : ''
                                        );

                                        // Clear work_authorization_id if selectedOption is null
                                        if (!selectedOption) {
                                            setFilter((prevJobData) => ({
                                                ...prevJobData,
                                                work_authorization_id: ''
                                            }));
                                        }
                                    }}
                                    placeholder="Work Authorization"
                                    isSearchable
                                    required
                                    isClearable
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px'
                                        }),
                                    }}
                                />

                            </div>
                            {/*-----------------Experience ------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Years of Experience</h2>
                                {resumeCounter?.experienceCount?.map((item) => (
                                    <div className="work-experience" key={item.experience_range}>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={

                                                        filter.yearsof_experience_id.some(id => id === item.id)
                                                }
                                                    onClick={() => handleExperienceChange(item.id)}
                                                />
                                                {item.experience_range}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>

                            {/*-----------------------Employment Type --------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Employment Type</h2>
                                {resumeCounter?.employmentTypeCount?.map((item) => (
                                    <div className="work-experience" key={item.preferred_job_type}>
                                        <div>
                                            <label>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    filter.preferred_job_type.some(id => id === item.preferred_job_type)

                                            }
                                                onClick={() => handleEmploymentTypeChange(item.preferred_job_type)}
                                            />
                                            {item.preferred_job_type}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>
                            {/*-----------------------Relocation --------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Relocation</h2>
                                {resumeCounter?.relocationCount?.map((item) => (
                                    <div className="work-experience" key={item.relocation}>
                                        <div>
                                            <label>
                                            <input
                                                type="checkbox"
                                                value={item.relocation}
                                                checked={
                                                filter.relocation.some(id => id === item.relocation)
                                              
                                            }
                                                onClick={handleRelocationChange}
                                            />
                                            {item.relocation}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/*--------------------------- Second  Column ---------------------*/}

                        <div className="postcol1">

                            {isMiniScreen && (
                                <SearchResumeFilter
                                    filter={filter}
                                    handleOptionChange={handleOptionChange}
                                    options={options}
                                    custom_date1={custom_date1}
                                    handleStartDateChange={handleStartDateChange}
                                    handleEndDateChange={handleEndDateChange}
                                    custom_date2={custom_date2}
                                    setFilter={setFilter}
                                    setSelectedWorkAuthorization={setSelectedWorkAuthorization}
                                    selectedWorkAuthorization={selectedWorkAuthorization}
                                    work_authorization_options={work_authorization_options}
                                    handleExperienceChange={handleExperienceChange}
                                    resumeCounter={resumeCounter}
                                    handleEmploymentTypeChange={handleEmploymentTypeChange}
                                    handleRelocationChange={handleRelocationChange}
                                    handleRemoveClick={handleRemoveClick}
                                    dropdownRef={dropdownRef}
                                    showRemoveButton={showRemoveButton}
                                    activeResumeId={activeResumeId}
                                    handleDropdownClick={handleDropdownClick}
                                    viewAllResumeHandler={viewAllResumeHandler}
                                    favoriteResumes={favoriteResumes}
                                    handleUserImg={handleUserImg}
                                    handleResumeClick={handleResumeClick}
                                />
                            )}
                          {/*  <div className="AJheadtitle ">*/}
                          
                          {/*<div className="AJtitle w-100">*/}
                          {/*  <h2>Search Resumes</h2>*/}
                          {/*</div>*/}
                          {/* </div>*/}
                            <div className="postanalyticsRS">
                                <p>No of views purchased: <span>{employer?.employer_data?.subscription?.package?.views}</span></p>
                                <p>Remaining views:<span> {employer?.employer_data?.subscription.remaining_views}</span>
                                </p>
                            </div>
                            {/* <h2>Search Candidates</h2> */}
                            <div className="input-group resumesearch-bar">
                                <div className="title-search">
                                    <label>Keywords Search</label>

                                    <div className="input-container">
                                        <input
                                            type="text"
                                            placeholder="Keywords Search"
                                            value={selectedTitle}
                                            className="searchtitle input-field"
                                            onChange={handlepreferred_job_titleInputChange}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    handleSearchButtonClick();
                                                }
                                            }}
                                        />
                                        {selectedTitle && (
                                            <button className="clear-button" onClick={clearInput}>
                                                &times; {/* Unicode for the multiplication sign */}
                                            </button>
                                        )}
                                    </div>
                                    {errors.title && (
                                        <div className="text-danger">{errors.title}</div>
                                    )}
                                </div>
                                <div className="location_container">

                                <div className="city-search">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <label style={{ marginRight: '10px' }}>
                                            Location Search
                                        </label>
                                        <label style={{ marginLeft: '10px' }}>
                                            US only:
                                            <input
                                                style={{ marginLeft: '5px' }}
                                                type="checkbox"
                                                checked={isUSBased}
                                                onChange={handleIsUSBasedChange}
                                            />
                                        </label>
                                    </div>



                                    <div className="input-container">
                                        {isUSBased ? (
                                            <Select
                                                options={optionsZipCodes}
                                                placeholder="Select ZIP Code/City"
                                                value={selectedZipCodeOptions}
                                                onChange={handleSelectZipCode}
                                                onInputChange={handleInputChange}
                                                onMenuClose={() => {
                                                    // Check if the clear button was clicked
                                                    if (!selectedZipCodeOptions) {
                                                        // Clear the filter when the clear button is clicked
                                                        setFilter((prevState) => ({
                                                            ...prevState,
                                                            zip_code_option: '',
                                                        }));
                                                    }
                                                }}
                                                isSearchable={true}
                                                isMulti={false}
                                                isClearable={true}
                                                styles={{
                                                    // fontFamily: colors.fontFamily,
                                                    control: (provided) => ({
                                                        ...provided,
                                                        // marginTop: '10px',
                                                        // padding: '4px 5px',
                                                        height:'38px',
                                                        border: '1px solid rgba(46, 46, 46, 0.5)',
                                                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                        borderRadius: '8px',
                                                        fontFamily: colors.fontFamily, // Add fontFamily here

                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999, // Set a higher z-index value
                                                    }),
                                                }}
                                            />
                                            // <input
                                            //     type="text"
                                            //     placeholder="Location Search"
                                            //     value={selectedLocation}
                                            //     className="searchtitle input-field"
                                            //     onChange={handleLocationInputChange}
                                            //     onKeyPress={(event) => {
                                            //         if (event.key === 'Enter') {
                                            //             handleSearchButtonClick();
                                            //         }
                                            //     }}
                                            // />
                                        ) : (
                                            <input
                                                type="text"
                                                placeholder="Search for Locations Worldwide"
                                                value={selectedLocation}
                                                className="searchtitle input-field"
                                                onChange={handleLocationInputChange}
                                                onKeyPress={(event) => {
                                                    if (event.key === 'Enter') {
                                                        handleSearchButtonClick();
                                                    }
                                                }}
                                            />
                                        )}

                                        {!isUSBased && selectedLocation && (
                                            <button className="clear-button" onClick={clearInputAddress}>
                                                &times;
                                            </button>
                                        )}

                                    </div>

                                    {/*<div className="input-container">*/}
                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        placeholder="Location Search"*/}
                                    {/*        value={selectedLocation}*/}
                                    {/*        className="searchtitle input-field"*/}
                                    {/*        onChange={handleLocationInputChange}*/}
                                    {/*        onKeyPress={(event) => {*/}
                                    {/*            if (event.key === 'Enter') {*/}
                                    {/*                handleSearchButtonClick();*/}
                                    {/*            }*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*    {selectedLocation && (*/}
                                    {/*        <button className="clear-button" onClick={clearInputAddress}>*/}
                                    {/*            &times; /!* Unicode for the multiplication sign *!/*/}
                                    {/*        </button>*/}
                                    {/*    )}*/}
                                    {/*</div>*/}


                                    {errors.location && (
                                        <div className="text-danger">{errors.location}</div>
                                    )}
                                </div>


                                <div className="input-group-append searchbtnRS ">
                                    <button className="btn" type="button" onClick={handleSearchButtonClick}>
                                        Search
                                    </button>
                                    <button className="btn red-btn" type="button" onClick={handleResetButtonClick}>
                                        Reset
                                    </button>
                                </div>
                                </div>

                            </div>
                            {total_resumes>0 &&(
                                <div className="total_resume">
                                    <h2 >Resume(s) Found:&nbsp;</h2>

                                    <h2 style={{color:"black"}}>
                                        {total_resumes}
                                    </h2>
                                </div>
                            )}

                            {/*--------------------- 1st card -------------------*/}

                            <div className="resumesercard-main" style={{ height: resumesCardHeight }} >
                                {resumes.length > 0 ? (
                                   resumes && resumes?.map((resume) => {
                                        const isFavorite = favoriteResumes && favoriteResumes.some((favResume) => favResume.resume?.id === resume?.id);
                                        return (
                                            <div className="searresume1 searchcard2">
                                                <div className="searchresum-img d-flex align-items-center gap-2">

                                                    {handleUserImg(resume?.job_seeker?.user)}
                                                    <div className='d-flex flex-column'>
                                                    <p className='blue_content_bold' onClick={() => handleResumeClick(resume.id, resume?.matched_keywords, resume?.matched_location)}
                                                       style={{ cursor: 'pointer' }}
                                                    >{resume.first_name} {resume.last_name}
                                                        {resume?.resume_availability_video_file &&(
                                                            <BiVideo size={25} color={colors.thirdColor} />
                                                        )}
                                                    </p>

                                                    <p
                                                        className='blue_content_bold'
                                                        dangerouslySetInnerHTML={{ __html: Helper.highlightJobTitle(resume?.preferred_job_title, resume?.matched_keywords) }}
                                                    ></p>
                                                    </div>
                                                </div>
                                                {/*-------------------------- information main div --------------- */}
                                                <div className="maininfo">
                                                    <div className="info1">

                                                        {resume && resume.matched_keywords && resume.matched_keywords.length>0 && (
                                                            <div className="info2">
                                                                <p  style={{ color: 'black'  }}>Keyword Match(es):&nbsp;</p>
                                                                <p className="skillspan" style={{ color: 'red', fontStyle: 'italic' }}>
                                                                    {resume.matched_keywords.join(", ")}
                                                                </p>
                                                            </div>
                                                        )}

                                                        <div className="info1-img">

                                                            {resume.viewed_resumes && resume.viewed_resumes.length > 0 && (
                                                                <BsEye size={24} style={{ color: '#808080' }}/>
                                                            )}
                                                            <Follow
                                                                user = {resume?.job_seeker?.user}
                                                                setLoading={setLoading}
                                                            />

                                                                {isFavorite ?
                                                                 <BsHeartFill title="Unfavorite" onClick={() => handleFavorite(resume.id, isFavorite)} size={24} style={{ color: '#e54545' , cursor: 'pointer' }} />
                                                                 : <BsHeart title="favorite" onClick={() => handleFavorite(resume.id, isFavorite)} size={24} style={{  cursor: 'pointer' }}/>}

                                                        </div>
                                                    </div>

                                                    {resume && resume.matched_location &&  resume.matched_location.length>0 &&(
                                                        <div className="info2">
                                                            <p  style={{ color: 'black' }}>Location Match(es):&nbsp;</p>

                                                            <p className="skillspan" style={{ color: 'red', fontStyle: 'italic' }}>
                                                                {resume.matched_location.join(", ")}
                                                            </p>
                                                        </div>
                                                    )}


                                                    <div className="info3 divider">
                                                        {resume.updated_at &&(
                                                        <div class="f-width-div">
                                                            <p className="full-width-p">
                                                                Last Resume Updated<span className="labelcol">:</span>{" "}
                                                                <span className="info-span">
                                                                {Helper.changeDateFormat(resume.updated_at)}
                                                              </span>
                                                            </p>
                                                        </div>
                                                        )}

                                                        {resume.preferred_job_type &&(
                                                        <div class="f-width-div">
                                                            <p className="full-width-p">
                                                                Employment Type<span className="labelcol">:</span>
                                                                <span className="info-span">
                                                                    {resume?.preferred_job_type === 'Any' ? ' Any (FT, Contract, 1099)' : resume?.preferred_job_type}
                                                                </span>
                                                                {/*<span*/}
                                                                {/*className="info-span">{resume.preferred_job_type}</span>*/}
                                                            </p>
                                                        </div>
                                                        )}

                                                        {resume.years_of_experiecne && (
                                                        <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Experience<span className="labelcol">: </span>
                                                                    <span>{resume.years_of_experiecne ? resume.years_of_experiecne.experience : ''}</span>
                                                                </p>
                                                        </div>
                                                        )}

                                                        {resume.work_authorization &&(
                                                        <div class="f-width-div">
                                                            <p className="full-width-p">
                                                                Work Authorization<span className="labelcol">:</span><span
                                                                className="info-span">{resume.work_authorization?.work_authorization}</span>
                                                            </p>
                                                        </div>
                                                        )}

                                                        {resume.relocation && (
                                                        <div class="f-width-div">
                                                            <p className="full-width-p">
                                                                Relocation<span className="labelcol">:</span><span
                                                                className="info-span">{resume.relocation}</span>
                                                            </p>
                                                        </div>
                                                        )}

                                                        {resume.expected_hourly_rate&&(
                                                        <div class="f-width-div">
                                                            <p className="full-width-p">
                                                                Salary/Rate<span className="labelcol">:</span>
                                                                <span className="info-span">${resume.expected_hourly_rate}/hour</span>
                                                            </p>
                                                        </div>
                                                        )}

                                                        {resume.salary_expectations&&(
                                                        <div className="f-width-div">
                                                            <p className="full-width-p">
                                                                Salary/Rate<span className="labelcol">:</span>
                                                                <span
                                                                    className="info-span">${resume.salary_expectations}/year</span>
                                                            </p>
                                                        </div>
                                                        )}

                                                        {/*{resume.location &&(*/}
                                                        {/*<div class="f-width-div">*/}
                                                        {/*    <img src={searchLoca}/>*/}
                                                        {/*    <span className="info-span" dangerouslySetInnerHTML={{ __html: highlightLocation(resume.location, resume?.matched_location) }}></span>*/}
                                                        {/*</div>*/}
                                                        {/*)}*/}
                                                        {resume?.searched_location && (
                                                            <div class="f-width-div">
                                                                <img src={searchLoca}/>
                                                                <span className="info-span" dangerouslySetInnerHTML={{ __html: Helper.highlightLocation(resume.searched_location, resume?.matched_location) }}></span>
                                                            </div>
                                                        )}
                                                        {resume?.skills?.length > 0 && (
                                                            <div class="f-width-div">
                                                                <img src={searchSkill} alt="Search Skill Icon" />
                                                                {resume.skills.map((skill, index) => (
                                                                    <span
                                                                        className="info-span"
                                                                        key={index}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: Helper.highlightSkills(skill.skill_set.name,
                                                                                resume?.matched_keywords,
                                                                                index,
                                                                                resume?.skills?.length
                                                                            ),
                                                                        }}
                                                                    ></span>
                                                                ))}
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="no-results-container">
                                        <h2 className="no-results-header">No Resumes Found</h2>
                                        <p className="no-results-description">
                                            We're sorry, we couldn't find any resumes that match your search criteria.
                                            Please try again with different filters.
                                        </p>
                                    </div>
                                )}


                                {/*--------------------- 3rd  card -------------------*/}

                            </div>
                        </div>
                        {/* --------------------------------Third Column ----------------------*/}
                        <div className="dashboardcol1">
                            <div className="analytics">
                                <div className="resume JSleftheading">
                                    <h2> Favorite Resumes</h2>
                                    {/* <p><a href="#">View All</a></p> */}
                                    {favoriteResumes && favoriteResumes.length > 0 && (
                                        <p style={{cursor:"pointer"}} onClick={viewAllResumeHandler}>View All <i className="fas fa-chevron-right"></i></p>
                                    )}

                                </div>
                                {/*-------------- Favourite Resume 1 ------------*/}
                                <div>
                                    {favoriteResumes && favoriteResumes.length > 0 ? (
                                        favoriteResumes && favoriteResumes?.map((resume, index) => (
                                            <div className="favourite" key={index}>
                                                <div className="fvtresum">
                                                    <div className="searchresum-img">

                                                        {/*<img src={profile5Image}/>*/}
                                                        {handleUserImg(resume?.resume?.job_seeker.user)}

                                                    </div>
                                                    <div className="rsfvtresumtxt">
                                                        <h6

                                                            onClick={() => handleResumeClick(resume?.resume?.id)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {resume?.resume?.first_name} {resume?.resume?.last_name}
                                                        </h6>
                                                        <p>
                                                            <a href="javascript:void(0)">{resume?.resume?.preferred_job_title}</a>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="dropdown" ref={dropdownRef}>
                                                    <button
                                                        className="dropbtn"
                                                        onClick={() => handleDropdownClick(resume?.resume?.id)}
                                                    >

                                                        <BsThreeDots className="basic_text"
                                                                     size={24}
                                                                     style={{  cursor: 'pointer' }} ></BsThreeDots>

                                                    </button>
                                                    {activeResumeId === resume?.resume?.id && showRemoveButton && (
                                                        <div className="dropdown-content" ref={dropdownRef}>
                                                            <button
                                                                className="removebtn"
                                                                onClick={() => handleRemoveClick(resume?.resume?.id)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p> No favorite resumes found.</p>
                                    )}
                                </div>

                            </div>
                        </div>

                        <EmployerSideBars
                            dashboardCheck ={false}
                            notificationCounter={notificationCounter}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SeachResume;