import React, {useEffect, useRef, useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/resumesearch.css';
import threeDots from '../src/assets/threedots.svg';
import searchLoca from '../src/assets/searchloca.svg';
import searchSkill from '../src/assets/searchskill.svg';
import searchFollow from '../src/assets/searchfollow.svg';
import style from '../src/assets/css/LoadingComponent.module.css';
import {BsHeart, BsHeartFill } from 'react-icons/bs';
import axios from "axios";
import BASE_URL from "./config";
import moment from 'moment';
import Select from "react-select";
import {Link, useNavigate} from "react-router-dom";
import {BsEye} from "react-icons/bs";
import JobSeekerHeader from "./JobSeekerHeader";
import Helper from "./Helper";
import { BsThreeDots } from "react-icons/bs";
import {Follow} from "./components/UserFollow/Follow";
import {useMediaQuery} from "react-responsive";
import {SearchJobfilter} from "./components/Sidbars/SearchJobfilter";
import {JobSeekerSiderBar} from "./components/Sidbars/JobSeekerSiderBar";
import { FaChevronRight } from "react-icons/fa";
import colors from "./colors";
import {InfoIconWithTooltip} from "icon-with-tooltip";

let custom_date1 = null
let custom_date2 = null
let count = 0;
let total_jobs=null;


const fetchResumes = async (filter, setLoading) => {
    let token = localStorage.getItem("access_token");
    let headers = {
        Authorization: `Bearer ${token}`,
    };
    document.querySelector('.resumesercard-main').classList.add('blur')
    let _data = JSON.parse(JSON.stringify(filter))
    delete _data.last_updated_dropdown
    delete _data.last_updated_label
    if (count === 0) setLoading(true); // set loading state to true
    if(token==null)
    {
        token = localStorage.getItem("access_token");
        headers = {
            Authorization: `Bearer ${token}`,
        };
    }
    const res = await fetch(`${BASE_URL}/jobad/filter?${new URLSearchParams(_data).toString()}`,{
        headers,
    });
    const data = await res.json();
    console.log('data', data)

    if (data && data.job && data.job.original && Array.isArray(data.job.original)) {
        total_jobs = data.job.original.length;

    }
    else {
        total_jobs=0;
    }
    if (Array.isArray(data)) {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched

        add_saperator()
        count++;
        return data;
    } else if (data.job && Array.isArray(data.job.original)) {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched

        add_saperator()
        count++;
        return data.job.original;
    } else {
        if (count === 0) setLoading(false); // set loading state to false when data is fetched
        // console.log('No resumes found');
        document.querySelector('.resumesercard-main').classList.remove('blur')
        count++;
        return [];
    }
};

function add_saperator(){
    setTimeout(function () {
        // console.log('called to remove')
        let lines = document.querySelectorAll(".add-line");
        if(lines.length > 0){
            lines.forEach(el => el.remove());

        }

        const containers = document.getElementsByClassName('divider');
        for (let idx = 0; idx < containers.length; idx++) {
            let container = containers[idx];

            const divElements = container.querySelectorAll('.f-width-div');

            let prevBottom = divElements[0].getBoundingClientRect().bottom;
            let update_elements = [];

            for (let i = 1; i < divElements.length; i++) {
                const currentElement = divElements[i];
                const currentBottom = currentElement.getBoundingClientRect().bottom;

                if (currentBottom > prevBottom) {
                    var div = document.createElement('div');
                    div.innerHTML = '';
                    div.className = 'add-line';
                    update_elements.push(div)
                    update_elements.push(currentElement)
                }

                prevBottom = currentBottom;
            }
            for (let i = 0; i < update_elements.length; i+=2) {
                container.insertBefore(update_elements[i], update_elements[i+1]);
            }
        }
        // console.log('called to add')
        document.querySelector('.resumesercard-main').classList.remove('blur')
    }, 500)

}


function SeachResume() {
    let token = localStorage.getItem("access_token");
    let headers = {
        Authorization: `Bearer ${token}`,
    };
    const [filter, setFilter] = useState(() => {
        const savedFilter = localStorage.getItem('resumeFilter');
        return savedFilter ? JSON.parse(savedFilter) : {
            last_updated: '',
            job_title: '',
            years_of_experience_id: [],
            contract_type_id: [],
            job_type: [],
            relocation: '',
            work_authorization_id: '',
            location:'',
            required_skills:'',
            job_mode:[],
            is_us_based: true


        };
    });
    const [notificationCounter, setNotificationCounter] = useState(0)

    useEffect(() => {
        localStorage.setItem('resumeFilter', JSON.stringify(filter));
    }, [filter]);

    //preferred_job_title callback
    const [selectedTitle, setSelectedTitle] = useState(null);
    const isMiniScreen = useMediaQuery({ query: '(max-width: 1024px)' })

    const [errors, setErrors] = useState([]);
    const handlepreferred_job_titleInputChange = (e) => {
        if (e.target.value.length > 70) {
            setErrors({ ...errors, title: "Maximum 70 characters allowed" });
            adjustResumesCardHeight();
        }
        else {
            setSelectedTitle(e.target.value);
            setErrors({ ...errors, title: "" });
            if (e.target.value.length <= 0) {
                setFilter((prevFilter) => ({
                    ...prevFilter,
                    job_title: '',

                }));
            }
        }
    };
    const clearInput = () => {
        setErrors({ ...errors, title: "" });
        setSelectedTitle('');
        setFilter((prevFilter) => ({
            ...prevFilter,
            job_title:'',

        }));
    };
    useEffect(() => {
        const savedFilter = localStorage.getItem('resumeFilter');
        if (savedFilter) {
            const parsedFilter = JSON.parse(savedFilter);
            setSelectedTitle(parsedFilter.job_title);
        }
    }, []);
    //location callback
    const [selectedLocation, setSelectedLocation] = useState(null);

    const handleLocationInputChange = (e) => {
        if (e.target.value.length > 70) {
            setErrors({ ...errors, location: "Maximum 70 characters allowed" });
            adjustResumesCardHeight();

        }
        else {
            setSelectedLocation(e.target.value);
            setErrors({ ...errors, location: "" });
            if (e.target.value.length <= 0) {
                setFilter((prevFilter) => ({
                    ...prevFilter,
                    location: '',
                }));
            }
        }
        // console.log(selectedLocation);
    };
    const clearInputAddress = () => {
        setErrors({ ...errors, location: "" });
        setSelectedLocation('');
        setFilter((prevFilter) => ({
            ...prevFilter,
            location:'',

        }));
    };
    useEffect(() => {
        const savedFilter = localStorage.getItem('resumeFilter');
        if (savedFilter) {
            const parsedFilter = JSON.parse(savedFilter);
            setSelectedLocation(parsedFilter.location);
        }
    }, []);


    const [activeResumeId, setActiveResumeId] = useState(null);
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const dropdownRef = useRef(null);
    //Employer Stats Data
    const [employer, setEmployer] = useState(null);
    const jobSeekerId = localStorage.getItem("jobSeekerId");
    const resume_viewed = 2;
    // useEffect(() => {
    //     async function fetchEmployerData() {
    //
    //         const response = await fetch(`${BASE_URL}/jobseeker_dashboard_data/${jobSeekerId}`,{
    //             headers,
    //         });
    //         if (response.status === 401) {
    //             // Redirect to the landing page if unauthenticated
    //             navigate("/");
    //         }
    //
    //         const data = await response.json();
    //         // console.log(data)
    //         setEmployer(data);
    //     }
    //
    //     fetchEmployerData();
    // }, []);

    //Employer Counters Data
    const [resumeCounter, setResumeCounter] = useState(null);
    useEffect(() => {
        async function fetchResumeCounterData() {
            if(token==null)
            {
                token = localStorage.getItem("access_token");
                headers = {
                    Authorization: `Bearer ${token}`,
                };
            }
            const response = await fetch(`${BASE_URL}/job_ads/job_counters`, {
                headers,
            });
            const data = await response.json();
            if(data.status === 200){
                setResumeCounter(data.jobs);

            }else if(data.status === 401){
                navigate("/");
            }
            // console.log(data.resumes)

        }

        fetchResumeCounterData();
    }, []);


    // Last Resume Updated code
    const options = [
        {label: 'Today', value: 'today'},
        // {label: 'Yesterday', value: 'yesterday'},
        {label: 'Last 7 Days', value: '7days'},
        {label: 'Last 30 Days', value: '30days'},
        {label: 'Custom', value: 'custom'},
    ];
    const [selectedOption, setSelectedOption] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
    }, [startDate, endDate])

    function handleOptionChange(selectedOption) {
        if (selectedOption) {
            const value = selectedOption.value;
            const label = selectedOption.label
            setSelectedOption(value);

            switch (value) {
                case 'today':
                    const today = moment().format('YYYY-MM-DD');
                    setStartDate(today);
                    setEndDate(today);
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: today + ' - ' + today,
                    }));
                    break;
                case '7days':
                    const last7DaysStart = moment().subtract(7, 'days').format('YYYY-MM-DD');
                    const last7DaysEnd = moment().format('YYYY-MM-DD');
                    setStartDate(last7DaysStart);
                    setEndDate(last7DaysEnd);
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: last7DaysStart + ' - ' + last7DaysEnd
                    }));
                    break;
                case '30days':
                    const last30DaysStart = moment().subtract(30, 'days').format('YYYY-MM-DD');
                    const last30DaysEnd = moment().format('YYYY-MM-DD');
                    setStartDate(last30DaysStart);
                    setEndDate(last30DaysEnd);
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: last30DaysStart + ' - ' + last30DaysEnd
                    }));
                    break;
                default:
                    setStartDate('');
                    setEndDate('');
                    setFilter(prevFilter => ({
                        ...prevFilter,
                        last_updated: ''
                    }));
            }
            setFilter(prevFilter => ({
                ...prevFilter,
                last_updated_dropdown: value,
                last_updated_label:label,

            }));
        }
        else {
            // Clear the selected option and set last_updated to an empty string
            // setSelectedOption(null);
            setFilter((prevFilter) => ({
                ...prevFilter,
                last_updated: '',last_updated_dropdown:''
            }));
        }
    }


    function handleStartDateChange(event) {
        const value = event.target.value;
        custom_date1 = value;
        setFilter(prevFilter => ({
            ...prevFilter,
            last_updated: custom_date1 && custom_date2 ? custom_date1 + ' - ' + custom_date2 : ''
        }));
    }

    function handleEndDateChange(event) {
        const value = event.target.value;
        custom_date2 = value;
        setFilter(prevFilter => ({
            ...prevFilter,
            last_updated: custom_date1 && custom_date2 ? custom_date1 + ' - ' + custom_date2 : ''
        }));
    }


    // End Code
    useEffect(() => {
        // Attach event listener to detect clicks outside of the dropdown
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowRemoveButton(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleDropdownClick = (resumeId) => {
        setActiveResumeId(resumeId);
        setShowRemoveButton(!showRemoveButton);
    };

    const handleRemoveClick = (resumeId) => {
        handleFavorite(resumeId, true);
        setShowRemoveButton(false);
    };

    const [loading, setLoading] = useState(false);

    const [favoriteResumes, setFavoriteResumes] = useState([]);
    const getFavoriteResumes = () => {
        setLoading(true)
        const jobSeekerId = localStorage.getItem("jobSeekerId");
        if(token==null)
        {
            token = localStorage.getItem("access_token");
            headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        axios.post(`${BASE_URL}/job_ad/get_favorite`, {job_seeker_id: jobSeekerId}, {
            headers:headers
        })
            .then((response) => {
                if(response?.data.status === 200){
                    setFavoriteResumes(response?.data?.favorite_job);
                    setLoading(false)
                }else if(response?.data.status === 401){
                    navigate("/");
                }
                else {
                    setFavoriteResumes([]);
                    setLoading(false)
                }

            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getFavoriteResumes(); // call the getFavoriteResumes function initially to fetch the favorite resumes
    }, []);


    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        fetchResumes(filter, setLoading).then(data => {
            setJobs(data);
        });
        if(filter.last_updated != ''){
            const [startDate, endDate] = filter.last_updated.split(' - ')
            custom_date1 = startDate??''
            custom_date2 = endDate??''
        }
        // console.log(filter, 'filter')
    }, [filter]);
    const handleWorkAuthChange = (event) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            work_authorization: event.target.value,
        }));

    }

    const handleJobType = (value) => {

        const isJobType = filter.job_type.includes(value);

        if (isJobType) {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_type: prevFilter.job_type.filter(item => item != value)
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_type: [...prevFilter.job_type, value]
            }));
        }

    }

    const handleJobMode = (value) => {

        const isJobMode = filter.job_mode.includes(value);

        if (isJobMode) {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_mode: prevFilter.job_mode.filter(item => item != value)
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                job_mode: [...prevFilter.job_mode, value]
            }));
        }

    }
    const handleRelocationChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setFilter(prevFilter => ({
                ...prevFilter,
                relocation: event?.target.value
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                relocation: ''
            }));
        }

    }
    const handleExperienceChange = (id) => {
        const isExperienceSelected = filter.years_of_experience_id.includes(id);

        if (isExperienceSelected) {
            // If the current experience is already selected, remove it
            setFilter(prevFilter => ({
                ...prevFilter,
                years_of_experience_id: prevFilter.years_of_experience_id.filter(item => item !== id)
            }));
        } else {
            // Otherwise, add the new experience
            setFilter(prevFilter => ({
                ...prevFilter,
                years_of_experience_id: [...prevFilter.years_of_experience_id, id]
            }));
        }
    };




    const handleEmploymentTypeChange = (value) => {

        const isContractTypeSelected = filter.contract_type_id.includes(value);

        if (isContractTypeSelected) {
            // If the current experience is already selected, remove it
            setFilter(prevFilter => ({
                ...prevFilter,
                contract_type_id: prevFilter.contract_type_id.filter(item => item !== value)
            }));
        } else {
            // Otherwise, add the new experience
            setFilter(prevFilter => ({
                ...prevFilter,
                contract_type_id: [...prevFilter.contract_type_id, value]
            }));
        }

    };
    const handleLastUpdateChange = (event) => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            last_updated: event.target.value,

        }));
    };


    const handleSearchButtonClick = async () => {
        if (selectedTitle) {
            setFilter((prevState) => ({
                ...prevState,
                job_title: selectedTitle ?? '',
            }));
        }
        if (selectedLocation) {
            setFilter((prevState) => ({
                ...prevState,
                location: selectedLocation ?? ''
            }));
        }
    };
    const handleResetButtonClick = async () => {

        localStorage.removeItem('resumeFilter');
        setErrors([]);
        setSelectedZipCodeOptions(null);
        setOptionsZipCpdes([]);
        setFilter((prevState) => ({
            ...prevState,
            last_updated_dropdown: '',
            last_updated: '',
            years_of_experience_id: [],
            work_authorization_id: '',
            job_type: [],
            relocation: '',
            job_title: '',
            location: '',
            last_updated_label: ''
        }));
        setSelectedTitle('')
        setSelectedLocation('');

    };
    const handleFavorite = async (jobId, isFavorite) => {
        setLoading(true);

        const jobSeekerId = localStorage.getItem("jobSeekerId");
        const url = isFavorite
            ? `${BASE_URL}/fav_job/${jobId}` // Use the correct endpoint for deleting favorite resumes
            : `${BASE_URL}/job_ad/favorite`;
        const method = isFavorite ? 'DELETE' : 'POST';
        const payload = {
            job_ad_id: jobId,
            job_seeker_id: parseInt(jobSeekerId)
        };
        if(token==null)
        {
            token = localStorage.getItem("access_token");
            headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        try {
            const response = await axios({
                method: method,
                url: url,
                headers: headers,
                data: payload  // Axios uses 'data' instead of 'body' for the request payload
            });

            const data = response.data;
            // Handle the response data here
        } catch (error) {
            // Handle errors here
        }

        getFavoriteResumes();
    };
    const calculateDaysSinceLastUpdated = (lastUpdated) => {
        if (!lastUpdated) {
            return null;
        }

        if (lastUpdated === 'custom') {
            return 'Custom Range';
        }
        // console.log(lastUpdated)
        const [startDate, endDate] = lastUpdated.split(' - ');

        if (startDate === endDate) {
            return 'today';
        }

        const daysSinceLastUpdated = moment().diff(moment(startDate), 'days');

        return `${daysSinceLastUpdated}days`;
    };

// Work Authorization Code
    const [selectedWorkAuthorization, setSelectedWorkAuthorization] = useState(null);
    const [workAuthorizations, setWorkAuthorizations] = useState([]);

    useEffect(() => {
        const fetchWorkAuthorizations = async () => {
            try {
                setLoading(true);
                if(token==null)
                {
                    token = localStorage.getItem("access_token");
                    headers = {
                        Authorization: `Bearer ${token}`,
                    };
                }
                const response = await fetch(`${BASE_URL}/work_authorization`, {
                    headers,
                });
                    const data = await response.json();
                    console.log(data)
                    if(data.status==200){
                    setWorkAuthorizations(data.work_authorizations);
                    setLoading(false)
                    }
                    else if(data.status === 401){
                        navigate("/");
                    }

            } catch (error) {
                setLoading(false)
                console.error('Error fetching work authorizations:', error);
            }
        };

        fetchWorkAuthorizations();
    }, []);

    const work_authorization_options = workAuthorizations?.map((authorization) => ({
        value: authorization.id,
        label: authorization.work_authorization,
    }));

    useEffect(() => {
        const storedLabel = localStorage.getItem('work_authorization_label');
        if (storedLabel) {
            const selectedOption = work_authorization_options.find(
                (option) => option.label === storedLabel
            );
            setSelectedWorkAuthorization(selectedOption);
        }
    }, [work_authorization_options]);

    useEffect(() => {
        const handleResize = () => {
            fetchResumes(filter, setLoading).then((data) => {
                setJobs(data);
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [filter, setLoading, setJobs]);

    const [resumesCardHeight, setResumesCardHeight] = useState(0);

    useEffect(() => {
        adjustResumesCardHeight(); // Call the function on component mount

        window.addEventListener('resize', adjustResumesCardHeight); // Add event listener for window resize

        return () => {
            window.removeEventListener('resize', adjustResumesCardHeight); // Clean up the event listener on component unmount
        };
    }, []);

    const adjustResumesCardHeight = () => {
        const windowHeight = window.innerHeight;
        const resumesCardTop = document.querySelector('.resumesercard-main').offsetTop;
        const calculatedHeight = windowHeight - resumesCardTop;

        setResumesCardHeight(calculatedHeight-10);
    };
    const navigate = useNavigate();

    const handleResumeClick = (jobId, matched_keywords,matched_location) => {
        // console.log(matched_keywords)
        var from='/search_job';
        navigate('/post_a_job_preview', { state: { jobId, matched_keywords,matched_location,from } });
    };

    const handleCompanyImg = (job)=>{
        let img = ""
        job?.employer?.users.map((user)=>{
            if(job?.employer?.id === user?.userable_id){
                img = Helper.profile_log(user)
            }
        })
        return img;
    }

    const viewAllFavJobsHandler = () => {
        const defaultfrom = "job_Search";
        navigate("/fav-jobs", {
            state: { defaultfrom } // Pass the favoriteResumes state
        });
    };

    const handleFollowing = (job)=>{

        let matchUser
        job?.employer?.users?.map(user =>{
            if(user?.userable_id == job?.employer?.id){
                console.log(user?.userable_id, job?.employer?.id)
                matchUser = user
            }
        })

        return (<Follow
            user = {matchUser}
            setLoading={setLoading}
        />)

    }
    //Us based code
    const [isUSBased, setIsUSBased] = useState(true); // Defaulting to US-based
    const [optionsZipCodes, setOptionsZipCpdes] = useState([]);
    const [selectedZipCodeOptions, setSelectedZipCodeOptions] = useState();
    function fetchLocationData(zipcode) {
        fetch(`${BASE_URL}/zipcode/${zipcode}`, {
            headers,
        })
            .then((response) => response.json())

            .then((data) => {
                if (data.status === 200) {
                    if (data.zipcodes) {
                        let location = '';
                        if (data.zipcodes[0].city != null) {
                            location = data.zipcodes[0].city;
                        }
                        if (data.zipcodes[0].state != null) {
                            location += ', ' + data.zipcodes[0].state;
                        }
                        if (data.zipcodes[0].county_area != null) {
                            location += ', ' + data.zipcodes[0].zipcode;
                        }
                        // location+=', USA';
                        // setFilter((prevFilter) => ({
                        //     ...prevFilter,
                        //     location: location,
                        // }));
                        setSelectedLocation(location);
                    } else {
                        console.error(`Zipcode ${zipcode} not found`);
                    }
                } else if (data.api_status == 401) {
                    navigate("/");
                }

            })
            .catch((error) => {
                console.error(`Error fetching location data for zipcode ${zipcode}:`, error);
            });
    }
// Function to handle option change
    function handleSelectZipCode(value) {
        if (value) {
            setSelectedZipCodeOptions(value);
            // Update job data with selected location
            fetchLocationData(value.value);
        } else {
            setOptionsZipCpdes([]);
            setSelectedZipCodeOptions(null); // Clear select ki hui value
            setSelectedLocation('');
            // Reset location field in job data
        }

        // Reset errors related to location
        setErrors({ ...errors, location: '' });

        // If the input value is empty, reset the filter
        if (!value) {
            setFilter((prevFilter) => ({
                ...prevFilter,
                location: '',
            }));
        }

    }
    function handleInputChange(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    const fetchZipCodes = (query) => {
        const isZipCode = /^\d+$/.test(query); // Check if the query is a number

        fetch(`${BASE_URL}/zipcode_filter/${query}`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {

                if (data.status === 200) {
                    const zipcodes = data.zipcodes.original;


                    let optionsArray = [];
                    if (Array.isArray(zipcodes)) {
                        optionsArray = zipcodes.map((zipcode) => {
                            let value, label;
                            if (isZipCode) {
                                value = zipcode.zipcode;
                                label = zipcode.zipcode;
                                label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;

                            } else {
                                value = zipcode.zipcode;
                                label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;
                            }
                            return {
                                value: value,
                                label: label,
                            };
                        });
                    }

                    setOptionsZipCpdes(optionsArray);
                } else if (data.api_status == 401) {
                    navigate("/");
                }

            })
            .catch((error) => {
                console.error("Error fetching zipcodes data:", error);
            });
    };
    // Update filter state when isUSBased changes
    const handleIsUSBasedChange = () => {
        setSelectedLocation('');
        setSelectedZipCodeOptions(null);
        setOptionsZipCpdes([]);
        setIsUSBased(!isUSBased); // Toggle isUSBased value
        setFilter(prevFilter => ({
            ...prevFilter,
            is_us_based: !isUSBased, // Update is_us_based field in filter state
            location: '' // Update is_us_based field in filter state

        }));
    };

    return (
        <div>
            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR Start                          */}
            {/*---------------------------------------------------------------*/}
            <JobSeekerHeader
                setNotificationCounter={setNotificationCounter}
            />
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR END                            */}
            {/*---------------------------------------------------------------*/}
            {/*---------------------------------------------------------------*/}
            {/*                        MAin Body Start                        */}
            {/*---------------------------------------------------------------*/}
            <div className="main1">
                <div className="container-fluid">
                    {/*---------------------------------Main section -----------------*/}
                    <div className="mainpage1">
                        {/*---------------------------First Column ---------------------*/}
                        <div className="resumecol1">


                            <div className="resumeupdate JSleftheading">
                                <h2>Latest Jobs Updated</h2>
                                <Select
                                    value={filter.last_updated_dropdown?{ label: filter?.last_updated_label, value: filter?.last_updated_dropdown }:'' }
                                    onChange={handleOptionChange}
                                    options={options}
                                    isClearable
                                    placeholder="Select an option"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px'
                                        }),
                                    }}
                                />
                                {filter.last_updated_dropdown === 'custom' ? (
                                    <div className="custom_date">
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={custom_date1}
                                            onChange={handleStartDateChange}
                                        />
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={custom_date2}
                                            onChange={handleEndDateChange}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        {/* Display the number of days since last_updated */}
                                        {/*<p>Number of days: {calculateDaysSinceLastUpdated(filter.last_updated)}</p>*/}
                                    </div>
                                )}
                            </div>


                            {/*-------------------Work Authorization ----------- */}
                            <div className="resumeupdate work-authoriz JSleftheading">

                                <h2>Work Authorization</h2>
                                <Select
                                    id="work_authorization"
                                    name="term-contract"
                                    options={work_authorization_options}
                                    value={selectedWorkAuthorization}
                                    onChange={(selectedOption) => {
                                        setSelectedWorkAuthorization(selectedOption);
                                        const id = selectedOption ? parseInt(selectedOption.value) : null;
                                        setFilter((prevJobData) => ({
                                            ...prevJobData,
                                            work_authorization_id: id
                                        }));
                                        // Save selected label to localStorage
                                        localStorage.setItem(
                                            'work_authorization_label',
                                            selectedOption ? selectedOption.label : ''
                                        );

                                        // Clear work_authorization_id if selectedOption is null
                                        if (!selectedOption) {
                                            setFilter((prevJobData) => ({
                                                ...prevJobData,
                                                work_authorization_id: ''
                                            }));
                                        }
                                    }}
                                    placeholder="Work Authorization"
                                    isSearchable
                                    required
                                    isClearable
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            padding: '4px 5px',
                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                            borderRadius: '8px'
                                        }),
                                    }}
                                />

                            </div>
                            {/*-----------------Experience ------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Years of Experience</h2>

                                {resumeCounter && resumeCounter?.experienceCount.map((item) => (
                                    <div className="work-experience" key={item.experience_range}>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        filter.years_of_experience_id.some(id => id === item.id)
                                                    }
                                                    onClick={() => handleExperienceChange(item.id)}
                                                />
                                                {item.experience_range}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>

                            {/*-----------------------Employment Type --------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Contract Type</h2>
                                {resumeCounter?.contractTypeCount.map((item) => (
                                    <div className="work-experience" key={item.employment_range}>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        filter.contract_type_id.some(id => id === item.id)
                                                       }
                                                    onClick={() => handleEmploymentTypeChange(item.id)}
                                                />
                                                {item.employment_range}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>
                            {/*-----------------------Job Type --------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Job Type</h2>
                                {resumeCounter?.jobTypeCount.map((item) => (
                                    <div className="work-experience" key={item.type}>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value={item.type}
                                                    checked={
                                                        filter.job_type.some(id => id === item.type)
                                                }
                                                    onClick={()=>handleJobType(item.type)}
                                                />
                                                {item.type}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>

                            {/*-----------------------Job Mode --------------- */}
                            <div className="resumeupdate work-authoriz work-authorizbord">
                                <h2>Job Mode</h2>
                                {resumeCounter?.jobModeCount.map((item) => (
                                    <div className="work-experience" key={item.job_mode}>
                                        <div>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value={item.job_mode}
                                                    checked={
                                                        filter.job_mode.some(id => id === item.job_mode)

                                                }
                                                    onClick={()=>handleJobMode(item.job_mode)}
                                                />
                                                {item.job_mode}
                                            </label>
                                        </div>
                                        <p></p>
                                        {/*<p>{item.count}</p>*/}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/*--------------------------- Second  Column ---------------------*/}

                        <div className="postcol1">

                            {isMiniScreen &&(
                                <SearchJobfilter
                                    setFilter={setFilter}
                                    setSelectedWorkAuthorization={setSelectedWorkAuthorization}
                                    selectedWorkAuthorization={selectedWorkAuthorization}
                                    work_authorization_options={work_authorization_options}
                                    handleEndDateChange={handleEndDateChange}
                                    custom_date2={custom_date2}
                                    handleStartDateChange={handleStartDateChange}
                                    custom_date1={custom_date1}
                                    options={options}
                                    handleOptionChange={handleOptionChange}
                                    filter={filter}
                                    handleJobMode={handleJobMode}
                                    handleJobType={handleJobType}
                                    handleEmploymentTypeChange={handleEmploymentTypeChange}
                                    handleExperienceChange={handleExperienceChange}
                                    resumeCounter={resumeCounter}
                                    handleRemoveClick={handleRemoveClick}
                                    showRemoveButton={showRemoveButton}
                                    activeResumeId={activeResumeId}
                                    handleDropdownClick={handleDropdownClick}
                                    dropdownRef={dropdownRef}
                                    handleResumeClick={handleResumeClick}
                                    handleCompanyImg={handleCompanyImg}
                                    favoriteResumes={favoriteResumes}
                                    viewAllFavJobsHandler={viewAllFavJobsHandler}
                                />
                            )}


                            <div className="input-group resumesearch-bar">
                                <div className="title-search">
                                    <label>Keywords Search
                                        {/*<InfoIconWithTooltip*/}
                                        {/*    text="Use double quotes for precise results "*/}
                                        {/*    placement="right" />*/}
                                    </label>


                                    <div className="input-container">
                                        <input
                                            type="text"
                                            placeholder="Keywords Search"
                                            value={selectedTitle}
                                            className="searchtitle input-field"
                                            onChange={handlepreferred_job_titleInputChange}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    handleSearchButtonClick();
                                                }
                                            }}
                                        />
                                        {selectedTitle && (
                                            <button className="clear-button" onClick={clearInput}>
                                                &times; {/* Unicode for the multiplication sign */}
                                            </button>
                                        )}
                                    </div>
                                    {errors.title && (
                                        <div className="text-danger">{errors.title}</div>
                                    )}
                                </div>
                                <div className="location_container">
                                    <div className="city-search">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <label style={{ marginRight: '10px' }}>
                                                Location Search
                                            </label>
                                            {/*<label style={{ marginLeft: '10px' }}>*/}
                                            {/*    US only:*/}
                                            {/*    <input*/}
                                            {/*        style={{ marginLeft: '5px' }}*/}
                                            {/*        type="checkbox"*/}
                                            {/*        checked={isUSBased}*/}
                                            {/*        onChange={handleIsUSBasedChange}                                                />*/}
                                            {/*</label>*/}
                                        </div>


                                        <div className="input-container">
                                            {isUSBased ? (
                                                <Select
                                                    options={optionsZipCodes}
                                                    placeholder="Select ZIP Code/City"
                                                    value={selectedZipCodeOptions}
                                                    onChange={handleSelectZipCode}
                                                    onInputChange={handleInputChange}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            height: '38px',
                                                            border: '1px solid rgba(46, 46, 46, 0.5)',
                                                            boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                            borderRadius: '8px',
                                                            fontFamily: colors.fontFamily,
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 9999, // Set a higher z-index value
                                                        }),
                                                    }}

                                                />

                                            ) : (
                                                <input
                                                    type="text"
                                                    placeholder="Search for Locations Worldwide"
                                                    value={selectedLocation}
                                                    className="searchtitle input-field"
                                                    onChange={handleLocationInputChange}
                                                    onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                            handleSearchButtonClick();
                                                        }
                                                    }}
                                                />
                                            )}

                                            {!isUSBased && selectedLocation && (
                                                <button className="clear-button" onClick={clearInputAddress}>
                                                    &times;
                                                </button>
                                            )}

                                        </div>
                                        {errors.location && (
                                            <div className="text-danger">{errors.location}</div>
                                        )}
                                    </div>
                                    <div className="input-group-append searchbtnRS ">
                                        <button  className="btn " type="button" onClick={handleSearchButtonClick}>
                                            Search
                                        </button>
                                        <button className="btn red-btn" type="button" onClick={handleResetButtonClick}>
                                            Reset
                                        </button>
                                    </div>
                                </div>


                            </div>
                            {total_jobs>0 &&(
                                <div className="total_resume">
                                    <h2 >Job(s) Found:&nbsp;</h2>
                                    <h2 style={{color:"black"}}>
                                        {total_jobs}
                                    </h2>
                                </div>
                            )}

                            {/*--------------------- 1st card -------------------*/}

                            <div className="resumesercard-main" style={{ height: resumesCardHeight }} >

                                {jobs.length > 0 ? (
                                    jobs.map((job) => {
                                        const isFavorite = favoriteResumes && favoriteResumes.some((favResume) => favResume.job_ad.id === job.id);
                                        return (
                                            <div className="searresume1 searchcard2">
                                                <div className="searchresum-img d-flex gap-2 align-items-center">

                                                    {/*<img src={avatarImg}/>*/}

                                                    {handleCompanyImg(job)}
                                                    <p
                                                        className='blue_content_bold'
                                                        onClick={() => handleResumeClick(job.id, job?.matched_keywords,job?.matched_location)}
                                                        style={{ cursor: 'pointer' }}
                                                        dangerouslySetInnerHTML={{ __html: Helper.highlightJobTitle(job.job_title, job?.matched_keywords) }}
                                                    ></p>
                                                </div>
                                                {/*-------------------------- information main div --------------- */}
                                                <div className="maininfo">
                                                    <div className="info1">

                                                        {/*<p onClick={() => handleResumeClick(job.id,job?.matched_keywords)} style={{ cursor: 'pointer' }}*/}
                                                        {/*>{job.job_title}</p>*/}
                                                        {job && job.matched_keywords && job.matched_keywords.length > 0 && (
                                                            <div className="info2">
                                                                <p style={{ color: 'black' }}>Keyword Match(es):&nbsp;</p>
                                                                <p className="skillspan" style={{ color: 'red', fontStyle: 'italic' }}>
                                                                    {job.matched_keywords.join(", ")}
                                                                </p>
                                                            </div>
                                                        )}

                                                        <div className="info1-img">

                                                            {job?.viewed_jobs && job?.viewed_jobs.length > 0 && (
                                                                <BsEye size={24} style={{ color: '#808080' }}/>
                                                            )}


                                                            {handleFollowing(job)}
                                                            {/*<img src={searchFollow}/>*/}

                                                            {/* <img
                                                                onClick={() => handleFavorite(job.id, isFavorite)}
                                                                src={isFavorite ? favorite_heart : headerIcon2} // add conditional class based on whether the resume is in favorite or not
                                                                className={isFavorite ? 'favorite-icon favorite' : 'favorite-icon'}
                                                            /> */}
                                                            {isFavorite ?
                                                                 <BsHeartFill title="Unfavorite" onClick={() => handleFavorite(job.id, isFavorite)} size={24} style={{ color: '#e54545' , cursor: 'pointer' }} /> 
                                                                 : <BsHeart title="favorite"  onClick={() => handleFavorite(job.id, isFavorite)} size={24} style={{  cursor: 'pointer' }}/>}



                                                        </div>
                                                    </div>
                                                    <div className="info2">
                                                        <p></p>
                                                    </div>


                                                    {job && job.matched_location && job.matched_location.length > 0 && (
                                                        <div className="info2">
                                                            <p style={{ color: 'black' }}>Location Match(es):&nbsp;</p>
                                                            <p className="skillspan" style={{ color: 'red', fontStyle: 'italic' }}>
                                                                {job.matched_location.join(", ")}
                                                            </p>
                                                        </div>
                                                    )}



                                                    <div className="info3 divider">
                                                        {job.updated_at &&(
                                                        <div class="f-width-div">
                                                            <p className="full-width-p">
                                                                Last Updated<span className="labelcol">:</span>{" "}
                                                                <span className="info-span">
                                                                {Helper.changeDateFormat(new Date(job.updated_at))}

                                                                {/* {new Date(job.updated_at).toLocaleDateString("en-US", {
                                                                    year: "numeric",
                                                                    month: "2-digit",
                                                                    day: "2-digit",
                                                                    timeZone: "UTC",

                                                                })} */}
                                                              </span>
                                                            </p>
                                                        </div>
                                                    )}


                                                        {job.job_type &&(
                                                            <div class="f-width-div">
                                                                {/*<p className="full-width-p">*/}
                                                                {/*    Type<span className="labelcol">:</span> <span*/}
                                                                {/*    className="info-span">{job.job_type}</span>*/}
                                                                {/*</p>*/}

                                                                <p className="full-width-p">
                                                                    Type<span className="labelcol">:</span> <span
                                                                    className="info-span">{job?.job_type === 'Any' ? 'Any (FT, Contract, 1099)' : job?.job_type}</span>
                                                                </p>
                                                            </div>
                                                        )}

                                                        {job.job_mode &&(
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Mode<span className="labelcol">:</span> <span
                                                                    className="info-span">{job.job_mode}</span>
                                                                </p>
                                                            </div>
                                                        )}

                                                        {job.contract_type &&(
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Contract Type<span className="labelcol">:</span> <span
                                                                    className="info-span">{job.contract_type.employment_type}</span>
                                                                </p>
                                                            </div>
                                                        )}

                                                        {job.job_status &&(
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Status<span className="labelcol">:</span> <span
                                                                    className="info-span">{job.job_status.status}</span>
                                                                </p>
                                                            </div>
                                                        )}

                                                        {job.interview &&(
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Interview<span className="labelcol">:</span> <span
                                                                    className="info-span">{job.interview}</span>
                                                                </p>
                                                            </div>
                                                        )}

                                                        {job.years_of_experience && (
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Experience<span className="labelcol">: </span>
                                                                    <span className="info-span">{job.years_of_experience ? job.years_of_experience.experience : ''}</span>
                                                                </p>
                                                            </div>
                                                        )}

                                                        {/*{job.work_authorization &&(*/}
                                                        {/*    <div class="f-width-div">*/}
                                                        {/*        <p className="full-width-p">*/}
                                                        {/*            Work Authorization<span className="labelcol">:</span><span*/}
                                                        {/*            className="info-span">{job.work_authorization?.work_authorization}</span>*/}
                                                        {/*        </p>*/}
                                                        {/*    </div>*/}
                                                        {/*)}*/}

                                                        {job.work_authorizations && job.work_authorizations.length>0 && (
                                                            <div className="f-width-div">
                                                                <span className="full-width-p">
                                                                    <b>Work Authorization</b> </span>
                                                                <span className="labelcol">: </span>

                                                                {job.work_authorizations.map((authorization, index) => (
                                                                    <span  key={authorization.id}>

                                                                {index > 0 && ', '}
                                                                        {authorization.work_authorization}

                                                                    </span>
                                                                ))}


                                                            </div>
                                                        )}



                                                        {job.relocation && (
                                                            <div class="f-width-div">
                                                                <p className="full-width-p">
                                                                    Relocation<span className="labelcol">:</span><span
                                                                    className="info-span">{job.relocation}</span>
                                                                </p>
                                                            </div>
                                                        )}

                                                        {/*{job.expected_hourly_rate&&(*/}
                                                        {/*    <div class="f-width-div">*/}
                                                        {/*        <p className="full-width-p">*/}
                                                        {/*            Salary/Rate<span className="labelcol">:</span>*/}
                                                        {/*            <span className="info-span">${job.expected_hourly_rate}/hour</span>*/}
                                                        {/*        </p>*/}
                                                        {/*    </div>*/}
                                                        {/*)}*/}

                                                        {/*{job.salary_expectations&&(*/}
                                                        {/*    <div className="f-width-div">*/}
                                                        {/*        <p className="full-width-p">*/}
                                                        {/*            Salary/Rate<span className="labelcol">:</span>*/}
                                                        {/*            <span*/}
                                                        {/*                className="info-span">${job.salary_expectations}/year</span>*/}
                                                        {/*        </p>*/}
                                                        {/*    </div>*/}
                                                        {/*)}*/}

                                                        {job.location && (
                                                            <div class="f-width-div">
                                                                <img src={searchLoca} alt="Search Location Icon" />
                                                                <span className="info-span" dangerouslySetInnerHTML={{ __html: Helper.highlightLocation(job.location, job?.matched_location) }}></span>
                                                            </div>
                                                        )}
                                                        {/*{job.location &&(*/}
                                                        {/*    <div class="f-width-div">*/}
                                                        {/*        <img src={searchLoca}/>*/}
                                                        {/*        <span className="info-span">{job.location}</span>*/}
                                                        {/*    </div>*/}
                                                        {/*)}*/}

                                                        {/*{job.required_skills && (*/}
                                                        {/*    <div class="f-width-div">*/}
                                                        {/*        <img src={searchSkill} alt="Search Skill Icon" />*/}
                                                        {/*        <span className="info-span">{job.required_skills}</span>*/}

                                                        {/*    </div>*/}
                                                        {/*)}*/}

                                                        {job.skills?.length > 0 && (
                                                            <div className="f-width-div">
                                                                <img src={searchSkill} alt="Search Skill Icon" />
                                                                {job.skills.map((skill, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <span className="info-span"
                                                                              dangerouslySetInnerHTML={{
                                                                                  __html: Helper.highlightSkills(skill.skill_set.name, job?.matched_keywords),
                                                                              }}
                                                                        ></span>
                                                                        {index < job.skills.length - 1 && <span>, </span>}
                                                                    </React.Fragment>
                                                                ))}
                                                            </div>
                                                        )}




                                                        {/*{job.required_skills && (*/}
                                                        {/*    <div class="f-width-div">*/}
                                                        {/*        <img src={searchSkill} alt="Search Skill Icon" />*/}
                                                        {/*        <span className="info-span" dangerouslySetInnerHTML={{ __html: highlightSkills(job.required_skills, job?.matched_keywords) }}></span>*/}
                                                        {/*    </div>*/}
                                                        {/*)}*/}
                                                    </div>




                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="no-results-container">
                                        <h2 className="no-results-header">No Jobs Found</h2>
                                        <p className="no-results-description">
                                            We're sorry, we couldn't find any jobs that match your search criteria.
                                            Please try again with different filters.
                                        </p>
                                    </div>
                                )}


                                {/*--------------------- 3rd  card -------------------*/}

                            </div>
                        </div>
                        {/* --------------------------------Third Column ----------------------*/}
                        <div className="dashboardcol1">
                            <div className=" analytics">
                                <div className="resume JSleftheading">
                                    <h2> Favorite Jobs</h2>
                                    {/* <p><a href="#">View All</a></p> */}
                                    {/*{favoriteResumes && favoriteResumes.length > 3 && (*/}
                                        <p style={{cursor:"pointer"}} onClick={viewAllFavJobsHandler}>View All <FaChevronRight /></p>
                                    {/*)}*/}

                                </div>
                                {/*-------------- Favourite Resume 1 ------------*/}
                                <div>
                                    {favoriteResumes && favoriteResumes.length > 0 ? (
                                        favoriteResumes.map((job, index) => (
                                            <div className="favourite" key={index}>
                                                <div className="fvtresum">
                                                    <div className="searchresum-img">
                                                        {handleCompanyImg(job.job_ad)}

                                                    </div>
                                                    <div className="rsfvtresumtxt">
                                                        <h6
                                                            onClick={() => handleResumeClick(job?.job_ad?.id)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {job?.job_ad?.job_title}
                                                        </h6>
                                                        <p>
                                                            <a href="javascript:void(0)">{job?.job_ad?.location}</a>


                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="dropdown" ref={dropdownRef}>
                                                    <button
                                                        className="dropbtn"
                                                        onClick={() => handleDropdownClick(job?.job_ad?.id)}
                                                    >
                                                        <BsThreeDots className="basic_text" size={24} style={{  cursor: 'pointer' }}></BsThreeDots>
                                                    </button>
                                                    {activeResumeId === job?.job_ad?.id && showRemoveButton && (
                                                        <div className="dropdown-content" ref={dropdownRef}>
                                                            <button
                                                                className="removebtn"
                                                                onClick={() => handleRemoveClick(job?.job_ad?.id)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="basic_text">No favorite jobs found.</p>
                                    )}

                                </div>

                            </div>
                        </div>

                        {isMiniScreen &&(
                            <JobSeekerSiderBar
                                dashboardCheck ={false}
                                notificationCounter={notificationCounter}
                            />
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SeachResume;