import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL, STORAGE_URL } from "./config";
import { useNavigate, useLocation } from "react-router-dom";
import EmployerHeader from "./EmployerHeader";
import JobSeekerHeader from "./JobSeekerHeader";
import avatar from "./assets/profile5.png";
import styles from "./assets/css/LoadingComponent.module.css";
import "./assets/css/mycircles.css";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { BsArrowLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "./store/slices/UserSlice";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Helper from "./Helper";
import {RiUserFollowFill, RiUserUnfollowFill} from "react-icons/ri";
import {EmployerSideBars} from "./components/Sidbars/EmpSideBars";
import {JobSeekerSiderBar} from "./components/Sidbars/JobSeekerSiderBar";
import colors from "./colors";

const FollowersFollowingScreen = () => {
    const navigate = useNavigate();
    const [followers, setFollowers] = useState([]);
    const [followings, setFollowings] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("access_token");
    const [showFollowModal, setShowFollowModal] = useState(false);
    const [userToFollow, setUserToFollow] = useState(null);
    const [openTab, setOpenTab] = useState('followers')
    const [notificationCounter, setNotificationCounter] = useState(0)
    const [followerCurrentPage, setFollowerCurrentPage] = useState(1);
    const [followingCurrentPage, setFollowingCurrentPage] = useState(1);
    const followerPerPage = 10;
    const followingPerPage = 10;
    const location = useLocation();
    const { from } = location.state || {};

    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const user_data = useSelector((state) => {
        return state?.user?.user;
    });

    const userable_type = localStorage.getItem('userable_type');
    let headerComponent;
    if (userable_type === 'App\\Models\\Employer') {
        headerComponent = <EmployerHeader
            setNotificationCounter={setNotificationCounter}
        />;
    } else {
        headerComponent = <JobSeekerHeader
            setNotificationCounter={setNotificationCounter}
        />;
    };

    const getUsers = ()=>{
        setLoading(true);
        // Fetch followers and followings data from your API
        axios.get(`${BASE_URL}/followers`, { headers })
            .then((response) => {
                if (response.data.status === 200) {
                    setFollowers(response.data.user.followers);
                    setLoading(false);
                } else if (response.data.status === 401) {
                    navigate("/");
                }
            })
            .catch((error) => {
                console.error('Error fetching followers:', error);
                setLoading(false);
            });

        axios.get(`${BASE_URL}/followings`, { headers })
            .then((response) => {
                if (response.data.status === 200) {
                    setFollowings(response.data.user.followings);
                    setLoading(false);
                } else if (response.data.status === 401) {
                    navigate("/");
                }
            })
            .catch((error) => {
                console.error('Error fetching followings:', error);
                setLoading(false);
            });
    }

    useEffect(() => {
        getUsers()
    }, []);

    // Function to check if a user is following another user
    const isFollowing = (userId) => {
        // Implement your logic to check if the user with userId is being followed by the current user.
        // You might want to compare userId with the list of IDs in the followings array.
        return followings.some((following) => following.id === userId);
    };

    const dispatch = useDispatch();

    const handleFollowUser = (userId) => {
        // Make an API call to follow the user with the provided userId
        // Use axios or your preferred HTTP library to send the request
        setLoading(true)
        axios
            .post(`${BASE_URL}/follow`, { following_id: userId }, { headers })
            .then((response) => {
                // Handle success
                if (response?.data?.status === 200) {
                    let userCopy = user_data;
                    const currentFollowingsCount = userCopy.followings_count
                    userCopy = { ...userCopy, followings_count: currentFollowingsCount + 1 }
                    dispatch(addUser(userCopy))
                    getUsers()
                    setLoading(false)
                    // toast.success('Followed Successfully!', {
                    //     position: toast.POSITION.TOP_CENTER
                    // });
                } else if (response?.data?.status === 401) {
                    navigate("/");
                    setLoading(false)
                } else {
                    // Handle other status codes or responses here
                    console.error("Follow failed:", response);
                    setLoading(false)
                }
            })
            .catch((error) => {
                // Handle error
                // You can display an error message or take any other action
                console.error("Follow failed:", error);
                setLoading(false)
            });
    };

    const handleUnFollowUser = (userId) => {

        setLoading(true)
        axios
            .post(`${BASE_URL}/unfollow`, { following_id: userId }, { headers })
            .then((response) => {
                // Handle success
                if (response?.data?.status === 200) {
                    let userCopy = user_data;
                    const currentFollowingsCount = userCopy.followings_count
                    userCopy = { ...userCopy, followings_count: currentFollowingsCount - 1 }
                    dispatch(addUser(userCopy))
                    getUsers()
                } else if (response?.data?.status === 401) {
                    setLoading(false)
                    navigate("/");
                } else {
                    // Handle other status codes or responses here
                    console.error("Follow failed:", response);
                    setLoading(false)
                }
            })
            .catch((error) => {
                // Handle error
                // You can display an error message or take any other action
                console.error("Follow failed:", error);
                setLoading(false)
            });
    }
    const showFollowUserModal = (userId) => {
        setUserToFollow(userId);
        setShowFollowModal(true);
    };

    const closeFollowUserModal = () => {
        setUserToFollow(null);
        setShowFollowModal(false);
    };

    const handleTab = ()=>{


        if(openTab === "followings"){
            setOpenTab("followers")
        }else{
            setOpenTab("followings")
        }
    }
    useEffect(()=>{}, [openTab])
    function handleUserClick (userId) {
        const from="/follow";
        const user_id=userId;
        navigate("/profile-view", { state: { from,user_id } });
    }

    const handleViewMoreFollower = () => {
        setFollowerCurrentPage(followerCurrentPage + 1);
    };

    const visibleFollowers = followers.slice(
        0,
        followerCurrentPage * followerPerPage
    );

    const handleViewMoreFollowing = () => {
        setFollowingCurrentPage(followingCurrentPage + 1);
    };

    const visibleFollowings = followings.slice(
        0,
        followingCurrentPage * followingPerPage
    );



    return (
        <div>
            {headerComponent}
            <div className="JBmain">
                <div className="container">
                        <div className="RDheadtitle fvthead">
                            <BsArrowLeft  className="AJbackbtn"  role='button' onClick={() => navigate(-1)}></BsArrowLeft>
                            <h2>{user_data?.firstname ? user_data?.firstname + "’s" + " Circle" : "My Circle"}</h2>
                            <span></span>
                        </div>
                    {loading && (
                        <div className={styles.loadingOverlay}>
                            <div className={styles.loadingSpinner}></div>
                        </div>
                    )
                    }
                        <div>

                            <Tabs defaultActiveKey={openTab} id="tabs" className="tabs_custom" onClick={handleTab}>
                                <Tab eventKey="followers" title="Followers" >
                                    <div className="list-group pt-1 followers-list">
                                        {visibleFollowers.length > 0 && visibleFollowers.map((follower) => (
                                            <div key={follower.id} className="list-group-item d-flex align-items-center" style={{padding:'0.3rem'}}>
                                                {Helper.profile_log(follower)}
                                                <div className="ml-2 p-1 flex-grow-1">
                                                    {follower.userable_type === "App\\Models\\JobSeeker" ? (
                                                        <span className="mb-0" role="button" onClick={() => handleUserClick(follower.id)}>
                                                            <strong>{follower.firstname} {follower.lastname}</strong> (JobSeeker)
                                                            {follower.userable.active_for_jobs === "Yes" && <strong><span style={{ color: colors.thirdColor }}> Active For Job</span></strong>}
                                                        </span>
                                                    ) : follower.userable_type === "App\\Models\\Employer" ? (
                                                        <span className="mb-0" role="button" onClick={() => handleUserClick(follower.id)}>
                                                        <strong>{follower.firstname} {follower.lastname}</strong> (Employer)
                                                      </span>
                                                    ) : (
                                                        <span className="mb-0" role="button" onClick={() => handleUserClick(follower.id)}>
                                                        <strong>{follower.firstname} {follower.lastname}</strong>
                                                        </span>
                                                    )}
                                                    {/*<h5 className="mb-0 " role="button"  onClick={() => handleUserClick(follower.id)}> {follower.firstname} {follower.lastname}</h5>*/}
                                                    <p className="mb-0">{follower.current_employee}</p>
                                                </div>
                                                    {isFollowing(follower.id) ?
                                                        (<RiUserFollowFill
                                                            role='button'
                                                            size={24}
                                                            title='Following'
                                                            style={{ color: "#079c15"}}
                                                            onClick={()=>{showFollowUserModal(follower.id)}}><
                                                            /RiUserFollowFill>)
                                                        : (<RiUserUnfollowFill
                                                            role='button'
                                                            title='Follow Back'
                                                            size={24}
                                                            style={{ color: "#e54545" }}
                                                            onClick={()=>{handleFollowUser(follower.id)}}>
                                                        </RiUserUnfollowFill>)}
                                            </div>
                                        ))}
                                        {followers.length === 0 &&(<p className="text-danger text-center">No follower found.</p>)}

                                        {visibleFollowers.length < followers.length && (
                                                 <p
                                                     className='text-center text-primary'
                                                 onClick={handleViewMoreFollower}
                                                 >View More</p>
                                        )}
                                    </div>
                                </Tab>
                                <Tab eventKey="followings" title="Followings">
                                    <div className="list-group pt-1 followings-list">
                                        {visibleFollowings.length > 0 && visibleFollowings.map((following) => (
                                            <div key={following.id} className="list-group-item d-flex align-items-center" style={{padding:'0.3rem'}}>

                                                {Helper.profile_log(following)}
                                                <div className="ml-2 p-1 flex-grow-1">
                                                    {/*<h5 className="mb-0"*/}
                                                    {/*    role="button" onClick={() => handleUserClick(following.id)}>{following.firstname} {following.lastname}</h5>*/}
                                                    {following.userable_type === "App\\Models\\JobSeeker" ? (
                                                        <span className="mb-0" role="button" onClick={() => handleUserClick(following.id)}>
                                                            <strong>{following.firstname} {following.lastname}</strong> (JobSeeker)
                                                            {following.userable.active_for_jobs === "Yes" && <strong><span style={{ color: colors.thirdColor }}> Active For Job</span></strong>}
                                                        </span>
                                                    ) : following.userable_type === "App\\Models\\Employer" ? (
                                                        <span className="mb-0" role="button" onClick={() => handleUserClick(following.id)}>
                                                            <strong>{following.firstname} {following.lastname}</strong>
                                                            <span> (Employer)</span>
                                                          </span>
                                                    ) : (
                                                        <span className="mb-0" role="button" onClick={() => handleUserClick(following.id)}>
                                                            <strong>{following.firstname} {following.lastname}</strong>
                                                          </span>
                                                    )}
                                                    <p className="mb-0">{following.current_employee}</p>
                                                </div>

                                                <RiUserFollowFill
                                                    role='button'
                                                    size={24}
                                                    title='Following'
                                                    style={{ color: "#079c15"}}
                                                    onClick={() => {showFollowUserModal(following.id)}}
                                                >
                                                </RiUserFollowFill>

                                            </div>
                                        ))}
                                        {followings.length === 0 &&(<p className="text-danger text-center">No followings found.</p>)}

                                        {visibleFollowings.length < followings.length && (
                                             <p className='text-center text-primary' onClick={handleViewMoreFollowing}>View More</p>
                                        )}
                                    </div>

                                </Tab>
                            </Tabs>
                        </div>

                </div>

                {userable_type === "App\\Models\\Employer" ? (
                    <EmployerSideBars
                        dashboardCheck ={false}
                        notificationCounter={notificationCounter}
                    />
                ):(
                    <JobSeekerSiderBar
                        dashboardCheck ={false}
                        notificationCounter={notificationCounter}
                    />
                )}
            </div>
            <Modal show={showFollowModal} onHide={closeFollowUserModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Unfollow</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isFollowing(userToFollow)
                        ? "Are you sure you want to unfollow this user?"
                        : "Are you sure you want to follow this user?"}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant={isFollowing(userToFollow) ? "danger" : "primary"}
                        size="sm"
                        onClick={() => {
                            isFollowing(userToFollow)
                                ? handleUnFollowUser(userToFollow)
                                : handleFollowUser(userToFollow);
                            closeFollowUserModal();
                        }}
                    >
                        {isFollowing(userToFollow) ? "Unfollow" : "Follow"}
                    </Button>
                    <Button variant="secondary" size="sm" onClick={closeFollowUserModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>

    );
};

export default FollowersFollowingScreen;
