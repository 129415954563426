import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import logoImage from '../assets/logo4.svg';
import styles from "../assets/css/LoadingComponent.module.css";
import {BsArrowLeft} from "react-icons/bs";
const ReqForSubscription = ()=>{

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mob_number: '',
        no_view:'',
        no_users:'',
        address: '',
        no_jobs:''
    })
    const [errors, setErrors] = useState({
        fullName: '',
        email: '',
        mob_number: '',
        no_view:'',
        no_users:'',
        address: '',
        no_jobs:''
    });
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const handleLogoClick = () => {
        navigate("/employer-login");

    }

    const checkValidation = ()=>{

        let hasError = false;
        const newErrors = { ...errors };
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const numberPattern = /^[0-9]*$/;

        if (!formData.fullName) {
            newErrors.fullName = 'Full name is required';
            hasError = true;
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
            hasError = true;
        }

        if (!formData.mob_number) {
            newErrors.mob_number = 'Mobile number is required';
            hasError = true;
        }

        if (!formData.address) {
            newErrors.address = 'Address is required';
            hasError = true;
        }
        if (!formData.no_view) {
            newErrors.no_view = 'Number of view is required';
            hasError = true;
        }
        if (!formData.no_users) {
            newErrors.no_users = 'No of users is required';
            hasError = true;
        }
        if (!formData.no_jobs) {
            newErrors.no_jobs = 'No of jobs is required';
            hasError = true;
        }
        if (!emailPattern.test(formData.email)) {
            newErrors.email = 'Please enter valid email';
            hasError = true;
        }

        if (!numberPattern.test(formData.no_view)) {
            newErrors.no_view = 'Please enter Valid number';
            hasError = true;
        }
        if (!numberPattern.test(formData.no_users)) {
            newErrors.no_users = 'Please enter Valid number';
            hasError = true;
        }
        if (!numberPattern.test(formData.no_jobs)) {
            newErrors.no_jobs = 'Please enter Valid number';
            hasError = true;
        }


        setErrors(newErrors);

        return hasError;
    }

    const handleChange = (e) => {

        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const SendQuote = ()=>{
        // setLoading(true)
        let check = checkValidation();
        if(check){
            return false;
        }
        console.log(check, 'check')
         console.log(formData, 'formData')
    }

    return(
        <div className='d-flex justify-content-center align-items-center flex-column' style={{height:'100vh'}}>
            <div className="position-absolute top-0 start-0 mt-5 m-3 d-flex align-items-center">
                <BsArrowLeft onClick={()=>navigate(-1)} className='AJbackbtn' role='button'></BsArrowLeft>
                <p className='mb-0 ml-2'>&nbsp;Back</p>
            </div>
            <div className="text-center">
                <img onClick={handleLogoClick} className='mb-4' src={logoImage} alt="Logo"/>
                <p className='text-center mb-0'>To become a part of the Rezmaze community,
                    you'll need to start by registering on our platform. Afterward,
                    you can explore and choose from a range of subscription options,
                    all conveniently available for purchase on our website.</p>
            </div>
        </div>






    )
}

export default ReqForSubscription;