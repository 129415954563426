import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from '../src/assets/css/LoadingComponent.module.css';
import { BsArrowLeft } from 'react-icons/bs';
import {BASE_URL} from "./config";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import JobSeekerHeader from "./JobSeekerHeader";
import Helper from "./Helper";
import styles from "./assets/css/favoriteResumes.module.css";
import {JobSeekerSiderBar} from "./components/Sidbars/JobSeekerSiderBar";

function JobsApplied() {
    const location = useLocation();
    const navigate = useNavigate();
    const { defaultfrom } = location.state || {}; // Get the passed state data
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("access_token");
    const [appliedJobs, setAppliedJobs] = useState([]);
    const jobSeekerId = localStorage.getItem("jobSeekerId");
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [jobId, setJobId] = useState("");
    const [notificationCounter, setNotificationCounter] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const jobsPerPage = 10;

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const fetchAppliedJobs = async () => {
        setLoading(true);
        try {
            // Fetch favorite resumes using the provided endpoint and payload
            const response = await fetch(`${BASE_URL}/job_ad/get_applied_jobs`, {
                method: "POST",
                headers: {
                    ...headers,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    job_seeker_id: jobSeekerId, 
                }),
            });

            const data = await response.json();
            console.log(data.applied_jobs)
            if (data.status === 200) {
                setAppliedJobs(data.applied_jobs);
            }
            else if (data.status==404) {
                setAppliedJobs([]);
            }
            else if (data.status==401) {
                navigate('/');
            }
        } catch (error) {
            console.error("Error fetching applied jobs:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAppliedJobs();
    }, []);

    const handleJobClick = (jobId) => {
        const from="/applied-jobs";
        navigate('/post_a_job_preview', { state: { jobId,from} });

    };
    const handleDeleteJobClick = (jobId) => {
        setIsDeleteModalOpen(true);
        setJobId(jobId);
    };
    const handleCloseModal =()=>{
        setIsDeleteModalOpen(false)
    }
    const handleConfirmDeleteJob = async () => {
        setLoading(true);
        const url = `${BASE_URL}/fav_job/${jobId}`
        const method = 'DELETE';
        const payload = {
            job_ad_id: jobId,
            job_seeker_id: parseInt(jobSeekerId)
        };
        try {
            const response = await axios({
                method: method,
                url: url,
                headers: headers,
                data:payload
            });

            const data = response.data;
            console.log(data)
            if(data.status==200){
                fetchAppliedJobs();
                setIsDeleteModalOpen(false)
            }
            else if(data.status==401){
                navigate("/")
            }
            // Handle the response data here
        } catch (error) {
            // Handle errors here
        }
    };

    const handleCompanyImg = (job)=>{

        let img = ""
        job?.job_ad?.employer?.users.map((user)=>{

            if(job?.job_ad?.employer?.id === user?.userable_id){

                img = Helper.profile_log(user)

            }
        })

        return img;

    }

    const handleViewMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const visibleJobs = appliedJobs.slice(
        0,
        currentPage * jobsPerPage
    );

    return (
        <div>
            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            <JobSeekerHeader
                setNotificationCounter={setNotificationCounter}
            />
            <div className="container">
                <div className=".job-JB-main">
                    <div className="JBmain">
                            <div className="RDheadtitle fvthead w-100">
                                <BsArrowLeft
                                    className='AJbackbtn'
                                    role='button'
                                    onClick={() => navigate(-1)}>
                                </BsArrowLeft>
                                <h2>Jobs Applied</h2>
                         
                        </div>

                        <div className={styles.resumeContent}>
                            <div className={styles.resumeContent_scroll}>
                                {visibleJobs.length > 0 &&  visibleJobs.map((job) =>
                                    (
                                        <div key={job.id}
                                             onClick={() => handleJobClick(job.job_ad.id)}
                                             className={`${styles.resume_content} d-flex gap-2 p-2 align-items-center`}>
                                            {handleCompanyImg(job)}

                                            <div className='d-flex flex-column'>
                                                <p className="m-0">
                                                    <strong>{job.job_ad.job_title}</strong></p>
                                                <small className={styles.resume_content_text}>
                                                    <strong>Interview:</strong> {job.job_ad.interview}
                                                </small>
                                                <small className={styles.resume_content_text}>
                                                    <strong>Job Mode:</strong> {job.job_ad.job_mode}
                                                </small>
                                                <small className={styles.resume_content_text}>
                                                    <strong>Status:</strong> {job?.job_application_status?.name === "Rejected" ? (
                                                    <span>Close</span>
                                                ) : (
                                                    <span>{job?.job_application_status?.name}</span>
                                                )}
                                                </small>
                                            </div>
                                        </div>
                                    )
                                )}

                                {visibleJobs.length < appliedJobs.length && (
                                    <p className="text-center m-0 p-2 text-primary" role="button" onClick={handleViewMore}>View More</p>
                                )}
                                {
                                    appliedJobs.length == 0 &&(
                                        <p className="text-center m-0 p-2 text-danger" role="button" >No Applied jobs found!</p>
                                    )
                                }

                            </div>
                        </div>
                        {/*<div className="table-responsive">*/}

                        {/*<table className="table">*/}
                        {/*    <thead>*/}
                        {/*    <tr>*/}
                        {/*        <th>Job Title</th>*/}
                        {/*        <th>Location</th>*/}
                        {/*        <th>Relocation</th>*/}
                        {/*        <th>Interview</th>*/}
                        {/*        <th>Job Mode</th>*/}
                        {/*        <th>Compensation</th>*/}
                        {/*        <th>Applied Date</th>*/}
                        {/*        <th>Status</th>*/}
                        {/*        <th>Action</th>*/}

                        {/*    </tr>*/}
                        {/*    </thead>*/}
                        {/*    <tbody>*/}
                        {/*    {appliedJobs.map((job) => (*/}
                        {/*        <tr key={job.id}>*/}
                        {/*            <td>{job.job_ad.job_title}</td>*/}
                        {/*            <td>{job.job_ad.location}</td>*/}
                        {/*            <td>{job.job_ad.relocation}</td>*/}
                        {/*            <td>{job.job_ad.interview}</td>*/}
                        {/*            <td>{job.job_ad.job_mode}</td>*/}
                        {/*            <td>{job.job_ad.compensation}</td>*/}
                        {/*        <td>{Helper.changeDateFormat(job?.application_date)}</td>*/}

                        {/*            <td><button className="AJstatus_btn" disabled>{job?.job_application_status?.name}</button></td>*/}
                        {/*            <td>*/}
                        {/*                <div className="editjobsicon">*/}
                        {/*                    <i*/}
                        {/*                        className="fa-solid fa-file"*/}
                        {/*                        style={{ cursor: "pointer" }}*/}
                        {/*                        data-bs-toggle="tooltip"*/}
                        {/*                        onClick={() => handleJobClick(job.job_ad.id)}*/}
                        {/*                        data-bs-placement="top"*/}
                        {/*                        title="View Job"*/}
                        {/*                    ></i>*/}

                        {/*                </div>*/}
                        {/*            </td>*/}
                        {/*            <Modal show={isDeleteModalOpen} onHide={()=>handleCloseModal()} centered>*/}
                        {/*                <Modal.Header closeButton>*/}
                        {/*                    <Modal.Title>Delete Resume</Modal.Title>*/}
                        {/*                </Modal.Header>*/}
                        {/*                <Modal.Body>Are you sure to delete this Resume!</Modal.Body>*/}
                        {/*                <Modal.Footer>*/}
                        {/*                    <Button variant="secondary" onClick={()=>handleCloseModal()}>*/}
                        {/*                        Close*/}
                        {/*                    </Button>*/}
                        {/*                    <Button variant="danger" onClick={() =>handleConfirmDeleteJob()}>*/}
                        {/*                        Delete*/}
                        {/*                    </Button>*/}
                        {/*                </Modal.Footer>*/}
                        {/*            </Modal>*/}
                        {/*        </tr>*/}
                        {/*    ))}*/}
                        {/*    </tbody>*/}
                        {/*</table>*/}
                        {/* </div>*/}
                    </div>
                </div>
            </div>
            <JobSeekerSiderBar
                dashboardCheck ={false}
                notificationCounter={notificationCounter}
            />
        </div>
    )
}

export default JobsApplied;
