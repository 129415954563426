import React, {useEffect, useState} from "react";
import {BsCCircleFill, BsHeart, BsHeartFill, BsSliders2, BsThreeDots} from "react-icons/bs";
import {SiSimpleanalytics} from "react-icons/si";
import {Button, Form, Modal} from "react-bootstrap";
import {AiOutlineClose, AiOutlineHome, AiOutlineSearch, AiTwotoneHome} from "react-icons/ai";
import avatar from "../../assets/profile5.png";
import {STORAGE_URL} from "../../config";
import Helper from "../../Helper";
import {CustomDivider} from "./SideBarContent/Divider";
import {ActiveForJob} from "./SeekerSideBarContent/ActiveForJob";
import {AppliedJobs} from "./SeekerSideBarContent/AppliedJobs";
import {FavoriteJobs} from "./SeekerSideBarContent/FavoriteJobs";
import {JobAnalytics} from "./SeekerSideBarContent/JobAnalytics";
import {MyCircle} from "./SeekerSideBarContent/MyCircle";
import {SeekerInfo} from "./SeekerSideBarContent/SeekerInfo";
import {SeekerResume} from "./SeekerSideBarContent/SeekerResume";
import {CustomModalStyle} from "../CustomModalStyle";
import {TbBellRinging2, TbBellRinging2Filled} from "react-icons/tb";
import {CgProfile} from "react-icons/cg";
import {BsCCircle} from "react-icons/bs";
import {useLocation, useNavigate} from "react-router-dom";
import {BiSearch, BiSolidSearch} from "react-icons/bi";
import {RiUserShared2Fill, RiUserShared2Line} from "react-icons/ri";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import styles from "../../assets/css/LoadingComponent.module.css";
import {BASE_URL} from "../../config";
import {handleActiveForJob} from "../../store/slices/JobSeekerData";

export const JobSeekerSiderBar = ({
                                      jobSeeker,

                                      handleEditResumeClick,
                                      showResumeDropdown,
                                      containerRef,
                                      handleResumeClick,
                                      createResume,
                                      handleConfirmDeleteResume,
                                      handleResumeCloseModal,
                                      isResumeModalOpen,
                                      handleDeleteResumeClick,
                                      handleResumeOptionClick,


                                 })=>{

    const [showAnalytics, setShowAnalytics] = useState(false)
    const [showMoreInfo, setShowMoreInfo] = useState(false)
    const [loading, setLoading] = useState(false);
    const initialActiveForJobs = jobSeeker?.job_seeker?.active_for_jobs === "Yes" ? true : false;
    const [isActiveForJobs, setIsActiveForJobs] = useState(initialActiveForJobs);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch();
    const currentUrl = location.pathname;

    const user_data = useSelector((state) => {
        return state?.user?.user;
    });

    const jobSeekerState =  useSelector((state) => {
        return state?.jobSeeker?.jobseeker;
    });

    const activeForJob =  useSelector((state) => {
        return state?.jobSeeker?.activeForJob;
    });


    let token = localStorage.getItem("access_token");
    const jobSeekerId = localStorage.getItem("jobSeekerId");
    let headers = {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    };


    useEffect(() => {
        setIsActiveForJobs(jobSeeker?.job_seeker?.active_for_jobs === "Yes" ? true : false);
    }, [jobSeeker?.job_seeker?.active_for_jobs]);

    const handleCompanyImg = (job)=>{
        let img = ""
        job?.job_ad?.employer?.users.map((user)=>{
            if(job?.job_ad?.employer?.id === user?.userable_id){
                img = Helper.profile_log(user)
            }
        })

        return img;

    }

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const handleProceed = () => {
        // Perform any action required after user proceeds
        setIsActiveForJobs(!isActiveForJobs);
        setShowConfirmationModal(false);
        // Call the API function here after user proceeds.
        callApiActiveForJobs(isActiveForJobs ? "No" : "Yes");
    };
    const handleToggle = () => {
        setShowConfirmationModal(true);
    };

    const callApiActiveForJobs = (isActiveForJobsValue) => {
        setLoading(true);
        const url = `${BASE_URL}/job_seekers/${jobSeekerId}/active_for_jobs`;
        const payload = {
            active_for_jobs: isActiveForJobsValue,
        };
        axios
            .put(url, payload, { headers })
            .then((response) => {
                setLoading(false);
                // Handle the API response if needed.
                console.log(response.data);
                dispatch(handleActiveForJob(isActiveForJobsValue))
            })
            .catch((error) => {
                // Handle errors if any.
                console.error("Error calling API:", error);
            });
    };

    const createUpdateProfile = () => {
        navigate("/create_profile");
    };
    const handleProfileClick = () => {
        navigate("/create_profile");
    };
    const viewAllAppliedJobsHandler = () => {
        const defaultfrom = "/job_seeker_dashboard";
        navigate("/applied-jobs", {
            state: { defaultfrom } // Pass the favoriteResumes state
        });
    };

    const handleFollowFollowing = () => {

        const from='/job_seeker_dashboard';
        navigate("/follow", { state: { from } });
    }
    const showMoreInfoModal=()=>{
        setShowMoreInfo(true)
    }
    const showAnalyticModal=()=>{
        setShowAnalytics(true)
    }
    const closeMoreInfo = ()=>{
        setShowMoreInfo(false)
    }

    const closeAnalytics = ()=>{
        setShowAnalytics(false)
    }

    const showSeekerDashBoard = ()=>{
        navigate("/job_seeker_dashboard")
    }

    const handleAppliedFavJobClick = (jobId) => {
        var from='/job_seeker_dashboard';
        navigate('/post_a_job_preview', { state: { jobId,from} });

    }

    const showFavoriteJobs = ()=>{
        navigate('/fav-jobs')
    }
    const showSearchJobs = ()=>{
        navigate('/search_job')
    }
    const showAllNotication = ()=>{
        navigate('/AllNotifications')
    }
    const showMyProfile = ()=>{
        navigate('/my-profile-view')
    }
    const showFollower = ()=>{
        navigate('/follow');
    }

    return(
        <>
            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}
            <div className="emp_sidebar">

                <div className="d-flex justify-content-around gap-4">
                    <div onClick={showSeekerDashBoard} className='d-flex align-items-center flex-column'>

                        {currentUrl === '/job_seeker_dashboard'?
                            (<AiTwotoneHome className='blue_color' size={18} role='button' title="home"></AiTwotoneHome>):
                            ( <AiOutlineHome size={18} role="button" title="Home"></AiOutlineHome>)}

                        <small className={`sidebar_content 
                        ${currentUrl === '/job_seeker_dashboard'? 'blue_color':''}`}>Home</small>

                    </div>
                    <div onClick={showFavoriteJobs} className='d-flex align-items-center flex-column'>

                        {currentUrl === '/fav-jobs'?(
                            <BsHeartFill className='blue_color' size={18} role='button' title="Favorite"></BsHeartFill>
                        ):(<BsHeart size={18} role="button" title="Favorite"></BsHeart>)}

                        <small className={`sidebar_content 
                        ${currentUrl === '/fav-jobs'? 'blue_color':''}`}>Favorite</small>

                    </div>
                    <div onClick={showSearchJobs} className='d-flex align-items-center flex-column'>

                        {currentUrl === '/search_job'?(
                            <BiSolidSearch  className='blue_color' size={18} role="button" title="Search"></BiSolidSearch>
                        ):(

                            <BiSearch size={18} role="button" title="Search"></BiSearch>
                        )}

                        <small className={`sidebar_content 
                             ${currentUrl === '/search_job'? 'blue_color':''}`}>Search</small>


                    </div>
                    <div onClick={showFollower} className='d-flex align-items-center flex-column'>
                        {currentUrl === '/follow'?(
                            <RiUserShared2Fill className='blue_color' size={18} role='button' title="My Circle"></RiUserShared2Fill>
                        ):(
                            <RiUserShared2Line size={18} role="button" title="My Circle"></RiUserShared2Line>
                        )}

                        <small className={`sidebar_content 
                             ${currentUrl === '/follow'? 'blue_color':''}`}>My Circle</small>
                    </div>
                    {/*{dashboardCheck ? (*/}


                            <div onClick={showMoreInfoModal} className='d-flex align-items-center flex-column'>
                                <BsSliders2 size={18} role="button" title="More Information"></BsSliders2>
                                <small className='sidebar_content'>Analytics</small>
                            </div>

                    {/*) : (*/}
                    {/*    <>*/}
                    {/*        <div onClick={showAllNotication} className='d-flex align-items-center flex-column'>*/}
                    {/*            <div className="icon-container1">*/}
                    {/*                {currentUrl === '/AllNotifications'?(*/}
                    {/*                    <TbBellRinging2Filled className='blue_color' size={18} role='button' title="notifications"></TbBellRinging2Filled>*/}
                    {/*                ):(*/}
                    {/*                    <TbBellRinging2 title='notifications' size={18}></TbBellRinging2>*/}
                    {/*                )}*/}
                    {/*                {notificationCounter > 0 && (*/}
                    {/*                    <small className="number-badge1"></small>*/}
                    {/*                )}*/}
                    {/*            </div>*/}
                    {/*            <small className={`sidebar_content */}
                    {/*         ${currentUrl === '/AllNotifications'? 'blue_color':''}`}>Notifications</small>*/}
                    {/*        </div>*/}
                    {/*        /!*<div onClick={showMyProfile} className='d-flex align-items-center flex-column'>*!/*/}
                    {/*        /!*    <CgProfile title='profile' className="icon_color" size={18}></CgProfile>*!/*/}
                    {/*        /!*    <small className='sidebar_content'>Profile</small>*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*    </>*/}
                    {/*)}*/}
                </div>

            </div>

            {/* More Info Modal*/}
            <Modal show={showMoreInfo} onHide={closeMoreInfo} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title >User Detail</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeMoreInfo}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div className="p-1">

                      <SeekerInfo
                          jobSeeker={jobSeekerState}
                          handleProfileClick={handleProfileClick}
                          createUpdateProfile={createUpdateProfile}
                      />
                        <CustomDivider />
                        <ActiveForJob
                            handleToggle={handleToggle}
                            showConfirmationModal={showConfirmationModal}
                            handleCloseConfirmationModal={handleCloseConfirmationModal}
                            handleProceed={handleProceed}
                            isActiveForJobs={isActiveForJobs}
                            jobSeeker={jobSeekerState}
                        />

                        <CustomDivider />

                        {/*<SeekerResume*/}
                        {/*    jobSeeker={jobSeeker}*/}
                        {/*    handleConfirmDeleteResume={handleConfirmDeleteResume}*/}
                        {/*    handleResumeCloseModal={handleResumeCloseModal}*/}
                        {/*    isResumeModalOpen={isResumeModalOpen}*/}
                        {/*    handleDeleteResumeClick={handleDeleteResumeClick}*/}
                        {/*    handleEditResumeClick={handleEditResumeClick}*/}
                        {/*    showResumeDropdown={showResumeDropdown}*/}
                        {/*    handleResumeOptionClick={handleResumeOptionClick}*/}
                        {/*    containerRef={containerRef}*/}
                        {/*    handleResumeClick={handleResumeClick}*/}
                        {/*    createResume={createResume}*/}
                        {/*/>*/}
                        {/*<CustomDivider />*/}
                        <AppliedJobs
                            jobSeeker={jobSeekerState}
                            handleAppliedFavJobClick={handleAppliedFavJobClick}
                            handleCompanyImg={handleCompanyImg}
                            viewAllAppliedJobsHandler={viewAllAppliedJobsHandler}
                        />
                        <CustomDivider />
                        {/*<FavoriteJobs*/}
                        {/*    jobSeeker={jobSeeker}*/}
                        {/*    handleConfirmDeleteJob={handleConfirmDeleteJob}*/}
                        {/*    showFavJobDropdown={showFavJobDropdown}*/}
                        {/*    openFavJobDropdownIndex={openFavJobDropdownIndex}*/}
                        {/*    handleJobOptionClick={handleJobOptionClick}*/}
                        {/*    handleAppliedFavJobClick={handleAppliedFavJobClick}*/}
                        {/*    handleCompanyImg={handleCompanyImg}*/}
                        {/*    viewAllResumeHandler={viewAllResumeHandler}*/}
                        {/*/>*/}

                        <JobAnalytics
                            jobSeeker={jobSeekerState}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            {/*Analytics Modal*/}
            <Modal show={showAnalytics} onHide={closeAnalytics} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title >Analytics</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeAnalytics}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div className='p-1'>

                        <CustomDivider />
                       <MyCircle
                           handleFollowFollowing={handleFollowFollowing}
                           user_data={user_data}
                       />
                        <CustomDivider />

                    </div>
                </Modal.Body>
            </Modal>

            <CustomModalStyle />

        </>
    )
}