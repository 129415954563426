import React, { useState } from "react";
import logoImage from "../src/assets/logo4.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import "../src/assets/css/header.css";
import "../src/assets/css/styles.css";
import UserFetch from "./UserFetch";
import { useDispatch } from "react-redux";
import {addUser, setResumeStatus} from "./store/slices/UserSlice";
import { useEffect } from "react";
import BASE_URL from "./config";
import axios from "axios";
import styles from "./assets/css/LoadingComponent.module.css";
import {useMediaQuery} from "react-responsive";
import {MainHeader, MiniHeader, MobileHeader} from "./components/Header/Header";
import { seeker_variable } from "./components/Header/variables"
import {Dropdown} from "react-bootstrap";
import colors from "./colors";
import { RxResume } from "react-icons/rx";
import {TbBellRinging2} from "react-icons/tb";
import {VideoResumeModal} from "./components/VideoResume/VideoResumeModal";
import {ToastContainer, toast} from 'react-toastify';
import {GrDocumentVideo} from "react-icons/gr";

function JobSeekerHeader({
                           setNotificationCounter
                         }) {
  const [notifications, setNotifications] = useState([]);
  const [serverTime, setServerTime] = useState('')
  const [unreadCount, setUnreadCount] = useState(0)
  const [showNotifcation, setShowNotifcation] = useState(false)
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [resume, setResume] = useState(null)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(0)
  const token = localStorage.getItem("access_token"); // Get the token from localStorage
  const isTablet = useMediaQuery({ query: '(max-width: 930px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const headers = {
    Authorization: `Bearer ${token}`, // Set the Authorization header with the token
  };
  const resumeStatus = useSelector((state) =>  state.user.resumeStatus);
  const uploadAResume = () => {
    // console.log(job_seeker_data);
    if (job_seeker_data) {
      if (job_seeker_data.resumes.length == 0 || !resumeStatus) navigate("/post-resume");
      else {
        var resumeId = job_seeker_data.resumes[0].id;
        var from='/job_seeker_dashboard';
        navigate("/edit_resume", { state: { resumeId,from } });
      }
    }
  };

  const job_seeker_data = useSelector((state) => {
    return state.user.user;
  });

  useEffect(() => {
    // Fetch notifications from the API
    axios
        .get(`${BASE_URL}/notifications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if(response?.data?.notifications){
            setNotifications(response?.data?.notifications);
            let count = 0
            response?.data?.notifications?.map((notif)=>{
              if(notif?.read === 0){
                count++;
              }
            })
            // setNotificationCounter(count)
            setUnreadCount(count)
            setServerTime(response?.data?.server_time);
          }

        })
        .catch((error) => {
          console.error('Error fetching notifications:', error);
        });
  }, [token]); // Include any dependencies that trigger the fetch

  useEffect(()=>{

  }, [count])
  useEffect(  () => {
    if (count === 0) {

     axios
        .get(`${BASE_URL}/get-user-data`, {
          headers,
        })
        .then((res) => {
          setCount(1);
          if (res?.data?.user?.resumes.length > 0) {
              dispatch(setResumeStatus(true))
          } else {
            dispatch(setResumeStatus(false))
          }

        })
        .catch((err) => console.log(err));
    }
  }, []);
  if (!job_seeker_data) {

    UserFetch()
      .then((response) => {
        const user_data = response.data.user;
        // console.log(user_data, "user data");
        dispatch(addUser(user_data));
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });
  }


  const signInClick = () => {

    let keysToExclude = ['email', 'password', 'userName', 'logo', 'userable_type'];
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (!keysToExclude.includes(key)) {
        localStorage.removeItem(key);
      }
    }

    navigate("/employer-login");
  };
  const viewProfileClick = () => {
    navigate("/my-profile-view");
  };
  const jobSeach = () => {
    navigate("/search_job");
  };
  const handleLogoClick = () => {
    navigate("/job_seeker_dashboard");
  };
  const handleProfileClick = () => {
    navigate("/create_profile");
  };
  const viewAllAppliedJobsHandler = () => {
    const defaultfrom = "/job_seeker_dashboard";
    navigate("/applied-jobs", {
      state: { defaultfrom } // Pass the favoriteResumes state
    });
  };

  const showJobSearchHandler = ()=>{
    const defaultfrom = "/job_seeker_dashboard";
    navigate("/search_job", {
      state: { defaultfrom } // Pass the favoriteResumes state
    });
  }

  const showFavoriteHandler = ()=>{
    const defaultfrom = "/job_seeker_dashboard";
    navigate("/fav-jobs", {
      state: { defaultfrom } // Pass the favoriteResumes state
    });
  }


  const jobSeekerState =  useSelector((state) => {
    return state?.jobSeeker?.jobseeker;
  });

  useEffect(()=>{
    setResume(jobSeekerState?.job_seeker?.resumes[0])
  }, [jobSeekerState])

  const handleResumeClick = () => {

    let resumeId = jobSeekerState.job_seeker.resumes[0]?.id
    let from='/job_seeker_dashboard';
    navigate("/resumes", { state: {resumeId, from  }});
  };

  const showNoficationModal = ()=>{
    setShowNotifcation(!showNotifcation)
  }
  const handleShowVideoResume =()=>{
    setIsVideoModalOpen(true)
  }

  return (

    <div className="nav bg-white ">
      {count === 0 && (<div className={styles.loadingOverlay}>
        <div className={styles.loadingSpinner}></div>
      </div>)}
      <ToastContainer/>

      {/* {console.log(buttonCheck, resumeCheck, "buttonCheck")} */}
      <div className="container-fluid">
        <div className="row navpad">
          <div className="d-flex justify-content-between align-items-center">
            <div className="col-lg-4 logosearch ">
              <a href="javascript:void(0)" onClick={handleLogoClick}>
                {" "}
                <img className="main_image" src={logoImage} />
              </a>
            </div>
            <div className='d-flex align-items-center'>
            <Dropdown>
              <Dropdown.Toggle
                  variant="link"
                  id="dropdown-basic-button"
                  style={{ color: colors.primaryColor, cursor: 'pointer' }}
              >
                { (resume?.resume_availability_video_file || resume?.resume_availability_file_path) && (
                <GrDocumentVideo  size={24} />
                )}
              </Dropdown.Toggle>
              {resume && (
                  <Dropdown.Menu>
                    {resume?.resume_availability_video_file && (
                      <Dropdown.Item
                          onClick={handleShowVideoResume}
                      >
                        Video Resume
                      </Dropdown.Item>
                    )}
                    {resume?.resume_availability_file_path && (
                    <Dropdown.Item
                        onClick={handleResumeClick}
                    >
                      Document Resume
                    </Dropdown.Item>
                    )}

                  </Dropdown.Menu>
              )}

            </Dropdown>
            <style>
              {`
                .dropdown-toggle::after {
                 display:none;
                }
                                `}
            </style>

            <div className="icon-container"
                 onClick={showNoficationModal}
            >
              <TbBellRinging2 className="icon_color" size={24}></TbBellRinging2>

              {unreadCount > 0 &&(
                  <small className="number-badge" dangerouslySetInnerHTML={{ __html: unreadCount > 99 ? '99<sup>+</sup>' : unreadCount }}></small>

              )}
            </div>
            {isTablet?

                (isMobile?(
                    <MobileHeader
                        notifications={notifications}
                        showNotifcation={showNotifcation}
                        setShowNotifcation={setShowNotifcation}
                        serverTime={serverTime}
                        viewProfileClick={viewProfileClick}
                        signInClick={signInClick}
                        showJobSearchHandler={showJobSearchHandler}
                        handleProfileClick={handleProfileClick}
                        showFavoriteHandler={showFavoriteHandler}
                        viewAllAppliedJobsHandler={viewAllAppliedJobsHandler}
                        variables={seeker_variable}

                    />
                ):(
                    <MiniHeader
                        notifications={notifications}
                        showNotifcation={showNotifcation}
                        setShowNotifcation={setShowNotifcation}
                        serverTime={serverTime}
                        viewProfileClick={viewProfileClick}
                        signInClick={signInClick}
                        showJobSearchHandler={showJobSearchHandler}
                        handleProfileClick={handleProfileClick}
                        showFavoriteHandler={showFavoriteHandler}
                        viewAllAppliedJobsHandler={viewAllAppliedJobsHandler}
                        uploadAResume={uploadAResume}
                        resumeStatus={resumeStatus}
                        jobSeach={jobSeach}
                        variables={seeker_variable}
                    />
                )
              )

                :(
                <MainHeader
                    notifications={notifications}
                    showNotifcation={showNotifcation}
                    setShowNotifcation={setShowNotifcation}
                    serverTime={serverTime}
                    viewProfileClick={viewProfileClick}
                    signInClick={signInClick}
                    showJobSearchHandler={showJobSearchHandler}
                    handleProfileClick={handleProfileClick}
                    showFavoriteHandler={showFavoriteHandler}
                    viewAllAppliedJobsHandler={viewAllAppliedJobsHandler}
                    uploadAResume={uploadAResume}
                    resumeStatus={resumeStatus}
                    jobSeach={jobSeach}
                    variables={seeker_variable}
                />
            )}
            </div>
            <VideoResumeModal
                isVideoModalOpen={isVideoModalOpen}
                setIsVideoModalOpen={setIsVideoModalOpen}
                resume={resume}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default JobSeekerHeader;
