import { createSlice } from "@reduxjs/toolkit";

const jobSeekerSlice = createSlice({
    name: "jobseeker",
    initialState: {
        jobseeker: null,
        activeForJob: false
    },
    reducers: {
        addJobSeeker(state, action) {
            state.jobseeker = action.payload; // Corrected syntax

        },
        handleActiveForJob(state, action) {
            state.activeForJob = action.payload
        }
    },
});

export default jobSeekerSlice.reducer;
export const { addJobSeeker, handleActiveForJob } = jobSeekerSlice.actions;
