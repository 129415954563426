import React, {useEffect, useState} from "react";
import styles from "./assets/css/ProfileView.module.css";
import profile_Header_Img from "./assets/profile_header.png";
import profile_detail_icon from "./assets/detail_pv_icon.svg";
import avatarImage from "./assets/profile5.png";
import headerIcone1 from "../src/assets/headericone1.svg";
import loading_component from '../src/assets/css/LoadingComponent.module.css';
import axios from "axios";
import BASE_URL, {STORAGE_URL} from "./config";
import EmployerHeader from "./EmployerHeader";
import JobSeekerHeader from "./JobSeekerHeader";
import {useLocation, useNavigate} from "react-router-dom";
import MyPosts from "./MyPosts";
import {EmployerSideBars} from "./components/Sidbars/EmpSideBars";
import {JobSeekerSiderBar} from "./components/Sidbars/JobSeekerSiderBar";
import {toast} from "react-toastify";
import UserFetch from "./UserFetch";
import {useDispatch} from "react-redux";
import {addUser} from "./store/slices/UserSlice";

const ProfileView = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notificationCounter, setNotificationCounter] = useState(0)
  const navigate = useNavigate()
  const location = useLocation();
  const { from } = location.state || {};
  const { user_id } = location.state || {};
  let logged_in_user_id = localStorage.getItem('userId'); // Get the user ID from localStorage

  const token = localStorage.getItem("access_token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let requestUrl = `${BASE_URL}/user-profile`;
        // Check if user_id exists in location.state, and include it in the request URL if it does
         if (user_id) {
          requestUrl += `/${user_id}`;
        }
        const response = await axios.get(requestUrl, {
          headers,
        });
        setUserData(response.data.user);
        console.log(response.data.user)
        if(response.data.status==200){
          setLoading(false);
        }
        else if(response.data.status===401){
          navigate("/");
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching user profile:', error);
      }
    };

    fetchData();
  }, []);
  const userable_type = localStorage.getItem('userable_type');
  let headerComponent;
  if (userable_type === 'App\\Models\\Employer') {
    headerComponent = <EmployerHeader
        setNotificationCounter={setNotificationCounter}
    />;
  } else {
    headerComponent = <JobSeekerHeader
        setNotificationCounter={setNotificationCounter}
    />;
  }
  const handleEditProfile = () => {
    navigate("/create_profile")

  }

  // Inside your ProfileView component, after you have fetched the userData
  const followers = userData?.followers || [];

// Display only the first three followers
  const firstThreeFollowers = followers.slice(0, 3);

// Create a state variable to control the display of all followers
  const [showAllFollowers, setShowAllFollowers] = useState(false);

  const toggleShowAllFollowers = () => {
    setShowAllFollowers(!showAllFollowers);
  };
  function handleUserClick (userId) {
    let from="";
    const userable_type = localStorage.getItem("userable_type");
    if (userable_type === "App\\Models\\Employer") {
      from = "/employer_dashboard";
    } else {
      from = "/job_seeker_dashboard";
    }
    const user_id=userId;
    navigate("/profile-view", { state: { from,user_id } });
  }
  const handleFollowFollowing = () => {
    let from="/profile-view";
      navigate("/follow", { state: { from } });
  }
  //Location code
  const generateNonUsLocation = () => {
    const country =  userData?.userable?.country?.name || '';
    const state =  userData?.userable?.state || '';
    const city = userData?.userable?.city || '';

    return `${country}, ${state}, ${city}`;
  };
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Check if the logged-in user is following the profile user
    const isUserFollowing = followers.some(follower => follower?.userable_id === user_id);
    setIsFollowing(!isUserFollowing);
  }, [followers, user_id]);
  const handleFollowUnfollow = () => {
    setLoading(true)
    if (isFollowing) {
      // Logic to unfollow
      axios
          .post(`${BASE_URL}/unfollow`, { following_id: user_id }, { headers })
          .then((response) => {
            // Handle success
            if (response?.data?.status === 200) {
              setLoading(false)
              setIsFollowing(!isFollowing);

              //Update Redux
              UserFetch()
                  .then((response) => {
                    const user_data = response.data.user;
                    // console.log(user_data, "user data");
                    dispatch(addUser(user_data));
                  })
                  .catch((error) => {
                    setLoading(false)
                    console.error("Error fetching user:", error);
                  });

              toast.success('Unfollowed Successfully!', {
                position: toast.POSITION.TOP_CENTER
              });
            }
          })
          .catch((error) => {
            setLoading(false)

          });
    } else {
      // Logic to follow
// Make an API call to follow the user with the provided userId
      // Use axios or your preferred HTTP library to send the request
      axios
          .post(`${BASE_URL}/follow`, { following_id: user_id }, { headers })
          .then((response) => {
            // Handle success
            if (response?.data?.status === 200) {
              setLoading(false)
              setIsFollowing(!isFollowing);

//Update Redux
              UserFetch()
                  .then((response) => {
                    const user_data = response.data.user;
                    // console.log(user_data, "user data");
                    dispatch(addUser(user_data));
                  })
                  .catch((error) => {
                    setLoading(false)
                    console.error("Error fetching user:", error);
                  });
              toast.success('Followed Successfully!', {
                position: toast.POSITION.TOP_CENTER
              });
            }
          })
          .catch((error) => {
            setLoading(false)
          });
    }
    // Toggle the following state

  };

  return (
    <>
      {headerComponent}
      {loading && (
          <div className={loading_component.loadingOverlay}>
            <div className={loading_component.loadingSpinner}></div>
          </div>
      )}
      <div className={styles.Profile_Container}>
        {/* top of profile */}
        <div className={styles.main_pv_container}>
          <div className={styles.pv_top_header}>
            {/*<img*/}
            {/*  className={styles.profile_backgound}*/}
            {/*  src={profile_Header_Img}*/}
            {/*  alt="Main background Img"*/}
            {/*/>*/}

            <img
                src={userData?.profile_background_image ? STORAGE_URL + userData?.profile_background_image : profile_Header_Img}
                alt="Main background Img"
                className={styles.profile_top_content}
            />

            <div >
              {/*-----------------PROFILE IMG --------------------- */}
              <div className={styles.profile_top_left_content}>
                {/*<img src={profile_img} alt="Profile Image" />*/}
                {userData?.image ? (
                    <img src={`${STORAGE_URL}${userData.image}`} alt="Profile Image" style={{ width: '90px', height: '90px', borderRadius: '10%' }} />
                ) : (
                    <img src={avatarImage} alt="Dummy Image" style={{ width: '90px', height: '90px', borderRadius: '10%' }} />
                )}

              </div>
             {/*----------------- PROFILE RIGHT SIDE ICONS ----------- */}
              <div className={styles.profile_top_right_content}>
                <div className={`${styles.profile_name_heading} d-flex align-items-center justify-content-between`}>
                  <div>
                    <h4>{userData?.firstname} {userData?.lastname}</h4>
                  </div>
                  <div style={{ marginLeft: '10px' }}>
                    <button className={styles.follow_button} onClick={handleFollowUnfollow}>
                      {isFollowing ? 'Unfollow' : 'Follow'}
                    </button>
                  </div>
                </div>
                <div className={styles.profile_icons}>

                </div>
              </div>
            </div>
          </div>
          <div className={styles.pv_below_content}>
            <div className={styles.pv_left_below}>
              <div className={styles.pv_left_detail}>
                <img src={profile_detail_icon} alt="detail icon" />
                <p>Details</p>
              </div>
              <div className={styles.pv_left_detail_desc}>
                <p>

                  {userData?.about_me}
                </p>
                <hr />

                <div className={styles.pv_left_detail_content1_section}>
                  <p>Location: <span>{userData?.userable?.us_based === 'yes' ?userData?.userable?.location : generateNonUsLocation() }</span></p>
                </div>


                <div className={styles.pv_left_detail_content1_section}>

                  {userData?.current_employee &&(
                      <p>Current Job: <span>{userData?.current_employee}</span></p>
                  )
                  }
                </div>
                {/*<div className={styles.pv_left_detail_content1_section}>*/}
                {/*  /!* <img src={searchSkill} alt="skill icon" /> *!/*/}
                {/*  {userData?.skills.length > 0 && (*/}
                {/*      <p>*/}
                {/*        Skill Set:&nbsp;*/}
                {/*        {userData?.skills.map((skill, index) => (*/}
                {/*            <span key={skill.id} className={styles.pv_blue_content}>*/}
                {/*                                        #{skill.name}*/}
                {/*              {index < userData.skills.length - 1 && ", "}*/}
                {/*                                      </span>*/}
                {/*        ))}*/}
                {/*      </p>*/}
                {/*  )}*/}
                {/*</div>*/}
                <div className={styles.pv_left_detail_content1_section}>
                  {/* <img src={searchSkill} alt="skill icon" /> */}
                  {userData?.experience_details.length > 0 && (
                      <p>
                        Experience Details:&nbsp;
                        {userData?.experience_details.map((experience_detail, index) => (
                            <span key={experience_detail.id} className={styles.pv_blue_content}>
                    {experience_detail.job_title} at {experience_detail.company_name} ({experience_detail.start_year} - {experience_detail.end_year})
                              {index < userData.experience_details.length - 1 && ", "}
                </span>
                        ))}
                      </p>
                  )}
                </div>


                <div className={styles.pv_left_detail_content1_section}>
                  {userData?.education_details.length > 0 && (
                      <p>
                        Education Details:&nbsp;
                        {userData?.education_details.map((education_detail, index) => (
                            <span key={education_detail.id} className={styles.pv_blue_content}>
                                                         {education_detail.degree_name} at {education_detail.institute_name}, Graduated in {education_detail.year_of_graduation}
                              {index < userData.education_details.length - 1 && ", "}
                                                     </span>
                        ))}
                      </p>
                  )}
                </div>
                <div className={styles.pv_left_detail_content1_section}>
                  {/* <img src={searchSkill} alt="skill icon" /> */}
                  {userData?.hobbies.length > 0 && (
                      <p>
                        Hobbies:&nbsp;
                        {userData?.hobbies.map((hobbie, index) => (
                            <span key={hobbie.id} className={styles.pv_blue_content}>
                                                        {hobbie.name}
                              {index < userData.hobbies.length - 1 && ", "}
                                                      </span>
                        ))}
                      </p>
                  )}
                </div>
              </div>


            </div>

            <div className={styles.pv_right_below}>

              {/*--------------------POST COMPONENTS--------------- */}
              <MyPosts userData={userData} user_id={user_id} />

            </div>

          </div>
        </div>
        {userable_type === "App\\Models\\Employer" ? (
            <EmployerSideBars
                dashboardCheck ={false}
                notificationCounter={notificationCounter}
            />
        ):(
            <JobSeekerSiderBar
                dashboardCheck ={false}
                notificationCounter={notificationCounter}
            />
        )}
      </div>
    </>
  );
};

export default ProfileView;
